import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import { DetailPettyCashAccountabilityParams } from "domains/entity/main/disi";
import DetailPettyCashAccountabilityResult from "domains/entity/main/disi/DetailPettyCashAccountabilityResult";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";

type iState = {
    loading: boolean;
    error: any;
    data: DetailPettyCashAccountabilityResult
}

const initialData = {
    data: [],
    pageLimit: 0,
    totalRows: 0
}

const initialState: iState = {
    data: initialData,
    error: "",
    loading: false
}

export const detailPettyCashAccountabilityDataAction = createAsyncThunk("detail-petty-cash-accountability-action", async (args: DetailPettyCashAccountabilityParams, { rejectWithValue }) => {
    try {

        const useCase = new DetailPettyCashUseCase(new DisiApi())

        return await useCase.getDetailPettyCashAccountabilityData(args)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const detailPettyCashAccountabilitySlice = createSlice({
    name: "detail-petty-cash-accountability-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(detailPettyCashAccountabilityDataAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(detailPettyCashAccountabilityDataAction.fulfilled, (state, action) => {
            const { data = [], pageLimit = 0, totalRows = 0 } = action.payload

            state.loading = false;
            state.data = { data, pageLimit, totalRows }
            state.error = ""
        })
        builder.addCase(detailPettyCashAccountabilityDataAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
            state.data = initialData
        })
    }
})

const detailPettyCashAccountabilityReducer = detailPettyCashAccountabilitySlice.reducer

export default detailPettyCashAccountabilityReducer