import { SelectChangeEvent } from "@mui/material/Select";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import MainApi from "data/main/mainApi";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { useSelector } from "react-redux";

//modal-filter

const useOtotransBulkViewModell = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // React.useEffect(()=> {
  //   dispatch(fetchOtotransBulkDisbursement({
  //       fromDate: '2022-01-01',
  //       toDate: '2022-01-31',
  //       status: 'ALL',
  //       partnerId: 1,
  //       pageSize: 10,
  //       pageNumber: 1,
  //       sortBy: 'trackingReff',
  //       dateType: 'createdDate'
  //   }))
  // },[])

  //   const [data, setData] = React.useState<OtotransBulkDisbursementUseCase>(
  // ototransBulkDisbursementUseCase
  //   );

  const dateNow = new Date();

  const day =
    dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : dateNow.getDate();

  const month =
    dateNow.getMonth() + 1 < 10
      ? `0${dateNow.getMonth() + 1}`
      : dateNow.getMonth() + 1;

  const year = dateNow.getFullYear();

  const dateNowFormated = `${day}-${month}-${year}`;

  const [mainApi] = React.useState<MainApi>(new MainApi());

  const [dateType, setDateType] = React.useState("createdDate");

  const fetchData = async (
    pageSize: number,
    page = 1,
    status = "ALL",
    partnerId = "ALL",
    startDate = dateNowFormated,
    endDate = dateNowFormated,
    sortBy = "trackingReff",
    dateType = "createdDate"
  ) => {
    await dispatch(
      // fetchOtotransBulkDisbursement({
      //   fromDate: "2022-01-01",
      //   toDate: "2022-01-31",
      //   status: "ALL",
      //   partnerId: 1,
      //   pageSize: 10,
      //   pageNumber: 1,
      //   sortBy: "trackingReff",
      //   dateType: "createdDate",
      //   // startDate,
      //   // endDate,
      //   // partnerId,
      //   // status,
      //   // pageSize,
      //   // page,
      //   // sortBy,
      //   // dateType
      // })
    );
    // await data.getOtotransBulk(
    //   startDate,
    //   endDate,
    //   partnerId,
    //   status,
    //   pageSize,
    //   page + 1,
    //   sortBy,
    //   dateType
    // );
    // setData(
    //   new OtotransBulkDisbursementUseCase(data.getRepository(), data.getData())
    // );
  };

  const [status, setStatus] = React.useState("ALL");

  const [sortBy, setSortBy] = React.useState("trackingReff");

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeDateType = (event: SelectChangeEvent) => {
    setDateType(event.target.value as string);
  };

  const [page, setPage] = React.useState<number>(1);

  const [pageSize, setPageSize] = React.useState<number>(6);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [startDate, setStartdate] = React.useState<Date>(new Date());

  const [endDate, setEndate] = React.useState<Date>(new Date());

  const [partnerId, setPartnerId] = React.useState("ALL");

  const handleChangePartner = (event: SelectChangeEvent) => {
    setPartnerId(event.target.value as string);
  };

  interface column {
    field: string;
    headerName: string;
    flex: number;
    minWidth: number;
  }

  const columns: column[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "trackingReff",
      headerName: "ID Transaksi",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "partnerId",
      headerName: "Tanggal & Waktu",
      flex: 1,
      minWidth: 200,
    },
    { field: "createdDate", headerName: "Partner ID", flex: 1, minWidth: 200 },
    {
      field: "settleDate",
      headerName: "Nama Transaksi",
      flex: 1,
      minWidth: 200,
    },
    { field: "customerId", headerName: "Deskripsi", flex: 1, minWidth: 200 },
    {
      field: "accountName",
      headerName: "Total Penerima",
      flex: 1,
      minWidth: 200,
    },
    { field: "bankName", headerName: "Berhasil", flex: 1, minWidth: 200 },
    { field: "bankAccount", headerName: "Gagal", flex: 1, minWidth: 200 },
    { field: "amount", headerName: "Tertunda", flex: 1, minWidth: 200 },
    {
      field: "custRefNumber",
      headerName: "Jumlah Total",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "Total",
      headerName: "Jumlah Total Gagal",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "Jumlah",
      headerName: "Jumlah Total Tertunda",
      flex: 1,
      minWidth: 200,
    },
    { field: "status", headerName: "Status", flex: 1, minWidth: 200 },
    { field: "action", headerName: "Aksi", flex: 1, minWidth: 200 },
  ];

  const clearFilter = () => {
    setStartdate(new Date());
    setEndate(new Date());
    setPartnerId("ALL");
    setStatus("ALL");
    const startDay =
      startDate.getDate() < 10
        ? `0${startDate.getDate()}`
        : startDate.getDate();
    const startMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    const endDay =
      endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();
    const endMonth =
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();

    fetchData(
      pageSize,
      page,
      partnerId,
      status,
      `${startDay}-${startMonth}-${year}`,
      `${endDay}-${endMonth}-${endYear}`,
      sortBy,
      dateType
    );
  };

  const onSubmit = (): void => {
    const startDay =
      startDate.getDate() < 10
        ? `0${startDate.getDate()}`
        : startDate.getDate();
    const startMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    const endDay =
      endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();
    const endMonth =
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();

    fetchData(
      pageSize,
      page,
      partnerId,
      status,
      `${startDay}-${startMonth}-${year}`,
      `${endDay}-${endMonth}-${endYear}`,
      sortBy,
      dateType
    );
  };

  React.useEffect(() => {
    const startDay =
      startDate.getDate() < 10
        ? `0${startDate.getDate()}`
        : startDate.getDate();
    const startMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    const endDay =
      endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();
    const endMonth =
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    fetchData(
      pageSize,
      page,
      partnerId,
      status,
      `${startDay}-${startMonth}-${year}`,
      `${endDay}-${endMonth}-${endYear}`,
      sortBy,
      dateType
    );
  }, [pageSize, page]);
  const { data, loading, error } = useAppSelector(
    (state) => state.main.ototrans.partner
  );
  console.log(data);

  const exportOtotrans = async (
    status = "ALL",
    partnerId = "ALL",
    startDate = dateNowFormated,
    endDate = dateNowFormated,
    sortBy = "trackingReff",
    dateType = "createdDate"
  ) => {
    await mainApi.exportOtotransTransaksiList(
      startDate,
      endDate,
      partnerId,
      status,
      sortBy,
      dateType
    );
  };

  const onExpor = () => {
    const startDay =
      startDate.getDate() < 10
        ? `0${startDate.getDate()}`
        : startDate.getDate();
    const startMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    const endDay =
      endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();
    const endMonth =
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    exportOtotrans(
      partnerId,
      status,
      `${startDay}-${startMonth}-${year}`,
      `${endDay}-${endMonth}-${endYear}`,
      sortBy,
      dateType
    );
  };

  return {
    // fetchData,
    data,
    status,
    handleChangeStatus,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    setOpen,
    handleOpen,
    handleClose,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    clearFilter,
    setStatus,
    partnerId,
    setPartnerId,
    handleChangePartner,
    onSubmit,
    columns,
    onExpor,
    t,
    dateType,
    setDateType,
    handleChangeDateType,
    loading,
    error,
  };
};

export default useOtotransBulkViewModell;
