import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Legend } from 'recharts';

const PieChartComponent = () => {
    const data01 = [
        {
            "name": "Product A",
            "value": 400
        },
        {
            "name": "Product B",
            "value": 300
        },
        {
            "name": "Product C",
            "value": 300
        },
        {
            "name": "Product D",
            "value": 200
        },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    
    return (
        <ResponsiveContainer
            maxHeight={200}
            width="100%"
        >
            <PieChart>
                <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" />
                <Legend layout='vertical' verticalAlign='top' align='right' />
            </PieChart>
        </ResponsiveContainer>
    );
};
export default PieChartComponent;