import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stack,
  Modal,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import useRekonViewModel from "./SetorkuRekonViewModel";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import ModalComponent from "presentation/components/ModalComponent";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

export const SetorkuRekonComponent = () => {
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    handleOpen,
    handleClose,
    startDate,
    setStartdate,
    endDate,
    setEndate,
    caId,
    handleChangePartner,
    billerId,
    handleChangeStatus,
    kodePembayaran,
    handleChange,
    onExpor,
    clearFilter,
    onSubmit,
    columns, 
    t,
    formFilterData,
    totalTransaction,
    totalAmount, control, register, handleSubmit
  } = useRekonViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("setorku.rekon.reconciliation_list_header")}
                </Typography>

                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}
                >
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                      }}
                      onClick={handleOpen}
                    >
                      {t("setorku.rekon.filter_button")}
                    </Button>
                      <ModalContainer
                        open={open}
                        onClose={handleClose}
                        header={t("setorku.rekon.filter_header")}
                        subHeader={t("setorku.rekon.filter_subheader")}
                        applyButton={handleSubmit(onSubmit)}
                        cancelButton={clearFilter}
                        >
                          <Stack spacing={2} direction="column" sx={
                            {
                              mb: "1.5rem",
                            }
                          } >
                          <DateRangeComponent
                            startDate={startDate}
                            setStartDate={setStartdate}
                            endDate={endDate}
                            setEndDate={setEndate}
                          />
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {t("setorku.rekon.partner_label")}
                            </InputLabel>
                            <Controller
                              name="caName"
                              control={control}
                              render={({ field }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              defaultValue="ALL"
                              label={t("setorku.rekon.partner_label")}
                            >
                              <MenuItem value='ALL'>
                                {t("setorku.rekon.partner_all")}
                              </MenuItem>
                              {data.partner.data.data.map((item, index) => (
                                <MenuItem key={index} value={item.partnerId}>{item.partnerId}</MenuItem>
                              ))}
                            </Select>
                              )}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {t("setorku.rekon.status_label")}
                            </InputLabel>
                            <Controller
                              name="billerName"
                              control={control}
                              render={({ field }) => (
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              defaultValue="ALL"
                              label={t("setorku.rekon.status_label")}
                            >
                              <MenuItem value='ALL'>
                                {t("setorku.rekon.status_all")}
                              </MenuItem>
                              {data.produk.data.data.map((item, index) => (
                                <MenuItem key={index} value={item.productId}>{item.productName}</MenuItem>
                              ))}
                            </Select>
                              )}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Controller
                              name="paymentCode"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  id="outlined-basic"
                                  label={t("setorku.rekon.payment_code")}
                                  variant="outlined"
                                  defaultValue=""
                                  {...field}
                                />
                              )}
                            />
                          </FormControl>
                          </Stack>
                        </ModalContainer>
                  </div>
                  <Button
                    onClick={onExpor}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("setorku.rekon.export_button")}
                  </Button>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  mt: "1rem",
                }}
              >

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: "1rem",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: 400,
                    fontSize: "0.8rem",
                    lineHeight: "150%",
                    color: "#000000",
                  }}
                >
                  Jumlah Transaksi: {totalTransaction}
                  <br />
                  Total Transaksi: {totalAmount}
                </Typography>
                </Stack>
              {data.rekonsiliasi.loading ? (
                <h1>Loading ...</h1>
              ) : (
                <DataTable
                  {...{
                    ...data.rekonsiliasi.data,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
                </Stack>
            </Grid>
          </Box>
        </div>
      </Container>
    </LayoutComponent>
  );
};

export default SetorkuRekonComponent;
