import { combineReducers } from "@reduxjs/toolkit";
import setorkuRekonListReducer from "./recon";
import setorkuTransaksiListReducer from "./transaksi";
import setorkuDaftarKodeReducer from "./setorkuDaftarKode";
import setorkuPartnerReducer from "./setorkuPartner";
import setorkuProdukReducer from "./product";

const setorkuReducer = combineReducers({
    daftarKode: setorkuDaftarKodeReducer,
    rekonsiliasi: setorkuRekonListReducer,
    transaksi: setorkuTransaksiListReducer,
    partner: setorkuPartnerReducer,
    produk: setorkuProdukReducer
})

export default setorkuReducer