import React from "react";
import { useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import {
    merchantDepositAction,
    initialFilterForm,
    resetUserDeposit,
    MerchantDepositState,
} from "utils/store/main/disi/MerchantDeposit";
import {
    merchantDepositAddAction,
    resetMerchantDepositActions,
} from "utils/store/main/disi/MerchantDepositActions";
import { merchantDepositBanksAction } from "utils/store/main/disi/MerchantDepositBank";
import { merchantDepositBanksVaAction } from "utils/store/main/disi/MerchantDepositBankVA";
import { merchantDepositPartnerAction } from "utils/store/main/disi/MerchantDepositPartner";
import { merchantDepositSaldoAction } from "utils/store/main/disi/MerchantDepositSaldo";
import { showAddModal, showFilterModal } from "utils/store/global/modal";
import currencyFormatter from "utils/helper/currencyFormatter";
import { Link } from "@mui/material";
import { useForm } from "react-hook-form";
import MerchantDepositUseCase from "domains/interactor/main/disi/MerchantDeposit";
import DisiApi from "datas/disi/DisiApi";
import { exportCSV } from "domain/hooks/exportCSV";
import useRole from "domain/hooks/useRole";
import { provinceListAction } from "utils/store/global/address";
import useSnackbar from "domain/hooks/useSnackbar";

const initialAddFormState = {
    partnerNm: "",
    merchantId: "",
    merchantNm: "",
    email: "",
    phone: "",
    address: "",
    province: "",
    city: "",
    districts: "",
    urbanVillage: "",
    postalCode: "",
    bankCode: "",
    bankNm: "",
    bankAccount: "",
    bankVa: "",
};

const DisiMerchantDepositViewModel = () => {
    const { t } = useTranslation();

    const role = useRole("merchant-deposit");

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {
        merchantDepositBank,
        merchantDepositBankVa,
        merchantDepositSaldo,
        merchantDepositPartner,
        merchantDeposit,
        merchantDepositActions,
    } = useAppSelector((state) => state.main.disi);

    const modal = useAppSelector((state) => state.global.modal);

    const filterForm = useForm({
        defaultValues: merchantDeposit.savedState.filterForm,
    });

    const addForm = useForm({
        defaultValues: initialAddFormState,
    });

    const { getValues: getFilterValues } = filterForm;

    const { setValue: setAddValue } = addForm;

    const resetFilterValue = () => {
        filterForm.reset(initialFilterForm);
    };

    const resetAddFormValue = () => {
        addForm.reset(initialAddFormState);
        setPartner();
    };

    const setPartner = () => {
        const { data } = merchantDepositPartner;
        if (data.length) {
            setAddValue("partnerNm", data[0].name);
        }
    };

    const handleModal = (state: string) => {
        switch (state) {
            case "add":
                if (!modal.add) {
                    resetAddFormValue();
                }
                dispatch(showAddModal(!modal.add));
                break;
            case "filter":
                dispatch(showFilterModal(!modal.filter));
                break;
        }
    };

    const onAddSubmit = (data: typeof initialAddFormState) => {
        dispatch(merchantDepositAddAction(data));
        handleModal("add");
    };

    const onFilterSubmit = () => {
        fetchList();
        handleModal("filter");
    };

    const [page, setPage] = React.useState(merchantDeposit.savedState.page);

    const [pageSize, setPageSize] = React.useState(
        merchantDeposit.savedState.pageSize
    );

    const columns: GridColDef[] = [
        {
            field: "active",
            headerName: t("disi.merchant_deposit.table_status") ?? "Status",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "merchantId",
            headerName:
                t("disi.merchant_deposit.table_merchant_id") ?? "ID Merchant",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "partnerNm",
            headerName:
                t("disi.merchant_deposit.table_partner_name") ?? "Nama Partner",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "merchantNm",
            headerName:
                t("disi.merchant_deposit.table_merchant_name") ??
                "Nama Merchant",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "bankNm",
            headerName:
                t("disi.merchant_deposit.table_bank_name") ?? "Nama Bank",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "merchantSaldo",
            headerName: t("disi.merchant_deposit.table_balance") ?? "Saldo",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => currencyFormatter(row.row.merchantSaldo),
        },
        {
            field: "aksi",
            headerName: t("disi.merchant_deposit.table_actions") ?? "Aksi",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => (
                <Link
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={(e) => {
                        navigate("/Dashboard/disi/detail-merchant-deposit", {
                            state: {
                                merchantNm: row.row.merchantNm,
                                partnerNm: row.row.partnerNm,
                                savedState: {
                                    filterForm: filterForm.getValues(),
                                    page,
                                    pageSize,
                                } as MerchantDepositState,
                            },
                        });
                    }}
                >
                    Detail
                </Link>
            ),
        },
    ];

    const fetchList = () => {
        dispatch(
            merchantDepositAction({
                pageNumber: page + 1,
                pageSize,
                merchantNm: getFilterValues("merchantNm"),
                partnerNm: getFilterValues("partnerNm"),
                status: getFilterValues("status"),
            })
        );
    };

    const fetchPartner = () => {
        dispatch(merchantDepositPartnerAction());
    };

    const fetchBalance = () => {
        dispatch(merchantDepositSaldoAction());
    };

    const fetchBank = () => {
        dispatch(merchantDepositBanksAction());
        dispatch(merchantDepositBanksVaAction());
    };

    const onExportList = () => {
        const useCase = new MerchantDepositUseCase(new DisiApi());

        const params = {
            partnerNm: getFilterValues("partnerNm"),
            merchantNm: getFilterValues("merchantNm"),
            status: getFilterValues("status"),
        };

        useCase.exportMerchantDepositList(params).then((csv) => {
            exportCSV(csv, "merchant-deposit-list");
        });
    };

    React.useEffect(() => {
        setPartner();
    }, [merchantDepositPartner.data, setPartner]);

    React.useEffect(() => {
        fetchList();
    }, [page, pageSize]);

    React.useEffect(() => {
        fetchBalance();
        return () => {
            dispatch(resetUserDeposit());
        };
    }, []);

    React.useEffect(() => {
        if (role.isAdd) {
            fetchBank();
            fetchPartner();
        }
    }, [role]);

    const { loading, error, result } = merchantDepositActions;

    React.useEffect(() => {
        if (!loading && result) {
            dispatch(
                merchantDepositAction({
                    pageNumber: 1,
                    pageSize,
                    merchantNm: "",
                    partnerNm: "",
                    status: "",
                })
            );
        }
    }, [loading, result]);

    useSnackbar(
        {
            error,
            loading,
            result,
        },
        resetMerchantDepositActions
    );

    React.useEffect(() => {
        if (modal.add) {
            dispatch(provinceListAction());
        }
    }, [modal.add]);

    return {
        t,
        columns,
        merchantDeposit,
        merchantDepositBank,
        merchantDepositBankVa,
        merchantDepositSaldo,
        merchantDepositPartner,
        page,
        setPage,
        pageSize,
        setPageSize,
        onFilterSubmit,
        resetFilterValue,
        filterForm,
        onExportList,
        addForm,
        resetAddFormValue,
        onAddSubmit,
        role,
        modal,
        handleModal,
    };
};

export default DisiMerchantDepositViewModel;
