import LayoutComponent from 'presentation/layout/Layout'
import React from 'react'

const PageNotFoundComponent = () => {
  return (
    <LayoutComponent>
        <h1>PageNotFound</h1>
    </LayoutComponent>
  )
}

export default PageNotFoundComponent