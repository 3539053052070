import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { getToken, getUser } from "utils/storage/LocalStorage";
import { loginAction } from "utils/store/auth/login/login";
import { useForm } from "react-hook-form";

export function useViewModel() {
  const navigate = useNavigate();
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const { control, handleSubmit, formState: {errors} } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { data, error, loading, errorMsg } = useAppSelector((state) => state.auth.login);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onClickSignIn = async (formData: any) => {
    try {
      await dispatch(
        loginAction({
          username: formData.username,
          password: formData.password,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const token = getToken() || ''
    const user = getUser() || ''
    if (token || user) {
      return navigate('/dashboard')
    }
  },[])

  return {
    showPassword,
    handleClickShowPassword,
    errors,
    t,
    onClickSignIn,
    control,
    handleSubmit,
    error,
    errorMsg,
    loading
  };
}
