import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LangChange from "presentation/components/LangChange";
import { useViewModel } from "./LoginViewModel";

const LoginComponent = () => {
    const {
        showPassword,
        handleClickShowPassword,
        errors,
        t,
        onClickSignIn,
        control,
        handleSubmit,
        error,
        errorMsg,
        loading,
    } = useViewModel();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "100%", p: 2 }}
        >
            <Grid
                item
                sx={{
                    maxWidth: "70rem",
                    width: "100%",
                    backgroundColor: "#fff",
                }}
            >
                <Grid
                    container
                    sx={{
                        boxShadow: { sm: "0 0 5px #ddd" },
                        p: "2rem",
                    }}
                >
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        rowSpacing={5}
                        sx={{
                            width: "100% !important",
                            margin: 0,
                            px: "4rem",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                display: "inline-flex",
                                paddingTop: "0!important",
                                paddingBottom: "1rem",
                            }}
                        >
                            <LangChange />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                borderRight: { sm: "1px solid #ddd" },
                                paddingTop: "0 !important",
                            }}
                        >
                            <form onSubmit={handleSubmit(onClickSignIn)}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    component="div"
                                    sx={{ paddingRight: { sm: "3rem" } }}
                                >
                                    <Typography
                                        variant="h4"
                                        component="h1"
                                        sx={{ mb: "1.5rem" }}
                                    >
                                        {t("auth.login")}
                                    </Typography>
                                    <Alert
                                        severity="error"
                                        variant="filled"
                                        sx={{
                                            mb: 2,
                                            display: error ? "flex" : "none",
                                        }}
                                    >
                                        {errorMsg}
                                    </Alert>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        <Controller
                                            name="username"
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Username"
                                                    error={false}
                                                    helperText={error}
                                                />
                                            )}
                                        />
                                        {errors.username && (
                                            <FormHelperText error={true}>
                                                {" "}
                                                {t(
                                                    "error.username_cannot_empty"
                                                )}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                    >
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue=""
                                            render={({
                                                field,
                                                formState: { errors },
                                            }) => (
                                                <>
                                                    <InputLabel
                                                        htmlFor="adornment-password"
                                                        error={Boolean(
                                                            errors.password
                                                        )}
                                                    >
                                                        {t("auth.password")}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        {...field}
                                                        id="adornment-password"
                                                        error={false}
                                                        type={
                                                            showPassword
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        label={t(
                                                            "auth.password"
                                                        )}
                                                        name="password"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={
                                                                        handleClickShowPassword
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                        {errors.password && (
                                            <FormHelperText error={true}>
                                                {" "}
                                                {t(
                                                    "error.password_cannot_empty"
                                                )}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                        sx={{
                                            py: "0.8rem",
                                            mt: 2,
                                            width: "80%",
                                            marginInline: "auto",
                                            background: "#286353",
                                            color: "white",
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            t("auth.login")
                                        )}
                                    </Button>
                                </Box>
                            </form>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                display: { xs: "none", sm: "block" },
                                paddingTop: "0 !important",
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    justifyContent: "center",
                                    paddingLeft: { sm: "3rem" },
                                    minHeight: 450,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    component="h2"
                                    sx={{ mb: "1.5rem" }}
                                >
                                    {t("auth.help_header")}
                                </Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            {t("auth.login_step_header")}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            sx={{
                                                fontSize: "x-small",
                                                fontWeight: "bold",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "auth.login_step_detail"
                                                ),
                                            }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Typography
                                    sx={{
                                        marginTop: { sm: "3rem" },
                                        mb: { sm: "0.5rem" },
                                        fontSize: "small",
                                    }}
                                >
                                    {t("auth.need_help_question")}
                                </Typography>
                                <Typography
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        fontSize: "small",
                                    }}
                                >
                                    <EmailIcon
                                        sx={{ fontSize: "18px", pr: "0.5rem" }}
                                    />
                                    {t("auth.contact_email")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LoginComponent;
