import React from "react";
import {
    Button,
    Typography,
    Card,
    CardContent,
    Container,
    Box,
    Skeleton,
    CircularProgress,
} from "@mui/material";
import LayoutComponent from "presentation/layout/Layout";
import DataTable from "presentation/components/DataTable";
import currencyFormatter from "utils/helper/currencyFormatter";
import DetailMerchantDepositViewModel from "./DetailMerchantDepositViewModel";
import { FormProvider } from "react-hook-form";
import FormFilter from "./components/FilterForm";

const DetailMerchantDepositComponent = () => {
    const {
        t,
        detailMerchantDepositHeader,
        detailMerchantDepositBody,
        detailMerchantDepositTotalSaldo,
        page,
        pageSize,
        setPage,
        setPageSize,
        columns,
        filterForm,
        filterModal,
        handleFilterModal,
        onSubmitFilter,
        handleResetForm,
        onExportClick,
    } = DetailMerchantDepositViewModel();

    return (
        <LayoutComponent>
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
            >
                <Box
                    display="grid"
                    sx={{
                        py: "1rem",
                        gap: "1rem",
                        width: "95%",
                        height: "fit-content",
                        "& .MuiCard-root .MuiCardContent-root": {
                            p: "1rem",
                        },
                    }}
                >
                    <Card>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto",
                                    gridAutoFlow: "row",
                                    gap: "1rem 0",
                                    p: {
                                        xs: 0,
                                        sm: "1rem",
                                    },
                                }}
                            >
                                <Typography
                                    className="headerName"
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        mb: "1.5rem",
                                        fontWeight: 600,
                                        gridColumn: {
                                            xs: "1 / span 2",
                                            sm: "1",
                                        },
                                    }}
                                >
                                    {t("disi.detail_merchant_deposit.header")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        gap: "1rem",
                                        "& .MuiButtonBase-root": {
                                            width: "100%",
                                        },
                                        gridColumn: {
                                            xs: "1 / span 2",
                                            sm: "span 1",
                                        },
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={handleFilterModal}
                                    >
                                        Filter
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={onExportClick}
                                    >
                                        Export
                                    </Button>
                                </Box>
                                <Typography
                                    fontWeight={800}
                                    sx={{
                                        color: "#ff9800",
                                        fontSize: "1.25rem",
                                        gridColumn: "1 / span 2",
                                    }}
                                >
                                    {`${t(
                                        "disi.detail_merchant_deposit.balance_remaining"
                                    )}: `}

                                    {detailMerchantDepositTotalSaldo.loading ? (
                                        <CircularProgress
                                            size={16}
                                            sx={{ ml: ".3rem" }}
                                        />
                                    ) : (
                                        `${currencyFormatter(
                                            detailMerchantDepositTotalSaldo.data
                                        )}`
                                    )}
                                </Typography>
                                <Typography
                                    fontWeight={800}
                                    sx={{
                                        gridColumn: "1 / span 2",
                                    }}
                                >
                                    {t(
                                        "disi.detail_merchant_deposit.information"
                                    )}
                                </Typography>
                                <Box
                                    display="grid"
                                    sx={{
                                        gap: "1rem",
                                        gridTemplateColumns: {
                                            xs: "auto 1fr",
                                            sm: "repeat(2, auto 1fr)",
                                            md: "repeat(3, auto 1fr)",
                                        },
                                        gridAutoFlow: "row",

                                        "& > .MuiTypography-root": {
                                            fontSize: {
                                                xs: "0.625rem",
                                                sm: ".75rem",
                                                md: "1rem",
                                            },
                                        },
                                    }}
                                >
                                    {detailMerchantDepositHeader.loading ? (
                                        <>
                                            <Skeleton
                                                sx={{
                                                    gridColumn: {
                                                        xs: "span 2",
                                                        sm: "span 2",
                                                    },
                                                }}
                                            />
                                            <Skeleton
                                                sx={{
                                                    gridColumn: {
                                                        xs: "span 2",
                                                        sm: "span 4",
                                                    },
                                                }}
                                            />
                                            <Skeleton
                                                sx={{
                                                    gridColumn: {
                                                        xs: "span 2",
                                                        sm: "span 2",
                                                    },
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Typography>
                                                {t(
                                                    "disi.detail_merchant_deposit.partner_name"
                                                )}
                                            </Typography>
                                            <Typography>
                                                :{" "}
                                                {
                                                    detailMerchantDepositHeader
                                                        .data.partnerNm
                                                }
                                            </Typography>

                                            <Typography>
                                                {t(
                                                    "disi.detail_merchant_deposit.bank_name"
                                                )}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    gridColumn: {
                                                        xs: "unset",
                                                        sm: "span 3",
                                                    },
                                                }}
                                            >
                                                :{" "}
                                                {
                                                    detailMerchantDepositHeader
                                                        .data.bankNm
                                                }
                                            </Typography>

                                            <Typography>
                                                {t(
                                                    "disi.detail_merchant_deposit.merchant_name"
                                                )}
                                            </Typography>
                                            <Typography>
                                                :{" "}
                                                {
                                                    detailMerchantDepositHeader
                                                        .data.merchantNm
                                                }
                                            </Typography>
                                            <Typography>
                                                {t(
                                                    "disi.detail_merchant_deposit.account_number"
                                                )}
                                            </Typography>
                                            <Typography>
                                                :{" "}
                                                {
                                                    detailMerchantDepositHeader
                                                        .data.bankAccount
                                                }
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        gridColumn: "1 / span 2",
                                    }}
                                >
                                    <DataTable
                                        {...{
                                            columns,
                                            page,
                                            pageSize,
                                            setPage,
                                            setPageSize,
                                            data: detailMerchantDepositBody.data
                                                .data,
                                            totalRows:
                                                detailMerchantDepositBody.data
                                                    .totalRows,
                                            loading:
                                                detailMerchantDepositBody.loading,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <FormProvider {...filterForm}>
                    <FormFilter
                        isOpen={filterModal}
                        handleReset={handleResetForm}
                        onSubmit={onSubmitFilter}
                        handleClose={handleFilterModal}
                    />
                </FormProvider>
            </Container>
        </LayoutComponent>
    );
};

export default DetailMerchantDepositComponent;
