import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import EditIcon from "@mui/icons-material/Edit";
import { GridColDef } from "@mui/x-data-grid";
import { editGroupAction, groupListAction } from "utils/store/main/ppob/group";
import { useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";

//modal-filter

const useSetorkuDashboardViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      ppobId: "",
      ppobName: "",
      isActive: "1",
    },
  });

  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [modalEdit, setModalEdit] = React.useState(false);

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "ppobId",
      headerName: t("ppob.group.id_ppob"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "ppobName",
      headerName: t("ppob.group.ppob_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: t("ppob.group.is_active"),
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <div>{row.row.isActive === "1" ? "Aktif" : "Tidak Aktif"}</div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              color="primary"
              onClick={() => showModalEdit(row.row)}
            >
              <EditIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const showModalEdit = (data: {
    ppobId: string;
    ppobName: string;
    isActive: string;
  }) => {
    setValue("ppobId", data.ppobId);
    setValue("ppobName", data.ppobName);
    setValue("isActive", data.isActive);
    setModalEdit(true);
  };

  const submitEdit = async (data: any) => {
    console.log(data);
    await dispatch(editGroupAction(data));
    fetchGroup();
    setModalEdit(false);
  };

  const fetchGroup = async () => {
    await dispatch(
      groupListAction({
        pageSize: pageSize,
        pageNumber: page,
        filterText: "",
      })
    );
  };

  React.useEffect(() => {
    fetchGroup();
  }, [page, pageSize]);

  return {
    data: selector,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    modalEdit,
    setModalEdit,
    t,
    handleSubmit,
    control,
    submitEdit,
  };
};

export default useSetorkuDashboardViewModel;
