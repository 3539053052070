import React from "react";
import { useAppDispatch } from "./redux-hook";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { openSnackBar } from "utils/store/snackbar";

type SnackbarProps = {
    loading?: boolean;
    result?: string;
    error?: any;
};

const useSnackbar = (
    { loading = false, result = "", error = "" }: SnackbarProps,
    reset: ActionCreatorWithoutPayload
) => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!loading && (result || error)) {
            const message = result ? result : error;
            dispatch(
                openSnackBar({
                    message,
                    severity: error ? "error" : "success",
                })
            );

            setTimeout(() => {
                dispatch(reset());
            }, 3000);
        }
    }, [loading, result, error]);
};

export default useSnackbar;
