import { combineReducers } from 'redux';
import alertReducer from './alert';
import modalReducer from './modal';
import addressReducer from './address';
import navbarReducer from './navbar';

const globalReducer = combineReducers({
    alert: alertReducer,
    modal: modalReducer,
    address: addressReducer,
    navbar: navbarReducer,
});

export default globalReducer;