import { AddressData, VillageData } from "domains/entity/main/AddressResult";
import AddressRepository from "domains/repository/main/address";
import httpCommon from "datas/httpCommon";

export default class AddressApi implements AddressRepository {
    async getProvinces(): Promise<AddressData[]> {
        return await httpCommon.get('/global/postal/province').then(res => res.data)
    }

    async getDistricts(id: string): Promise<AddressData[]> {
        return await httpCommon.get(`/global/postal/city?id=${id}`).then(res => res.data)
    }

    async getSubDistricts(id: string): Promise<AddressData[]> {
        return await httpCommon.get(`/global/postal/subdistrict?id=${id}`).then(res => res.data)
    }

    async getVillages(id: string): Promise<VillageData[]> {
        return await httpCommon.get(`/global/postal/village?id=${id}`).then(res => res.data)
    }
}