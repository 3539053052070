import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { MerchantDepositResult, MerchantDepositListArgs, UserDepositFilterForm } from "domains/entity/main/disi";
import DisiApi from "datas/disi/DisiApi";
import MerchantDepositUseCase from "domains/interactor/main/disi/MerchantDeposit";

export type MerchantDepositState = {
    filterForm: UserDepositFilterForm,
    page: number;
    pageSize: number;
}

type iState = {
    data: MerchantDepositResult;
    loading: boolean;
    result: string;
    error: any;
    savedState: MerchantDepositState
}

export const initialFilterForm: UserDepositFilterForm = {
    merchantNm: "",
    partnerNm: "",
    status: "ALL"
}

const initialData = {
    data: [],
    pageLimit: 0,
    totalRows: 0
}

const initialState: iState = {
    data: initialData,
    loading: false,
    result: "",
    error: "",
    savedState: {
        filterForm: initialFilterForm,
        page: 0,
        pageSize: 6
    }
}

export const merchantDepositAction = createAsyncThunk('merchant-deposit-list', async (args: MerchantDepositListArgs, { rejectWithValue }) => {
    try {

        const { pageNumber, pageSize, merchantNm, partnerNm, status } = args

        const useCase = new MerchantDepositUseCase(new DisiApi())
        const result = await useCase.getMerchantDepositList(pageSize, pageNumber, merchantNm, partnerNm, status)

        return result
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error;
        }
        return rejectWithValue(response.data)
    }
})


const merchantDepositSlice = createSlice({
    name: "merchant-deposit-slice",
    initialState,
    reducers: {
        setUserDepositSavedState: (state, action) => {
            state.savedState = action.payload
        },
        resetUserDeposit: () => {
            return initialState
        },
    },
    extraReducers: builder => {
        builder.addCase(merchantDepositAction.pending, state => {
            state.loading = true;
            state.error = ""
            state.result = ""
        })
        builder.addCase(merchantDepositAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = ""
            state.result = ""
        })
        builder.addCase(merchantDepositAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
            state.data = initialData
        })
    }
})

const merchantDepositReducer = merchantDepositSlice.reducer

export const { resetUserDeposit, setUserDepositSavedState } = merchantDepositSlice.actions

export default merchantDepositReducer 