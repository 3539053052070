import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import useOtotransPartnerViewModel from "./PartnerViewModel";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

export const OtotransPartnerComponent = () => {
  const {
    data,
    status,
    handleChangeStatus,
    page,
    setPage,
    pageSize,
    setPageSize,
    openModalFilter,
    setOpenModalFilter,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    clearFilter,
    partnerId,
    handleChangePartner,
    onSubmit,
    columns,
    onExpor,
    t,
    dateType,
    handleChangeDateType,
  } = useOtotransPartnerViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ototrans.partner.header")}
                </Typography>

                <Stack spacing={2} direction="row" className="headerButton">
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                      }}
                      onClick={() => setOpenModalFilter(true)}
                    >
                      {t("setorku.transaction.filter_button")}
                    </Button>
                    <Modal
                      open={openModalFilter}
                      onClose={() => setOpenModalFilter(false)}
                    >
                      <Box sx={style} className="ModelFilter">
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          {t("setorku.transaction.filter_header")}
                        </Typography>
                        <Typography
                          id="modal-modal-description"
                          sx={{
                            fontSize: "0.5rem",
                            lineHeight: "166%",
                            color: "#6E6E6E",
                            mb: "1rem",
                          }}
                        >
                          {t("setorku.transaction.filter_subheader")}
                        </Typography>
                        <Divider
                          variant="middle"
                          sx={{
                            mt: "0 !important",
                            mr: "0 !important",
                            ml: "0 !important",
                            mb: "1rem !important",
                          }}
                        />
                        <FormControl fullWidth sx={{ mb: "1rem" }}>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{ background: "white" }}
                          >
                            Tipe Tanggal
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dateType}
                            label="Tipe Tanggal"
                            onChange={handleChangeDateType}
                          >
                            <MenuItem value="createdDate">
                              Tanggal Dibuat
                            </MenuItem>
                            <MenuItem value="settleDate">
                              Tanggal Penyelesaian
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack
                            spacing={3}
                            direction="row"
                            className="DateModal"
                            sx={{
                              alignItems: "center",
                              mb: "1rem",
                            }}
                          >
                            <DateRangeComponent
                              startDate={startDate}
                              endDate={endDate}
                              setStartDate={setStartdate}
                              setEndDate={setEndate}
                              sx={{ my: "1rem" }}
                            />
                          </Stack>
                        </LocalizationProvider>
                        <FormControl fullWidth sx={{ mb: "1rem" }}>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{ background: "white" }}
                          >
                            Partner Via
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={partnerId}
                            label="partnerId"
                            onChange={handleChangePartner}
                          >
                            <MenuItem value="ALL">-- All Partner --</MenuItem>
                            {Array.isArray(data.partner.data)
                              ? data.partner.data.map((item) => {
                                  return (
                                    <MenuItem
                                      key={`${item.name}-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  );
                                })
                              : []}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: "1rem" }}>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{ background: "white" }}
                          >
                            {t("setorku.transaction.status")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChangeStatus}
                          >
                            <MenuItem value="ALL">-- All Status --</MenuItem>
                            <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                            <MenuItem value="FAIL">FAIL</MenuItem>
                          </Select>
                        </FormControl>
                        <Stack spacing={2} direction="row">
                          <Button
                            onClick={clearFilter}
                            variant="text"
                            sx={{
                              color: "rgba(40, 99, 83, 1)",
                              width: "317.5px",
                            }}
                          >
                            {t("setorku.transaction.remove_filter_button")}
                          </Button>
                          <Button
                            onClick={onSubmit}
                            variant="contained"
                            sx={{
                              color: "white",
                              background: "rgba(40, 99, 83, 1) !important",
                              width: "317.5px",
                            }}
                          >
                            {t("setorku.transaction.apply_button")}
                          </Button>
                        </Stack>
                      </Box>
                    </Modal>
                  </div>
                  <Button
                    onClick={onExpor}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("setorku.transaction.export_button")}
                  </Button>
                </Stack>
              </Stack>

              <DataTable
                {...{
                  ...data.transaksi.data,
                  columns,
                  setPage,
                  setPageSize,
                  page,
                  pageSize,
                }}
              />
            </Grid>
          </Box>
        </div>
      </Container>
    </LayoutComponent>
  );
};

export default OtotransPartnerComponent;
