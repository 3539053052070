

const argsToParams = <T,>(args: T) => {
    const params = new URLSearchParams()

    for (const key in args) {
        const value = args[key]

        if (value !== null && value !== undefined) {
            const _value = typeof value !== "string" ? value.toString() : value
            if (_value) params.append(key, _value);
        }
    }

    return params
}

export default argsToParams