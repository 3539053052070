import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { setorkuDaftarKodeAction } from "utils/store/main/setorku/setorkuDaftarKode";
import SetorkuApi from "data/main/setorkuApi";
import dayjs from "dayjs";
import { setorkuPartnerAction } from "utils/store/main/setorku/setorkuPartner";
import { GridColDef } from "@mui/x-data-grid";

//modal-filter

const useMultifinanceRekonTransaksiViewModel = () => {
  const { t }:any = useTranslation();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.main.setorku);

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(6);
  const [modalAddProduct, setModalAddProduct] = React.useState(false);
  const [modalFilter, setModalFilter] = React.useState(false);
  const [modalEditProduct, setModalEditProduct] = React.useState(false);
  const [modalDeleteProduct, setModalDeleteProduct] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [partnerId, setPartnerId] = React.useState("ALL");

  const columns:GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
        field: "idProduct",
        headerName: t('setorku.product.id_product'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "idCompany",
        headerName: t('setorku.product.id_company'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "companyCode",
        headerName: t('setorku.product.company_code'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "companyName",
        headerName: t('setorku.product.company_name'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "prefix",
        headerName: t('setorku.product.prefix'),
        flex: 1,
        minWidth: 200,
      },
    {
      field: "action",
      headerName: t("setorku.product.action"),
      flex: 1,
      minWidth: 200,
      renderCell: () => {
        return(
            <div>
                <EditIcon/>
                <DeleteIcon/>
            </div>
        )
      }
    },
  ];

  return {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    partnerId,
    columns,
    t,
    modalAddProduct,
    setModalAddProduct,
    modalFilter,
    setModalFilter,
    modalEditProduct,
    setModalEditProduct,
    modalDeleteProduct,
    setModalDeleteProduct,
  };
};

export default useMultifinanceRekonTransaksiViewModel;
