const constant = {
    token: "",
    user: "",
    i18nextLng: "",
    firstTime: "",
};

export const saveToken = (value: string) => localStorage.setItem(Object.keys(constant)[0], value)

export const saveUser = (value: string) => localStorage.setItem(Object.keys(constant)[1], value);

export const setLng = (value: string) => {
    localStorage.setItem(Object.keys(constant)[2], value);
    window.location.reload();
}

export const saveFirstTime = (value: string) => localStorage.setItem(Object.keys(constant)[3], value);

export const getToken = () => localStorage.getItem(Object.keys(constant)[0]);

export const getUser = () => localStorage.getItem(Object.keys(constant)[1]);

export const getLng = () => localStorage.getItem(Object.keys(constant)[2]);

export const getFirstTime = () => localStorage.getItem(Object.keys(constant)[3]);

export const removeToken = () => localStorage.removeItem(Object.keys(constant)[0]);

export const removeUser = () => localStorage.removeItem(Object.keys(constant)[1]);

export const removeLng = () => localStorage.removeItem(Object.keys(constant)[2]);