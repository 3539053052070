import React from "react";
import {
    Box,
    Typography,
    Stack,
    IconButton,
    CircularProgress,
    SxProps,
} from "@mui/material";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useTranslation } from "react-i18next";

type FileUploadComponentProps = {
    onChange?: (e: any) => void;
    onCancel?: (e: any) => void;
    sx?: SxProps;
    error?: string;
};

const FileUploadComponent: React.FC<FileUploadComponentProps> = (props) => {
    const { t } = useTranslation();

    const { onChange, onCancel, sx, error } = props;

    const [label, setLabel] = React.useState("");

    const [loading, setLoading] = React.useState(false);

    const handleCancel = (e: any) => {
        if (typeof onCancel !== "function") return;
        setLoading(true);
        onCancel(e);
        setLoading(false);
        setLabel("");
    };

    const handleChange = (e: any) => {
        e.preventDefault();

        if (typeof onChange !== "function") return;

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const { name } = file;
            setLoading(true);
            onChange(file);
            setLoading(false);
            setLabel(name);
        }
    };

    return (
        <>
            {label && (
                <>
                    <Box
                        display="flex"
                        sx={{ justifyContent: "space-between" }}
                    >
                        <Typography
                            sx={{
                                display: "inline-flex",
                                verticalAlign: "middle",
                            }}
                        >
                            <InsertDriveFileRoundedIcon sx={{ mr: ".5rem" }} />
                            {label}
                        </Typography>
                        {/* <IconButton onClick={handleCancel}>
                            <HighlightOffRoundedIcon color="warning" />
                        </IconButton> */}
                    </Box>
                </>
            )}
            <Box
                display="grid"
                sx={{
                    borderStyle: "dashed",
                    borderColor: "#cbd5e1",
                    borderWidth: 2,
                    borderRadius: 2,
                    minHeight: 100,
                    position: "relative",
                    ...sx,
                }}
            >
                {loading ? (
                    <CircularProgress sx={{ margin: "auto" }} />
                ) : (
                    <>
                        <input
                            type="file"
                            style={{
                                width: "100%",
                                height: "100%",
                                zIndex: 10,
                                position: "absolute",
                                opacity: "0",
                            }}
                            onChange={handleChange}
                        />
                        <Stack
                            spacing={1}
                            sx={{ margin: "auto", alignItems: "center" }}
                        >
                            <FileUploadRoundedIcon />
                            <Typography color="inherit">
                                {t("file_upload")}
                            </Typography>
                        </Stack>
                    </>
                )}
                <Typography
                    sx={{
                        position: "absolute",
                        bottom: -24,
                        right: 10,
                        color: "red",
                    }}
                >
                    {error && t(error)}
                </Typography>
            </Box>
        </>
    );
};

export default FileUploadComponent;
