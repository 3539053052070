import axios from 'axios'
import { getUser, getLng, getToken } from 'utils/storage/LocalStorage';

const {REACT_APP_URL_API} = process.env;
const user = JSON.parse(getUser() || "{}");
const token = getToken()
let a = getLng()!
if (!a) {
  a = 'en';
}

const username = user.username || ""

export default axios.create({
  baseURL: REACT_APP_URL_API,
  headers: {
    "Content-type": "application/json",
    "language": a.toUpperCase(),
    "authorization": `Bearer ${token}`,
    username
  }
})