import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Container,
  Stack,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import usePPOBTransactionViewModel from "./TransactionViewModel";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";
import ConfirmationModalComponent from "presentation/components/ConfirmationModalComponent";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

const PPOBTransactionComponent = () => {
  const {
    data,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    partnerId,
    columns,
    handleSubmitRefund,
    handleSubmitFilter,
    handleSubmit,
    setValue,
    control,
    onExport,
    t,
  } = usePPOBTransactionViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.transaction.transaction_header")}
                </Typography>

                <Stack spacing={1} direction="row" sx={{
                  '@media (max-width: 600px)': {
                    width: "100%",
                    pb: "1rem"
                  },
                }}>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                        '@media (max-width: 600px)': {
                          width: "100%"
                        },
                      }}
                      onClick={() => closeModal('filter')}
                    >
                      {t("ppob.transaction.filter_button")}
                    </Button>
                  <Button
                    onClick={onExport}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                      '@media (max-width: 600px)': {
                        width: "100%"
                      },
                    }}
                  >
                    {t("ppob.transaction.export_button")}
                  </Button>
                </Stack>
              </Stack>

              {data.transactionList.loading ? (
                <h1>Loading...</h1>
              ) : (
                <DataTable
                  {...{
                    data: data.transactionList.data.list,
                    totalRows: data.transactionList.data.total,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <ConfirmationModalComponent action={handleSubmitRefund} type="refund" open={modal.refund} onClose={() => closeModal('refund')} />

        <ModalContainer
          header={t("ppob.transaction.filter_header")}
          subHeader={t("ppob.transaction.filter_subheader")}
          open={modal.filter}
          onClose={() => closeModal('filter')}
          applyButton={handleSubmit(handleSubmitFilter)}
        >
          <DateRangeComponent
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartdate}
            setEndDate={setEndate}
          />
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="partnerId"
              control={control}
              defaultValue={partnerId}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("ppob.transaction.filter_partner")}
                  onChange={(e) => {
                    setValue("partnerId", e.target.value);
                  }}
                >
                  <MenuItem value="ALL">
                    {t("ppob.transaction.filter_partner_all")}
                  </MenuItem>
                  {data.partnerList.data.list.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.partnerId}>
                        {item.partnerName}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </ModalContainer>
      </Container>
    </LayoutComponent>
  );
};

export default PPOBTransactionComponent;
