import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ConstantsResult from "domains/entity/main/ConstantResult";
import DisiApi from "datas/disi/DisiApi";
import MerchantDepositUseCase from "domains/interactor/main/disi/MerchantDeposit";

type iState = {
    data: ConstantsResult;
    error: any;
    loading: boolean;
}

const initialState: iState = {
    data: [],
    error: "",
    loading: false
}

export const merchantDepositBanksVaAction = createAsyncThunk('merchant-deposit-banks-va-list', async (_, { rejectWithValue }) => {
    try {
        const useCase = new MerchantDepositUseCase(new DisiApi())

        return await useCase.getMerchantDepositBankList("bankVa")

    } catch (error) {
        const { response = null } = error

        if (!response) {
            throw error
        }

        return rejectWithValue(response.data)
    }
})

const merchantDepositBankVaSlice = createSlice(
    {
        name: "merchant-deposit-banks-va-slice",
        initialState,
        reducers: {},
        extraReducers: builder => {
            builder.addCase(merchantDepositBanksVaAction.pending, state => {
                state.error = ""
                state.loading = true
            })

            builder.addCase(merchantDepositBanksVaAction.fulfilled, (state, action) => {
                state.loading = false;
                state.error = ""
                state.data = action.payload
            })

            builder.addCase(merchantDepositBanksVaAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload
            })
        }
    }
)

const merchantDepositBankVaReducer = merchantDepositBankVaSlice.reducer

export default merchantDepositBankVaReducer