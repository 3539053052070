import httpCommon from "datas/httpCommon";
import { SetorkuDetailPaymentCodeParams, SetorkuDetailPaymentCodeResult, SetorkuExportPaymentCodeParams, SetorkuListPaymentCodeParams, SetorkuListPaymentCodeResult } from "domains/entity/main/setorku/listPaymentCode";
import { SetorkuAddPartnerParams, SetorkuDeletePartnerParams, SetorkuEditPartnerParams, SetorkuPartnerParams, SetorkuPartnerResult } from "domains/entity/main/setorku/partner";
import { SetorkuAddProductParams, SetorkuDeleteProductParams, SetorkuEditProductParams, SetorkuProductParams, SetorkuProductResult } from "domains/entity/main/setorku/product";
import { SetorkuExportReconParams, SetorkuReconParams, SetorkuReconResult } from "domains/entity/main/setorku/recon";
import { SetorkuExportTransactionParams, SetorkuTransactionParams, SetorkuTransactionResult } from "domains/entity/main/setorku/transaction";
import { SetorkuRepository } from "domains/repository/main/setorku/SetorkuRepository";

export default class SetorkuApi implements SetorkuRepository {
    async getTransaction(params: SetorkuTransactionParams): Promise<SetorkuTransactionResult> {
        const {fromDate, toDate,status,partnerId, pageNumber, pageSize} = params;
        const data = new URLSearchParams({
            fromDate,
            toDate,
            status,
            partnerId,
            pageNumber: pageNumber.toString(),
            pageSize: pageSize.toString()
        });
        return await httpCommon
        .get<SetorkuTransactionResult>(`/setorku/transaction?${data}`)
        .then((res) => res.data);
    }

    async exportTransaction(params: SetorkuExportTransactionParams): Promise<any> {
        const {fromDate, toDate,status,partnerId} = params;
        const data = new URLSearchParams({
            fromDate,
            toDate,
            status,
            partnerId
        });
        return await httpCommon
        .get<any>(`/setorku/transaction/export?${data}`)
        .then((res) => res.data);
    }

    async getPartner(params: SetorkuPartnerParams): Promise<SetorkuPartnerResult> {
        const {fromDate, toDate} = params;
        const data = new URLSearchParams({
            fromDate,
            toDate
        });
        return await httpCommon
        .get<SetorkuPartnerResult>(`/setorku/partner?${data}`)
        .then((res) => res.data);
    }

    async addPartner(params: SetorkuAddPartnerParams): Promise<string> {
        return await httpCommon
        .post<string>(`/setorku/partner`, params)
        .then((res) => res.data);
    }

    async editPartner(params: SetorkuEditPartnerParams): Promise<string> {
        return await httpCommon
        .put<string>(`/setorku/partner`, params)
        .then((res) => res.data);
    }

    async deletePartner(params: SetorkuDeletePartnerParams): Promise<string> {
        const data = new URLSearchParams({
            partnerId: params.partnerId
        });
        return await httpCommon
        .delete<string>(`/setorku/partner/${data}`)
        .then((res) => res.data);
    }

    async getProduct(params: SetorkuProductParams): Promise<SetorkuProductResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
        });
        return await httpCommon
        .get<SetorkuProductResult>(`/setorku/product?${data}`)
        .then((res) => res.data);
    }

    async addProduct(params: SetorkuAddProductParams): Promise<string> {
        return await httpCommon
        .post<string>(`/setorku/product`, params)
        .then((res) => res.data);
    }

    async editProduct(params: SetorkuEditProductParams): Promise<string> {
        return await httpCommon
        .put<string>(`/setorku/product`, params)
        .then((res) => res.data);
    }

    async deleteProduct(params: SetorkuDeleteProductParams): Promise<string> {
        return await httpCommon
        .delete<string>(`/setorku/product/`, {data: params})
        .then((res) => res.data);
    }

    async getRecon(params: SetorkuReconParams): Promise<SetorkuReconResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            kodePembayaran: params.kodePembayaran,
            billerId: params.billerId,
            caId: params.caId,
        });
        return await httpCommon
        .get<SetorkuReconResult>(`/setorku/recon?${data}`)
        .then((res) => res.data);
    }

    async exportRecon(params: SetorkuExportReconParams): Promise<any> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            kodePembayaran: params.kodePembayaran,
            billerId: params.billerId,
            caId: params.caId,
        });
        return await httpCommon
        .get<any>(`/setorku/recon/export?${data}`)
        .then((res) => res.data);
    }

    async getListPaymentCode(params: SetorkuListPaymentCodeParams): Promise<SetorkuListPaymentCodeResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            partnerId: params.partnerId,
            pageSize: String(params.pageSize),
            pageNumber: String(params.pageNumber),
        });
        return await httpCommon
        .get<SetorkuListPaymentCodeResult>(`/setorku/list?${data}`)
        .then((res) => res.data);
    }

    async getDetailPaymentCode(params: SetorkuDetailPaymentCodeParams): Promise<SetorkuDetailPaymentCodeResult> {
        const data = new URLSearchParams({
            paymentCode: params.paymentCode,
            pageSize: String(params.pageSize),
            pageNumber: String(params.pageNumber),
        });
        return await httpCommon
        .get<SetorkuDetailPaymentCodeResult>(`/setorku/detail?${data}`)
        .then((res) => res.data);
    }

    async exportPaymentCode(params: SetorkuExportPaymentCodeParams): Promise<any> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            partnerId: params.partnerId,
        });
        const x = document.createElement('a');
        x.href = `${process.env.REACT_APP_API_URL}/setorku/export?${data}`;
        x.click();
        // return await httpCommon
        // .get<any>(`/setorku/export?${data}`)
        // .then((res) => res.data);
    }
}