import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  InputLabel,
  Stack,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";
import usePPOBPaymentMethodViewModel from "./PPOBPaymentMethodViewModel";
import SuccessModal from "presentation/components/SuccessModal";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import ConfirmationModalComponent from "presentation/components/ConfirmationModalComponent";


const PPOBPaymentMethodComponent = () => {
  const {
    data,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitFilter,
    submitAdd,
    submitEdit,
    submitDelete,
  } = usePPOBPaymentMethodViewModel();

  const alertSelector = useAppSelector((state) => state.global.alert);
  const dispatch = useAppDispatch();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.payment_method.header")}
                </Typography>

                <Stack spacing={1} direction="row" className="headerButton" sx={{
                  '@media (max-width: 600px)': {
                    width: '100%',
                    pb: '1rem',
                  },
                }} >
                  <Button
                    variant="outlined"
                    sx={{
                      height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                        '@media (max-width: 600px)': {
                          width: "100%"
                        },
                    }}
                    onClick={() => closeModal('filter')}
                  >
                    {t("ppob.payment_method.filter_button")}
                  </Button>
                  <Button
                    onClick={() => closeModal('add')}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                      '@media (max-width: 600px)': {
                        width: "100%"
                      },
                    }}
                  >
                    {t("ppob.payment_method.add_button")}
                  </Button>
                </Stack>
              </Stack>

              {data.paymentMethod.loading ? (
                <h1>Loading...</h1>
              ) : (
                <DataTable
                  {...{
                    data: data.paymentMethod.data.data,
                    totalRows: data.paymentMethod.data.total,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
        <ConfirmationModalComponent
          open={modal.delete}
          onClose={() => closeModal("delete")}
          action={submitDelete}
          type="delete"
        />
        {/* <SuccessModal
          type={alertSelector.type}
          open={alertSelector.open}
          onClose={() =>
            dispatch(
              setAlert({
                open: false,
              })
            )
          }
        /> */}

        <ModalContainer
          open={modal.filter}
          onClose={() => closeModal("filter")}
          applyButton={handleSubmit(submitFilter)}
          header={t("ppob.payment_method.filter_header")}
          subHeader={t("ppob.payment_method.filter_sub_header")}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.payment_method.partner")}
            </InputLabel>
            <Controller
              name="filter.partner"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.payment_method.partner")}
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="ALL">{t("ppob.payment_method.all")}</MenuItem>
                  {data.paymentMethod.partnerList.map((item: any, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.payment_method.payment_method")}
            </InputLabel>
            <Controller
              name="filter.paymentMethod"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("ppob.payment_method.payment_method")}
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="ALL">{t("ppob.payment_method.all")}</MenuItem>
                  {data.paymentMethod.paymentMethodList.map(
                    (item: any, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </ModalContainer>

        <ModalContainer
          open={modal.edit}
          onClose={() => closeModal("edit")}
          applyButton={handleSubmit(submitEdit)}
          header={t("ppob.payment_method.edit_header")}
          subHeader={t("ppob.payment_method.edit_sub_header")}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.partnerId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  disabled
                  variant="outlined"
                  label={t("ppob.payment_method.id_partner")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.paymentMethod"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  disabled
                  variant="outlined"
                  label={t("ppob.payment_method.partner_name")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.payment_method.access")}
            </InputLabel>
            <Controller
              name="edit.access"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  label={t("ppob.payment_method.access")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  <MenuItem value="1">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.nominal"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.payment_method.nominal")}
                  {...field}
                />
              )}
            />
          </FormControl>
        </ModalContainer>

        <ModalContainer
          open={modal.add}
          onClose={() => closeModal("add")}
          applyButton={handleSubmit(submitAdd)}
          header={t("ppob.payment_method.add_header")}
          subHeader={t("ppob.payment_method.add_sub_header")}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.payment_method.id_partner")}
            </InputLabel>
            <Controller
              name="add.partnerId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  label={t("ppob.payment_method.id_partner")}
                  {...field}
                >
                  {data.paymentMethod.partnerList.map((item: any, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.payment_method.payment_method")}
            </InputLabel>
            <Controller
              name="add.paymentMethod"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  label={t("ppob.payment_method.payment_method")}
                  {...field}
                >
                  {data.paymentMethod.paymentMethodList.map(
                    (item: any, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.payment_method.access")}
            </InputLabel>
            <Controller
              name="add.access"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  label={t("ppob.payment_method.access")}
                  {...field}
                >
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">Inactive</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.nominalFee"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.payment_method.nominal_fee")}
                  {...field}
                />
              )}
            />
          </FormControl>
        </ModalContainer>
      </Container>
    </LayoutComponent>
  );
};

export default PPOBPaymentMethodComponent;
