import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stack,
  InputLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";
import usePPOBCollectingAgentViewModel from "./PPOBCollectingAgentViewModel";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

const PPOBCollectingAgentComponent = () => {
  const {
    data,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitAdd,
  } = usePPOBCollectingAgentViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.collecting_agent.header")}
                </Typography>

                <Stack spacing={1} direction="row" className="headerButton" sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                    pb: "1rem",
                  },
                }}>
                  <Button
                    onClick={() => closeModal("add")}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                    }}
                  >
                    {t("ppob.collecting_agent.add_button")}
                  </Button>
                </Stack>
              </Stack>

              {data.paymentMethod.loading ? (
                <h1>Loading...</h1>
              ) : (
                <DataTable
                  {...{
                    data: data.collectingAgent.data.data,
                    totalRows: data.collectingAgent.data.totalRows,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <ModalContainer
          open={modal.add}
          onClose={() => closeModal("add")}
          applyButton={handleSubmit(submitAdd)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.partnerId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.collecting_agent.partner_name")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.collecting_agent.description")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.secretKey"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.collecting_agent.secret_key")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.status")}
            </InputLabel>
            <Controller
              name="add.status"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.status")}
                  variant="outlined"
                >
                  <MenuItem value={1}>ACTIVE</MenuItem>
                  <MenuItem value={0}>INACTIVE</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </ModalContainer>
      </Container>
    </LayoutComponent>
  );
};

export default PPOBCollectingAgentComponent;
