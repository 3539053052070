import { makeStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import React, { useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: "100vh",
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        imagePreview: {
            height: 200,
            width: 200,
            objectFit: "contain",
        },
    })
);

const allowedFileTypes = ["image/jpeg", "image/png"];

const UploadFile = () => {
    // const classes = useStyles();
    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<any>(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            if (!allowedFileTypes.includes(selectedFile.type)) {
                setUploadError("File type is not allowed. Only jpeg and png are allowed.");
                return;
            }
            setFile(selectedFile);
            setPreviewUrl(URL.createObjectURL(selectedFile));
            setUploadSuccess(true);
            setUploadError(null);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div >
            <Grid container>
                <Grid item xs={12}>
                    <Paper >
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            style={{ height: 200, border: "dashed 1px #ccc", display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            {file ? (
                                <img src={previewUrl} />
                            ) : (
                                <Typography variant="subtitle1">Drop an image here</Typography>
                            )}
                        </div>
                        {uploadSuccess && <Typography variant="body2" color="success">File uploaded successfully!</Typography>}
                        {uploadError && <Typography variant="body2" color="error">{uploadError}</Typography>}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default UploadFile;