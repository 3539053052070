import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModalContainer from "presentation/components/FormModalContainer";

type RequestFormProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    handleReset?: () => void;
    onSubmit?: (...input: any[]) => void;
};

const FilterForm: React.FC<RequestFormProps> = ({
    isOpen = false,
    handleClose = () => {},
    handleReset = () => {},
    onSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, register } = useFormContext();

    return (
        <FormModalContainer
            open={isOpen}
            handleSubmit={handleSubmit}
            applyText={t("disi.partner_deposit.filter_submit") as string}
            applyButton={onSubmit}
            cancelButton={handleReset}
            cancelText={t("disi.partner_deposit.filter_cancel") as string}
            onClose={handleClose}
            header="Filter"
            subHeader="User Deposit"
        >
            <TextField
                fullWidth
                {...register("merchantNm")}
                label={t("disi.merchant_deposit.table_merchant_name")}
            />
            <TextField
                fullWidth
                {...register("partnerNm")}
                label={t("disi.merchant_deposit.table_partner_name")}
            />
            <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                    <TextField
                        select
                        value={value}
                        onChange={onChange}
                        label={t("disi.merchant_deposit.table_status")}
                    >
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="ACTIVE">Active</MenuItem>
                        <MenuItem value="NON ACTIVE">Non Active</MenuItem>
                    </TextField>
                )}
            />
        </FormModalContainer>
    );
};

export default FilterForm;
