import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { detailPettyCashHeaderDataAction } from "utils/store/main/disi/DetailPettyCashHeader";
import { Box, Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useForm } from "react-hook-form";
import useRole from "domain/hooks/useRole";
import currencyFormatter from "utils/helper/currencyFormatter";
import useRouterDomState from "utils/helper/useRouterDomState";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { detailPettyCashBodyDataAction } from "utils/store/main/disi/DetailPettyCashBody";
import { detailPettyCashAccountabilityDataAction } from "utils/store/main/disi/DetailPettyCashAccountability";
import {
    approvePettyCashAction,
    rejectPettyCashAction,
    resetDetailPettyCashActions,
} from "utils/store/main/disi/DetailPettyCashActions";
import {
    approveDetailPettyCashAccountabilityAction,
    createDetailPettyCashAccountabilityAction,
    rejectDetailPettyCashAccountabilityAction,
    resetDetailPettyCashAccountabilityActions,
} from "utils/store/main/disi/DetailPettyCashAccountabilityActions";
import {
    resetDetailPettyCashHeaderActions,
    cancelTokenAction,
} from "utils/store/main/disi/DetailPettyCashHeaderActions";
import { setPettyCashState } from "utils/store/main/disi/PettyCashBody";
import { extractColor, pettyStatusColorMap } from "utils/helper/statusColor";
import useSnackbar from "domain/hooks/useSnackbar";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { DetailPettyCashAccountabilityBody } from "domains/entity/main/disi";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDate } from "utils/formatDate";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";
import DisiApi from "datas/disi/DisiApi";
import { pettyFileValidation } from "utils/validation";

type ModalType = "accountability" | "jumlah" | "";

const initialRejectState = {
    rejectNote: "",
};

const createAccountabilityValidation = yup.object({
    trxDate: yup.string().required("field_required"),
    pettyCashId: yup.string().required("field_required"),
    channelPettyCash: yup.string().required("field_required"),
    amount: yup.string().required("field_required"),
    file: pettyFileValidation,
});

const initialAccountabilityFormState: DetailPettyCashAccountabilityBody = {
    trxDate: "",
    pettyCashId: "",
    channelPettyCash: "",
    amount: "",
    file: null,
};

const DetailPettyCashViewModel = () => {
    const { t } = useTranslation();

    const location = useLocation();

    const navigate = useNavigate();

    const { routerState } = useRouterDomState<{
        partnerId: string;
        savedState: any;
    }>(location, "/Dashboard/disi/petty-cash");

    const dispatch = useAppDispatch();

    const role = useRole("petty-cash");

    const {
        detailPettyCashHeader,
        detailPettyCashBody,
        detailPettyCashAccountability,
        detailPettyCashAction,
        detailPettyCashAccountabilityActions,
        detailPettyCashHeaderActions,
    } = useAppSelector((state) => state.main.disi);

    const [pageJumlah, setPageJumlah] = React.useState(0);

    const [pageSizeJumlah, setPageSizeJumlah] = React.useState(6);

    const [pageAccountability, setPageAccountability] = React.useState(0);

    const [pageSizeAccountability, setPageSizeAccountability] =
        React.useState(6);

    const [selectedId, setSelectedId] = React.useState("");

    const [rechargeModal, setRechargeModal] = React.useState(false);

    const handleRechargeModalclose = () => setRechargeModal(false);

    const [modalOpen, setModalOpen] = React.useState<ModalType>("");

    const [approveOpen, setApproveOpen] = React.useState<ModalType>("");

    const handleApproveOpen = (flag: ModalType) => setApproveOpen(flag);

    const handleApproveClose = () => setApproveOpen("");

    const rejectReason = useForm({ defaultValues: initialRejectState });

    const accountabilityForm = useForm({
        defaultValues: initialAccountabilityFormState,
        resolver: yupResolver(createAccountabilityValidation),
    });

    const { setValue: setAccountabilityFormValue } = accountabilityForm;

    const [accountabilityModal, setAccountabilityModal] = React.useState(false);

    const handleAccountabilityModalOpen = () => setAccountabilityModal(true);

    const handleAccountabilityModalClose = () => {
        accountabilityForm.setValue("amount", "");
        accountabilityForm.setValue("trxDate", "");
        accountabilityForm.setValue("file", null);

        setAccountabilityModal(false);
    };

    const handleModalOpen = (flag: ModalType) => setModalOpen(flag);

    const handleModalClose = () => {
        rejectReason.reset(initialRejectState);
        setModalOpen("");
    };

    const useCase = new DetailPettyCashUseCase(new DisiApi());

    const columnTableJumlah: GridColDef[] = [
        {
            field: "id",
            headerName: "No",
            width: 35,
        },
        {
            field: "dtTrx",
            headerName: t("disi.detail_petty_cash.table_petty_date_time") || "",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "trackingRef",
            headerName: t("disi.detail_petty_cash.table_petty_ref_code") || "",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "amount",
            headerName: t("disi.detail_petty_cash.table_petty_amount") || "",
            flex: 1,
            minWidth: 100,
            renderCell: (row) => currencyFormatter(row.row.amount),
        },
        {
            field: "status",
            headerName: t("disi.detail_petty_cash.table_petty_status") || "",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    sx={{
                        color: extractColor(
                            pettyStatusColorMap,
                            params.row.status
                        ),
                    }}
                >
                    {params.row.status}
                </Typography>
            ),
        },
        {
            field: "Aksi",
            headerName: t("disi.detail_petty_cash.table_petty_actions") || "",
            minWidth: 150,
            flex: 1,
            renderCell: (params) =>
                Boolean(role.isEdit) &&
                params.row.status === "NEW" && (
                    <Box sx={{ display: "flex", gap: ".3rem" }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#2E7D32",
                                minWidth: "unset",
                            }}
                            onClick={() => {
                                handleApproveOpen("jumlah");
                                setSelectedId(params.row.pettyCashId);
                            }}
                        >
                            <CheckCircleRoundedIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: "#D32F2F",
                                color: "#D32F2F",
                                minWidth: "unset",
                            }}
                            onClick={() => {
                                handleModalOpen("jumlah");
                                setSelectedId(params.row.pettyCashId);
                            }}
                        >
                            <CancelRoundedIcon />
                        </Button>
                    </Box>
                ),
        },
    ];

    const columnTableAccountability: GridColDef[] = [
        {
            field: "id",
            headerName: "No",
            width: 35,
        },
        {
            field: "dtTrx",
            headerName:
                t("disi.detail_petty_cash.table_accountability_date_time") ||
                "",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "accountabilityId",
            headerName:
                t("disi.detail_petty_cash.table_accountability_id") || "",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "amountAccountability",
            headerName:
                t("disi.detail_petty_cash.table_accountability_amount") || "",
            flex: 1,
            minWidth: 100,
            renderCell: (row) =>
                currencyFormatter(row.row.amountAccountability),
        },
        {
            field: "status",
            headerName:
                t("disi.detail_petty_cash.table_accountability_status") || "",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    sx={{
                        color: extractColor(
                            pettyStatusColorMap,
                            params.row.status
                        ),
                    }}
                >
                    {params.row.status}
                </Typography>
            ),
        },
        {
            field: "file",
            headerName: "File",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        if (detailPettyCashHeader.data.pettyCashId) {
                            useCase
                                .getAccountabilityFile({
                                    accountabilityId:
                                        params.row.accountabilityId,
                                    pettyCashId:
                                        detailPettyCashHeader.data.pettyCashId,
                                })
                                .then((data) => {
                                    const { accountabilityFile } = params.row;

                                    const extension =
                                        accountabilityFile.match(
                                            /\.[0-9a-z]+$/i
                                        );

                                    if (!extension.length) return;

                                    const mp = new Map([
                                        [".pdf", "application/pdf"],
                                        [".jpg", "image/jpg"],
                                        [".jpeg", "image/jpeg"],
                                        [".png", "image/png"],
                                    ]);

                                    const dataType = mp.get(extension[0]);

                                    if (!dataType) return;

                                    const linkElement =
                                        document.createElement("a");
                                    linkElement.setAttribute(
                                        "href",
                                        window.URL.createObjectURL(
                                            new Blob([data], { type: dataType })
                                        )
                                    );
                                    linkElement.setAttribute(
                                        "target",
                                        "_blank"
                                    );
                                    linkElement.click();
                                });
                        }
                    }}
                >
                    File
                </Button>
            ),
        },
        {
            field: "Aksi",
            headerName:
                t("disi.detail_petty_cash.table_accountability_actions") || "",
            minWidth: 100,
            flex: 1,
            renderCell: (params) =>
                Boolean(role.isEdit) &&
                params.row.status === "NEW" && (
                    <Box sx={{ display: "flex", gap: ".3rem" }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#2E7D32",
                                minWidth: "unset",
                            }}
                            onClick={() => {
                                handleApproveOpen("accountability");
                                setSelectedId(params.row.accountabilityId);
                            }}
                        >
                            <CheckCircleRoundedIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: "#D32F2F",
                                color: "#D32F2F",
                                minWidth: "unset",
                            }}
                            onClick={() => {
                                handleModalOpen("accountability");
                                setSelectedId(params.row.accountabilityId);
                            }}
                        >
                            <CancelRoundedIcon />
                        </Button>
                    </Box>
                ),
        },
    ];

    const handleApproveSubmit = () => {
        if (approveOpen === "") return;

        if (approveOpen === "jumlah") {
            dispatch(
                approvePettyCashAction({
                    action: "APPROVE",
                    pettyCashId: selectedId,
                })
            );
        }

        if (approveOpen === "accountability") {
            dispatch(
                approveDetailPettyCashAccountabilityAction({
                    action: "APPROVE",
                    pettyCashId: detailPettyCashHeader.data.pettyCashId,
                    accountabilityId: selectedId,
                })
            );
        }
    };

    const handleRejectSubmit = (data: typeof initialRejectState) => {
        if (modalOpen === "") return;
        const { rejectNote } = data;

        if (modalOpen === "jumlah") {
            dispatch(
                rejectPettyCashAction({
                    action: "REJECT",
                    note: rejectNote,
                    pettyCashId: selectedId,
                })
            );
        }

        if (modalOpen === "accountability") {
            dispatch(
                rejectDetailPettyCashAccountabilityAction({
                    accountabilityId: selectedId,
                    pettyCashId: detailPettyCashHeader.data.pettyCashId,
                    action: "REJECT",
                    note: rejectNote,
                })
            );
        }
    };

    const handleAccountabilityFormSubmit = (
        data: DetailPettyCashAccountabilityBody
    ) => {
        dispatch(
            createDetailPettyCashAccountabilityAction({
                ...data,
                trxDate: `${formatDate(
                    new Date(data.trxDate),
                    "YYYY-MM-DD"
                )} 00:00:00`,
            })
        );
    };

    const handleCancelTokenSubmit = () => {
        dispatch(
            cancelTokenAction({
                pettyCashId: detailPettyCashHeader.data.pettyCashId,
                token: detailPettyCashHeader.data.token,
            })
        );
    };

    const fetchDetailPettyCashHeader = () => {
        dispatch(detailPettyCashHeaderDataAction(routerState.partnerId));
    };

    const fetchDetailPettyCashBody = () => {
        dispatch(detailPettyCashBodyDataAction(routerState.partnerId));
    };

    const fetchDetailPettyCashAccountability = () => {
        dispatch(
            detailPettyCashAccountabilityDataAction({
                pageNumber: pageAccountability + 1,
                pageSize: pageSizeAccountability,
                pettyCashId: detailPettyCashHeader.data.pettyCashId,
            })
        );
    };

    const redirectToPartnerDeposit = () => {
        navigate("/dashboard/disi/partner-deposit", {
            state: "recharge me xd",
        });
    };

    React.useEffect(() => {
        if (routerState.partnerId) {
            fetchDetailPettyCashHeader();
            fetchDetailPettyCashBody();
        }
        return () => {};
    }, [routerState.partnerId]);

    // save petty cash filter state

    React.useEffect(() => {
        if (routerState.savedState) {
            dispatch(setPettyCashState(routerState.savedState));
        }
    }, [routerState.savedState]);

    /** setup form value */
    React.useEffect(() => {
        const { pettyCashId, typeChannel } = detailPettyCashHeader.data;

        if (pettyCashId) {
            fetchDetailPettyCashAccountability();
            setAccountabilityFormValue("pettyCashId", pettyCashId);
        }

        if (typeChannel) {
            setAccountabilityFormValue("channelPettyCash", typeChannel);
        }
    }, [
        pageAccountability,
        pageSizeAccountability,
        detailPettyCashHeader.data,
    ]);

    /** side effect petty cash */
    const {
        error: errorPettyAction,
        loading: loadingPettyAction,
        result: resultPettyAction,
    } = detailPettyCashAction;

    useSnackbar(
        {
            error: errorPettyAction,
            loading: loadingPettyAction,
            result: resultPettyAction,
        },
        resetDetailPettyCashActions
    );

    /** show balance not enough pop up */

    React.useEffect(() => {
        if (errorPettyAction === "Balance not enough") {
            setRechargeModal(true);
        }
    }, [errorPettyAction]);

    /** side effect accountability */

    const {
        error: errorAccountabilityActions,
        loading: loadingAccountabilityActions,
        result: resultAccountabilityActions,
    } = detailPettyCashAccountabilityActions;

    useSnackbar(
        {
            error: errorAccountabilityActions,
            loading: loadingAccountabilityActions,
            result: resultAccountabilityActions,
        },
        resetDetailPettyCashAccountabilityActions
    );

    const {
        error: errorHeaderPettyCashActions,
        loading: loadingHeaderPettyCashActions,
        result: resultHeaderPettyCashActions,
    } = detailPettyCashHeaderActions;

    /** refetch data on success */

    useSnackbar(
        {
            error: errorHeaderPettyCashActions,
            loading: loadingHeaderPettyCashActions,
            result: resultHeaderPettyCashActions,
        },
        resetDetailPettyCashHeaderActions
    );

    React.useEffect(() => {
        if (
            (!loadingAccountabilityActions && resultAccountabilityActions) ||
            (!loadingPettyAction && resultPettyAction) ||
            (!loadingHeaderPettyCashActions && resultHeaderPettyCashActions)
        ) {
            handleAccountabilityModalClose();
            fetchDetailPettyCashAccountability();
            fetchDetailPettyCashHeader();
            fetchDetailPettyCashBody();
        }
    }, [
        loadingAccountabilityActions,
        resultAccountabilityActions,
        loadingPettyAction,
        resultPettyAction,
        loadingHeaderPettyCashActions,
        resultHeaderPettyCashActions,
    ]);

    /** handle close form */
    React.useEffect(() => {
        if (loadingPettyAction || loadingAccountabilityActions) return;

        if (approveOpen !== "") {
            handleApproveClose();
        }
        if (modalOpen !== "") {
            handleModalClose();
        }
    }, [loadingPettyAction, loadingAccountabilityActions]);

    return {
        t,
        detailPettyCashHeader,
        detailPettyCashBody,
        detailPettyCashAccountability,
        columnTableJumlah,
        pageJumlah,
        setPageJumlah,
        pageSizeJumlah,
        setPageSizeJumlah,
        pageAccountability,
        setPageAccountability,
        pageSizeAccountability,
        setPageSizeAccountability,
        columnTableAccountability,
        modalOpen,
        handleModalClose,
        handleModalOpen,
        approveOpen,
        handleApproveOpen,
        handleApproveSubmit,
        handleApproveClose,
        handleRejectSubmit,
        rejectReason,
        handleAccountabilityModalClose,
        handleAccountabilityModalOpen,
        accountabilityForm,
        accountabilityModal,
        handleAccountabilityFormSubmit,
        role,
        rechargeModal,
        handleRechargeModalclose,
        redirectToPartnerDeposit,
        handleCancelTokenSubmit,
        loadingHeaderPettyCashActions,
    };
};

export default DetailPettyCashViewModel;
