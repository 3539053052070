import React from "react";
import { Box, TextField } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

type DateRangeComponentProps = {
    startDate: Date;
    setStartDate: React.Dispatch<React.SetStateAction<Date>>;
    endDate: Date | string;
    setEndDate: React.Dispatch<React.SetStateAction<Date>>;
    sx?: SxProps;
};

const DateRangeComponent: React.FC<DateRangeComponentProps> = (props) => {
    const { t } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...props.sx,
                }}
            >
                <DesktopDatePicker
                    label={t("date_picker.start_date")}
                    inputFormat="DD-MM-YYYY"
                    disableFuture
                    value={props.startDate}
                    onChange={(val) => {
                        const date = dayjs(val).toDate();
                        props.setStartDate(date);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => e.target.blur()}
                            onKeyDown={(e) => e.preventDefault()}
                        />
                    )}
                />
                <MinimizeOutlinedIcon
                    sx={{
                        color: "rgba(0, 0, 0, 0.54)",
                        m: "1rem",
                    }}
                />
                <DesktopDatePicker
                    label={t("date_picker.end_date")}
                    disableFuture
                    inputFormat="DD-MM-YYYY"
                    value={props.endDate}
                    onChange={(val) => {
                        const date = dayjs(val).toDate();
                        props.setEndDate(date);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            onChange={(e) => e.target.blur()}
                            onKeyDown={(e) => e.preventDefault()}
                        />
                    )}
                />
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangeComponent;
