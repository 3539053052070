import React from "react";
import {
    Stack,
    Box,
    Card,
    CardContent,
    Container,
    Tooltip,
    Typography,
    Button,
    CircularProgress,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HowToVoteRoundedIcon from "@mui/icons-material/HowToVoteRounded";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import LayoutComponent from "presentation/layout/Layout";
import DataTable from "presentation/components/DataTable";
import PartnerDepositViewModel from "./PartnerDepositViewModel";
import currencyFormatter from "utils/helper/currencyFormatter";
import FilterForm from "./components/FilterForm";
import RechargeComponent from "./components/RechargeComponent";
import WithdrawComponent from "./components/WithdrawComponent";

const PartnerDepositComponent = () => {
    const {
        t,
        partnerDeposit,
        partnerDepositWallet,
        columns,
        page,
        pageSize,
        setPage,
        setPageSize,
        filterForm,
        filterModal,
        handleFilterModalClose,
        handleFilterModalOpen,
        handleFilterReset,
        onFilterSubmit,
        handleRechargeModalOpen,
        handleRechargeModalclose,
        rechargeModal,
        withdrawForm,
        handleWithdrawModalClose,
        handleWithdrawModalOpen,
        withdrawModal,
        onExportClick,
    } = PartnerDepositViewModel();

    return (
        <LayoutComponent>
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
            >
                <Box
                    display="grid"
                    sx={{
                        py: "1rem",
                        gap: "1rem",
                        width: "95%",
                        gridTemplateColumns: {
                            xs: "1fr",
                            sm: "1fr .5fr",
                        },
                        gridAutoRows: "max-content",
                        gridTemplateAreas: {
                            xs: `
                                "saldoGroup" 
                                "saldo"
                                "saldoDelayed"
                                "dataTable"
                            `,
                            sm: `
                                "saldoGroup saldo"
                                "saldoGroup saldoDelayed"
                                "dataTable dataTable"
                            `,
                        },
                        "& .MuiCard-root .MuiCardContent-root": {
                            p: "1rem",
                        },
                    }}
                >
                    <Card
                        sx={{ height: "fit-content", gridArea: "saldoGroup" }}
                    >
                        <CardContent
                            sx={{
                                display: "grid",
                                gap: "1rem",
                                gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr 1fr",
                                },
                                gridTemplateRows: {
                                    xs: "repeat(3, 1fr)",
                                    sm: "repeat(2, 1fr)",
                                },
                                gridAutoFlow: "column",
                            }}
                        >
                            <Stack sx={{ gap: "1rem" }}>
                                <Stack direction="row">
                                    <Typography fontWeight={600}>
                                        {t("disi.partner_deposit.saldo_ready")}
                                    </Typography>
                                    <Tooltip
                                        title={t(
                                            "disi.partner_deposit.saldo_ready"
                                        )}
                                    >
                                        <InfoRoundedIcon />
                                    </Tooltip>
                                </Stack>
                                {partnerDepositWallet.loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Typography fontWeight={600}>
                                        {currencyFormatter(
                                            partnerDepositWallet.totalReadyWallet
                                        )}
                                    </Typography>
                                )}
                            </Stack>
                            <Stack sx={{ gap: "1rem" }}>
                                <Stack direction="row">
                                    <Typography fontWeight={600}>
                                        {t("disi.partner_deposit.saldo_debt")}
                                    </Typography>
                                    <Tooltip
                                        title={t(
                                            "disi.partner_deposit.saldo_debt"
                                        )}
                                    >
                                        <InfoRoundedIcon />
                                    </Tooltip>
                                </Stack>
                                {partnerDepositWallet.loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Typography fontWeight={600}>
                                        {currencyFormatter(
                                            partnerDepositWallet.totalDebtWallet
                                        )}
                                    </Typography>
                                )}
                            </Stack>
                            <Stack
                                sx={{
                                    gap: ".5rem",
                                    alignSelf: "end",
                                    width: "fit-content",
                                    flexDirection: {
                                        xs: "row",
                                        sm: "column",
                                    },
                                    margin: {
                                        xs: "auto",
                                        sm: "0 0 0 auto",
                                    },
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontSize: "calc(3px + 1.6vmin)",
                                        display: "inline-flex",
                                        gap: ".5rem",
                                    }}
                                    onClick={handleWithdrawModalOpen}
                                >
                                    <HowToVoteRoundedIcon />
                                    {t("disi.partner_deposit.withdraw_header")}
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        display: "inline-flex",
                                        gap: ".5rem",
                                        fontSize: "calc(3px + 1.6vmin)",
                                    }}
                                    onClick={handleRechargeModalOpen}
                                >
                                    <CurrencyExchangeRoundedIcon />
                                    {t("disi.partner_deposit.recharge_header")}
                                </Button>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card sx={{ gridArea: "saldo" }}>
                        <CardContent>
                            <Stack sx={{ gap: "1rem" }}>
                                <Stack direction="row">
                                    <Typography fontWeight={600}>
                                        {t("disi.partner_deposit.saldo")}
                                    </Typography>
                                    <Tooltip
                                        title={t("disi.partner_deposit.saldo")}
                                    >
                                        <InfoRoundedIcon />
                                    </Tooltip>
                                </Stack>
                                {partnerDepositWallet.loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Typography fontWeight={600}>
                                        {currencyFormatter(
                                            partnerDepositWallet.totalRealWallet
                                        )}
                                    </Typography>
                                )}
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card sx={{ gridArea: "saldoDelayed" }}>
                        <CardContent>
                            <Stack sx={{ gap: "1rem" }}>
                                <Stack direction="row">
                                    <Typography fontWeight={600}>
                                        {t(
                                            "disi.partner_deposit.saldo_merchant"
                                        )}
                                    </Typography>
                                    <Tooltip
                                        title={t(
                                            "disi.partner_deposit.saldo_merchant"
                                        )}
                                    >
                                        <InfoRoundedIcon />
                                    </Tooltip>
                                </Stack>
                                {partnerDepositWallet.loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Typography fontWeight={600}>
                                        {currencyFormatter(
                                            partnerDepositWallet.totalMerchantWallet
                                        )}
                                    </Typography>
                                )}
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card sx={{ gridArea: "dataTable" }}>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto",
                                    gridAutoFlow: "row",
                                    gap: ".5rem 0",
                                    p: {
                                        xs: 0,
                                        sm: "1rem",
                                    },
                                }}
                            >
                                <Typography
                                    className="headerName"
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        mb: "1.5rem",
                                        fontWeight: 600,
                                        gridColumn: {
                                            xs: "1 / span 2",
                                            sm: "1",
                                        },
                                    }}
                                >
                                    {t("disi.partner_deposit.table_header")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        gap: ".5rem",
                                        "& .MuiButtonBase-root": {
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={handleFilterModalOpen}
                                    >
                                        Filter
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={onExportClick}
                                    >
                                        Export
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        gridColumn: "1 / span 2",
                                    }}
                                >
                                    <DataTable
                                        {...{
                                            columns,
                                            page,
                                            pageSize,
                                            setPage,
                                            setPageSize,
                                            data: partnerDeposit.data.data,
                                            totalRows:
                                                partnerDeposit.data.totalRows,
                                            loading: partnerDeposit.loading,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <FormProvider {...filterForm}>
                    <FilterForm
                        isOpen={filterModal}
                        handleClose={handleFilterModalClose}
                        handleReset={handleFilterReset}
                        onSubmit={onFilterSubmit}
                    />
                </FormProvider>
                <RechargeComponent
                    isOpen={rechargeModal}
                    handleClose={handleRechargeModalclose}
                />
                <FormProvider {...withdrawForm}>
                    <WithdrawComponent
                        isOpen={withdrawModal}
                        handleClose={handleWithdrawModalClose}
                    />
                </FormProvider>
            </Container>
        </LayoutComponent>
    );
};

export default PartnerDepositComponent;
