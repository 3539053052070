import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MerchantOnBoardingAPI } from "datas/merchantOnBoarding/merchantOnBoardingAPI";
import { TransactionRefundListParams, TransactionRefundListResult } from "domains/entity/main/merchantOnBoarding/transactionRefund";
import { MerchantOnBoardingInteractor } from "domains/interactor/main/merchantOnBoarding/merchantOnBoardingInteractor";

export const getTransactionRefundListAction = createAsyncThunk(
    "merchant-transaksi/getTransactionRefundListAction",
    async (params: TransactionRefundListParams) => {
        try {
            const interactor = new MerchantOnBoardingInteractor(
                new MerchantOnBoardingAPI()
            );
            const response = await interactor.getMerchantOnBoardingTransactionRefundList(
                params
            );
            return response;
        } catch (error) {
            throw error;
        }
    }
);

interface iState {
    data: TransactionRefundListResult;
    loading: boolean;
    error: boolean;
}

const initialState: iState = {
    data: {
        data: [],
        pageLimit: 0,
        totalRows: 0,
    },
    loading: false,
    error: false,
};

const slice = createSlice({
    name: "merchant-transaksi",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTransactionRefundListAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTransactionRefundListAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getTransactionRefundListAction.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default slice.reducer;