import { Box, Modal, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'domain/hooks/redux-hook';
import React from 'react'
import { setAlert } from 'utils/store/global/alert';

interface iSuccessModal {
  type?: string | undefined
  open?: boolean
  onClose?: () => void
}

const SuccessModal: React.FC<iSuccessModal> = ({open, onClose, type}) => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector(state => state.global.alert)
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    bgcolor: "white",
    borderRadius: "20px",
    p: 4,
  };


  return (
    <Modal open={selector.open} onClose={() => dispatch(setAlert(!selector.open))}>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {selector.type}
        </Typography>
        </Box>
    </Modal>
  )
}

export default SuccessModal