
const formatter = new Intl.NumberFormat('id-Id')

const currencyFormatter = (money: number | string) => {
    try {
        let _money = typeof money === "string" ? Number(money) : money;
        let currFormat = `Rp. ${formatter.format(_money)}`
        return currFormat
    } catch (e) {
        return "Rp. 0"
    }

}

export default currencyFormatter