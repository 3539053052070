import httpCommon from "datas/httpCommon";
import { ExportHistoryTransactionParams, HistoryTransactionParams, HistoryTransactionResult, RefundHistoryTransactionParams } from "domains/entity/main/dana/historyTransaction";
import { SuspectTransactionParams, SuspectTransactionResult, ExportSuspectTransactionParams, FailedSuspectTransactionParams, SuccessSuspectTransactionParams } from "domains/entity/main/dana/transactionSuspect";
import { DanaRepository } from "domains/repository/main/dana/DanaRepository";

export default class DanaApi implements DanaRepository {
    async getHistoryTransaction(params: HistoryTransactionParams): Promise<HistoryTransactionResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            acquirementId: params.acquirementId,
            customerid: params.customerid,
            productdesc: params.productdesc,
            tracking_ref: params.tracking_ref,
            amount: params.amount,
            status: params.status,
            pageSize: params.pageSize.toString(),
            pageNumber: params.pageNumber.toString(),
        })
        return await httpCommon
            .get<HistoryTransactionResult>(`/dana/transaction/history?${data}`)
            .then((res) => res.data);
    }

    async refundHistoryTransaction(params: RefundHistoryTransactionParams): Promise<string> {
        return await httpCommon
            .put(`/dana/transaction/history/refund`, params)
    }

    async exportHistoryTransaction(params: ExportHistoryTransactionParams): Promise<any> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            acquirementid: params.acquirementid,
            customerid: params.customerid,
            productdesc: params.productdesc,
            finishTime: params.finishTime,
            tracking_ref: params.tracking_ref,
            amount: params.amount,
            pageSize: params.pageSize.toString(),
            pageNumber: params.pageNumber.toString(),
        })
        return await httpCommon
            .get(`/dana/transaction/history/export?${data}`, {
                responseType: "blob",
            })
            .then((res) => res.data);
    }

    async getSuspectTransaction(params: SuspectTransactionParams): Promise<SuspectTransactionResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            acquirementId: params.acquirementid,
            customerid: params.customerid,
            productdesc: params.productdesc,
            finishTime: params.finishTime,
            tracking_ref: params.tracking_ref,
            amount: params.amount,
            pageSize: params.pageSize.toString(),
            pageNumber: params.pageNumber.toString(),
        })
        return await httpCommon
            .get<SuspectTransactionResult>(`/dana/transaction/suspect?${data}`)
            .then((res) => res.data);
    }

    successSuspectTransaction(params: SuccessSuspectTransactionParams): Promise<string> {
        return httpCommon.put(`/dana/transaction/suspect`, params);
    }
    
    failedSuspectTransaction(params: FailedSuspectTransactionParams): Promise<string> {
        return httpCommon.put(`/dana/transaction/suspect`, params);
    }
    
    async exportSuspectTransaction(params: ExportSuspectTransactionParams): Promise<any> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            acquirementId: params.acquirementid,
            customerid: params.customerid,
            productdesc: params.productdesc,
            finishTime: params.finishTime,
            tracking_ref: params.tracking_ref,
            amount: params.amount,
            pageSize: params.pageSize.toString(),
            pageNumber: params.pageNumber.toString(),
        })
        return await httpCommon
            .get(`/dana/transaction/suspect/export?${data}`).then((res) => res.data);
    }
}