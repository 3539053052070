import {
    createSlice, createAsyncThunk
} from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import { CancelTokenBody } from "domains/entity/main/disi";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";


type iState = {
    result: string;
    loading: boolean;
    error: any;
}

const initialState: iState = {
    error: "",
    loading: false,
    result: ""
}

export const cancelTokenAction = createAsyncThunk("detail-petty-cash-cancel-token-actions", async (body: CancelTokenBody, { rejectWithValue }) => {
    try {
        const useCase = new DetailPettyCashUseCase(new DisiApi())
        return await useCase.postDetailPettyCashCancelToken(body)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error;
        }
        return rejectWithValue(response.data)
    }
})

const detailPettyCashHeaderActionsSlice = createSlice({
    name: "detail-petty-cash-header-actions-slice",
    initialState,
    reducers: {
        resetDetailPettyCashHeaderActions: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(cancelTokenAction.pending, state => {
            state.loading = true;
            state.error = "";
            state.result = ""
        })
        builder.addCase(cancelTokenAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload
            state.loading = false;
            state.error = "";
            state.result = responsedesc
        })
        builder.addCase(cancelTokenAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.result = ""
        })
    }
})

const detailPettyCashHeaderActionsReducer = detailPettyCashHeaderActionsSlice.reducer

export const { resetDetailPettyCashHeaderActions } = detailPettyCashHeaderActionsSlice.actions

export default detailPettyCashHeaderActionsReducer