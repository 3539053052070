import React from "react";
import {
    Button,
    Typography,
    Card,
    CardContent,
    Container,
    Box,
    Stack,
    CircularProgress,
} from "@mui/material";
import LayoutComponent from "presentation/layout/Layout";
import DataTable from "presentation/components/DataTable";
import DetailPettyCashViewModel from "./DetailPettyCashViewModel";
import ApproveForm from "./components/ApproveForm";
import { FormProvider } from "react-hook-form";
import RejectForm from "./components/RejectForm";
import AccountabilityForm from "./components/AccountabilityForm";
import currencyFormatter from "utils/helper/currencyFormatter";
import RechargeBalanceModal from "./components/RechargeBalanceModal";

const DisiDetailPettyCashComponent = () => {
    const viewModel = DetailPettyCashViewModel();

    const {
        t,
        detailPettyCashHeader,
        detailPettyCashBody,
        detailPettyCashAccountability,
        columnTableJumlah,
        pageJumlah,
        pageSizeJumlah,
        setPageJumlah,
        setPageSizeJumlah,
        pageAccountability,
        pageSizeAccountability,
        setPageAccountability,
        setPageSizeAccountability,
        columnTableAccountability,
        role,
        rechargeModal,
        handleRechargeModalclose,
        redirectToPartnerDeposit,
        handleCancelTokenSubmit,
        loadingHeaderPettyCashActions,
    } = viewModel;

    return (
        <LayoutComponent>
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
            >
                <Box
                    display="grid"
                    sx={{
                        py: "2rem",
                        width: "95%",
                        gridAutoFlow: "row",
                        gap: "1rem",
                        gridTemplateRows: "repeat(3, auto)",
                    }}
                >
                    <Card>
                        <CardContent>
                            <Typography
                                sx={{ fontWeight: 800, fontSize: "1.5rem" }}
                            >
                                {t("disi.detail_petty_cash.header_title")}
                            </Typography>
                            {detailPettyCashHeader.loading ? (
                                <CircularProgress
                                    size={24}
                                    sx={{ mt: "1rem" }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        display: "grid",
                                        gap: ".5rem 1rem",
                                        gridTemplateColumns:
                                            "auto auto auto 1fr",
                                        mt: "1rem",
                                    }}
                                >
                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_petty_id"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {detailPettyCashHeader.data.pettyCashId}
                                    </Typography>

                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_date_time"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {detailPettyCashHeader.data.dtTrx}
                                    </Typography>

                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_merchant_id"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {detailPettyCashHeader.data.merchantId}
                                    </Typography>

                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_petty_type"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {
                                            detailPettyCashHeader.data
                                                .typePettyCash
                                        }
                                    </Typography>

                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_amount"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {currencyFormatter(
                                            Number(
                                                detailPettyCashHeader.data
                                                    .amount
                                            )
                                        )}
                                    </Typography>

                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_accountability_amount"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {currencyFormatter(
                                            Number(
                                                detailPettyCashHeader.data
                                                    .amountResponsibility
                                            )
                                        )}
                                    </Typography>

                                    <Typography
                                        fontWeight={700}
                                        sx={{
                                            gridColumn: {
                                                xs: "span 3",
                                                sm: "span 1",
                                            },
                                        }}
                                    >
                                        {t(
                                            "disi.detail_petty_cash.header_note"
                                        )}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            gridColumn: {
                                                xs: "span 4",
                                                sm: "span 3",
                                            },
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "inline",
                                                },
                                            }}
                                        >
                                            :{" "}
                                        </Typography>
                                        {detailPettyCashHeader.data.description}
                                    </Typography>

                                    {detailPettyCashHeader.data.token &&
                                        detailPettyCashHeader.data
                                            .tokenStatus && (
                                            <>
                                                <Typography
                                                    sx={{ my: "auto" }}
                                                    fontWeight={700}
                                                >
                                                    {" "}
                                                    {t(
                                                        "disi.detail_petty_cash.header_withdraw_code"
                                                    )}
                                                </Typography>
                                                <Stack
                                                    alignItems="center"
                                                    direction="row"
                                                    sx={{
                                                        lineHeight: 0,
                                                        gridColumn: {
                                                            xs: "span 4",
                                                            sm: "span 1",
                                                        },
                                                        gap: "1rem",
                                                    }}
                                                >
                                                    <Typography>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "inline",
                                                                },
                                                            }}
                                                        >
                                                            :{" "}
                                                        </Typography>
                                                        <Typography
                                                            component="span"
                                                            display="inline"
                                                            sx={{
                                                                color:
                                                                    detailPettyCashHeader
                                                                        .data
                                                                        .tokenStatus ===
                                                                    "NEW"
                                                                        ? "#00F"
                                                                        : detailPettyCashHeader
                                                                              .data
                                                                              .tokenStatus ===
                                                                          "PAID"
                                                                        ? "#0F0"
                                                                        : "#F00",
                                                            }}
                                                        >
                                                            {role.isAdd
                                                                ? detailPettyCashHeader
                                                                      .data
                                                                      .token
                                                                : "XXXXXXXXXX"}
                                                        </Typography>
                                                    </Typography>
                                                </Stack>
                                                {!role.isAdd &&
                                                    detailPettyCashHeader.data
                                                        .tokenStatus ===
                                                        "NEW" && (
                                                        <Button
                                                            disabled={
                                                                loadingHeaderPettyCashActions
                                                            }
                                                            variant="contained"
                                                            sx={{
                                                                fontSize:
                                                                    ".75rem",
                                                                p: "2px",
                                                                gridColumn: {
                                                                    xs: "span 4",
                                                                    sm: "span 1",
                                                                },
                                                            }}
                                                            onClick={
                                                                handleCancelTokenSubmit
                                                            }
                                                        >
                                                            {loadingHeaderPettyCashActions ? (
                                                                <CircularProgress
                                                                    size={16}
                                                                />
                                                            ) : (
                                                                "Cancel"
                                                            )}
                                                        </Button>
                                                    )}
                                            </>
                                        )}
                                </Box>
                            )}
                        </CardContent>
                    </Card>

                    <Card sx={{ height: "fit-content" }}>
                        <CardContent
                            sx={{
                                "& .MuiDataGrid-root": {
                                    height: 161,
                                },
                            }}
                        >
                            <Typography
                                className="headerName"
                                variant="h6"
                                component="h1"
                                sx={{
                                    mb: "1.5rem",
                                    fontWeight: 600,
                                }}
                            >
                                {t("disi.detail_petty_cash.table_petty_header")}
                            </Typography>
                            <DataTable
                                {...{
                                    columns: columnTableJumlah,
                                    page: pageJumlah,
                                    pageSize: pageSizeJumlah,
                                    setPage: setPageJumlah,
                                    setPageSize: setPageSizeJumlah,
                                    data: detailPettyCashBody.data.data,
                                    totalRows:
                                        detailPettyCashBody.data.totalRows,
                                    loading: detailPettyCashBody.loading,
                                }}
                            />
                        </CardContent>
                    </Card>

                    <Card sx={{ height: "fit-content" }}>
                        <CardContent>
                            <Stack
                                spacing={2}
                                direction="row"
                                className="headerSetorku"
                                sx={{
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    className="headerName"
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        mb: "1.5rem",
                                        fontWeight: 600,
                                    }}
                                >
                                    {t("disi.petty_cash.list_accountability")}
                                </Typography>
                                {Boolean(role.isAdd) &&
                                    (detailPettyCashHeader.data
                                        .accountabilityStatus === "NEW" ||
                                        detailPettyCashHeader.data
                                            .accountabilityStatus ===
                                            "NOT COMPLETED") &&
                                    detailPettyCashHeader.data.status ===
                                        "APPROVE" && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                height: "42px",
                                                borderColor:
                                                    "rgba(34, 136, 102, 0.5)",
                                                color: "#FFFFFF",
                                                backgroundColor:
                                                    "rgba(40, 99, 83, 1) !important",
                                                shadows: "rgba(0, 0, 0, 0.12)",
                                            }}
                                            onClick={
                                                viewModel.handleAccountabilityModalOpen
                                            }
                                        >
                                            {t("disi.petty_cash.add")}
                                        </Button>
                                    )}
                            </Stack>
                            <DataTable
                                {...{
                                    columns: columnTableAccountability,
                                    page: pageAccountability,
                                    pageSize: pageSizeAccountability,
                                    setPage: setPageAccountability,
                                    setPageSize: setPageSizeAccountability,
                                    data: detailPettyCashAccountability.data
                                        .data,
                                    totalRows:
                                        detailPettyCashAccountability.data
                                            .totalRows,
                                    loading:
                                        detailPettyCashAccountability.loading,
                                }}
                            />
                        </CardContent>
                    </Card>
                </Box>
                <ApproveForm
                    isOpen={viewModel.approveOpen !== ""}
                    handleClose={viewModel.handleApproveClose}
                    handleSubmit={viewModel.handleApproveSubmit}
                />
                <FormProvider {...viewModel.rejectReason}>
                    <RejectForm
                        isOpen={viewModel.modalOpen !== ""}
                        onClose={viewModel.handleModalClose}
                        onSubmit={viewModel.handleRejectSubmit}
                    />
                </FormProvider>
                <FormProvider {...viewModel.accountabilityForm}>
                    <AccountabilityForm
                        isOpen={viewModel.accountabilityModal}
                        onClose={viewModel.handleAccountabilityModalClose}
                        onSubmit={viewModel.handleAccountabilityFormSubmit}
                    />
                </FormProvider>
                <RechargeBalanceModal
                    isOpen={rechargeModal}
                    handleClose={handleRechargeModalclose}
                    handleSubmit={redirectToPartnerDeposit}
                />
            </Container>
        </LayoutComponent>
    );
};

export default DisiDetailPettyCashComponent;
