import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import MerchantDepositUseCase from "domains/interactor/main/disi/MerchantDeposit";

type iState = {
    data: string;
    error: any;
    loading: boolean;
}

const initialState: iState = {
    data: "",
    error: "",
    loading: false
}

export const merchantDepositSaldoAction = createAsyncThunk('merchant-deposit-saldo', async (_, { rejectWithValue }) => {
    try {
        const useCase = new MerchantDepositUseCase(new DisiApi())

        return await useCase.getTotalSaldo()
    } catch (error) {
        const { response = null } = error

        if (!response) {
            throw error;
        }

        return rejectWithValue(response.data)
    }
})

const merchantDepositSaldoSlice = createSlice(
    {
        name: "merchant-deposit-total-saldo",
        initialState,
        reducers: {},
        extraReducers: builder => {
            builder.addCase(merchantDepositSaldoAction.pending, state => {
                state.loading = true;
                state.error = "";
            })
            builder.addCase(merchantDepositSaldoAction.fulfilled, (state, action) => {
                state.loading = false;
                state.error = "";
                state.data = action.payload;
            })
            builder.addCase(merchantDepositSaldoAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.data = ""
            })
        }
    }
)

const merchantDepositSaldoReducer = merchantDepositSaldoSlice.reducer

export default merchantDepositSaldoReducer 