import React from "react";
import { Grid, Box, NativeSelect } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { getLng, setLng } from "utils/storage/LocalStorage";

export default function LangChange() {
    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 2,
                    pl: 1,
                    borderColor: "black",
                }}
            >
                <LanguageIcon
                    sx={{ color: "black", marginRight: { sm: "0.5rem" } }}
                />
                <NativeSelect
                    defaultValue={getLng()}
                    onChange={(e): any => setLng(e.target.value)}
                    disableUnderline
                >
                    <option value={"id"}>ID</option>
                    <option value={"en"}>EN</option>
                </NativeSelect>
            </Box>
        </Grid>
    );
}
