import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { showFilterModal } from "utils/store/global/modal";

export const useOtotransBankAccountViewModel = () => {
    const { t }: any = useTranslation();
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state.main.ototrans);
    const modal = useAppSelector((state) => state.global.modal);

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const columns: GridColDef[] = [
        { field: "id", headerName: "No", flex: 1, minWidth: 100, maxWidth: 100 },
        {
            field: "bankCode",
            headerName: t("ototrans.bank_account.bank_code"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "bankName",
            headerName: t("ototrans.bank_account.bank_name"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "accountNumber",
            headerName: t("ototrans.bank_account.account_number"),
            flex: 1,
            minWidth: 200,
        },
    ];

    const modalHandler = (type: string, data?: any) => {
        switch (type) {
            case "filter":
                dispatch(showFilterModal(!modal.filter));
                break;
                default:
                    break;
        }
    }

    const submitFilterProduct = (data: any) => {
    }

    return {
        t,
        data: selector,
        modal,
        modalHandler,
        columns,
        submitFilterProduct,
        page,
        setPage,
        pageSize,
        setPageSize,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    }
}