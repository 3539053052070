import React from "react";
import "./assets/css/_global.scss";
import Router from "./domain/router/Router";
import SnackBarComponent from "presentation/components/SnackBarComponent";
import { ThemeProvider, createTheme } from "@mui/material";
import SuccessModal from "presentation/components/SuccessModal";

const sobTheme = createTheme({
    palette: {
        primary: {
            main: "rgba(40, 99, 83, 1)",
        },
    },
});

function App(): JSX.Element {
    React.useEffect(() => {
        document.title = "Inovasi Daya Solusi";
    }, []);
    return (
        <ThemeProvider theme={sobTheme}>
            <Router />
            <SnackBarComponent />
            <SuccessModal/>
        </ThemeProvider>
    );
}

export default App;
