import { SelectChangeEvent } from "@mui/material/Select";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { OtotransApi } from "data/main/ototransApi";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { formatDate } from "utils/formatDate";
import { ototransTransaksiListAction } from "utils/store/main/ototrans/transaction";
import { ototransPartnerListAction } from "utils/store/main/ototrans/partner";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const useOtotransPartnerViewModel = () => {
  const { t }:any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ototrans);
  const apiRef = React.useRef(new OtotransApi());

  const [dateType, setDateType] = React.useState("createdDate");
  const [status, setStatus] = React.useState("ALL");
  const [sortBy, setSortBy] = React.useState("trackingReff");
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [openModalFilter, setOpenModalFilter] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [partnerId, setPartnerId] = React.useState("ALL");

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "idPartner",
      headerName: t("ototrans.partner.id_partner"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "description",
      headerName: t("ototrans.partner.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "withdrawFee",
      headerName: t("ototrans.partner.withdraw_fee"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "transactionFee",
      headerName: t("ototrans.partner.transaction_fee"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "serviceFee",
      headerName: t("ototrans.partner.service_fee"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "idEmail",
      headerName: t("ototrans.partner.id_email"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: t("ototrans.partner.action"),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button variant="contained" color="primary">
            {t("ototrans.partner.edit")}
          </Button>
          <Button variant="contained" color="error">
            {t("ototrans.partner.delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  const fetchData = async (
    pageSize: number,
    pageNumber: number,
    status: string,
    partnerId: string,
    fromDate: string,
    toDate: string,
    sortBy: string,
    dateType: string
  ) => {
    await dispatch(
      ototransTransaksiListAction({
        dateType,
        fromDate,
        pageNumber,
        pageSize,
        partnerId,
        sortBy,
        status,
        toDate,
      })
    );
  };

  const fetchPartner = async () => {
    await dispatch(ototransPartnerListAction());
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeDateType = (event: SelectChangeEvent) => {
    setDateType(event.target.value as string);
  };

  const handleChangePartner = (event: SelectChangeEvent) => {
    setPartnerId(event.target.value as string);
  };

  const clearFilter = () => {
    setStartdate(new Date());
    setEndate(new Date());
    setPartnerId("ALL");
    setStatus("ALL");
    fetchData(
      pageSize,
      page,
      "ALL",
      "ALL",
      dayjs(new Date()).format("DD-MM-YYYY"),
      dayjs(new Date()).format("DD-MM-YYYY"),
      "trackingReff",
      "createdDate"
    )
    setOpenModalFilter(false);
  };

  const onSubmit = () => {
    fetchData(
      pageSize,
      page,
      status,
      partnerId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY"),
      sortBy,
      dateType
    )
    setOpenModalFilter(false);
  };

  const exportOtotrans = async (
    status = "ALL",
    partnerId = "ALL",
    startDate: string,
    endDate: string,
    sortBy = "trackingReff",
    dateType = "createdDate"
  ) => {
    const exportResult = await apiRef.current.exportOtotransTransaksiList(
      startDate,
      endDate,
      partnerId,
      status,
      sortBy,
      dateType
    );

    const blob = new Blob([exportResult], { type: "application/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "ExportMerchantTransaksi.csv";
    link.click();
  };

  const onExpor = () => {
    exportOtotrans(partnerId, status, dayjs(startDate).format('DD-MM-YYYY') , dayjs(endDate).format('DD-MM-YYYY'), sortBy, dateType);
  };

  React.useEffect(() => {
    fetchData(
      pageSize,
      page,
      status,
      partnerId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY"),
      sortBy,
      dateType
    );
  }, [pageSize, page]);

  React.useEffect(() => {
    fetchPartner();
  }, []);

  return {
    fetchData,
    data: selector,
    fetchPartner,
    status,
    handleChangeStatus,
    page,
    setPage,
    pageSize,
    setPageSize,
    openModalFilter,
    setOpenModalFilter,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    clearFilter,
    setStatus,
    partnerId,
    setPartnerId,
    handleChangePartner,
    onSubmit,
    columns,
    onExpor,
    t,
    dateType,
    setDateType,
    handleChangeDateType,
  };
};

export default useOtotransPartnerViewModel;
