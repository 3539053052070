import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import shadows from "@mui/material/styles/shadows";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DaftarKodeUseCase from "domain/interactor/main/setorku/DaftarKodeUseCase";
import PartnerUseCase from "domain/interactor/main/setorku/PartnerUseCase";
import { width } from "@mui/system";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import useProdukViewModel from "./ProductViewModel";
import useHistoryTransaksiViewModel from "./ProductViewModel";
import useDanaHistoryTransactionViewModel from "./ProductViewModel";
import usePPOBProductViewModel from "./ProductViewModel";
import ModalComponent from "presentation/components/ModalComponent";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

const PPOBProductComponent = () => {
  const {
    data,
    modal,
    modalHandler,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    submitFilter,
    submitEdit,
    handleSubmit,
    register,
    control,
    clearFilter,
  } = usePPOBProductViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.product.product_list_header")}
                </Typography>

                <Stack spacing={1} direction="row" className="headerButton" sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                    pb: "1rem",
                  },
                }}>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                        "@media (max-width: 600px)": {
                          width: "100%",
                        },
                      }}
                      onClick={() => modalHandler('filter')}
                    >
                      {t("ppob.product.filter_button")}
                    </Button>
                </Stack>
              </Stack>

              {data.productList.loading ? (
                <h1>Loading ...</h1>
              ) : (
              <DataTable
                {...{
                  data: data.productList.data.list,
                  columns,
                  totalRows: data.productList.data.total,
                  setPage,
                  setPageSize,
                  page,
                  pageSize,
                }}
              />
                )}
            </Grid>
          </Box>
        </div>

        <ModalContainer open={modal.filter} onClose={()=>modalHandler('filter')} applyButton={handleSubmit(submitFilter)}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Controller
              name="filter.filterText"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("ppob.product.filter_text")}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          </ModalContainer>

          <ModalContainer open={modal.edit} onClose={()=>modalHandler('edit')} applyButton={handleSubmit(submitEdit)}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Controller
              name="edit.ppobId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("ppob.product.id_ppob")}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Controller
              name="edit.productId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("ppob.product.id_product")}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <Controller
              name="edit.productName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("ppob.product.product_name")}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.product.status")}
            </InputLabel>
            <Controller
              name="edit.isActive"
              control={control}
              defaultValue="1"
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">Inactive</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          </ModalContainer>

          {/* <ModalComponent
          header={t("ppob.product.edit_header")}
          subHeader={t("ppob.product.edit_subheader")}
          closeModal={() => setModalEditProduct(false)}
          modal={modalEditProduct}
          applyButton={submitFilter}
          cancelButton={()=>setModalEditProduct(false)}
          formData={formEditData}
          /> */}
      </Container>
    </LayoutComponent>
  );
};

export default PPOBProductComponent;
