import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  Divider,
  Stack,
  Modal,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface iModalContainer {
  open: boolean;
  onClose: () => void;
  applyButton?: () => void;
  cancelButton?: () => void;
  header?: string;
  subHeader?: string;
  children: React.ReactNode;
}

const ModalContainer: React.FC<iModalContainer> = ({
  open,
  onClose,
  header,
  subHeader,
  cancelButton,
  applyButton,
  children,
}) => {
  const { t }: any = useTranslation();
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "white",
    borderRadius: "20px",
    p: 4,
    "@media (max-width: 600px)": {
      width: "100%",
      height: "100%",
      borderRadius: "0px",
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style} className="ModelFilter">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 600,
              }}
            >
              {header ? header : "HEADER"}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                fontSize: "0.5rem",
                lineHeight: "166%",
                color: "#6E6E6E",
                mb: "1rem",
              }}
            >
              {subHeader ? subHeader : "SUBHEADER"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CloseIcon onClick={onClose} />
          </Grid>
        </Grid>
        <Divider
          variant="middle"
          sx={{
            mb: "1rem",
          }}
        />
        <Stack spacing={2} direction="column" sx={{ mb: "1rem" }}>
          {children}
        </Stack>
        <Stack spacing={2} direction="row">
          <Button
            onClick={cancelButton ? cancelButton : onClose}
            variant="text"
            sx={{
              color: "rgba(40, 99, 83, 1)",
              width: "317.5px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={applyButton ? applyButton : onClose}
            variant="contained"
            sx={{
              color: "white",
              background: "rgba(40, 99, 83, 1) !important",
              width: "317.5px",
            }}
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalContainer;
