import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import dayjs from "dayjs";
import { PettyCashBodyParams, PettyCashFilterForm } from "domains/entity/main/disi";
import PettyCashBodyResult from "domains/entity/main/disi/PettyCashBodyResult";
import PettyCashUseCase from "domains/interactor/main/disi/PettyCash";

export type PettyCashState = {
    page: number;
    pageSize: number;
    formState: PettyCashFilterForm;
};

type iState = {
    data: PettyCashBodyResult;
    loading: boolean;
    error: any;
    savedState: PettyCashState
}

export const initialFilterForm = {
    fromDate: dayjs().format('MM-DD-YYYY'),
    toDate: dayjs().format('MM-DD-YYYY'),
    pettyCashId: "",
    tipePettyCash: "",
    status: "",
    accountabilityStatus: "",
}

const initialData = {
    data: [],
    pageLimit: 0,
    totalRows: 0
}

const initialState: iState = {
    data: initialData,
    error: "",
    loading: false,
    savedState: {
        formState: initialFilterForm,
        page: 0,
        pageSize: 6
    }
}

export const pettyCashBodyAction = createAsyncThunk('petty-cash-body-action', async (args: PettyCashBodyParams, { rejectWithValue }) => {
    try {
        const useCase = new PettyCashUseCase(new DisiApi())

        return await useCase.getPettyCashBodyData(args)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const pettyCashBodySlice = createSlice({
    name: "petty-cash-body-slice",
    initialState,
    reducers: {
        setPettyCashState: (state, action) => {
            state.savedState = action.payload
        },
        resetPettyCashBody: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(pettyCashBodyAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(pettyCashBodyAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = ""
        })
        builder.addCase(pettyCashBodyAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
            state.data = initialData
        })
    }
})

const pettyCashBodyReducer = pettyCashBodySlice.reducer

export const { resetPettyCashBody, setPettyCashState } = pettyCashBodySlice.actions

export default pettyCashBodyReducer
