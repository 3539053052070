import * as React from "react";
import { Box, Stack } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "presentation/components/navbar/Navbar";
import { getToken, getUser } from "utils/storage/LocalStorage";
import { useAppDispatch } from "domain/hooks/redux-hook";
import SuccessModal from "presentation/components/SuccessModal";
import {  } from "utils/store/global/alert";
// import "./_layout.scss";

interface LayoutComponentProps {
    children: JSX.Element
}

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children }) => {
  const navigate = useNavigate()

  React.useEffect(() => {
    const token = getToken() || ''
    const user = getUser() || ''
    if (!token || !user) {
      return navigate('/')
    } 
  },[navigate])

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar produkRoutes={[]}/>
      <Stack
        className="homeBox setorkuBox"
        sx={{ pt: "68px", flexGrow: 1, minWidth: "20%" }}
      >
        {children}
      </Stack>
    </Box>
  );
};

export default LayoutComponent;
