export const extractColor = <T extends object>(map: T, key: keyof T) => {
    if (key in map) {
        return map[key] as string;
    }
    return "#000";
};

export const pettyStatusColorMap = {
    NEW: "#0288D1",
    APPROVE: "#2E7D32",
    REJECTED: "#D32F2F",
};

export const accountabilityStatusColorMap = {
    NEW: "#0288D1",
    ONPROGRESS: "#FF9800",
    FAILED: "#D32F2F",
    "NOT COMPLETED": "#D32F2F",
    COMPLETED: "#2E7D32",
};
