import React from "react";
import { TextField, MenuItem, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import { useAppSelector } from "domain/hooks/redux-hook";
import FormModalContainer from "presentation/components/FormModalContainer";

type RequestFormProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    handleReset?: () => void;
    onSubmit?: (...input: any[]) => void;
};

const FilterForm: React.FC<RequestFormProps> = ({
    isOpen = false,
    handleClose = () => {},
    handleReset = () => {},
    onSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const { control, register, handleSubmit, setValue } = useFormContext();

    const {  partnerDepositStatus, partnerDepositType } = useAppSelector(
        (state) => state.main.disi
    );

    return (
        <FormModalContainer
            open={isOpen}
            onClose={handleClose}
            handleSubmit={handleSubmit}
            applyButton={onSubmit}
            applyText={t("disi.partner_deposit.filter_submit") as string}
            cancelButton={handleReset}
            cancelText={t("disi.partner_deposit.filter_cancel") as string}
            header="Filter"
            subHeader="Partner Deposit"
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Controller
                        name="fromDate"
                        control={control}
                        render={({ field: { value, name } }) => (
                            <DesktopDatePicker
                                label={t(
                                    "disi.partner_deposit.filter_start_date"
                                )}
                                inputFormat="DD-MM-YYYY"
                                disableFuture
                                value={value === "" ? null : value}
                                onChange={(val) => {
                                    const date = dayjs(val).toDate();
                                    setValue(name, date);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        onChange={(e) => e.target.blur()}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                )}
                            />
                        )}
                    />
                    <MinimizeOutlinedIcon
                        sx={{
                            color: "rgba(0, 0, 0, 0.54)",
                            m: "1rem",
                        }}
                    />
                    <Controller
                        name="toDate"
                        control={control}
                        render={({ field: { value, name } }) => (
                            <DesktopDatePicker
                                label={t(
                                    "disi.partner_deposit.filter_end_date"
                                )}
                                mask=""
                                inputFormat="DD-MM-YYYY"
                                disableFuture
                                value={value === "" ? null : value}
                                onChange={(val) => {
                                    const date = dayjs(val).toDate();
                                    setValue(name, date);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        onChange={(e) => e.target.blur()}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                )}
                            />
                        )}
                    />
                </Stack>
            </LocalizationProvider>

            <TextField
                {...register("trxId")}
                label={t("disi.partner_deposit.table_transaction_id")}
            />
            <TextField
                {...register("merchantId")}
                label={t("disi.partner_deposit.table_merchant_id")}
            />
            <Controller
                control={control}
                name="tipeTrx"
                render={({ field: { onChange, value } }) => (
                    <TextField
                        select
                        value={value}
                        onChange={onChange}
                        label={t("disi.partner_deposit.table_transaction_type")}
                    >
                        {partnerDepositType.data.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="statusTrx"
                render={({ field: { onChange, value } }) => (
                    <TextField
                        select
                        value={value}
                        onChange={onChange}
                        label={t("disi.partner_deposit.table_status")}
                    >
                        {partnerDepositStatus.data.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
        </FormModalContainer>
    );
};

export default FilterForm;
