import * as yup from 'yup'

const allowed_petty_file_types = ["image/png", "image/jpeg", "application/pdf"]

export const pettyFileValidation = yup.mixed().required("field_required").test({
    name: "petty_file_type_validation",
    test: (file) => {
        if (!file) return false;

        const { type = "" } = file
        if (!type) return false;
        return allowed_petty_file_types.includes(type)
    },
    message: "not_valid_file_type"
}).test({
    name: 'petty_file_size_validation',
    test: (file) => {
        if (!file) return false;
        const { size = 0 } = file
        return size <= 2e+6
    },
    message: "not_valid_file_size"
})