import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import ModalContainer from "presentation/components/ModalContainer";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

interface Props {
    header?: string;
    subHeader?: string;
    open: boolean;
    onClose: () => void;
    applyButton: (data: any) => void;
}

const AddModal: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const { handleSubmit, control, formState: { errors } } = useForm();

    return (
        <ModalContainer
            header={props.header ?? "Header"}
            subHeader={props.subHeader ?? "SubHeader"}
            open={props.open}
            onClose={props.onClose}
            applyButton={handleSubmit(props.applyButton)}
            cancelButton={props.onClose}
        >
            <FormControl fullWidth>
                <Controller
                    name="partnerName"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t("setorku.partner.partner_name")}
                            variant="outlined"
                            size="small"
                            sx={{
                                width: "100%",
                            }}
                        />
                    )}
                />
                {errors.partnerName && (
                    <Typography variant="caption" color="error">
                        {t("setorku.product.id_product_required")}
                    </Typography>
                )}
            </FormControl>
            <FormControl fullWidth>
                <Controller
                    name="partnerId"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t("setorku.partner.partner_id")}
                            variant="outlined"
                            size="small"
                            sx={{
                                width: "100%",
                            }}
                        />
                    )}
                />
                {errors.partnerId && (
                    <Typography variant="caption" color="error">
                        {t("setorku.product.id_product_required")}
                    </Typography>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("setorku.partner.is_active")}</InputLabel>
                <Controller
                    name="isActive"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            label={t("setorku.partner.is_active")}
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            size="small"
                            sx={{
                                width: "100%",
                            }}
                        >
                            <MenuItem value="1">ACTIVE</MenuItem>
                            <MenuItem value="0">INACTIVE</MenuItem>
                        </Select>
                    )}
                />
                {errors.isActive && (
                    <Typography variant="caption" color="error">
                        {t("setorku.product.id_product_required")}
                    </Typography>
                )}
            </FormControl>
        </ModalContainer>
    );
};

export default AddModal;