import { combineReducers } from "@reduxjs/toolkit"
import pettyCashHeaderReducer from "./PettyCashHeader"
import pettyCashBodyReducer from "./PettyCashBody"
import pettyCashTypeReducer from "./PettyCashType"
import pettyCashStatusReducer from "./PettyCashStatus"
import pettyCashAccountabilityStatusReducer from "./PettyCashAccountabilityStatus"
import pettyCashChannelReducer from "./PettyCashChannel"
import pettyCashCreateReducer from "./PettyCashCreate"
import detailPettyCashAccountabilityReducer from "./DetailPettyCashAccountability"
import detailPettyCashHeaderReducer from "./DetailPettyCashHeader"
import detailPettyCashBodyReducer from "./DetailPettyCashBody"
import detailPettyCashActionReducer from "./DetailPettyCashActions"
import detailPettyCashAccountabilityActionsReducer from "./DetailPettyCashAccountabilityActions"
import detailPettyCashHeaderActionsReducer from "./DetailPettyCashHeaderActions"
import partnerDepositReducer from './PartnerDeposit'
import partnerDepositPartnerReducer from './PartnerDepositPartner'
import partnerDepositTypeReducer from "./PartnerDepositType"
import partnerDepositStatusReducer from "./PartnerDepositPartner"
import partnerDepositVaReducer from "./PartnerDepositVA"
import partnerDepositWithdrawReducer from "./PartnerDepositWithdraw"
import partnerDepositRequestOTPReducer from "./PartnerDepositRequestOTP"
import partnerDepositWalletReducer from "./PartnerDepositWallet"
import merchantDepositReducer from './MerchantDeposit'
import merchantDepositSaldoReducer from './MerchantDepositSaldo'
import merchantDepositBankReducer from "./MerchantDepositBank"
import merchantDepositBankVaReducer from './MerchantDepositBankVA'
import merchantDepositPartnerReducer from "./MerchantDepositPartner"
import merchantDepositActionsReducer from "./MerchantDepositActions"
import detailMerchantDepositHeaderReducer from "./DetailMerchantDepositHeader"
import detailMerchantDepositBodyReducer from "./DetailMerchantDepositBody"
import detailMerchantDepositSaldoReducer from "./DetailMerchantDepositTotalSaldo"

const disiReducer = combineReducers({
    pettyCashHeader: pettyCashHeaderReducer,
    pettyCashBody: pettyCashBodyReducer,
    pettyCashStatus: pettyCashStatusReducer,
    pettyCashType: pettyCashTypeReducer,
    pettyCashAccountabilityStatus: pettyCashAccountabilityStatusReducer,
    pettyCashCreate: pettyCashCreateReducer,
    pettyCashChannel: pettyCashChannelReducer,
    detailPettyCashHeader: detailPettyCashHeaderReducer,
    detailPettyCashBody: detailPettyCashBodyReducer,
    detailPettyCashAccountability: detailPettyCashAccountabilityReducer,
    detailPettyCashAction: detailPettyCashActionReducer,
    detailPettyCashAccountabilityActions: detailPettyCashAccountabilityActionsReducer,
    detailPettyCashHeaderActions: detailPettyCashHeaderActionsReducer,
    partnerDeposit: partnerDepositReducer,
    partnerDepositVa: partnerDepositVaReducer,
    partnerDepositStatus: partnerDepositStatusReducer,
    partnerDepositType: partnerDepositTypeReducer,
    partnerDepositPartner: partnerDepositPartnerReducer,
    partnerDepositWithdraw: partnerDepositWithdrawReducer,
    partnerDepositRequestOTP: partnerDepositRequestOTPReducer,
    partnerDepositWallet: partnerDepositWalletReducer,
    merchantDeposit: merchantDepositReducer,
    merchantDepositSaldo: merchantDepositSaldoReducer,
    merchantDepositBank: merchantDepositBankReducer,
    merchantDepositBankVa: merchantDepositBankVaReducer,
    merchantDepositPartner: merchantDepositPartnerReducer,
    merchantDepositActions: merchantDepositActionsReducer,
    detailMerchantDepositHeader: detailMerchantDepositHeaderReducer,
    detailMerchantDepositBody: detailMerchantDepositBodyReducer,
    detailMerchantDepositSaldo: detailMerchantDepositSaldoReducer,
})
export default disiReducer