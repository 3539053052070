import { TransactionListParams, TransactionListResult, TransactionRefundParams, TransactionRefundResult, ExportTransactionListParams } from "domains/entity/main/merchantOnBoarding/transaction";
import { TransactionRefundListParams, TransactionRefundListResult, ExportTransactionRefundParams } from "domains/entity/main/merchantOnBoarding/transactionRefund";
import { MerchantOnBoardingRepository } from "domains/repository/main/merchantOnBoarding/MerchantOnBoardingRepository";

export class MerchantOnBoardingInteractor {
    private repository: MerchantOnBoardingRepository;
    constructor(repository: MerchantOnBoardingRepository) {
        this.repository = repository;
    }

    async getMerchantOnBoardingTransactionList(params: TransactionListParams): Promise<TransactionListResult> {
        return this.repository.getMerchantOnBoardingTransactionList(params);
    }

    async merchantOnBoardingTransactionRefund(params: TransactionRefundParams): Promise<TransactionRefundResult> {
        return this.repository.merchantOnBoardingTransactionRefund(params);
    }

    async exportMerchantOnBoardingTransactionList(params: ExportTransactionListParams): Promise<any> {
        return this.repository.exportMerchantOnBoardingTransactionList(params);
    }

    async getMerchantOnBoardingTransactionRefundList(params: TransactionRefundListParams): Promise<TransactionRefundListResult> {
        return this.repository.getMerchantOnBoardingTransactionRefundList(params);
    }

    async exportMerchantOnBoardingTransactionRefundList(params: ExportTransactionRefundParams): Promise<any> {
        return this.repository.exportMerchantOnBoardingTransactionRefundList(params);
    }
}