import { createSlice } from "@reduxjs/toolkit";

interface ModalState {
    add: boolean;
    edit: boolean;
    filter: boolean;
    delete: boolean;
    refund: boolean;
    upload: boolean;
    data: any;
}

const initialState: ModalState = {
    add: false,
    edit: false,
    filter: false,
    delete: false,
    refund: false,
    upload: false,
    data: {},
};

const slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showAddModal: (state, action) => {
            state.add = action.payload;
        },
        showEditModal: (state, action) => {
            state.edit = action.payload;
        },
        showFilterModal: (state, action) => {
            state.filter = action.payload;
        },
        showDeleteModal: (state, action) => {
            state.delete = action.payload;
        },
        showRefundModal: (state, action) => {
            state.refund = action.payload;
        },
        showUploadModal: (state, action) => {
            state.upload = action.payload;
        },
    },
});

export const {
    showAddModal,
    showEditModal,
    showFilterModal,
    showDeleteModal,
    showRefundModal,
    showUploadModal,
} = slice.actions;

export default slice.reducer;