import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stack,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import usePartnerViewModel from "./SetorkuPartnerViewModel";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import { Controller } from "react-hook-form";
import ModalContainer from "presentation/components/ModalContainer";
import ConfirmationModalComponent from "presentation/components/ConfirmationModalComponent";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";


export const SetorkuPartnerComponent = () => {
  const {
    t,
    modal,
    modalHandler,
    submitDelete,
    submitEdit,
    submitAddPartner,
    data,
    columns,
    page,
    setPage,
    pageSize,
    setPageSize,
    control,
    handleSubmit,
    register,
  } = usePartnerViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("setorku.partner.partner_header")}
                </Typography>

                <Stack spacing={2} direction="row" className="headerButton">
                  <Button
                    onClick={() => modalHandler("add")}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("setorku.partner.add_partner_button")}
                  </Button>
                </Stack>
              </Stack>

              {data.partner.loading ? (
                <h1>Loading ...</h1>
              ) : (
                <DataTable
                  {...{
                    ...data.partner.data,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <ConfirmationModalComponent open={modal.delete} onClose={()=>modalHandler('delete')} type="delete" contentText="Delete sam?" action={handleSubmit(submitDelete)} />
        <AddModal applyButton={submitAddPartner} onClose={() => modalHandler('add')} open={modal.add} />
        <EditModal applyButton={submitEdit} onClose={() => modalHandler('edit')} open={modal.edit} data={data.partner.detail} />
      </Container>
    </LayoutComponent>
  );
};
