import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import {
  detailPPobTransaction,
  refundTransactionAction,
  transactionListAction,
} from "utils/store/main/ppob/transaction";
import { Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { partnerListAction } from "utils/store/main/ppob/partner";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { exportCSV } from "domain/hooks/exportCSV";
import { showFilterModal, showRefundModal } from "utils/store/global/modal";

//modal-filter

const usePPOBTransactionViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal);
  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      partnerId: "ALL",
    },
  });

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [partnerId, setPartnerId] = React.useState("ALL");

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 50 },
    // {
    //   field: "trxId",
    //   headerName: t("ppob.transaction.trx_id"),
    //   flex: 1,
    //   minWidth: 150,
    // },
    {
      field: "date",
      headerName: t("ppob.transaction.date"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "partnerId",
      headerName: t("ppob.transaction.code"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "customerId",
      headerName: t("ppob.transaction.customer_no"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "productId",
      headerName: t("ppob.transaction.via"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "receiptNo",
      headerName: t("ppob.transaction.sn"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "trackingRef",
      headerName: t("ppob.transaction.ref"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: t("ppob.transaction.status"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "amount",
      headerName: t("ppob.transaction.amount"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "action",
      headerName: t("ppob.transaction.action"),
      flex: 1,
      minWidth: 100,
      renderCell: (row) => {
        return (
          <Stack direction="row" spacing={1}>
            {row.row.status === "SUCCESS" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleOpenConfirmRefund(row.row)}
              >
                Refund
              </Button>
            ) : (
              <Button variant="contained" color="primary" size="small" disabled>
                Refunded
              </Button>
            )}
          </Stack>
        );
      },
    },
  ];

  const closeModal = (type: string) => {
    switch (type) {
      case "refund":
        dispatch(showRefundModal(!modal.refund));
        break;
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      default:
        break;
    }
  };

  const onExport = async () => {
    const interactor = new PPOBInteractor(new PPOBApi());
    const csv = await interactor.exportTransactionList({
      fromDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
      toDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
      partnerId: partnerId,
    });
    exportCSV(csv, "ppob-transaction-list");
  };

  const handleOpenConfirmRefund = async (data: object) => {
    closeModal("refund");
    await dispatch(detailPPobTransaction(data));
  };

  const handleSubmitRefund = async () => {
    console.log(data.transactionList.detail);
    await dispatch(
      refundTransactionAction({
        customerId: data.transactionList.detail.customerId,
        status: data.transactionList.detail.status,
        trackingRef: data.transactionList.detail.trackingRef,
      })
    );
    closeModal("refund");
    fetchTransaction();
  };

  const handleSubmitFilter = async (data: any) => {
    console.log(data);
    closeModal("filter");
    setPartnerId(data.partnerId === "ALL" ? "" : data.partnerId);
    await dispatch(
      transactionListAction({
        fromDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
        toDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
        // fromDate: "2017-01-18 09:10:00",
        // toDate: "2023-01-23 15:48:39",
        pageNumber: page,
        pageSize: pageSize,
        partnerId: data.partnerId === "ALL" ? "" : data.partnerId,
        trxId: "",
        transactionDate: "",
        productId: "",
        customerId: "",
        receiptNo: "",
        trackingRef: "",
      })
    );
  };

  const fetchTransaction = async () => {
    await dispatch(
      transactionListAction({
        fromDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
        toDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
        // fromDate: "2017-01-18 09:10:00",
        // toDate: "2023-01-23 15:48:39",
        pageNumber: page,
        pageSize: pageSize,
        partnerId: "",
        trxId: "",
        transactionDate: "",
        productId: "",
        customerId: "",
        receiptNo: "",
        trackingRef: "",
      })
    );
  };

  const fetchPartner = async () => {
    await dispatch(
      partnerListAction({
        pageNumber: 1,
        pageSize: 100,
        filterText: "",
      })
    );
  };

  React.useEffect(() => {
    fetchTransaction();
    fetchPartner();
  }, [dispatch, page, pageSize]);

  return {
    data,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    partnerId,
    columns,
    handleSubmitRefund,
    handleSubmitFilter,
    handleSubmit,
    setValue,
    control,
    onExport,
    t,
  };
};

export default usePPOBTransactionViewModel;
