import React from "react";
import { useNavigate } from "react-router-dom";
import { Location } from "react-router-dom";

const useRouterDomState = <T,>(location: Location, redirectURL: string) => {
    const navigate = useNavigate();

    const [routerState, setRouterstate] = React.useState<T>({} as T);

    React.useEffect(() => {
        /* eslint-disable */
        if (!Boolean(location.state)) {
            navigate(redirectURL);
            return;
        }

        const { state } = location;

        if (typeof state !== "object") {
            throw new Error("state location harus berupa object");
        }

        const obj: T = Object.create(state);

        setRouterstate(obj);
    }, [location]);

    return { routerState };
};

export default useRouterDomState;
