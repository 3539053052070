import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositRequestOTPResult from "domains/entity/main/disi/PartnerDepositRequestOTPResult";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";

type iState = {
    result: string;
    loading: boolean;
    error: any;
}

const initialState: iState = {
    result: "",
    loading: false,
    error: ""
}

export const partnerDepositWithdrawAction = createAsyncThunk('partner-deposit-withdraw-action', async ({ codeOTP, amount }: { codeOTP: string, amount: string }, { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())

        const _res = await useCase.postPartnerDepositWithdraw(codeOTP, amount)

        const result = typeof _res === "object" && "responsedesc" in _res ? _res.responsedesc : ""

        return result
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositWithdrawSlice = createSlice({
    name: "partner-deposit-withdraw-slice",
    initialState,
    reducers: {
        resetPartnerDepositWithdraw: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(partnerDepositWithdrawAction.pending, state => {
            state.loading = true;
            state.error = "";
            state.result = "";
        })
        builder.addCase(partnerDepositWithdrawAction.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
            state.error = "";
        })
        builder.addCase(partnerDepositWithdrawAction.rejected, (state, action) => {
            state.loading = false;
            state.result = ""
            state.error = action.payload
        })
    }
})

const partnerDepositWithdrawReducer = partnerDepositWithdrawSlice.reducer

export const { resetPartnerDepositWithdraw } = partnerDepositWithdrawSlice.actions

export default partnerDepositWithdrawReducer