import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { PpobEditGroupParams, PpobGroupParams, PpobGroupResult } from "domains/entity/main/ppob/group";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

interface iState {
  data: PpobGroupResult;
  loading: boolean;
  error: boolean;
}

export const groupListAction = createAsyncThunk(
  "ppob/group",
  async (data: PpobGroupParams) => {
    const { pageNumber, pageSize, filterText } = data;
    try {
      const interactor = new PPOBInteractor(new PPOBApi());
      const response = await interactor.getGroupList({
        pageSize,
        pageNumber,
        filterText
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editGroupAction = createAsyncThunk(
    "ppob/group/edit",
    async (data: PpobEditGroupParams) => {
        const { ppobId,isActive } = data;
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.editGroupList({ppobId, isActive});
            return response;
        } catch (error) {
            throw error;
        }
    }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 0,
    totalRows: 0,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "ppob-group",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(groupListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        groupListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(groupListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

const ppobGroupReducer = slice.reducer;
export default ppobGroupReducer;
