import { Box, TextField } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import React from "react";

type OTPComponentProps = {
    otpValue?: string;
    onChange?: (...args: any[]) => void;
    length?: number;
    sx?: SxProps;
};

const allowedCharacters = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "Backspace",
    "Control",
];

const allowedControlCharacters = ["V", "v"];

type SplitValue = {
    character: string;
    inputRef: React.RefObject<HTMLInputElement>;
};

const OTPComponent: React.FC<OTPComponentProps> = ({
    otpValue = "",
    onChange = () => {},
    length = 5,
    sx = {},
}) => {
    const tValue: Array<SplitValue> = Array.from({ length }, (_, tIdx) => {
        return {
            character: otpValue[tIdx] ? otpValue[tIdx] : "",
            inputRef: React.createRef<HTMLInputElement>(),
        };
    }) as SplitValue[];

    const focusIndex = React.useRef(0);

    const join = () => tValue.map((t) => t.character).join("");

    const selectField = (idx: number) => {
        tValue[idx].inputRef.current?.select();
    };

    const focusField = (idx: number) => {
        tValue[idx].inputRef.current?.focus();
    };

    const nextRef = (currIdx: number) => {
        if (currIdx === length - 1) return;
        const nextIdx = currIdx + 1;
        if (tValue[nextIdx].character) {
            selectField(nextIdx);
        } else {
            focusField(nextIdx);
        }
    };

    const prevRef = (currIdx: number) => {
        if (currIdx === 0) return;
        const prevIdx = currIdx - 1;
        if (tValue[prevIdx].character) {
            selectField(prevIdx);
        } else {
            focusField(prevIdx);
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLDivElement>,
        idx: number
    ) => {
        const { key, ctrlKey } = e;

        if (
            (!ctrlKey && !allowedCharacters.includes(key)) ||
            (ctrlKey && !allowedControlCharacters.includes(key))
        ) {
            e.preventDefault();
            return;
        }

        if (key === "Backspace") {
            e.preventDefault();
            tValue[idx].character = "";
            prevRef(idx);
        } else if (allowedCharacters.includes(key)) {
            e.preventDefault();
            tValue[idx].character = key;
            nextRef(idx);
        }

        onChange(join());
    };

    const handleFocus = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        idx: number
    ) => {
        e.preventDefault();
        e.target.select();
        focusIndex.current = idx;
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        const paste = e.clipboardData.getData("text");
        if (paste.length !== length || !/^\d+$/.test(paste)) {
            e.preventDefault();
            return;
        }

        for (let i = 0; i < length; i++) {
            tValue[i].character = paste[i];
        }
        tValue[focusIndex.current].inputRef.current?.blur();
        onChange(join());
    };

    return (
        <Box display="flex" maxWidth={300} margin="auto" gap={1} sx={{ ...sx }}>
            {tValue.map(({ character, inputRef }, rIdx) => (
                <TextField
                    key={rIdx}
                    value={character}
                    inputRef={inputRef}
                    variant="standard"
                    autoComplete="off"
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: "center" },
                    }}
                    onFocus={(e) => handleFocus(e, rIdx)}
                    onKeyDown={(e) => handleKeyDown(e, rIdx)}
                    onPaste={handlePaste}
                />
            ))}
        </Box>
    );
};

export default React.forwardRef<HTMLDivElement, OTPComponentProps>(
    (props, _) => <OTPComponent {...props} />
);
