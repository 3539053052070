import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { SellingPriceComboboxCategoryResult, SellingPriceListParams, SellingPriceListResult } from "domains/entity/main/ppob/sellingPrice";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

interface iState {
    data: SellingPriceListResult;
    loading: boolean;
    error: boolean;
}

const initialState: iState = {
    data: { total: 0, list: [] },
    loading: false,
    error: false,
};

export const sellingPriceListAction = createAsyncThunk(
  "sellingPriceListAction",
  async (params: SellingPriceListParams) => {
    try {
      const interactor = new PPOBInteractor(new PPOBApi());
      const data = await interactor.getSellingPrice({
        categoryId: params.categoryId,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        partnerId: params.partnerId,
        productSKUCode: params.productSKUCode,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const sellingCategoryListAction = createAsyncThunk(
    "sellingCategoryListAction",
    async () => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const data = await interactor.getComboboxCategories();
            return data;
        } catch (error) {
            throw error;
        }
    }
);

const slice = createSlice({
  name: "sellingPrice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sellingPriceListAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sellingPriceListAction.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(sellingPriceListAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default slice.reducer;
