import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { setorkuDaftarKodeAction } from "utils/store/main/setorku/setorkuDaftarKode";
import SetorkuApi from "data/main/setorkuApi";
import dayjs from "dayjs";
import { setorkuPartnerAction } from "utils/store/main/setorku/setorkuPartner";

//modal-filter

const useDaftarKodeViewModel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector(
    (state) => state.main.setorku.daftarKode
  );
  const partner = useAppSelector(
    (state) => state.main.setorku.partner
  );
  const [mainApi] = React.useState<SetorkuApi>(new SetorkuApi());

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(6);
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [partnerId, setPartnerId] = React.useState("ALL");

  const handleChangePartner = (event: SelectChangeEvent) => {
    setPartnerId(event.target.value as string);
  };
  const fetchPartner = async () => {
    await dispatch(setorkuPartnerAction({
      fromDate: dayjs(startDate).format("DD-MM-YYYY"),
      toDate: dayjs(endDate).format("DD-MM-YYYY")
    }));
  };
  const fetchData = async (
    pageSize: number,
    pageNumber: number,
    partnerId = "ALL",
    fromDate = dayjs(startDate).format("DD-MM-YYYY"),
    toDate = dayjs(endDate).format("DD-MM-YYYY")
  ) => {
    await dispatch(
      setorkuDaftarKodeAction({
        fromDate,
        toDate,
        partnerId,
        pageSize,
        pageNumber,
      })
    );
  };

  const exportDaftarKode = async (
    partnerId = "ALL",
    x = dayjs(startDate).format("DD-MM-YYYY"),
    y = dayjs(endDate).format("DD-MM-YYYY")
  ) => {
    await mainApi.exportSetorkuDaftarKodeList(x, y, partnerId);
  };

  const clearFilter = () => {
    setStartdate(new Date());
    setEndate(new Date());
    setPartnerId("");

    fetchData(
      pageSize,
      page,
      partnerId,
      dayjs(new Date()).format("DD-MM-YYYY"),
      dayjs(new Date()).format("DD-MM-YYYY")
    );
  };

  const handleModal = () => setOpen(!open);

  const onSubmit = (): void => {

    fetchData(
      pageSize,
      page,
      partnerId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY")
    );
    setOpen(false);
  };

  React.useEffect(() => {
    fetchPartner();
    fetchData(
      pageSize,
      page,
      partnerId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY")
    );
  }, [pageSize, page]);

  const onExpor = () => {

    exportDaftarKode(
      partnerId,
      dayjs(startDate).format('DD-MM-YYYY'),
      dayjs(endDate).format('DD-MM-YYYY')
    );
  };
  const columns = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "partnerId",
      headerName: t("setorku.payment_code.biller_id"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "partnerName",
      headerName: t("setorku.payment_code.biller_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "paymentCode",
      headerName: t("setorku.payment_code.payment_code"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "merchantTransactionId",
      headerName: t("setorku.payment_code.transaction_id"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "userCode",
      headerName: t("setorku.payment_code.user_code"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "username",
      headerName: t("setorku.payment_code.username"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "totalAmount",
      headerName: t("setorku.payment_code.amount"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "quantity",
      headerName: t("setorku.payment_code.qty"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "createdDate",
      headerName: t("setorku.payment_code.date_created"),
      flex: 1,
      minWidth: 200,
    },
  ];

  return {
    data,
    page,
    setPage,
    loading,
    pageSize,
    setPageSize,
    open,
    handleModal,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    clearFilter,
    partnerId,
    handleChangePartner,
    onSubmit,
    partner,
    onExpor,
    columns,
    t,
  };
};

export default useDaftarKodeViewModel;
