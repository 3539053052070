import React from "react";
import {
    Box,
    Typography,
    Button,
    Divider,
    Stack,
    Modal,
    Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

interface iModalContainer {
    open: boolean;
    onClose: () => void;
    handleSubmit?: any;
    applyButton?: () => void;
    cancelButton?: () => void;
    header?: string;
    subHeader?: string;
    children: React.ReactNode;
    cancelText?: string;
    applyText?: string;
    loading?: boolean;
}

const style = {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    overflow: "auto",
    bgcolor: "white",
    borderRadius: "20px",
    p: 4,
    "@media (min-width: 968px)": {
        maxHeight: 640,
    },
    "@media (max-width: 600px)": {
        width: "100%",
        height: "100%",
        borderRadius: "0px",
    },
};

const FormModalContainer: React.FC<iModalContainer> = ({
    open,
    onClose,
    header,
    subHeader,
    cancelButton,
    applyButton,
    handleSubmit = () => {},
    children,
    applyText = "apply",
    cancelText = "cancel",
    loading = false,
}) => {
    const handleClose = (_: any, reason: string) => {
        if (reason === "backdropClick" && loading) return;
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(applyButton)}>
                <Box sx={style} className="ModelFilter">
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                {header ? header : "HEADER"}
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{
                                    fontSize: "0.5rem",
                                    lineHeight: "166%",
                                    color: "#6E6E6E",
                                    mb: "1rem",
                                }}
                            >
                                {subHeader ? subHeader : "SUBHEADER"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CloseIcon onClick={onClose} />
                        </Grid>
                    </Grid>
                    <Divider
                        variant="middle"
                        sx={{
                            mb: "1rem",
                        }}
                    />
                    <Stack
                        spacing={2}
                        direction="column"
                        sx={{
                            mb: "1rem",
                            flex: 1,
                            maxHeight: "calc(100svh - 113px)",
                            overflow: "auto",
                            gap: ".5rem",
                            "&:before": {
                                content: '""',
                            },
                            "&:after": {
                                content: '""',
                            },
                            "&::-webkit-scrollbar": {
                                width: 0,
                                background: "transparent",
                            },
                            "&::-moz-scrollbar": {
                                width: 0,
                                background: "transparent",
                            },
                            "&::-ms-scrollbar": {
                                width: 0,
                                background: "transparent",
                            },
                        }}
                    >
                        {children}
                    </Stack>
                    <Stack
                        spacing={2}
                        direction="row"
                        sx={{
                            position: "sticky",
                            bottom: 0,
                            width: "100%",
                        }}
                    >
                        <Button
                            onClick={cancelButton ? cancelButton : onClose}
                            variant="text"
                            disabled={loading}
                            sx={{ flex: 1 }}
                        >
                            {cancelText}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading}
                            sx={{ flex: 1 }}
                        >
                            {loading ? (
                                <CircularProgress size={24} />
                            ) : (
                                applyText
                            )}
                        </Button>
                    </Stack>
                </Box>
            </form>
        </Modal>
    );
};

export default FormModalContainer;
