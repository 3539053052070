import { HistoryTransactionParams, HistoryTransactionResult, RefundHistoryTransactionParams, ExportHistoryTransactionParams } from "domains/entity/main/dana/historyTransaction";
import { SuspectTransactionParams, SuspectTransactionResult, SuccessSuspectTransactionParams, FailedSuspectTransactionParams, ExportSuspectTransactionParams } from "domains/entity/main/dana/transactionSuspect";
import { DanaRepository } from "domains/repository/main/dana/DanaRepository";

export class DanaInteractor implements DanaRepository {
    private danaRepository: DanaRepository;

    public constructor(danaRepository: DanaRepository) {
        this.danaRepository = danaRepository;
    }

    public async getHistoryTransaction(params: HistoryTransactionParams): Promise<HistoryTransactionResult> {
        const result = await this.danaRepository.getHistoryTransaction(params);
        return result;
    }

    public async refundHistoryTransaction(params: RefundHistoryTransactionParams): Promise<string> {
        const result = await this.danaRepository.refundHistoryTransaction(params);
        return result;
    }

    public async exportHistoryTransaction(params: ExportHistoryTransactionParams): Promise<any> {
        const result = await this.danaRepository.exportHistoryTransaction(params);
        return result;
    }

    public async getSuspectTransaction(params: SuspectTransactionParams): Promise<SuspectTransactionResult> {
        const result = await this.danaRepository.getSuspectTransaction(params);
        return result;
    }

    public async successSuspectTransaction(params: SuccessSuspectTransactionParams): Promise<string> {
        const result = await this.danaRepository.successSuspectTransaction(params);
        return result;
    }

    public async failedSuspectTransaction(params: FailedSuspectTransactionParams): Promise<string> {
        const result = await this.danaRepository.failedSuspectTransaction(params);
        return result;
    }

    public async exportSuspectTransaction(params: ExportSuspectTransactionParams): Promise<any> {
        const result = await this.danaRepository.exportSuspectTransaction(params);
        return result;
    }
}