import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import { PettyCashCreateBody } from "domains/entity/main/disi";
import PettyCashUseCase from "domains/interactor/main/disi/PettyCash";

type iState = {
    result: string;
    loading: boolean;
    error: any
}

const initialState: iState = {
    result: "",
    loading: false,
    error: ""
}

export const createPettyCashAction = createAsyncThunk('petty-cash-create-action', async (body: PettyCashCreateBody, { rejectWithValue }) => {
    try {
        const useCase = new PettyCashUseCase(new DisiApi())

        return await useCase.postPettyCashCreateData(body)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const pettyCashCreateSlice = createSlice({
    name: "petty-cash-create-slice",
    initialState,
    reducers: {
        resetPettyCashCreate: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(createPettyCashAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(createPettyCashAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload as { responsedesc: string }

            state.loading = false;
            state.error = ""
            state.result = responsedesc
        })
        builder.addCase(createPettyCashAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.result = ""
        })
    }
})

const pettyCashCreateReducer = pettyCashCreateSlice.reducer

export const { resetPettyCashCreate } = pettyCashCreateSlice.actions

export default pettyCashCreateReducer