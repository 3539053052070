import { combineReducers } from "@reduxjs/toolkit";
import ppobTransactionListReducer from "./transaction";
import ppobPartnerReducer from "./partner";
import ppobProductReducer from "./product";
import ppobGroupReducer from "./group";
import ppobPaymentMethodReducer from "./paymentMethod";
import ppobCollectingAgentReducer from "./collectingAgent";
import ppobDetailCollectingAgentReducer from "./detailCollectingAgent";
import ppobSellingPriceReducer from "./sellingPrice";

const ppobReducer = combineReducers({
    partnerList: ppobPartnerReducer,
    transactionList: ppobTransactionListReducer,
    productList: ppobProductReducer,
    groupList: ppobGroupReducer,
    paymentMethod: ppobPaymentMethodReducer,
    collectingAgent: ppobCollectingAgentReducer,
    detailCollectingAgent: ppobDetailCollectingAgentReducer,
    sellingPrice: ppobSellingPriceReducer,
})

export default ppobReducer