import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { setorkuDaftarKodeAction } from "utils/store/main/setorku/setorkuDaftarKode";
import SetorkuApi from "data/main/setorkuApi";
import dayjs from "dayjs";
import { setorkuPartnerAction } from "utils/store/main/setorku/setorkuPartner";
import { GridColDef } from "@mui/x-data-grid";
import { productListAction, editProductAction } from "utils/store/main/ppob/product";
import { Button } from "@mui/material";
import { useForm, useController } from "react-hook-form";
import { showAddModal, showEditModal, showFilterModal } from "utils/store/global/modal";

//modal-filter

const usePPOBProductViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal);
  const {handleSubmit,register, setValue, control, reset} = useForm({
    defaultValues: {
      edit: {
        ppobId: "",
        productId: "",
        productName: "",
        isActive: "1"
      },
      filter: {
        filterText: "",
      }
    }
  })

  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [modalAddProduct, setModalAddProduct] = React.useState(false);
  const [modalFilter, setModalFilter] = React.useState(false);
  const [modalEditProduct, setModalEditProduct] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [detail, setDetail] = React.useState<any>({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "ppobId",
      headerName: t("ppob.product.id_ppob"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "productId",
      headerName: t("ppob.product.id_product"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "productName",
      headerName: t("ppob.product.product_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "isActive",
      headerName: t("ppob.product.status"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row:any) => {
        return (
          <Button onClick={() => openModalEdit(row.row)}>
            <EditIcon />
          </Button>
        );
      },
    },
  ];

const openModalEdit = (data:any) => {
  console.log(data);
    modalHandler("edit");
    setValue("edit.ppobId", data.ppobId);
    setValue("edit.productId", data.productId);
    setValue("edit.productName", data.productName);
    setValue("edit.isActive", data.isActive === "Active" || data.isActive === "Aktif" ? "1" : "0");
  };

  const submitFilter = (data:any) => {
    console.log(data);
    dispatch(
      productListAction({
        pageSize,
        pageNumber: page,
        filterText: data.filter.filterText,
      })
    );
    setModalFilter(false);
  }

  const submitEdit = async (data:any) => {
    await dispatch(
      editProductAction({
        ppobId: data.edit.ppobId,
        productId: data.edit.productId,
        isActive: data.edit.isActive
      })
    );
    modalHandler("edit");
    dispatch(
      productListAction({ pageSize, pageNumber: page, filterText: "" })
    );
    reset()
  }

  const clearFilter = () => {
    dispatch(
      productListAction({ pageSize: 6, pageNumber: 1, filterText: "" })
    );
    setModalFilter(false);
  };

  React.useEffect(() => {
    dispatch(
      productListAction({ pageSize, pageNumber: page, filterText: "" })
    );
  }, [pageSize, page]);

  const formFilterData = [
    {
      label: t("setorku.product.product_name"),
      value: "",
      type: "text",
      dataId: "filterText",
    },
  ];

  const formEditData = [
    {
      label: t("setorku.product.id_ppob"),
      value: "",
      type: "text",
      dataId: "idPpob",
    },
    {
      label: t("setorku.product.id_product"),
      value: "",
      type: "text",
      dataId: "idProduct",
    },
    {
      label: t("setorku.product.product_name"),
      value: "",
      type: "text",
      dataId: "productName",
    },
    {
      label: t("setorku.product.status"),
      value: "",
      type: "text",
      dataId: "isActive",
    },
  ];

  const closeModal = (type:string) => {
    if (type === "add") {
    dispatch(showAddModal(false));
    } else if (type === "filter") {
    dispatch(showFilterModal(false));
    } else if (type === "edit") {
    dispatch(showEditModal(false));
    } 
  }   

  const openModal = (type:string) => {
    if (type === "add") {
    dispatch(showAddModal(true));
    } else if (type === "filter") {
    dispatch(showFilterModal(true));
    } else if (type === "edit") {
    dispatch(showEditModal(true));
    }
  }

  const modalHandler = (type:string, data?:any) => {
    switch (type) {
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        break;
      default:
        break;
    }
  };

  return {
    data: selector,
    modal,
    modalHandler,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    columns,
    t,
    modalAddProduct,
    setModalAddProduct,
    modalFilter,
    setModalFilter,
    modalEditProduct,
    setModalEditProduct,
    submitFilter,
    submitEdit,
    handleSubmit,
    register,
    control,
    clearFilter,
    detail,
  };
};

export default usePPOBProductViewModel;
