import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { DetailMerchantDepositHeaderResult } from "domains/entity/main/disi";
import DisiApi from "datas/disi/DisiApi";
import DetailMerchantDepositUseCase from "domains/interactor/main/disi/DetailMerchantDeposit";

type iState = {
    data: DetailMerchantDepositHeaderResult,
    loading: boolean;
    error: any
}

const initialData = {
    bankAccount: "",
    bankCode: "",
    bankNm: "",
    bankVa: "",
    bankVaNm: "",
    merchantNm: "",
    partnerNm: "",
    vaNumber: ""
}

const initialState: iState = {
    data: initialData,
    loading: false,
    error: ""
}

type HeaderArgs = {
    merchantNm: string;
    partnerNm: string;
}

export const detailMerchantDepositHeaderAction = createAsyncThunk('disi-detail-merchant-header', async (args: HeaderArgs, { rejectWithValue }) => {
    try {
        const { merchantNm, partnerNm } = args

        const useCase = new DetailMerchantDepositUseCase(new DisiApi())
        const result = await useCase.getDetailMerchantDepositHeader(merchantNm, partnerNm)

        return result
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const detailMerchantDepositHeaderSlice = createSlice({
    name: 'detail-merchant-deposit-header-slice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(detailMerchantDepositHeaderAction.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(detailMerchantDepositHeaderAction.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = ""
        })
        builder.addCase(detailMerchantDepositHeaderAction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
            state.data = initialData
        })
    }
})

const detailMerchantDepositHeaderReducer = detailMerchantDepositHeaderSlice.reducer

export default detailMerchantDepositHeaderReducer