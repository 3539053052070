import React from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { setorkuPartnerAction } from "utils/store/main/setorku/setorkuPartner";
import { productListAction } from "utils/store/main/setorku/product";
import dayjs from "dayjs";
import { reconciliationsListAction } from "utils/store/main/setorku/recon";
import { SetorkuInteractor } from "domains/interactor/main/setorku/SetorkuInteractor";
import SetorkuApi from "datas/setorku/SetorkuApi";
import { exportCSV } from "domain/hooks/exportCSV";
import { useForm } from "react-hook-form";
//modal-filter

const useRekonViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.setorku);
  const {control, register, handleSubmit } = useForm({
    defaultValues: {
        caName: "ALL",
        billerName: "ALL",
        paymentCode: "",
    },
  });
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(6);
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [caId, setCaId] = React.useState("ALL");
  const [billerId, setBillerId] = React.useState("ALL");
  const [totalTransaction, setTotalTransaction] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dateNowFormated = dayjs(new Date()).format("DD-MM-YYYY");
  const handleChangePartner = (event: SelectChangeEvent) => {
    setCaId(event.target.value as string);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setBillerId(event.target.value as string);
  };

  const [kodePembayaran, setKodePembayaran] = React.useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKodePembayaran(event.target.value);
  };

  const fetchData = async (
    pageSize: number,
    pageNumber: number,
    caId = "ALL",
    startDate = dateNowFormated,
    endDate = dateNowFormated,
    kodePembayaran = "",
    billerId = "ALL"
  ) => {
    await dispatch(
      reconciliationsListAction({
        fromDate: startDate,
        toDate: endDate,
        caId,
        pageSize,
        pageNumber,
        kodePembayaran,
        billerId,
      })
    );

    let totalTransaction = 0;

    for (const data of selector.rekonsiliasi.data.data) {
      totalTransaction += parseInt(data.amount, 10);
    }
    console.log(totalTransaction);
    setTotalTransaction(totalTransaction);
    setTotalAmount(selector.rekonsiliasi.data.totalRows);
  };

  const exportRekon = async (
    caId = "ALL",
    startDate = dateNowFormated,
    endDate = dateNowFormated,
    kodePembayaran = "",
    billerId = "ALL"
  ) => {
    const interactor = new SetorkuInteractor(new SetorkuApi());
    const response = await interactor.exportRecon({
      caId,
      fromDate: startDate,
      toDate: endDate,
      kodePembayaran,
      billerId,
    });
    exportCSV(response, "setorku-recon");
  };

  const fetchPartner = async () => {
    dispatch(
      setorkuPartnerAction({
        fromDate: dayjs(startDate).format("DD-MM-YYYY"),
        toDate: dayjs(endDate).format("DD-MM-YYYY"),
      })
    );
  };
  const fetchProduct = async () => {
    new Date();
    dispatch(
      productListAction({
        fromDate: dayjs(startDate).format("DD-MM-YYYY"),
        toDate: dayjs(endDate).format("DD-MM-YYYY"),
      })
    );
  };

  React.useEffect(() => {
    fetchPartner();
  }, []);

  React.useEffect(() => {
    fetchProduct();
  }, []);

  const onExpor = () => {
    exportRekon(
      caId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY"),
      kodePembayaran,
      billerId
    );
  };

  React.useEffect(() => {
    fetchData(
      pageSize,
      page,
      caId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY"),
      kodePembayaran,
      billerId
    );
  }, [pageSize, page]);

  const onSubmit = (data: any): void => {
    console.log('data',data);
    fetchData(
      pageSize,
      page,
      data.caName,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY"),
      data.paymentCode,
      data.billerName
    );
    setOpen(false);
  };

  const clearFilter = () => {
    setStartdate(new Date());
    setEndate(new Date());
    setCaId("ALL");
    setBillerId("ALL");
    setKodePembayaran("");
    fetchData(
      pageSize,
      page,
      caId,
      dayjs(new Date()).format("DD-MM-YYYY"),
      dayjs(new Date()).format("DD-MM-YYYY"),
      kodePembayaran,
      billerId
    );
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "transDate",
      headerName: t("setorku.rekon.transaction_date"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "paymentCode",
      headerName: t("setorku.rekon.payment_code"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "caName",
      headerName: t("setorku.rekon.ca_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "billerName",
      headerName: t("setorku.rekon.biller_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "productName",
      headerName: t("setorku.rekon.product_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "amount",
      headerName: t("setorku.rekon.total_amount"),
      flex: 1,
      minWidth: 200,
    },
  ];

  const formFilterData = [
    {
      label: t("setorku.product.id_product"),
      value: {
        startDate: startDate,
        setStartDate: setStartdate,
        endDate: endDate,
        setEndDate: setEndate,
      },
      type: "date",
    },
    {
      label: t("setorku.product.payment_code"),
      type: "text",
      value: "",
      dataId: "paymentCode",
    },
    {
      label: t("setorku.product.status"),
      type: "select",
      value: [
        "ALL",
        ...selector.partner.data.data.map((item) => item.partnerId),
      ],
      dataId: "caName",
    },
    {
      label: t("setorku.product.status"),
      type: "select",
      value: [
        "ALL",
        ...selector.produk.data.data.map((item) => item.productName),
      ],
      dataId: "billerName",
    },
  ];
  return {
    data: selector,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    handleOpen,
    handleClose,
    startDate,
    setStartdate,
    endDate,
    setEndate,
    caId,
    handleChangePartner,
    billerId,
    handleChangeStatus,
    kodePembayaran,
    handleChange,
    onExpor,
    clearFilter,
    onSubmit,
    columns,
    t,
    formFilterData,
    totalTransaction,
    totalAmount, control, register, handleSubmit
  };
};

export default useRekonViewModel;
