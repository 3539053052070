import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DanaApi from "datas/dana/DanaApi";
import { SuspectTransactionParams, SuspectTransactionResult } from "domains/entity/main/dana/transactionSuspect";
import { DanaInteractor } from "domains/interactor/main/dana/DanaInteractor";

export const transactionSuspectListAction = createAsyncThunk(
    'dana/transactionSuspect',
    async (data: SuspectTransactionParams) => {
        try {
            const interactor = new DanaInteractor(new DanaApi());
            const response = await interactor.getSuspectTransaction(data);
            return response;
        } catch (error) {
            throw error;
        }
    }
);
    
interface iState {
    data: SuspectTransactionResult;
    loading: boolean;
    error: string | null;
}

const initialState:iState = {
    data: {
        totalRows: 0,
        pageLimit: 0,
        data: [],
    },
    loading: false,
    error: null,
};

const slice = createSlice({
    name: 'danaTransactionSuspect',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(transactionSuspectListAction.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(transactionSuspectListAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(transactionSuspectListAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || null;
        });
    }
});

export default slice.reducer;