import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import { DetailPettyCashApproveBody, DetailPettyCashRejectBody } from "domains/entity/main/disi";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";

type iState = {
    result: string;
    loading: boolean;
    error: any;
}

const initialState: iState = {
    result: "",
    loading: false,
    error: ""
}

export const approvePettyCashAction = createAsyncThunk('detail-petty-cash-approve-action', async (body: DetailPettyCashApproveBody, { rejectWithValue }) => {
    try {
        const useCase = new DetailPettyCashUseCase(new DisiApi())
        return await useCase.postDetailPettyCashApprove(body)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

export const rejectPettyCashAction = createAsyncThunk('detail-petty-cash-reject-action', async (body: DetailPettyCashRejectBody, { rejectWithValue }) => {
    try {
        const useCase = new DetailPettyCashUseCase(new DisiApi())
        return await useCase.postDetailPettyCashReject(body)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})


const detailPettyCashActionSlice = createSlice({
    name: "detail-petty-cash-actions-slice",
    initialState,
    reducers: {
        resetDetailPettyCashActions: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(approvePettyCashAction.pending, state => {
            state.loading = true;
            state.error = ""
            state.result = ""
        })
        builder.addCase(approvePettyCashAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload as { responsedesc: string }

            state.loading = false;
            state.result = responsedesc
            state.error = ""
        })
        builder.addCase(approvePettyCashAction.rejected, (state, action) => {
            state.loading = false;
            state.result = ""
            state.error = action.payload
        })

        builder.addCase(rejectPettyCashAction.pending, state => {
            state.loading = true;
            state.error = ""
            state.result = ""
        })
        builder.addCase(rejectPettyCashAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload as { responsedesc: string }

            state.loading = false;
            state.result = responsedesc
            state.error = ""
        })
        builder.addCase(rejectPettyCashAction.rejected, (state, action) => {
            state.loading = false;
            state.result = ""
            state.error = action.payload
        })
    }
})

const detailPettyCashActionReducer = detailPettyCashActionSlice.reducer

export const { resetDetailPettyCashActions } = detailPettyCashActionSlice.actions

export default detailPettyCashActionReducer