import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/formatDate";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import MerchantOnBoardingAPI from "data/main/merchantOnBoarding/merchant0nBoardingApi";
import { getTransactionRefundListAction } from "utils/store/main/merchantOnBoarding/transactionRefund";

interface column {
    field: string;
    headerName: string;
    flex: number;
    minWidth: number;
}

const useMerchantRefundViewModel = () => {
    const { t } = useTranslation();

    const [page, setPage] = React.useState<number>(0);

    const [pageSize, setPageSize] = React.useState<number>(6);

    const [startDate, setStartdate] = React.useState<Date>(new Date());

    const [endDate, setEndDate] = React.useState<Date>(new Date());

    const [startTime, setStartTime] = React.useState<string>("");

    const [endTime, setEndTime] = React.useState<string>("");

    const ApiRef = React.useRef(new MerchantOnBoardingAPI());

    const _fromDate = `${formatDate(startDate)} ${
        startTime !== "" ? startTime : "00:00"
    }`;

    const _toDate = `${formatDate(endDate)} ${
        endTime !== "" ? endTime : "23:59"
    }`;

    const [open, setOpen] = React.useState(false);

    const dispatch = useAppDispatch();

    const {
        data,
        error,
        loading: fetchLoading,
    } = useAppSelector((state) => state.main.merchant.refund);

    const fetchData = async (
        pageSize: number,
        pageNumber: number,
        fromDate: string,
        toDate: string
    ) => {
        dispatch(
            getTransactionRefundListAction({
                pageNumber,
                pageSize,
                fromDate,
                toDate,
            })
        );
    };

    const [loading, setLoading] = React.useState(false);

    const columns: column[] = [
        {
            field: "id",
            headerName: t("merchantOnBoarding.refund.table_no"),
            flex: 1,
            minWidth: 100,
        },
        {
            field: "paymentTime",
            headerName: t("merchantOnBoarding.refund.table_date_time"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "mpan",
            headerName: t("merchantOnBoarding.refund.table_pan_merchant"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "acquirerName",
            headerName: t(
                "merchantOnBoarding.refund.table_recipient_institution"
            ),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "issuerName",
            headerName: t(
                "merchantOnBoarding.refund.table_issueing_institution"
            ),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "customerName",
            headerName: t("merchantOnBoarding.refund.table_issuer_cust"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "customerNumber",
            headerName: t("merchantOnBoarding.refund.table_pan_cust"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "partnerRefundNo",
            headerName: t("merchantOnBoarding.refund.table_tracking_ref"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "referenceNo",
            headerName: t("merchantOnBoarding.refund.table_acquier_refund"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "partnerRefundReferenceNo",
            headerName: t(
                "merchantOnBoarding.refund.table_request_refund_reference"
            ),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "amountValue",
            headerName: t("merchantOnBoarding.refund.table_payment_amount"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "feeAmountValue",
            headerName: t("merchantOnBoarding.refund.table_service_fee"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "transactionType",
            headerName: t("merchantOnBoarding.refund.table_trans_type"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "transactionStatusDesc",
            headerName: t("merchantOnBoarding.refund.table_status"),
            flex: 1,
            minWidth: 200,
        },
    ];

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setStartTime("");
        setEndTime("");
    };

    const handleRefreshButtonClick = () => setLoading(true);

    const exportMerchantTransaksiRefund = async (
        startDate: string,
        endDate: string
    ) => {
        const Exportresult =
            await ApiRef.current.exportMerchantOnboardingRefund(
                startDate,
                endDate
            );
        const blob = new Blob([Exportresult], { type: "application/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "ExportMerchantTransaksiRefund.csv";
        link.click();
    };

    const onExport = async () => {
        const start = formatDate(startDate);
        const end = formatDate(endDate);

        exportMerchantTransaksiRefund(start, end);
    };

    const clearFilter = () => {
        setStartdate(new Date());
        setEndDate(new Date());
        setStartTime("");
        setEndTime("");
    };

    const onFilterSubmit = () => {
        fetchData(pageSize, page, _fromDate, _toDate).then(() =>
            setOpen(false)
        );
    };

    React.useEffect(() => {
        fetchData(pageSize, page, _fromDate, _toDate);
    }, [pageSize, page]);

    React.useEffect(() => {
        let timer: NodeJS.Timer;

        if (loading) {
            timer = setTimeout(() => {
                setLoading(false);
            }, 2000);

            fetchData(pageSize, page, _fromDate, _toDate);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [loading]);

    return {
        t,
        data,
        columns,
        page,
        setPage,
        pageSize,
        setPageSize,
        setStartdate,
        setEndDate,
        open,
        handleOpen,
        handleClose,
        startDate,
        endDate,
        clearFilter,
        onFilterSubmit,
        onExport,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        loading,
        handleRefreshButtonClick,
        // isExport,
    };
};

export default useMerchantRefundViewModel;
