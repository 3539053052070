import React from "react";
import {
    Box,
    IconButton,
    TextField,
    MenuItem,
    Card,
    CardContent,
    Typography,
    Modal,
    Button,
    Divider,
    Stack,
    useTheme,
    CircularProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { openSnackBar } from "utils/store/snackbar";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import { useTranslation } from "react-i18next";
import { partnerDepositCreateVaAction } from "utils/store/main/disi/PartnerDepositVA";

type RechargeComponentProps = {
    isOpen?: boolean;
    handleClose?: () => void;
};

const RechargeComponent: React.FC<RechargeComponentProps> = ({
    isOpen = false,
    handleClose = () => {},
}) => {
    const dispatch = useAppDispatch();

    const { result, loading, error } = useAppSelector(
        (state) => state.main.disi.partnerDepositVa
    );

    const { data: listBank } = useAppSelector(
        (state) => state.main.disi.partnerDepositPartner
    );

    const { palette } = useTheme();

    const { t } = useTranslation();

    const [selectedBankId, setSelectedBankId] = React.useState("");

    const handleCopyToClipBoard = () => {
        navigator.clipboard.writeText(result.vanumber);

        dispatch(
            openSnackBar({
                message: t("disi.partner_deposit.recharge_copy_done"),
                severity: "success",
            })
        );
    };

    const handleSubmitButton = () => {
        dispatch(partnerDepositCreateVaAction(selectedBankId));
    };

    const onClose = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        reason: string
    ) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Card
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    background: "white",
                    borderRadius: "20px",
                    width: "90%",
                    maxWidth: 640,
                    maxHeight: 640,
                }}
            >
                <CardContent
                    sx={{
                        "@media screen and (max-height: 40rem)": {
                            maxHeight: 440,
                        },
                        maxHeight: 600,
                        overflow: "auto",
                        p: 4,
                        "&::-webkit-scrollbar": {
                            width: 0,
                            background: "transparent",
                        },
                        "&::-moz-scrollbar": {
                            width: 0,
                            background: "transparent",
                        },
                        "&::-ms-scrollbar": {
                            width: 0,
                            background: "transparent",
                        },
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            {t("disi.partner_deposit.recharge_header")}
                        </Typography>
                        <IconButton
                            onClick={(e) =>
                                onClose(e, "totallynotbackdropclick")
                            }
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </Stack>
                    <Divider
                        variant="middle"
                        sx={{
                            mt: "0 !important",
                            mr: "0 !important",
                            ml: "0 !important",
                            mb: "1rem !important",
                        }}
                    />
                    <Box
                        display="grid"
                        sx={{
                            gridTemplateColumns: {
                                xs: "auto 1fr",
                                sm: "auto 1fr auto",
                            },
                        }}
                        alignItems="center"
                    >
                        <Typography>
                            {t("disi.partner_deposit.recharge_bank")}
                        </Typography>
                        <TextField
                            select
                            value={selectedBankId}
                            onChange={(e) => setSelectedBankId(e.target.value)}
                            sx={{ p: "1rem" }}
                        >
                            {listBank.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            sx={{
                                gridColumn: {
                                    xs: "1 / span 2",
                                    sm: "initial",
                                },
                                minWidth: 92,
                                minHeight: 36.5,
                            }}
                            variant="contained"
                            disabled={
                                selectedBankId === "" ||
                                result.vanumber !== "-" ||
                                loading
                            }
                            onClick={handleSubmitButton}
                        >
                            {loading ? (
                                <CircularProgress size={16} />
                            ) : (
                                t("disi.partner_deposit.recharge_submit")
                            )}
                        </Button>
                    </Box>
                    <Stack
                        sx={{ width: "100%", mt: "1rem" }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            sx={{
                                color: "rgba(0, 0, 0, 0.38)",
                            }}
                        >
                            {t("disi.partner_deposit.recharge_va")}
                        </Typography>
                        <Typography fontWeight={800} sx={{ p: ".5rem" }}>
                            {result.vanumber}
                        </Typography>
                        <Typography
                            sx={{
                                textTransform: "uppercase",
                                textDecoration: "underline",
                                color: palette.primary.main,
                                cursor: "pointer",
                            }}
                            onClick={handleCopyToClipBoard}
                        >
                            <Typography color="inherit" component="span">
                                <CopyAllRoundedIcon />
                            </Typography>
                            {t("disi.partner_deposit.recharge_copy")}
                        </Typography>
                        <Typography
                            sx={{
                                color: "rgba(0, 0, 0, 0.38)",
                                mt: "1rem",
                            }}
                        >
                            {t("disi.partner_deposit.recharge_expired")}
                        </Typography>
                        <Typography fontWeight={800} sx={{ p: ".5rem" }}>
                            {result.vaexpired}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Modal>
    );
};

export default RechargeComponent;
