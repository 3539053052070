import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from 'formik';
import ModalContainer from 'presentation/components/ModalContainer';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    header?: string;
    subHeader?: string;
    open: boolean;
    onClose: () => void;
    applyButton: (data: any) => void;
} 

const AddModal: React.FC<Props> = (props: Props) => {
    const {t} = useTranslation();
    const {handleSubmit, control, formState: {errors}} = useForm()

    return (
        <ModalContainer
            header={props.header ?? "Header"}
            subHeader={props.subHeader ?? "SubHeader"}
            open={props.open}
            onClose={props.onClose}
            applyButton={handleSubmit(props.applyButton)}
            cancelButton={props.onClose}
        >
            <FormControl>
              <Controller
                name="productId"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.id_product")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
              {errors.productId && (
                <Typography variant="caption" color="error">
                    {t("setorku.product.id_product_required")}
                </Typography>
              )}
            </FormControl>
            <FormControl>
              <Controller
                name="companyId"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.company_id")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>
            {errors.companyId && (
                <Typography variant="caption" color="error">
                    {t("setorku.product.company_id_required")}
                </Typography>
            )}
            <FormControl>
              <Controller
                name="companyCode"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.company_code")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
              {errors.companyCode && (
                <Typography variant="caption" color="error">
                    {t("setorku.product.company_code_required")}
                </Typography>
                )}
            </FormControl>
            <FormControl>
              <Controller
                name="companyName"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.company_name")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
              {errors.companyName && (
                <Typography variant="caption" color="error">
                    {t("setorku.product.company_name_required")}
                </Typography>
                )}
            </FormControl>
            <FormControl>
              <Controller
                name="prefix"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.prefix")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
              {errors.prefix && (
                <Typography variant="caption" color="error">
                    {t("setorku.product.prefix_required")}
                </Typography>
                )}
            </FormControl>
            <FormControl>
              <Controller
                name="secretKey"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.secret_key")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
            {errors.secretKey && (
                <Typography variant="caption" color="error">
                    {t("setorku.product.secret_key_required")}
                </Typography>
            )}
            </FormControl>
        </ModalContainer>
    );
}

export default AddModal;