import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { PPOBRefundTransactionParams, PPOBTransactionParams, PPOBTransactionResult } from "domains/entity/main/ppob/transaction";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

interface iState {
  data: PPOBTransactionResult;
  loading: boolean;
  error: boolean;
  detail: {
    [key: string]: any;
  }
}

export const transactionListAction = createAsyncThunk(
  "ppob/transaction",
  async (data: PPOBTransactionParams) => {
    const {
      pageSize,
      pageNumber,
      partnerId,
      fromDate,
      toDate,
      trxId,
      transactionDate,
      productId,
      customerId,
      receiptNo,
      trackingRef,
    } = data;
    try {
      const interactor = new PPOBInteractor(new PPOBApi());
      const data = await interactor.getTransactionList({
        pageSize,
        pageNumber,
        partnerId,
        fromDate,
        toDate,
        trxId,
        transactionDate,
        productId,
        customerId,
        receiptNo,
        trackingRef,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const refundTransactionAction = createAsyncThunk(
  "ppob/refund",
  async (body: PPOBRefundTransactionParams) => {
    try {
      const interactor = new PPOBInteractor(new PPOBApi());
      const data = await interactor.postRefundTransactionList({
        customerId: body.customerId,
        status: body.status,
        trackingRef: body.trackingRef,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: { total: 0, list: []} ,
  loading: false,
  error: false,
  detail: {},
};

const slice = createSlice({
  name: "setorku-daftar-kode",
  initialState,
  reducers: {
    detailPPobTransaction: (state, action: PayloadAction<any>) => {
      state.detail = { ...action.payload };
    },
      
  },
  extraReducers: (builder) => {
    builder
      .addCase(transactionListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        transactionListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = { ...action.payload };
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(transactionListAction.rejected, (state) => {
        console.log("rejected");
        state.loading = false;
        state.error = true;
      });
  },
});

export const { detailPPobTransaction } = slice.actions;
const ppobTransactionListReducer = slice.reducer;
export default ppobTransactionListReducer;
