import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";
import PartnerDepositResult from "domains/entity/main/disi/PartnerDepositResult";

type iState = {
    data: PartnerDepositResult;
    loading: boolean;
    error: any;
}

const initialData = {
    data: [],
    pageLimit: 0,
    totalRows: 0
}

const initialState: iState = {
    data: initialData,
    loading: false,
    error: ""
}

export const partnerDepositAction = createAsyncThunk('partner-deposit-list', async (
    {
        fromDate,
        pageNumber,
        pageSize,
        partnerId,
        statusTrx,
        tipeTrx,
        toDate,
        trxId
    }: {
        partnerId: string,
        pageSize: number,
        pageNumber: number,
        fromDate: string,
        toDate: string,
        trxId: string,
        tipeTrx: string,
        statusTrx: string,
    }, { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())

        const result = await useCase.getPartnerDepositList(
            partnerId,
            pageSize,
            pageNumber,
            fromDate,
            toDate,
            trxId,
            tipeTrx,
            statusTrx
        )
        return result
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositSlice = createSlice({
    name: "disi-partner-deposit",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(partnerDepositAction.pending, state => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(partnerDepositAction.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(partnerDepositAction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    }
})

const partnerDepositReducer = partnerDepositSlice.reducer

export default partnerDepositReducer

