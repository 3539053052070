import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridColDef } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import {
  addPaymentMethodAction,
  comboboxPartnerAction,
  comboboxPaymentMethodAction,
  deletePaymentMethodAction,
  detailPaymentMethod,
  editPaymentMethodAction,
  paymentMethodListAction,
} from "utils/store/main/ppob/paymentMethod";
import { Stack } from "@mui/system";
import { showAddModal, showDeleteModal, showEditModal, showFilterModal } from "utils/store/global/modal";
import { setAlert } from "utils/store/global/alert";


const usePPOBPaymentMethodViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      add: {
        partnerId: "",
        paymentMethod: "",
        access: "1",
        nominalFee: 0,
      },
      edit: {
        partnerId: "",
        paymentMethod: "",
        access: "",
        nominal: "",
      },
      filter: {
        partner: "ALL",
        paymentMethod: "ALL",
      },
    },
  });

  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(20);

  const columns: GridColDef[] = [
    // {
    //   field: "partnerId",
    //   headerName: t("ppob.payment_method.id_partner"),
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "description",
      headerName: t("ppob.payment_method.partner_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "methodeDesc",
      headerName: t("ppob.payment_method.payment_method"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "isAccess",
      headerName: t("ppob.payment_method.access"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "nominalFee",
      headerName: t("ppob.payment_method.nominal_fee"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Button variant="text" onClick={() => showModalEdit(row.row)}>
              <EditIcon />
            </Button>
            <Button variant="text" onClick={() => showModalDelete(row.row)}>
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const closeModal = (type: string) => {
    switch (type) {
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete));
        break;
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      default:
        break;
    }
  };

  const showModalDelete = async (data: any) => {
    closeModal("delete");
    await dispatch(detailPaymentMethod(data));
  };

  const submitDelete = async () => {
    const detail = selector.paymentMethod.detail;
    console.log(detail);
    await dispatch(
      deletePaymentMethodAction({
        partnerId: detail.partnerId,
        paymentMethod: detail.payMethode,
      })
    );
    if (selector.paymentMethod.error === false) {
      console.log("success");
      await dispatch(
        paymentMethodListAction({
          pageNumber: page,
          pageSize: pageSize,
          partnerId: "",
          paymentMethod: "",
        })
      );
      closeModal("delete");
      await dispatch(setAlert({
        open: true,
        type: "success",
      }))
    } else {
      closeModal("delete");
      await dispatch(setAlert({
        open: true,
        type: "error",
      }))
    }
  };

  const showModalEdit = (data: any) => {
    console.log(data);
    closeModal("edit");
    setValue("edit.partnerId", data.partnerId);
    setValue("edit.paymentMethod", data.payMethode);
    setValue("edit.access", data.isAccess);
    setValue("edit.nominal", data.nominalFee);
  };

  const submitFilter = async (data: any) => {
    closeModal("filter");
    dispatch(
      paymentMethodListAction({
        pageNumber: page,
        pageSize: pageSize,
        partnerId: data.filter.partner === "ALL" ? "" : data.filter.partner,
        paymentMethod: data.filter.paymentMethod === "ALL" ? "" : data.filter.paymentMethod,
      })
    );
  };

  const submitAdd = async (data: any) => {
    closeModal("add");
    await dispatch(
      addPaymentMethodAction({
        partnerId: data.add.partnerId,
        paymentMethod: data.add.paymentMethod,
        nominalFee: data.add.nominalFee,
        access: data.add.access,
      })
    );
    reset();
    fetchPaymentMethod();
  };

  const submitEdit = async (data: any) => {
    console.log(data);
    await dispatch(
      editPaymentMethodAction({
        partnerId: data.edit.partnerId,
        paymentMethod: data.edit.paymentMethod,
        nominalFee: data.edit.nominal,
        access: data.edit.access,
      })
    );
    reset();
    closeModal("edit");
    fetchPaymentMethod();
  };

  const fetchPaymentMethod = async () => {
    await dispatch(
      paymentMethodListAction({
        pageNumber: page,
        pageSize: pageSize,
        paymentMethod: "",
        partnerId: "",
      })
    );
  };

  const fetchCombobox = async () => {
    await dispatch(comboboxPartnerAction());
    await dispatch(comboboxPaymentMethodAction());
  };

  React.useEffect(() => {
    fetchCombobox();
    fetchPaymentMethod();
  }, [page, pageSize]);

  return {
    data: selector,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitFilter,
    submitAdd,
    submitEdit,
    submitDelete,
  };
};

export default usePPOBPaymentMethodViewModel;
