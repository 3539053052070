import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OtotransApi } from "data/main/ototransApi";
import OtotransPartnerResult from "domain/entity/main/structure/OtotransPartnerResult";
import OtotransTransaksiResult from "domain/entity/main/structure/OtotransTransaksiResult";
import OtotransPartnerUseCase from "domain/interactor/main/ototrans/PartnerUseCase";

interface iState {
  data: OtotransPartnerResult;
  loading: boolean;
  error: boolean;
}

export const ototransPartnerListAction = createAsyncThunk(
  "otorans/getOtotransPartner",
  async () => {
    try {
      const interactor = new OtotransPartnerUseCase(new OtotransApi())
      const response = interactor.getPartner()
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    id: '',
    name: ''
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "ototrans-partner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ototransPartnerListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        ototransPartnerListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(ototransPartnerListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

const ototransPartnerReducer = slice.reducer;
export default ototransPartnerReducer;
