import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OtotransApi } from "data/main/ototransApi";
import OtotransTransaksiResult from "domain/entity/main/structure/OtotransTransaksiResult";

interface iData {
    fromDate: string,
    toDate: string,
    status: string,
    partnerId: string,
    pageSize: number,
    pageNumber: number,
    sortBy: string,
    dateType: string
}

interface iState {
  data: OtotransTransaksiResult;
  loading: boolean;
  error: boolean;
}

export const ototransTransaksiListAction = createAsyncThunk(
  "ototrans/getOtotransTransaksi",
  async (data: iData) => {
    const {
      fromDate,
      toDate,
      status,
      partnerId,
      pageSize,
      pageNumber,
      sortBy,
      dateType,
    } = data;
    try {
      const api = new OtotransApi();
      const response = await api.getOtotransTransaksiList(
        fromDate,
        toDate,
        status,
        partnerId,
        pageSize,
        pageNumber,
        sortBy,
        dateType
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 1,
    totalRows: 6,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "ototrans-transaksi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ototransTransaksiListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        ototransTransaksiListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(ototransTransaksiListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

const ototransTransaksiReducer = slice.reducer;
export default ototransTransaksiReducer;
