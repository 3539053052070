import { combineReducers } from "@reduxjs/toolkit";
import merchantReducer from "./merchantOnBoarding";
import ototransReducers from "./ototrans";
import setorkuReducer from "./setorku";
import ppobReducer from "./ppob";
import danaReducer from "./dana";
import disiReducer from './disi'

const mainReducer = combineReducers({
            merchant: merchantReducer,
            ototrans: ototransReducers,
            ppob: ppobReducer,
            setorku: setorkuReducer,
            dana: danaReducer,
            disi: disiReducer
})

export default mainReducer