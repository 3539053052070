import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { GridColDef } from "@mui/x-data-grid";
import { showAddModal, showFilterModal } from "utils/store/global/modal";
import { pettyCashHeaderAction } from "utils/store/main/disi/PettyCashHeader";
import {
    pettyCashBodyAction,
    setPettyCashState,
    resetPettyCashBody,
    initialFilterForm,
    PettyCashState,
} from "utils/store/main/disi/PettyCashBody";
import { pettyCashAccountabilityStatusAction } from "utils/store/main/disi/PettyCashAccountabilityStatus";
import { pettyCashStatusAction } from "utils/store/main/disi/PettyCashStatus";
import { pettyCashTypeAction } from "utils/store/main/disi/PettyCashType";
import { createPettyCashAction } from "utils/store/main/disi/PettyCashCreate";
import { formatDate } from "utils/formatDate";
import {
    PettyCashCreateBody,
    PettyCashFilterForm,
} from "domains/entity/main/disi";
import { pettyCashChannelAction } from "utils/store/main/disi/PettyCashChannel";
import useRole from "domain/hooks/useRole";
import currencyFormatter from "utils/helper/currencyFormatter";
import { exportCSV } from "domain/hooks/exportCSV";
import PettyCashUseCase from "domains/interactor/main/disi/PettyCash";
import DisiApi from "datas/disi/DisiApi";
import {
    accountabilityStatusColorMap,
    extractColor,
    pettyStatusColorMap,
} from "utils/helper/statusColor";
import useSnackbar from "domain/hooks/useSnackbar";
import { resetPettyCashCreate } from "utils/store/main/disi/PettyCashCreate";
import dayjs from "dayjs";
import { pettyFileValidation } from "utils/validation";

const requestFormDefaultValues: PettyCashCreateBody = {
    amount: "",
    channelPettyCash: "",
    description: "",
    trxDate: "",
    typePettyCash: "",
    file: null,
};

const requestFormValidation = yup.object({
    amount: yup.string().required("field_required"),
    channelPettyCash: yup.string().required("field_required"),
    description: yup.string().notRequired(),
    trxDate: yup.string().required("field_required"),
    typePettyCash: yup.string().required("field_required"),
    file: yup.mixed().when("typePettyCash", {
        is: (val: string) => {
            return val === "REIMBURSE";
        },
        then: () => pettyFileValidation,
    }),
});

const PettyCashViewModel = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const { pettyCashHeader, pettyCashBody, pettyCashCreate } = useAppSelector(
        (state) => state.main.disi
    );

    const modal = useAppSelector((state) => state.global.modal);

    const role = useRole("petty-cash");

    const requestForm = useForm({
        defaultValues: requestFormDefaultValues,
        resolver: yupResolver(requestFormValidation),
    });

    const filterForm = useForm({
        defaultValues: pettyCashBody.savedState.formState,
    });

    const resetAddForm = () => {
        requestForm.reset(requestFormDefaultValues);
    };

    const resetFilterForm = () => {
        filterForm.reset(initialFilterForm);
    };

    const { getValues: getFilterValues } = filterForm;

    const [page, setPage] = React.useState(pettyCashBody.savedState.page);

    const [pageSize, setPageSize] = React.useState(
        pettyCashBody.savedState.pageSize
    );

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "No",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "dtTrx",
            headerName:
                t("disi.petty_cash.table_date_time") || "Tanggal & Waktu",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "pettyCashId",
            headerName:
                t("disi.petty_cash.table_transaction_id") || "ID Transaksi",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "merchantId",
            headerName: t("disi.petty_cash.table_merchant_id") || "ID Merchant",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "amount",
            headerName: t("disi.petty_cash.table_amount") || "Jumlah",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => currencyFormatter(params.row.amount),
        },
        {
            field: "amountResponsibility",
            headerName:
                t("disi.petty_cash.table_accountability_amount") ||
                "Jumlah Pertanggungjawaban",
            flex: 1,
            minWidth: 200,
            renderCell: (params) =>
                currencyFormatter(params.row.amountResponsibility),
        },
        {
            field: "amountRest",
            headerName:
                t("disi.petty_cash.table_amount_left") || "Jumlah Tersisa",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => currencyFormatter(params.row.amountRest),
        },
        {
            field: "description",
            headerName: t("disi.petty_cash.table_note") || "Catatan Transaksi",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "typePettyCash",
            headerName:
                t("disi.petty_cash.table_petty_type") || "Jenis Permintaan",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "typeChannel",
            headerName:
                t("disi.petty_cash.table_channel_type") || "Jenis Penarikan",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "status",
            headerName: t("disi.petty_cash.table_status") || "Status",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    sx={{
                        color: extractColor(
                            pettyStatusColorMap,
                            params.row.status
                        ),
                    }}
                >
                    {params.row.status}
                </Typography>
            ),
        },
        {
            field: "accountabilityStatus",
            headerName:
                t("disi.petty_cash.table_accountability_status") ||
                "Status Pertanggungjawaban",
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography
                    variant="body1"
                    sx={{
                        color: extractColor(
                            accountabilityStatusColorMap,
                            params.row.accountabilityStatus
                        ),
                    }}
                >
                    {params.row.accountabilityStatus}
                </Typography>
            ),
        },
        {
            field: "actions",
            headerName: t("disi.petty_cash.table_actions") || "Aksi",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Link
                    sx={{ "&:hover": { cursor: "pointer" } }}
                    onClick={(e) => {
                        navigate("/dashboard/disi/detail-petty-cash", {
                            state: {
                                partnerId: params.row.pettyCashId,
                                savedState: {
                                    formState: filterForm.getValues(),
                                    page,
                                    pageSize,
                                } as PettyCashState,
                            },
                        });
                    }}
                >
                    Detail
                </Link>
            ),
        },
    ];

    const handleModal = (lbl: string) => {
        switch (lbl) {
            case "add":
                if (!modal.add) {
                    resetAddForm();
                    console.log("add form reset on close");
                }
                dispatch(showAddModal(!modal.add));
                break;
            case "filter":
                dispatch(showFilterModal(!modal.filter));
                break;
        }
    };

    const onFilterSubmit = async (data: PettyCashFilterForm) => {
        fetchList();
        dispatch(setPettyCashState(data));
        handleModal("filter");
    };

    const onRequestSubmit = () => {
        const data = requestForm.getValues();
        dispatch(
            createPettyCashAction({
                ...data,
                trxDate: `${formatDate(
                    new Date(data.trxDate),
                    "YYYY-MM-DD"
                )} 00:00:00`,
            })
        );
    };

    const onExportClick = () => {
        const useCase = new PettyCashUseCase(new DisiApi());
        const fromDate = getFilterValues("fromDate");
        const toDate = getFilterValues("toDate");
        const pettyCashId = getFilterValues("pettyCashId");
        const status = getFilterValues("status");
        const tipePettyCash = getFilterValues("tipePettyCash");
        const accountabilityStatus = getFilterValues("accountabilityStatus");

        useCase
            .exportPettyCash({
                fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                toDate: dayjs(toDate).format("DD-MM-YYYY"),
                pettyCashId,
                status,
                tipePettyCash,
                accountabilityStatus,
            })
            .then((csv) => exportCSV(csv, "petty-cash-list"));
    };

    const fetchHeader = () => {
        dispatch(pettyCashHeaderAction());
    };

    const fetchList = () => {
        const fromDate = getFilterValues("fromDate");
        const toDate = getFilterValues("toDate");
        const pettyCashId = getFilterValues("pettyCashId");
        const status = getFilterValues("status");
        const tipePettyCash = getFilterValues("tipePettyCash");
        const accountabilityStatus = getFilterValues("accountabilityStatus");

        dispatch(
            pettyCashBodyAction({
                pageNumber: (page + 1).toString(),
                pageSize: pageSize.toString(),
                fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                toDate: dayjs(toDate).format("DD-MM-YYYY"),
                pettyCashId,
                status,
                tipePettyCash,
                accountabilityStatus,
            })
        );
    };

    const fetchAccountabilityStatus = () => {
        dispatch(pettyCashAccountabilityStatusAction());
    };

    const fetchType = () => {
        dispatch(pettyCashTypeAction());
    };

    const fetchStatus = () => {
        dispatch(pettyCashStatusAction());
    };

    const fetchChannel = () => {
        dispatch(pettyCashChannelAction());
    };

    React.useEffect(() => {
        fetchList();
    }, [page, pageSize]);

    React.useEffect(() => {
        fetchHeader();
        return () => {
            dispatch(resetPettyCashBody());
        };
    }, []);

    const {
        result: resultCreate,
        error: errorCreate,
        loading: loadingCreate,
    } = pettyCashCreate;

    useSnackbar(
        {
            error: errorCreate,
            loading: loadingCreate,
            result: resultCreate,
        },
        resetPettyCashCreate
    );

    React.useEffect(() => {
        if (!loadingCreate && resultCreate) {
            handleModal("add");
            fetchList();
        }
    }, [loadingCreate, resultCreate]);

    React.useEffect(() => {
        if (modal.filter) {
            fetchStatus();
            fetchAccountabilityStatus();
        }
        if (modal.filter || modal.add) {
            fetchType();
        }
        if (modal.add) {
            fetchChannel();
        }
    }, [modal.filter, modal.add]);

    return {
        pettyCashHeader,
        pettyCashBody,
        requestForm,
        columns,
        page,
        pageSize,
        setPage,
        setPageSize,
        t,
        onRequestSubmit,
        filterForm,
        onFilterSubmit,
        role,
        modal,
        handleModal,
        resetAddForm,
        resetFilterForm,
        onExportClick,
    };
};

export default PettyCashViewModel;
