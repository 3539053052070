import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import MerchantDepositUseCase from "domains/interactor/main/disi/MerchantDeposit";

type iState = {
    result: string;
    loading: boolean;
    error: any
}

const initialState: iState = {
    result: "",
    loading: false,
    error: ""
}

export const merchantDepositAddAction = createAsyncThunk('merchant-deposit-add', async (body: any, { rejectWithValue }) => {
    try {
        const useCase = new MerchantDepositUseCase(new DisiApi())
        return await useCase.postMerchantDepositAdd(body)
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error;
        }
        return rejectWithValue(response.data)
    }
})

const merchantDepositActionsSlice = createSlice({
    name: "merchant-deposit-actions-slice",
    initialState,
    reducers: {
        resetMerchantDepositActions: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(merchantDepositAddAction.pending, (state) => {
            state.loading = true
            state.error = ""
            state.result = ""
        })
        builder.addCase(merchantDepositAddAction.fulfilled, (state, action) => {
            state.loading = false
            state.result = action.payload
            state.error = ""
        })
        builder.addCase(merchantDepositAddAction.rejected, (state, action) => {
            state.loading = false
            state.result = ""
            state.error = action.payload
        })
    }
})

const merchantDepositActionsReducer = merchantDepositActionsSlice.reducer

export const { resetMerchantDepositActions } = merchantDepositActionsSlice.actions

export default merchantDepositActionsReducer
