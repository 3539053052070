import { Button, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  addSetorkuPartnerAction,
  deleteSetorkuPartnerAction,
  detailSetorkuPartner,
  setorkuPartnerAction,
  updateSetorkuPartnerAction,
} from "utils/store/main/setorku/setorkuPartner";
import { showAddModal, showDeleteModal, showEditModal, showFilterModal } from "utils/store/global/modal";

const usePartnerViewModel = () => {
  const { t }: any = useTranslation();
  const { handleSubmit, control, reset, register, setValue } = useForm({
    defaultValues: {
      partnerId: "",
      partnerName: "",
      isActive: "1",
    }
  });
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.setorku);
  const modal = useAppSelector((state) => state.global.modal);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100, maxWidth: 100 },
    {
      field: "partnerName",
      headerName: t("setorku.partner.id_product"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "createdOn",
      headerName: t("setorku.partner.date_created"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: t("setorku.partner.action"),
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Stack direction="row">
            <Button
              variant="text"
              size="small"
              onClick={() => {
                modalHandler("edit", row.row)
                setValue("partnerId", row.row.partnerId);
                setValue("partnerName", row.row.partnerName);
                setValue("isActive", row.row.isActive);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                modalHandler("delete", row.row)
                setValue("partnerId", row.row.partnerId);
                setValue("partnerName", row.row.partnerName);
                setValue("isActive", row.row.isActive);
              }}
            >
              <DeleteIcon />
            </Button>

          </Stack>
        );
      },
    },
  ];

  const modalHandler = (type: string, data?: any) => {
    switch (type) {
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        console.log(data);
        dispatch(
          detailSetorkuPartner({
            partnerId: data?.partnerId,
            partnerName: data?.partnerName,
            isActive: data?.isActive,
          })
        )
        break;
      default:
        break;
    }
  };

  const submitEdit = async (data: any) => {
    modalHandler("edit");
    setValue("partnerId", data.partnerId);
    setValue("partnerName", data.partnerName);
    data.isActive === "1"
      ? setValue("isActive", "1")
      : setValue("isActive", "0");

    modalHandler("edit");
    await dispatch(
      updateSetorkuPartnerAction({
        partnerId: data.partnerId,
        partnerName: data.partnerName,
        isActive: data.isActive,
      })
    );
    fetchPartner();
  };

  const submitAddPartner = async (data: any) => {
    console.log(data);
    await dispatch(
      addSetorkuPartnerAction({
        partnerId: data.partnerId,
        partnerName: data.partnerName,
        isActive: data.isActive,
      })
    );
    fetchPartner();
    modalHandler("add");
  };

  const submitDelete = async (data: any) => {
    console.log(data);
    await dispatch(deleteSetorkuPartnerAction(data.partnerId));
    setValue("partnerId", "");
    setValue("partnerName", "");
    setValue("isActive", "1");
    fetchPartner();
    modalHandler("delete");
  };

  const handleUpdatePartner = async (data: any) => {

  };

  const fetchPartner = () => {
    dispatch(
      setorkuPartnerAction({
        fromDate: dayjs(new Date()).format("DD-MM-YYYY"),
        toDate: dayjs(new Date()).format("DD-MM-YYYY"),
      })
    );
  };

  useEffect(() => {
    fetchPartner();
  }, []);

  return {
    data: selector,
    t,
    modal,
    modalHandler,
    submitDelete,
    submitEdit,
    submitAddPartner,
    columns,
    page,
    setPage,
    pageSize,
    setPageSize,
    control,
    handleSubmit,
    register,
  };
};

export default usePartnerViewModel;
