import React from "react";
import { styled } from "@mui/material/styles";
import {
  ButtonBase,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Divider,
  Hidden,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import shadows from "@mui/material/styles/shadows";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import OtoTransLogo from "assets/img/OtoTransLogo.png";
import SetorKuLogo from "assets/img/SetorKuLogo.png";
import PPOBLogo from "assets/img/PPOBLogo.png";
import SolusiPayLogo from "assets/img/SolusiPayLogo.png";
import HelloModalHome from "assets/img/HelloModalHome.png";
// import AreaChart from "../home/ChartComponent";
import { textAlign } from "@mui/system";
import { useTranslation } from "react-i18next";
// import "./_home.scss";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getFirstTime,
  saveFirstTime,
  getUser
} from "utils/storage/LocalStorage";
// import { Overlay } from "../../../../config/context/overlayContext";
import { Link } from "react-router-dom";
import LayoutComponent from "presentation/layout/Layout";

const HomeComponent = () => {
  const navigate = useNavigate();
  const user = JSON.parse(getUser() || "{}");
  const refBody = React.useRef<HTMLBodyElement | null>(null);
  const refJelah = React.useRef<HTMLDivElement>(null);
  const refAktivasi = React.useRef<HTMLDivElement>(null);
  const refDemo = React.useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    refBody.current = document.getElementsByTagName("body")[0];
    refDemo.current = document.getElementById("menuItemDemo");
    const timer = setTimeout(() => {
      const firstTimeUser = getFirstTime();
      setOpen(!firstTimeUser);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
//   const { overlay, setOverlay, steper, setSteper } = React.useContext(
//     Overlay
//   ) as {
//     overlay: boolean;
//     setOverlay: React.Dispatch<React.SetStateAction<boolean>>;
//     steper: number;
//     setSteper: React.Dispatch<React.SetStateAction<number>>;
//   };
  const handeCloseOverlay = () => {
    // setOverlay(false);
    saveFirstTime("false");
  };
  const openOverlay = () => {
    // setOverlay(true);
    setOpen(false);
  };
//   const closeOverlay = () => setOverlay(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "650px",
    bgcolor: "background.paper",
    textAlign: "center",
    p: 4,
    borderRadius: "8px",
  };
//   React.useEffect(() => {
//     if (refBody != null && refBody.current != null) {
//       refBody.current.style.position = overlay ? "fixed" : "";
//     }
//   }, [overlay]);

  React.useEffect(() => {
    if (
      refJelah != null &&
      refJelah.current != null &&
      refAktivasi != null &&
      refAktivasi.current != null
    ) {
    //   if (overlay) {
    //     if (steper == 1) {
    //       refAktivasi.current.style.zIndex = "0";
    //       refJelah.current.style.zIndex = "1000";
    //     }
    //     if (steper == 2) {
    //       refAktivasi.current.style.zIndex = "1000";
    //       refJelah.current.style.zIndex = "0";
    //     }
    //   } else {
    //     refAktivasi.current.style.zIndex = "0";
    //     refJelah.current.style.zIndex = "0";
    //   }
    }
  }, []);

  const getAktivasiAkun = () => {
    const { isActivate } = user;
    return Boolean(Number.parseInt(isActivate));
  };

  const navigateTo = (url: string) => {
    navigate(url);
  };

  return (
    <>
    <LayoutComponent>
        <div>
        {/* {!getAktivasiAkun() && (
        <Grid
          sx={{
            width: "100%",
            backgroundColor: "rgba(219, 234, 198, 1)",
            position: "relative",
            zIndex: 0,
          }}
          ref={refAktivasi}
        >
          <Box className="DemoBox">
            <Card
              className="NavOverlayAktivasi"
              sx={{
                display: "block",
                // display: overlay && steper == 2 ? "block" : "none",
              }}
            >
              <Typography
                sx={{
                  padding: "1rem",
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {t("home.intro.activation_header")}
              </Typography>
              <Typography
                sx={{
                  pl: "1rem",
                  pr: "1rem",
                  fontWeight: 400,
                  fontSize: "Small",
                }}
              >
                {t("home.intro.activation_desc")}
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "end", m: "1em" }}
              >
                <Button
                  variant="text"
                  className="ButtonOverlayTutup"
                //   onClick={closeOverlay}
                >
                  {t("home.intro.close_button")}
                </Button>
                <Button
                  variant="contained"
                  className="ButtonOverlaySelanjutnya"
                  onClick={handeCloseOverlay}
                >
                  {t("home.intro.next_button")}
                </Button>
              </Stack>
            </Card>
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "center", p: "1em", alignItems: "center" }}
            > 
            <Typography className="AktivasiText">
              {t("home.intro.demo_header")}
            </Typography>
            <Link to="/AktivasiAccount">
              <Button variant="contained" className="ButtonAktivasi">
                {t("home.intro.demo_button")}
              </Button>
            </Link>
            </Stack>
          </Box>
        </Grid>
      )} */}
      <Container
        className="HeadContainer"
        sx={{
          backgroundColor: { xs: "#fff", md: "#f4f4f4" },
          margin: 0,
          minHeight: "calc(100vh - 68px)"
        }}
        maxWidth={false}
      >
        {false && <div id="overlay"></div>}
        <Typography
          variant="h6"
          component="h1"
          sx={{
            mb: "1rem",
            fontWeight: 400,
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          {user.name}
          {t("home.welcome_header")}
        </Typography>
        <Card
          variant="outlined"
          sx={{
            boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
            mb: "2rem",
            zIndex: 0,
            position: "relative",
          }}
          ref={refJelah}
        >
          <Card
            className="NavOverlay"
            sx={{
              // display: overlay && steper == 1 ? "block" : "none",
              display: "none",
            }}
          >
            <Typography
              sx={{
                padding: "1rem",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              {t("home.intro.explore_to_start_header")}
            </Typography>
            <Typography
              sx={{
                pl: "1rem",
                pr: "1rem",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {t("home.intro.explore_description")}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "end", m: "1em" }}
            >
              <Button
                variant="text"
                className="ButtonOverlayTutup"
                // onClick={closeOverlay}
              >
                {t("home.intro.close_button")}
              </Button>
              <Button
                variant="contained"
                className="ButtonOverlaySelanjutnya"
                // onClick={() => setSteper(steper + 1)}
              >
                {t("home.intro.next_button")}
              </Button>
            </Stack>
          </Card>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              pl: "2rem",
              pb: "1.5rem",
              pt: "2rem",
              fontWeight: 400,
              fontSize: "medium",
            }}
          >
            {t("home.explore_header")}
          </Typography>
          <Card
            className="Ids-Menu-Home"
            sx={{
              pl: "2rem",
              pb: "2rem",
              pr: "2rem",
              pt: "2rem",
              overflowX: "auto",
              display: "flex",
              gap: "1rem",
              minWidth: 400,
            }}
          >
            <Card
              className="Home-Card-Menu"
              onClick={() =>
                navigateTo("/Dashboard/MerchantOnboarding/Transaksi")
              }
            >
              <CardMedia
                className="backgroudnColor"
                component="img"
                alt="green iguana"
                height="120"
                image={OtoTransLogo}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="HeaderCardHome"
                >
                  Merchant Onboarding
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="ContentCardHome"
                >
                  Merchat Onboarding desc
                </Typography>
              </CardContent>
            </Card>

            {/* <Card className="Home-Card-Menu">
                            <CardMedia
                                className="backgroudnColor"
                                component="img"
                                alt="green iguana"
                                height="120"
                                image={OtoTransLogo}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    className="HeaderCardHome"
                                >
                                    OtoTrans
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    className="ContentCardHome"
                                >
                                    {t("home.ototrans_desc")}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="Home-Card-Menu">
                            <CardMedia
                                className="backgroudnColor"
                                component="img"
                                alt="green iguana"
                                height="120"
                                image={SetorKuLogo}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    className="HeaderCardHome"
                                >
                                    SetorKu
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    className="ContentCardHome"
                                >
                                    {t("home.setorku_desc")}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="Home-Card-Menu">
                            <CardMedia
                                className="backgroudnColor"
                                component="img"
                                alt="green iguana"
                                height="120"
                                image={PPOBLogo}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    className="HeaderCardHome"
                                >
                                    PPOB
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    className="ContentCardHome"
                                >
                                    {t("home.ppob_desc")}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className="Home-Card-Menu">
                            <CardMedia
                                className="backgroudnColor"
                                component="img"
                                alt="green iguana"
                                height="120"
                                image={SolusiPayLogo}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    className="HeaderCardHome"
                                >
                                    SolusiPay
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    className="ContentCardHome"
                                >
                                    {t("home.solpay_desc")}
                                </Typography>
                            </CardContent>
                        </Card> */}
          </Card>
        </Card>
        {/* <Stack className="Ids-Menu-Home" direction="row" spacing={2}>
                    <Card
                        variant="outlined"
                        className="VolumeTransaksi"
                        sx={{
                            boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            mb: "2rem",
                            width: "70%",
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="h1"
                            sx={{
                                pl: "1rem",
                                pb: "1rem",
                                pt: "1rem",
                                fontWeight: 400,
                            }}
                            className="HeaderCardHome"
                        >
                            {t("home.transaction_volume_header")}
                        </Typography>
                        <AreaChart />
                    </Card>
                    <Stack
                        className="Home-Graffic-Card"
                        direction="column"
                        spacing={4}
                        sx={{
                            width: "30%",
                        }}
                    >
                        <Card
                            variant="outlined"
                            sx={{
                                boxShadow:
                                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    pt: "1rem",
                                    pb: "0.5rem",
                                    fontWeight: 400,
                                }}
                                className="HeaderCardHome"
                            >
                                {t("home.revenue")}
                            </Typography>

                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    fontWeight: 600,
                                    fontSize: "large",
                                }}
                            >
                                Rp. 100.000.000
                            </Typography>

                            <Stack
                                direction={"row"}
                                spacing={1}
                                sx={{
                                    mt: "1rem",
                                    pl: "1rem",
                                    mb: "1rem",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        borderRadius: "64px",
                                        pl: "6.5px",
                                        pr: "6.5px",
                                        color: "rgba(46, 125, 50, 1)",
                                        backgroundColor:
                                            "rgba(46, 125, 50, 0.3)",
                                        textAlign: "center",
                                    }}
                                >
                                    +14%
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "x-small",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("home.since_last_week")}
                                </Typography>
                            </Stack>
                        </Card>

                        <Card
                            variant="outlined"
                            sx={{
                                boxShadow:
                                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    pt: "1rem",
                                    pb: "0.5rem",
                                    fontWeight: 400,
                                }}
                                className="HeaderCardHome"
                            >
                                {t("home.expenditure")}
                            </Typography>

                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    fontWeight: 600,
                                    fontSize: "large",
                                }}
                            >
                                Rp. 100.000.000
                            </Typography>

                            <Stack
                                direction={"row"}
                                spacing={1}
                                sx={{
                                    mt: "1rem",
                                    pl: "1rem",
                                    mb: "1rem",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        borderRadius: "64px",
                                        pl: "6.5px",
                                        pr: "6.5px",
                                        color: "#D32F2F",
                                        backgroundColor:
                                            "rgba(211, 47, 47, 0.3)",
                                        textAlign: "center",
                                    }}
                                >
                                    -8%
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "x-small",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("home.since_last_week")}
                                </Typography>
                            </Stack>
                        </Card>

                        <Card
                            variant="outlined"
                            sx={{
                                boxShadow:
                                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    pt: "1rem",
                                    pb: "0.5rem",
                                    fontWeight: 400,
                                    fontSize: "medium",
                                }}
                            >
                                {t("home.numbof_revenue_transaction")}
                            </Typography>

                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    fontWeight: 600,
                                    fontSize: "large",
                                }}
                            >
                                0
                            </Typography>

                            <Stack
                                direction={"row"}
                                spacing={1}
                                sx={{
                                    mt: "1rem",
                                    pl: "1rem",
                                    mb: "1rem",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        borderRadius: "64px",
                                        pl: "6.5px",
                                        pr: "6.5px",
                                        color: "rgba(46, 125, 50, 1)",
                                        backgroundColor:
                                            "rgba(46, 125, 50, 0.3)",
                                        textAlign: "center",
                                    }}
                                >
                                    +14%
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "x-small",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("home.since_last_week")}
                                </Typography>
                            </Stack>
                        </Card>

                        <Card
                            variant="outlined"
                            sx={{
                                boxShadow:
                                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    pt: "1rem",
                                    pb: "0.5rem",
                                    fontWeight: 400,
                                    fontSize: "medium",
                                }}
                            >
                                {t("home.numbof_expenditure_transaction")}
                            </Typography>

                            <Typography
                                variant="h6"
                                component="h1"
                                sx={{
                                    pl: "1rem",
                                    fontWeight: 600,
                                    fontSize: "large",
                                }}
                            >
                                0
                            </Typography>

                            <Stack
                                direction={"row"}
                                spacing={1}
                                sx={{
                                    mt: "1rem",
                                    pl: "1rem",
                                    mb: "1rem",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        borderRadius: "64px",
                                        pl: "6.5px",
                                        pr: "6.5px",
                                        color: "#D32F2F",
                                        backgroundColor:
                                            "rgba(211, 47, 47, 0.3)",
                                        textAlign: "center",
                                    }}
                                >
                                    -8%
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "x-small",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("home.since_last_week")}
                                </Typography>
                            </Stack>
                        </Card>
                    </Stack>
                </Stack> */}
        <div>
          <Modal open={false}>
            <Box sx={style} className="modalHome">
              <CardMedia
                className="backgroudnColor"
                component="img"
                alt="HelloModalHome"
                height="250"
                image={HelloModalHome}
                sx={{
                  objectFit: "contain",
                  backgroundColor: "white",
                }}
              />
              <Typography className="ModalHome">
                {t("home.intro.welcome_header")}
              </Typography>
              <Typography className="ModalContent" sx={{ mt: 2, mb: 2 }}>
                {t("home.intro.welcome_message")}
              </Typography>
              <Button
                variant="contained"
                onClick={openOverlay}
                className="ModalButton"
              >
                {t("home.intro.start_button")}
              </Button>
            </Box>
          </Modal>
        </div>
      </Container>
        </div>
    </LayoutComponent>
    </>
  );
};

export default HomeComponent;
