import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginApi from "datas/auth/LoginApi";
import { AuthLoginRequest, AuthLoginResult } from "domains/entity/auth/login";
import { LoginInteractor } from "domains/interactor/auth/LoginInteractor";
import { saveToken, saveUser } from "utils/storage/LocalStorage";

interface iState {
  data: AuthLoginResult;
  loading: boolean;
  error: boolean;
  errorMsg: string;
}

export const loginAction = createAsyncThunk(
  "auth/login",
  async (data: AuthLoginRequest) => {
    const { username, password } = data;
    try {
      const interactor = new LoginInteractor(new LoginApi());
      const response = await interactor.login({ username, password });
      return response;
    } catch (error) {
      throw error.response.data 
    }
  }
);

const initialState:iState = {
  data: {
    username: "",
    token: "",
    createdAt: "",
    deletedAt: "",
    email: "",
    isActivate: "",
    name: "",
    parentMenuList: [],
    roleName: "",
  },
  loading: false,
  error: false,
  errorMsg: "",
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => {
        console.log("loginAction.pending");
        state.loading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        console.log("loginAction.fulfilled", action);
        state.error = false;
        state.loading = false;
        state.data = action.payload;
        saveUser(JSON.stringify(action.payload))
          saveToken(action.payload.token)
          window.location.href = "/dashboard";
      })
      .addCase(loginAction.rejected, (state, action:any) => {
        console.log("loginAction.rejected", action);
        state.loading = false;
        state.error = true;
        state.errorMsg = action.error.message;
      });
  }
});

export default slice.reducer;