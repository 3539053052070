import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositRequestOTPResult from "domains/entity/main/disi/PartnerDepositRequestOTPResult";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";


type iState = {
    result: PartnerDepositRequestOTPResult
    loading: boolean;
    error: any
}

const initialState: iState = {
    result: {
        data: ["", "", "", "", ""],
        rc: "",
        rcdesc: "",
        tipe: ""
    },
    loading: false,
    error: ""
}

export const partnerDepositRequestOTPAction = createAsyncThunk('partner-deposit-request-otp', async (tipe: "1" | "2", { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())

        const result = await useCase.postPartnerDepositRequestOTP(tipe)

        return result
    } catch (error) {
        const { response = null } = error;
        if (!response) {
            throw error;
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositRequestOTPSlice = createSlice({
    name: "partner-deposit-request-otp-slice",
    initialState,
    reducers: {
        resetPartnerDepositRequestOTP: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(partnerDepositRequestOTPAction.pending, state => {
            state.loading = true;
            state.error = "";
        })
        builder.addCase(partnerDepositRequestOTPAction.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
            state.error = "";
        })
        builder.addCase(partnerDepositRequestOTPAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
})

const partnerDepositRequestOTPReducer = partnerDepositRequestOTPSlice.reducer

export const { resetPartnerDepositRequestOTP } = partnerDepositRequestOTPSlice.actions

export default partnerDepositRequestOTPReducer 