import { ExportDetailMerchantDepositParams } from "domains/entity/main/disi";
import DisiRepository from "domains/repository/main/disi/DisiRepository";
export default class DetailMerchantDepositUseCase {
    constructor(private repository: DisiRepository) { }

    getDetailMerchantDepositHeader(merchantNm: string, partnerNm: string) {
        return this.repository.getMerchantDepositDetailHeader(merchantNm, partnerNm)
    }

    getDetailMerchantDepositBodyList(pageSize: number, pageNumber: number, fromDate: string, toDate: string, merchantNm: string, partnerNm: string) {
        return this.repository.getMerchantDepositDetailBodyList(pageSize, pageNumber, fromDate, toDate, merchantNm, partnerNm)
    }

    getDetailMerchantDepositBalance() {
        return this.repository.getMerchantDepositDetailSaldo()
    }

    exportMerchantDepositDetailList(params: ExportDetailMerchantDepositParams) {
        return this.repository.exportMerchantDepositDetailList(params)
    }
}