// import httpCommon from "data/httpCommon";
import httpDisi from "data/httpDisi";
import ConstantsResult from "domains/entity/main/ConstantResult";
import {
    ExportDetailMerchantDepositParams,
    ExportMerchantDepositList,
    ExportPartnerdepositListParams,
    PettyCashBodyParams,
    DetailPettyCashAccountabilityParams,
    DetailPettyCashApproveBody,
    DetailPettyCashRejectBody,
    DetailPettyCashAccountabilityApproveBody,
    DetailPettyCashAccountabilityRejectBody,
    AccountabilityFileParams,
    CancelTokenBody
} from "domains/entity/main/disi";
import DetailMerchantDepositHeaderResult from "domains/entity/main/disi/DetailMerchantDepositHeader";
import DetailMerchantDepositResult from "domains/entity/main/disi/DetailMerchantDepositResult";
import DetailPettyCashAccountabilityResult from "domains/entity/main/disi/DetailPettyCashAccountabilityResult";
import DetailPettyCashBodyResult from "domains/entity/main/disi/DetailPettyCashBodyResult";
import { DetailPettyCashHeaderResult } from "domains/entity/main/disi/DetailPettyCashHeaderResult";
import MerchantDepositResult from "domains/entity/main/disi/MerchantDepositResult";
import PartnerDepositCreateVAResult from "domains/entity/main/disi/PartnerDepositCreateVAResult";
import PartnerDepositRequestOTPResult from "domains/entity/main/disi/PartnerDepositRequestOTPResult";
import PartnerDepositResult from "domains/entity/main/disi/PartnerDepositResult";
import {
    PartnerDepositDebtWalletResult,
    PartnerDepositMerchantWalletResult,
    PartnerDepositReadyWalletResult,
    PartnerDepositRealWalletResult
} from "domains/entity/main/disi/PartnerDepositWallet";
import PartnerDepositWithdrawDataResult from "domains/entity/main/disi/PartnerDepositWithdrawDataResult";
import PettyCashBodyResult from "domains/entity/main/disi/PettyCashBodyResult";
import PettyCashHeaderResult from "domains/entity/main/disi/PettyCashHeaderResult";
import DisiRepository from "domains/repository/main/disi/DisiRepository";
import argsToParams from "utils/helper/ArgsToParams";

export default class DisiApi implements DisiRepository {

    async getPettyCashHeaderData(): Promise<PettyCashHeaderResult> {
        return await httpDisi.get('/digitalbalance/pettycashheaderdata').then(res => res.data)
    }

    async getPettyCashBodyData(args: PettyCashBodyParams): Promise<PettyCashBodyResult> {
        const params = argsToParams(args)

        return await httpDisi.get(`/digitalbalance/pettycashdata?${params}`).then(res => res.data)
    }

    async getPettyCashType(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/listtypepettycash').then(res => res.data)
    }

    async getPettyCashStatus(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/liststatus').then(res => res.data)
    }

    async getPettyCashAccountabilityStatus(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/listaccountabilitystatus').then(res => res.data)
    }

    async getPettyCashChannel(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/listtypecashout').then(res => res.data)
    }

    async postPettyCash(body: FormData): Promise<string | { responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/pettycash/create', body, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => res.data)
    }

    async exportPettyCash(args: Omit<PettyCashBodyParams, "pageNumber" | "pageSize">): Promise<any> {
        const params = argsToParams(args);
        return await httpDisi.get(`/digitalbalance/pettycashdata/export?${params}`).then(res => res.data)
    }

    async getDetailPettyCashHeader(pettyCashId: string): Promise<DetailPettyCashHeaderResult> {
        const params = argsToParams({ pettyCashId })
        return await httpDisi.get(`/digitalbalance/pettycashdetailheaderdata?${params}`).then(res => res.data)
    }

    async getDetailPettyCashBody(pettyCashId: string): Promise<DetailPettyCashBodyResult> {
        const params = argsToParams({ pettyCashId })
        return await httpDisi.get(`/digitalbalance/pettycashdetailtabledata?${params}`).then(res => res.data)
    }

    async getDetailPettyCashAccountability(args: DetailPettyCashAccountabilityParams): Promise<DetailPettyCashAccountabilityResult> {
        const params = argsToParams(args)
        return await httpDisi.get(`/digitalbalance/accountabilitydata?${params}`).then(res => res.data)
    }

    async postDetailPettyCashApprove(body: DetailPettyCashApproveBody): Promise<string | { responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/pettycash/approve', body).then(res => res.data)
    }

    async postDetailPettyCashReject(body: DetailPettyCashRejectBody): Promise<string | { responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/pettycash/reject', body).then(res => res.data)
    }

    async postDetailPettyAccountability(body: FormData): Promise<string | { responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/accountability/create', body, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => res.data)
    }

    async postDetailPettyAccountabilityApprove(body: DetailPettyCashAccountabilityApproveBody): Promise<string | { responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/accountability/approve', body).then(res => res.data)
    }

    async postDetailPettyAccountabilityReject(body: DetailPettyCashAccountabilityRejectBody): Promise<string | { responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/accountability/reject', body).then(res => res.data)
    }

    async getAccountabilityFile(args: AccountabilityFileParams): Promise<any> {
        return await httpDisi.post(`/digitalbalance/accountability/file`, args, {
            responseType: "arraybuffer",
        }).then(res => res.data)
    }

    async postDetailPettyCashCancelToken(body: CancelTokenBody): Promise<{ responsedesc: string; }> {
        return await httpDisi.post('/digitalbalance/token/cancel', body).then(res => res.data)
    }

    async getPartnerDepositRealWallet(): Promise<PartnerDepositRealWalletResult> {
        return await httpDisi.get<PartnerDepositRealWalletResult>(`/digitalbalance/saldorealpartner`).then(res => res.data)
    }

    async getPartnerDepositReadyWallet(): Promise<PartnerDepositReadyWalletResult> {
        return await httpDisi.get<PartnerDepositReadyWalletResult>(`/digitalbalance/saldoreadypartner`).then(res => res.data)
    }

    async getPartnerDepositDebtWallet(): Promise<PartnerDepositDebtWalletResult> {
        return await httpDisi.get<PartnerDepositDebtWalletResult>(`/digitalbalance/saldodebtpartner`).then(res => res.data)
    }

    async getPartnerDepositMerchantWallet(): Promise<PartnerDepositMerchantWalletResult> {
        return await httpDisi.get<PartnerDepositMerchantWalletResult>(`/digitalbalance/saldopendingpartner`).then(res => res.data)
    }

    async getPartnerDepositList(partnerId: string, pageSize: number, pageNumber: number, fromDate: string, toDate: string, trxId: string, tipeTrx: string, statusTrx: string): Promise<PartnerDepositResult> {
        const params = argsToParams({
            partnerId,
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
            fromDate,
            toDate
        })

        if (trxId) {
            params.append('trxId', trxId)
        }

        if (tipeTrx) {
            params.append('tipeTrx', tipeTrx)
        }

        if (statusTrx) {
            params.append('statusTrx', statusTrx)
        }

        return await httpDisi.get<PartnerDepositResult>(`/digitalbalance/partnerdeposit?${params}`).then(res => res.data)
    }

    async getPartnerDepositStatus(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/liststatustransaction').then(res => res.data)
    }

    async getPartnerDepositType(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/listtypetransaction').then(res => res.data)
    }

    async getMerchantDepositList(pageSize: number, pageNumber: number, merchantNm: string, partnerNm: string, status: string): Promise<MerchantDepositResult> {

        const params = argsToParams({
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
            merchantNm,
            partnerNm,
            status
        })

        return await httpDisi.get(`/digitalbalance/merchantdeposit?${params}`).then(res => res.data)
    }

    async getMerchantDepositSaldo(): Promise<string> {
        return await httpDisi.get(`/digitalbalance/totalsaldomerchant`).then(res => res.data.totalSaldo || 0)
    }

    async getMerchantDepositDetailBodyList(pageSize: number, pageNumber: number, fromDate: string, toDate: string, merchantNm: string, partnerNm: string): Promise<DetailMerchantDepositResult> {

        const params = argsToParams({
            pageSize: pageSize.toString(),
            pageNumber: pageNumber.toString(),
            fromDate,
            toDate,
            merchantNm,
            partnerNm
        })

        return await httpDisi.get(`/digitalbalance/bodymerchant?${params}`).then(res => res.data)
    }

    async getMerchantDepositDetailSaldo(): Promise<string> {
        return await httpDisi.get(`/digitalbalance/saldomerchant`).then(res => res.data.totalSaldo || 0)
    }

    async getMerchantDepositDetailHeader(merchantNm: string, partnerNm: string): Promise<DetailMerchantDepositHeaderResult> {
        const params = argsToParams({
            merchantNm,
            partnerNm
        })
        return await httpDisi.get(`/digitalbalance/headermerchant?${params}`).then(res => res.data)
    }

    async getPartnerDepositDataWithdrawPartner(): Promise<PartnerDepositWithdrawDataResult> {
        return await httpDisi.get('/digitalbalance/datawithdrawpartner').then(res => res.data)
    }

    async postPartnerDepositCreateVa(bankVa: string): Promise<PartnerDepositCreateVAResult> {
        return await httpDisi.post('/digitalbalance/createvapartner', { bankVa }).then(res => res.data)
    }

    async postPartnerDepositRequestOTP(tipe: string): Promise<PartnerDepositRequestOTPResult> {
        return await httpDisi.post('/digitalbalance/otpwithdrawpartner', { tipe }).then(res => res.data)
    }

    async postPartnerDepositWithdraw(codeOTP: string, amount: string): Promise<string | { responsedesc: string }> {
        return await httpDisi.post('/digitalbalance/withdrawpartner', { codeOTP, amount }).then(res => res.data)
    }

    async exportmerchantDepositList(params: ExportMerchantDepositList): Promise<any> {
        const p = argsToParams(params)
        return await httpDisi.get(`/digitalbalance/merchantdeposit/export?${p}`).then(res => res.data)
    }

    async exportMerchantDepositDetailList(params: ExportDetailMerchantDepositParams): Promise<any> {
        const p = argsToParams(params)
        return await httpDisi.get(`/digitalbalance/merchantdetail/export?${p}`).then(res => res.data)
    }

    async exportPartnerDepositList(params: ExportPartnerdepositListParams): Promise<any> {
        const p = argsToParams(params)

        return await httpDisi.get(`/digitalbalance/partnerdeposit/export?${p}`).then(res => res.data)
    }

    async getMerchantDepositBankList(params: "bank" | "bankVa"): Promise<ConstantsResult> {
        return await httpDisi.get(`/digitalbalance/listbank?tipe=${params}`).then(res => res.data)
    }

    async getMerchantDepositPartner(): Promise<ConstantsResult> {
        return await httpDisi.get('/digitalbalance/listpartner').then(res => res.data)
    }

    async postMerchantDepositAdd(body: any): Promise<string> {
        return await httpDisi.post('/digitalbalance/createmerchant', body).then(res => {
            const { data } = res
            const { responsedesc = "" } = data
            return responsedesc
        })
    }
}
