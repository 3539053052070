import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";
import ConstantsResult from "domains/entity/main/ConstantResult";

type iState = {
    data: ConstantsResult;
    loading: boolean;
    error: any
}

const initialState: iState = {
    data: [],
    loading: false,
    error: ""
}

export const partnerDepositGetTypeAction = createAsyncThunk('partner-deposit-get-type', async (_, { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())
        return await useCase.getPartnerType()
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositTypeSlice = createSlice({
    name: "partner-deposit-type-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(partnerDepositGetTypeAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(partnerDepositGetTypeAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(partnerDepositGetTypeAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const partnerDepositTypeReducer = partnerDepositTypeSlice.reducer

export default partnerDepositTypeReducer