import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { PPOBAddPaymentMethodParams, PPOBDeletePaymentMethodParams, PPOBEditPaymentMethodParams, PPOBPaymentMethodListParams, PPOBPaymentMethodListResult } from "domains/entity/main/ppob/paymentMethod";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

export const comboboxPartnerAction = createAsyncThunk(
    "ppob/payment-method/combo-partner",
    async () => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.getComboPartnerList();
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const comboboxPaymentMethodAction = createAsyncThunk(
    "ppob/payment-method/combo-payment-method",
    async () => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.getComboBoxPaymentMethodList();
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const paymentMethodListAction = createAsyncThunk(
    "ppob/payment-method",
    async (data: PPOBPaymentMethodListParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.getPaymentMethodList({
                pageNumber: data.pageNumber,
                pageSize: data.pageSize,
                partnerId: data.partnerId,
                paymentMethod: data.paymentMethod,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const addPaymentMethodAction = createAsyncThunk(
    "ppob/payment-method/add",
    async (data: PPOBAddPaymentMethodParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.addPaymentMethodList({
                partnerId: data.partnerId,
                paymentMethod: data.paymentMethod,
                access: data.access,
                nominalFee: data.nominalFee,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const editPaymentMethodAction = createAsyncThunk(
    "ppob/payment-method/edit",
    async (data: PPOBEditPaymentMethodParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.editPaymentMethodList({
                partnerId: data.partnerId,
                paymentMethod: data.paymentMethod,
                access: data.access,
                nominalFee: data.nominalFee,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const deletePaymentMethodAction = createAsyncThunk(
    "ppob/payment-method/delete",
    async (data: PPOBDeletePaymentMethodParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi());
            const response = await interactor.deletePaymentMethodList({
                partnerId: data.partnerId,
                paymentMethod: data.paymentMethod,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);


const slice = createSlice({
    name: "ppob-payment-method",
    initialState: {
        data: {
            data: [],
            total: 0,
            pageLimit: 0,
        },
        partnerList: [{
            id: 0,
            name: "",
        }],
        paymentMethodList: [{
            id: 0,
            name: "",
        }],
        detail: {
            partnerId: '',
            payMethode: '',
        },
        loading: false,
        error: false,
    },
    reducers: {
        detailPaymentMethod: (state, action) => {
            state.detail = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(paymentMethodListAction.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(paymentMethodListAction.fulfilled, (state: {
            data: any;
            loading: boolean;
            error: boolean;
        }, action) => {
            state.loading = false;
            state.data = {
                data: action.payload.data,
                total: action.payload.totalRows,
                pageLimit: action.payload.pageLimit,
            };
        });
        builder.addCase(paymentMethodListAction.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(comboboxPartnerAction.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(comboboxPartnerAction.fulfilled, (state: {
            partnerList: any;
            loading: boolean;
            error: boolean;
        }, action) => {
            state.loading = false;
            state.partnerList = action.payload;
        });
        builder.addCase(comboboxPartnerAction.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(comboboxPaymentMethodAction.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(comboboxPaymentMethodAction.fulfilled, (state: {
            paymentMethodList: any;
            loading: boolean;
            error: boolean;
        }, action) => {
            state.loading = false;
            state.paymentMethodList = action.payload;
        });
        builder.addCase(comboboxPaymentMethodAction.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(addPaymentMethodAction.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(addPaymentMethodAction.fulfilled, (state: {
            data: any;
            loading: boolean;
            error: boolean;
        }, action) => {
            state.loading = false;
            // state.data = action.payload.actionStatus
        });
        builder.addCase(addPaymentMethodAction.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export const { detailPaymentMethod } = slice.actions;
export default slice.reducer;