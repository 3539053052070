import PPOBGroupResult from "domain/entity/main/structure/PPOBGroupResult";
import {
  PPOBCollectingAgentListParams,
  PPOBCollectingAgentListResult,
  PPOBCollectingAgentEditParams,
  PPOBCollectingAgentEditResult,
  PPOBCollectingAgentDetailListParams,
  PPOBCollectingAgentDetailListResult,
  PPOBCollectingAgentDetailEditParams,
  PPOBCollectingAgentDetailEditResult,
  PPOBCollectingAgentDetailAddParams,
  PPOBCollectingAgentDetailAddResult,
  PPOBCollectingAgentDetailDeleteParams,
  PPOBCollectingAgentDetailDeleteResult,
  PPOBAddCollectingAgentListParams,
  PPOBAddCollectingAgentListResult,
} from "domains/entity/main/ppob/collectingAgent";
import {
  PpobEditGroupParams,
  PpobEditGroupResult,
  PpobGroupParams,
} from "domains/entity/main/ppob/group";
import {
  PPOBPartnerListParams,
  PPOBPartnerListResult,
  PPOBPartnerEditParams,
  PPOBPartnerEditResult,
  PPOBPartnerAddParams,
  PPOBPartnerAddResult,
} from "domains/entity/main/ppob/partner";
import {
  PPOBPaymentMethodListParams,
  PPOBPaymentMethodListResult,
  PPOBComboPartnerListResult,
  PPOBComboBoxPaymentMethodListResult,
  PPOBAddPaymentMethodParams,
  PPOBAddPaymentMethodResult,
  PPOBEditPaymentMethodParams,
  PPOBEditPaymentMethodResult,
  PPOBDeletePaymentMethodParams,
  PPOBDeletePaymentMethodResult,
} from "domains/entity/main/ppob/paymentMethod";
import {
  PPOBProductListParams,
  PPOBProductListResult,
  PPOBProductEditParams,
  PPOBProductEditResult,
} from "domains/entity/main/ppob/product";
import { SellingPriceListParams, SellingPriceListResult, SellingPriceComboboxCategoryResult, SellingPriceExportParams } from "domains/entity/main/ppob/sellingPrice";
import {
  PPOBExportTransactionParams,
  PPOBRefundTransactionParams,
  PPOBRefundTransactionResult,
  PPOBSuccessTransactionParams,
  PPOBSuccessTransactionResult,
  PPOBTransactionParams,
  PPOBTransactionResult,
} from "domains/entity/main/ppob/transaction";
import { PPOBRepository } from "domains/repository/main/ppob/PPOBRepository";

export class PPOBInteractor implements PPOBRepository {
  constructor(private repository: PPOBRepository) {}

  // transaction
  async getTransactionList(
    params: PPOBTransactionParams
  ): Promise<PPOBTransactionResult> {
    return await this.repository.getTransactionList(params);
  }

  async exportTransactionList(
    params: PPOBExportTransactionParams
  ): Promise<any> {
    return await this.repository.exportTransactionList(params);
  }

  postRefundTransactionList(
    params: PPOBRefundTransactionParams
  ): Promise<PPOBRefundTransactionResult> {
    return this.repository.postRefundTransactionList(params);
  }
  postSuccessTransactionList(
    params: PPOBSuccessTransactionParams
  ): Promise<PPOBSuccessTransactionResult> {
    return this.repository.postSuccessTransactionList(params);
  }

  // group
  async getGroupList(params: PpobGroupParams): Promise<PPOBGroupResult> {
    return await this.repository.getGroupList(params);
  }

  async editGroupList(
    params: PpobEditGroupParams
  ): Promise<PpobEditGroupResult> {
    return await this.repository.editGroupList(params);
  }

  // collecting agent
  getCollectingAgentList(
    params: PPOBCollectingAgentListParams
  ): Promise<PPOBCollectingAgentListResult> {
    return this.repository.getCollectingAgentList(params);
  }
  addCollectingAgent(params: PPOBAddCollectingAgentListParams): Promise<PPOBAddCollectingAgentListResult> {
    return this.repository.addCollectingAgent(params);
  }
  editCollectingAgent(
    params: PPOBCollectingAgentEditParams
  ): Promise<PPOBCollectingAgentEditResult> {
    return this.repository.editCollectingAgent(params);
  }
  getCollectingAgentDetailList(
    params: PPOBCollectingAgentDetailListParams
  ): Promise<PPOBCollectingAgentDetailListResult> {
    return this.repository.getCollectingAgentDetailList(params);
  }
  editPPOBCollectingAgentDetailEdit(
    params: PPOBCollectingAgentDetailEditParams
  ): Promise<PPOBCollectingAgentDetailEditResult> {
    return this.repository.editPPOBCollectingAgentDetailEdit(params);
  }
  addPPOBCollectingAgentDetail(
    params: PPOBCollectingAgentDetailAddParams
  ): Promise<PPOBCollectingAgentDetailAddResult> {
    return this.repository.addPPOBCollectingAgentDetail(params);
  }
  deletePPOBCollectingAgentDetail(
    params: PPOBCollectingAgentDetailDeleteParams
  ): Promise<PPOBCollectingAgentDetailDeleteResult> {
    return this.repository.deletePPOBCollectingAgentDetail(params);
  }

  // product
  getProductList(
    params: PPOBProductListParams
  ): Promise<PPOBProductListResult> {
    return this.repository.getProductList(params);
  }
  editProductList(
    params: PPOBProductEditParams
  ): Promise<PPOBProductEditResult> {
    return this.repository.editProductList(params);
  }

  //payment method
  getPaymentMethodList(
    params: PPOBPaymentMethodListParams
  ): Promise<PPOBPaymentMethodListResult> {
    return this.repository.getPaymentMethodList(params);
  }
  getComboPartnerList(): Promise<PPOBComboPartnerListResult> {
    return this.repository.getComboPartnerList();
  }
  getComboBoxPaymentMethodList(): Promise<PPOBComboBoxPaymentMethodListResult> {
    return this.repository.getComboBoxPaymentMethodList();
  }
  addPaymentMethodList(
    params: PPOBAddPaymentMethodParams
  ): Promise<PPOBAddPaymentMethodResult> {
    return this.repository.addPaymentMethodList(params);
  }
  editPaymentMethodList(
    params: PPOBEditPaymentMethodParams
  ): Promise<PPOBEditPaymentMethodResult> {
    return this.repository.editPaymentMethodList(params);
  }
  deletePaymentMethodList(
    params: PPOBDeletePaymentMethodParams
  ): Promise<PPOBDeletePaymentMethodResult> {
    return this.repository.deletePaymentMethodList(params);
  }

  //partner
  getPartnerList(
    params: PPOBPartnerListParams
  ): Promise<PPOBPartnerListResult> {
    return this.repository.getPartnerList(params);
  }
  editPartnerList(
    params: PPOBPartnerEditParams
  ): Promise<PPOBPartnerEditResult> {
    return this.repository.editPartnerList(params);
  }
  addPartnerList(params: PPOBPartnerAddParams): Promise<PPOBPartnerAddResult> {
    return this.repository.addPartnerList(params);
  }

  //selling price
  getSellingPrice(params: SellingPriceListParams): Promise<SellingPriceListResult> {
    return this.repository.getSellingPrice(params);
  }
  getComboboxCategories(): Promise<SellingPriceComboboxCategoryResult> {
    return this.repository.getComboboxCategories();
  }
  exportSellingPrice(params: SellingPriceExportParams): Promise<any> {
    return this.repository.exportSellingPrice(params);
  }
}
