import * as React from "react";
// import { GlobalContext } from "../context/GlobalContext";
import { useMemo, useRef } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IDSIcon from "assets/img/IDSIcon.png";
import IIcon from "assets/img/IIcon.png";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import CloseIcon from "@mui/icons-material/Close";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Link,
  NavLink,
} from "react-router-dom";
import {
  removeToken,
  removeUser,
  getUser,
  getToken,
  setLng,
  getLng,
  getFirstTime,
} from "utils/storage/LocalStorage";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useMediaQuery } from "react-responsive";
// import { Overlay } from "../context/overlayContext";
import { Card, FormControl, Stack } from "@mui/material";
import "./_NavBar.scss";

// import MainRouters from "domain/router/MainRouters";
import { Routes as IRoutes } from "domain/router/RouterInterface";
import MainRouters from "domain/router/MainRouters";
import { navMain, productRouters } from "domain/router/ProductRouters";
import LangChangeNavbar from "../LangChangeNavbar";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { toggleMenu, toggleNavbar } from "utils/store/global/navbar";
import modal from "utils/store/global/modal";

interface NavbarProps {
  produkRoutes: IRoutes[];
}

const Navbar: React.FC<NavbarProps> = ({ produkRoutes }) => {
  const dispatch = useAppDispatch()
  const navbar = useAppSelector((state) => state.global.navbar)

  const isMobile = useMediaQuery({ query: `(max-width: 610px)` });
  const drawerWidth = 240;

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
  });

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: isMobile ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1,
    width: isMobile ? "100%" : `calc(100% - (${theme.spacing(7)} + 1px))`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: isMobile ? 0 : drawerWidth,
      width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = useMemo(
    () =>
      styled(MuiDrawer, {
        shouldForwardProp: (prop) => prop !== "open",
      })(({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "pre-wrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(theme),
          position: isMobile ? "absolute" : "unset",
          "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        }),
      })),
    []
  );
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const user = JSON.parse(getUser() || "{}");
  const [openList, setOpenList] = React.useState<string[]>([]);
  const handleClick = (clickedIndex: string) => {
    dispatch(toggleMenu(clickedIndex))
  };

  // console.log(navbar.openedMenu);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleLogOut = () => {
    removeToken();
    removeUser();
    // window.location.reload()
    navigate("/");
  };
  const [accountType, setAccountType] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAccountType(event.target.value);
  };

  const [menu, setMenu] = React.useState<any[]>([]);

  React.useEffect(() => {
    const user = getUser();
    if (user) {
      const userJson = JSON.parse(user);
      const menu = userJson.parentMenuList;
      const filteredMenu = menu.filter((item: any) => {
        return (
          item.parentPrefix === "DANA" ||
          item.parentPrefix === "MerchantOnboarding" ||
          item.parentPrefix === "Multifinance" ||
          item.parentPrefix === "PPOB" ||
          item.parentPrefix === "Setorku" ||
          item.parentPrefix === "ototrans" ||
          item.parentPrefix === "disi"
        );
      });
      console.log(filteredMenu);
      setMenu(filteredMenu);
    }
  }, []);

  const [openSetting, setOpenSetting] = React.useState(false);

  const handleClickSetting = () => {
    setOpenSetting(!openSetting);
  };

  return (
    <Box sx={{ zIndex: 100, margin: "-0.5px" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={navbar.isNavbarOpen}
        sx={{
          backgroundColor: "white",
          opacity: navbar.isNavbarOpen ? (isMobile ? "0.5" : "1") : "1",
          boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.14)",
          zIndex: 99,
        }}
        // ref={elementRef}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={6}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(toggleNavbar())}
                edge="start"
                sx={{
                  color: "black",
                }}
              >
                <MenuIcon style={{ color: "#286353" }} />
              </IconButton>
            </Grid>
            <Grid
              item
              sm={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                verticalAlign: "middle",
              }}
              justifyContent="flex-end"
            >
              <LangChangeNavbar />
            </Grid>
            <Grid
              item
              sm={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                verticalAlign: "middle",
              }}
              justifyContent="flex-end"
            >
              <Grid>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        {...bindTrigger(popupState)}
                        sx={{
                          color: "#286353",
                          // marginRight: { sm: "1rem" },
                          borderRadius: "5px !important",
                        }}
                      >
                        <AccountCircle />
                        <Typography
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          color="#286353"
                        >
                          {user.name}
                        </Typography>
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          {t("header.profile_button")}
                        </MenuItem>
                        <MenuItem onClick={handleLogOut}>
                          {t("header.quit_button")}
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={navbar.isNavbarOpen} sx={{ border: "none" }}>
        {isMobile ? (
          <MuiDrawer
            open={navbar.isNavbarOpen}
            sx={{
              zIndex: 0,
              p: "0!important",
              border: "none !important",
            }}
            onClose={() => dispatch(toggleNavbar())}
          ></MuiDrawer>
        ) : null}
        <img
            src={navbar.isNavbarOpen ? IDSIcon : IIcon}
            // height={navbar.isNavbarOpen ? (isMobile ? "60%" : "auto") : "60%"}
            style={{
              height: navbar.isNavbarOpen ? (isMobile ? "auto" : "auto") : "auto",
              width: navbar.isNavbarOpen ? (isMobile ? "60%" : "50%") : "60%",
              marginLeft: navbar.isNavbarOpen ? (isMobile ? "0" : "1rem") : "0",
            }}
            alt="IDS Icon"
            loading="lazy"
          />
        <Typography
          sx={{
            fontWeight: 400,
            marginLeft: { sm: "1rem" },
            mt: 1,
            ml: 1,
            opacity: navbar.isNavbarOpen ? 1 : 0,
          }}
        >
          {t("navbar.main_menu")}
        </Typography>
        <List>
          {navMain.map((route, index) => (
            <Link
              key={index}
              to={route.path}
              onClick={isMobile ? handleDrawerClose : void 0}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(`navbar.${route.key}`)}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <Typography
          sx={{
            fontWeight: 400,
            marginLeft: { sm: "1rem" },
            mt: 1,
            ml: 1,
            opacity: open ? 1 : 0,
          }}
        >
          {t("navbar.product")}
        </Typography>
        <List>
          {Array.isArray(menu) &&
            menu.map((route: any, index: any) => {
              // if (route.exclude)
              //   return <React.Fragment key={route.key}></React.Fragment>;
              return (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={() => handleClick(`${route.parentMenuName}-${index}`)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={route.parentMenuName}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {openList.includes(`${route.key}-${index}`) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={navbar.openedMenu.includes(`${route.parentMenuName}-${index}`)}
                    timeout="auto"
                    unmountOnExit
                  >
                    {!Array.isArray(menu) ? [] : 
                      route.menuList.map((child:any, cIndex:any) => {
                        // if (child.exclude)s
                        //   return (
                        //     <React.Fragment key={child.key}></React.Fragment>
                        //   );
                        return (
                          <NavLink
                            key={cIndex}
                            to={`/dashboard/${route.parentPrefix}/${child.urlName}`}
                            // onClick={
                            //   isMobile
                            //     ? handleDrawerClose
                            //     : () => {
                            //         dispatchRefetch();
                            //       }
                            // }
                          >
                            <List component="div" disablePadding>
                              <ListItemButton sx={
                                location.pathname === `/dashboard/${route.parentPrefix}/${child.urlName}` ? {
                                  pl: 4,
                                  bgcolor: "lightgray",
                                } : {
                                  pl: 4,
                                }
                              }>
                                <ListItemIcon>
                                  <StarBorder />
                                </ListItemIcon>
                                <ListItemText
                                  primary={t(`navbar.${route.parentMenuName}.${child.menuName}`)}
                                />
                              </ListItemButton>
                            </List>
                          </NavLink>
                        );
                      })}
                  </Collapse>
                </ListItem>
              );
            })}
        </List>
        <Typography
          sx={{
            fontWeight: 400,
            marginLeft: { sm: "1rem" },
            mt: 1,
            ml: 1,
            opacity: open ? 1 : 0,
          }}
        >
          {t("navbar.general")}
        </Typography>
        {/* {MainRouters.map((item,index) => {
          return (
            <div>
              <h1>{item.name}</h1>
            </div>
          )
        }
        )} */}
        {/* {MainRouters.map((item, pIdx) => {
          if (item.exclude) {
            return <React.Fragment key={`${item.key}-${pIdx}`} />;
          }
          return (
            <ListItem key={item.key} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => handleClick(`${item.key}-${pIdx}`)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openList.includes(`${item.key}-${pIdx}`) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
              <Collapse
                in={openList.includes(`${item.key}-${pIdx}`)}
                timeout="auto"
                unmountOnExit
              >
                {Array.isArray(item.childs) &&
                  item.childs.map((child, cIdx) => {
                    if (child.exclude) {
                      return <React.Fragment key={`${child.key}-${cIdx}`} />;
                    }
                    return (
                      <Link
                        key={child.key}
                        to={`/Dashboard${item.path}${child.path}`}
                        onClick={isMobile ? handleDrawerClose : () => {}}
                      >
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 2 }}>
                            <ListItemIcon>
                              <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary={child.name} />
                          </ListItemButton>
                        </List>
                      </Link>
                    );
                  })}
              </Collapse>
            </ListItem>
          );
        })} */}
      </Drawer>
    </Box>
  );
};

export default Navbar;
