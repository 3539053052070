import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import DetailPettyCashBodyResult from "domains/entity/main/disi/DetailPettyCashBodyResult";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";

type iState = {
    loading: boolean;
    error: any;
    data: DetailPettyCashBodyResult
}

const initialData = {
    data: [],
    pageLimit: 0,
    totalRows: 0
}

const initialState: iState = {
    data: initialData,
    error: "",
    loading: false
}

export const detailPettyCashBodyDataAction = createAsyncThunk("detail-petty-cash-body-action", async (partnerId: string, { rejectWithValue }) => {
    try {

        const useCase = new DetailPettyCashUseCase(new DisiApi())

        return await useCase.getDetailPettyCashBodyData(partnerId)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const detailPettyCashBodySlice = createSlice({
    name: "detail-petty-cash-body-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(detailPettyCashBodyDataAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(detailPettyCashBodyDataAction.fulfilled, (state, action) => {
            const { data = [], pageLimit = 0, totalRows = 0 } = action.payload
            state.loading = false;
            state.data = { data, pageLimit, totalRows }
            state.error = ""
        })
        builder.addCase(detailPettyCashBodyDataAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
            state.data = initialData
        })
    }
})

const detailPettyCashBodyReducer = detailPettyCashBodySlice.reducer

export default detailPettyCashBodyReducer