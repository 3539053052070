import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";

type iState = {
    totalRealWallet: number
    totalMerchantWallet: number;
    totalReadyWallet: number;
    totalDebtWallet: number;
    loading: boolean;
    error: any
}

const initialState: iState = {
    totalDebtWallet: 0,
    totalMerchantWallet: 0,
    totalReadyWallet: 0,
    totalRealWallet: 0,
    loading: false,
    error: ''
}

export const partnerDepositWalletAction = createAsyncThunk('partner-deposit-wallet-action', async (_, { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())

        const realWallet = useCase.getPartnerDepositRealWallet()
        const readyWallet = useCase.getPartnerDepositReadyWallet()
        const debtWallet = useCase.getPartnerDepositDebtWallet()
        const merchantWallet = useCase.getPartnerDepositMerchantWallet()

        const [{ totalRealWallet }, { totalReadyWallet }, { totalDebtWallet }, { totalMerchantWallet }] = await Promise.all([realWallet, readyWallet, debtWallet, merchantWallet])

        return {
            totalRealWallet, totalReadyWallet, totalDebtWallet, totalMerchantWallet
        }
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositWalletSlice = createSlice({
    name: "partner-deposit-wallet-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(partnerDepositWalletAction.pending, state => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(partnerDepositWalletAction.fulfilled, (state, action) => {
            const { totalDebtWallet, totalMerchantWallet, totalReadyWallet, totalRealWallet } = action.payload
            state.totalMerchantWallet = totalMerchantWallet
            state.totalDebtWallet = totalDebtWallet
            state.totalReadyWallet = totalReadyWallet
            state.totalRealWallet = totalRealWallet
            state.loading = false;
            state.error = ""
        })
        builder.addCase(partnerDepositWalletAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const partnerDepositWalletReducer = partnerDepositWalletSlice.reducer

export default partnerDepositWalletReducer