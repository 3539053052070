import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query-devtools'
import {Provider} from 'react-redux'
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import enTranslation from './utils/i18n/en/translation.json'
import idTranslation from './utils/i18n/id/translation.json'
import store from './utils/store';
// import enTranslation from './config/i18n/en/translation.json';
// import idTranslation from './config/i18n/id/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en','id'],
    resources:{
      en:{
        translation:enTranslation
      },
      id:{
        translation: idTranslation
      }
    },
    fallbackLng: "en",
    detection:{
      order: ['localStorage', 'sessionStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],      
    },
  });

  const queryClient = new QueryClient()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  // <QueryClientProvider client={queryClient}>
    <Provider store={store}>
    <BrowserRouter>
      <App />
    {/* <ReactQueryDevtools initialIsOpen={false}/> */}
    </BrowserRouter>
    </Provider>
  // </QueryClientProvider>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
