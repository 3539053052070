import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Container,
  Stack,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import usePPOBGroupViewModel from "./groupViewModel";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";


const PPOBGroupComponent = () => {
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    modalEdit,
    setModalEdit,
    t,
    handleSubmit,
    control,
    submitEdit,
  } = usePPOBGroupViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.group.header")}
                </Typography>
              </Stack>
              {data.groupList.loading ? (
                <h1>Loading...</h1>
              ) : (
                <DataTable
                  {...{
                    data: data.groupList.data.data,
                    totalRows: data.groupList.data.totalRows,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
        <ModalContainer
          open={modalEdit}
          onClose={() => setModalEdit(false)}
          applyButton={handleSubmit(submitEdit)}
          header={t("ppob.group.modal_edit_header")}
          subHeader={t("ppob.group.modal_edit_subheader")}
        >
          <Controller
            name="ppobId"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  label={t("ppob.group.id_ppob")}
                  variant="outlined"
                  size="small"
                  disabled
                  sx={{ marginBottom: "1rem" }}
                />
              </FormControl>
            )}
          />
          <Controller
            name="ppobName"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  label={t("ppob.group.ppob_name")}
                  variant="outlined"
                  size="small"
                  disabled
                  sx={{ marginBottom: "1rem" }}
                />
              </FormControl>
            )}
          />
          <Controller
            name="isActive"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("ppob.group.is_active")}
                </InputLabel>
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("ppob.product.is_active")}
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: "1rem" }}
                >
                  <MenuItem value="1">{t("ppob.group.active")}</MenuItem>
                  <MenuItem value="0">{t("ppob.group.inactive")}</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </ModalContainer>
      </Container>
    </LayoutComponent>
  );
};

export default PPOBGroupComponent;
