import httpCommon from "datas/httpCommon";
import { PPOBCollectingAgentListParams, PPOBCollectingAgentListResult, PPOBCollectingAgentEditParams, PPOBCollectingAgentEditResult, PPOBCollectingAgentDetailListParams, PPOBCollectingAgentDetailListResult, PPOBCollectingAgentDetailEditParams, PPOBCollectingAgentDetailEditResult, PPOBCollectingAgentDetailAddParams, PPOBCollectingAgentDetailAddResult, PPOBCollectingAgentDetailDeleteParams, PPOBCollectingAgentDetailDeleteResult, PPOBAddCollectingAgentListParams, PPOBAddCollectingAgentListResult } from "domains/entity/main/ppob/collectingAgent";
import {
  PpobEditGroupParams,
  PpobEditGroupResult,
  PpobGroupParams,
  PpobGroupResult,
} from "domains/entity/main/ppob/group";
import { PPOBPartnerListParams, PPOBPartnerListResult, PPOBPartnerEditParams, PPOBPartnerEditResult, PPOBPartnerAddParams, PPOBPartnerAddResult } from "domains/entity/main/ppob/partner";
import { PPOBPaymentMethodListParams, PPOBPaymentMethodListResult, PPOBComboPartnerListResult, PPOBComboBoxPaymentMethodListResult, PPOBAddPaymentMethodParams, PPOBAddPaymentMethodResult, PPOBEditPaymentMethodParams, PPOBEditPaymentMethodResult, PPOBDeletePaymentMethodParams, PPOBDeletePaymentMethodResult } from "domains/entity/main/ppob/paymentMethod";
import { PPOBProductListParams, PPOBProductListResult, PPOBProductEditParams, PPOBProductEditResult } from "domains/entity/main/ppob/product";
import { SellingPriceListParams, SellingPriceListResult, SellingPriceComboboxCategoryResult, SellingPriceExportParams } from "domains/entity/main/ppob/sellingPrice";
import {
  PPOBExportTransactionParams,
  PPOBRefundTransactionParams,
  PPOBRefundTransactionResult,
  PPOBSuccessTransactionParams,
  PPOBSuccessTransactionResult,
  PPOBTransactionParams,
  PPOBTransactionResult,
} from "domains/entity/main/ppob/transaction";
import { PPOBRepository } from "domains/repository/main/ppob/PPOBRepository";

export class PPOBApi implements PPOBRepository {
  // transaction
  async getTransactionList(
    params: PPOBTransactionParams
  ): Promise<PPOBTransactionResult> {
    const {
      pageSize,
      pageNumber,
      partnerId,
      fromDate,
      toDate,
      trxId,
      transactionDate,
      productId,
      customerId,
      receiptNo,
      trackingRef,
    } = params;
    const data = new URLSearchParams({
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
      partnerId,
      fromDate,
      toDate,
      trxId,
      transactionDate,
      productId,
      customerId,
      receiptNo,
      trackingRef,
    });
    return await httpCommon
      .get<PPOBTransactionResult>(`/ppob/transaction?${data}`)
      .then((res) => res.data);
  }

  async exportTransactionList(
    params: PPOBExportTransactionParams
  ): Promise<any> {
    const { fromDate, toDate, partnerId } = params;
    const data = new URLSearchParams({
      fromDate,
      toDate,
      partnerId,
    });
    return await httpCommon
      .get<any>(`/ppob/transaction/export?${data}`)
      .then((res) => res.data);
  }

  async postRefundTransactionList(
    params: PPOBRefundTransactionParams
  ): Promise<PPOBRefundTransactionResult> {
    return await httpCommon
      .post<PPOBRefundTransactionResult>(`/ppob/transaction/refund`, params)
      .then((res) => res.data);
  }

  postSuccessTransactionList(
    params: PPOBSuccessTransactionParams
  ): Promise<PPOBSuccessTransactionResult> {
    return httpCommon
      .post<PPOBSuccessTransactionResult>(`/ppob/transaction/success`, params)
      .then((res) => res.data);
  }

  // group
  getGroupList(params: PpobGroupParams): Promise<PpobGroupResult> {
    const { 
      pageSize,
      pageNumber,
      filterText
     } = params;
    const data = new URLSearchParams({
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
      filterText
    });
    return httpCommon.get<PpobGroupResult>(`/ppob/group?${data}`).then((res) => res.data);
  }
  editGroupList(params: PpobEditGroupParams): Promise<PpobEditGroupResult> {
    return httpCommon.put<PpobEditGroupResult>(`/ppob/group`, params).then((res) => res.data);
  }

  //collecting agent
  getCollectingAgentList(params: PPOBCollectingAgentListParams): Promise<PPOBCollectingAgentListResult> {
    const data = new URLSearchParams({
      pageSize: params.pageSize.toString(),
      pageNumber: params.pageNumber.toString(),
      filterText: params.filterText,
      partnerId: params.partnerId,
    });
    return httpCommon.get<PPOBCollectingAgentListResult>(`/ppob/ca?${data}`).then((res) => res.data);
  }

  addCollectingAgent(params: PPOBAddCollectingAgentListParams): Promise<PPOBAddCollectingAgentListResult> {
    return httpCommon.post<PPOBAddCollectingAgentListResult>(`/ppob/ca`, params).then((res) => res.data);
  }

  editCollectingAgent(params: PPOBCollectingAgentEditParams): Promise<PPOBCollectingAgentEditResult> {
    return httpCommon.put<PPOBCollectingAgentEditResult>(`/ppob/ca`, params).then((res) => res.data);
  }

  getCollectingAgentDetailList(params: PPOBCollectingAgentDetailListParams): Promise<PPOBCollectingAgentDetailListResult> {
    const { pageSize, pageNumber, filterText, partnerId } = params;
    const data = new URLSearchParams({
      partnerId: params.partnerId,
    });
    return httpCommon.get<PPOBCollectingAgentDetailListResult>(`/ppob/caDetail?${data}`).then((res) => res.data);
  }

  editPPOBCollectingAgentDetailEdit(params: PPOBCollectingAgentDetailEditParams): Promise<PPOBCollectingAgentDetailEditResult> {
    return httpCommon.put<PPOBCollectingAgentDetailEditResult>(`/ppob/caDetail`, params).then((res) => res.data);
  }
  addPPOBCollectingAgentDetail(params: PPOBCollectingAgentDetailAddParams): Promise<PPOBCollectingAgentDetailAddResult> {
    return httpCommon.post<PPOBCollectingAgentDetailAddResult>(`/ppob/caDetail`, params).then((res) => res.data);
  }
  deletePPOBCollectingAgentDetail(params: PPOBCollectingAgentDetailDeleteParams): Promise<PPOBCollectingAgentDetailDeleteResult> {
    return httpCommon.delete<PPOBCollectingAgentDetailDeleteResult>(`/ppob/caDetail`, { data: params }).then((res) => res.data);
  }

  //product
  getProductList(params: PPOBProductListParams): Promise<PPOBProductListResult> {
    const data = new URLSearchParams({
      pageSize: params.pageSize.toString(),
      pageNumber: params.pageNumber.toString(),
      filterText: params.filterText
    });
    return httpCommon.get<PPOBProductListResult>(`/ppob/product?${data}`).then((res) => res.data);
  }
  editProductList(params: PPOBProductEditParams): Promise<PPOBProductEditResult> {
    return httpCommon.put<PPOBProductEditResult>(`/ppob/product`, params).then((res) => res.data);
  }

  //payment method
  getPaymentMethodList(params: PPOBPaymentMethodListParams): Promise<PPOBPaymentMethodListResult> {
    const data = new URLSearchParams({
      pageSize: params.pageSize.toString(),
      pageNumber: params.pageNumber.toString(),
      partnerId: params.partnerId,
      paymentMethod: params.paymentMethod
    });
    return httpCommon.get<PPOBPaymentMethodListResult>(`/ppob/paymentmethod?${data}`).then((res) => res.data);
  }
  getComboPartnerList(): Promise<PPOBComboPartnerListResult> {
    return httpCommon.get<PPOBComboPartnerListResult>(`/global/partner/solusipay`).then((res) => res.data);
  }
  getComboBoxPaymentMethodList(): Promise<PPOBComboBoxPaymentMethodListResult> {
    return httpCommon.get<PPOBComboBoxPaymentMethodListResult>(`/global/paymentmethod`).then((res) => res.data);
  }
  addPaymentMethodList(params: PPOBAddPaymentMethodParams): Promise<PPOBAddPaymentMethodResult> {
    return httpCommon.post<PPOBAddPaymentMethodResult>(`/ppob/paymentmethod`, params).then((res) => res.data);
  }
  editPaymentMethodList(params: PPOBEditPaymentMethodParams): Promise<PPOBEditPaymentMethodResult> {
    return httpCommon.put<PPOBEditPaymentMethodResult>(`/ppob/paymentmethod`, params).then((res) => res.data);
  }
  deletePaymentMethodList(params: PPOBDeletePaymentMethodParams): Promise<PPOBDeletePaymentMethodResult> {
    return httpCommon.delete<PPOBDeletePaymentMethodResult>(`/ppob/paymentmethod`, { data: params }).then((res) => res.data);
  }

  //partner
  getPartnerList(params: PPOBPartnerListParams): Promise<PPOBPartnerListResult> {
    const data = new URLSearchParams({
      pageSize: params.pageSize.toString(),
      pageNumber: params.pageNumber.toString(),
      filterText: params.filterText
    });
    return httpCommon.get<PPOBPartnerListResult>(`/ppob/partner?${data}`).then((res) => res.data);
  }
  editPartnerList(params: PPOBPartnerEditParams): Promise<PPOBPartnerEditResult> {
    return httpCommon.put<PPOBPartnerEditResult>(`/ppob/partner`, params).then((res) => res.data);
  }
  addPartnerList(params: PPOBPartnerAddParams): Promise<PPOBPartnerAddResult> {
    return httpCommon.post<PPOBPartnerAddResult>(`/ppob/partner`, params).then((res) => res.data);
  }

  //selling price
  getSellingPrice(params: SellingPriceListParams): Promise<SellingPriceListResult> {
    const data = new URLSearchParams({
      pageSize: params.pageSize.toString(),
      pageNumber: params.pageNumber.toString(),
      partnerId: params.partnerId,
      productSKUCode: params.productSKUCode,
      categoryId: params.categoryId,
    });
    return httpCommon.get<SellingPriceListResult>(`/ppob/sellingprice?${data}`).then((res) => res.data);
  }
  getComboboxCategories(): Promise<SellingPriceComboboxCategoryResult> {
    return httpCommon.get<SellingPriceComboboxCategoryResult>(`/global/categorylist`).then((res) => res.data);
  }
  exportSellingPrice(params: SellingPriceExportParams): Promise<any> {
    const data = new URLSearchParams({
      partnerId: params.partnerId,
    });
    return httpCommon.get(`/ppob/sellingprice/export?${data}`).then((res) => res.data);
  }
}
