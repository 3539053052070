import { Button, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  addProductAction,
  productListAction,
  deleteProductAction,
  updateProductAction,
  detailProduct,
} from "utils/store/main/setorku/product";
import { showAddModal, showDeleteModal, showEditModal, showFilterModal } from "utils/store/global/modal";
import IconButton from "@mui/material/IconButton";

const useSetorkuProductViewModel = () => {
  const { t }: any = useTranslation();
  const { handleSubmit, control, reset, register, setValue } = useForm({
    defaultValues: {
      productId: "",
      companyId: "",
      companyCode: "",
      companyName: "",
      prefix: "",
      secretKey: "",
    },
  });
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.setorku);
  const modal = useAppSelector((state) => state.global.modal);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100, maxWidth: 100 },
    {
      field: "productId",
      headerName: t("setorku.product.id_product"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "companyId",
      headerName: t("setorku.product.company_id"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "companyDesc",
      headerName: t("setorku.product.company_code"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "productName",
      headerName: t("setorku.product.product_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "prefix",
      headerName: t("setorku.product.prefix"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Stack direction="row">
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => modalHandler('edit',row.row)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => modalHandler('delete',row.row)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const modalHandler = (type: string, data?:any) => {
    switch (type) {
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        dispatch(detailProduct(data));
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete));
        dispatch(detailProduct(data));
        break;
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      default:
        break;
    }
  }

  const submitAddProduct = async (data: any) => {
    await dispatch(
      addProductAction({
        productId: data.productId,
        perusahaanId: data.companyId,
        kodePerusahaan: data.companyCode,
        namaPerusahaan: data.companyName,
        prefix: data.prefix,
        secretKey: data.secretKey,
      })
    );
    fetchProduct();
    modalHandler("add");
  };

  const submitFilterProduct = async (data: any) => {
    await dispatch(
      productListAction({
        fromDate: dayjs(startDate).format("DD-MM-YYYY"),
        toDate: dayjs(endDate).format("DD-MM-YYYY"),
      })
    );
    modalHandler("filter");
  }

  const submitEditProduct = async (data: any) => {
    await dispatch(
      updateProductAction({
        productId: data.productId,
        perusahaanId: data.companyId,
        kodePerusahaan: data.companyCode,
        namaPerusahaan: data.companyName,
        prefix: data.prefix,
      })
    );
    fetchProduct();
    modalHandler("edit");
  };

  const submitDeleteProduct = async () => {
    await dispatch(
      deleteProductAction({
        productId: selector.produk.detail.productId,
        companyId: selector.produk.detail.companyId,
      })
    );
    fetchProduct();
    modalHandler("delete");
  };

  const fetchProduct = () => {
    dispatch(
      productListAction({
        fromDate: dayjs(startDate).format("DD-MM-YYYY"),
        toDate: dayjs(endDate).format("DD-MM-YYYY"),
      })
    );
  };

  useEffect(() => {
    fetchProduct();
  }, [page, pageSize]);

  return {
    t,
    data: selector,
    modal,
    modalHandler,
    columns,
    submitAddProduct,
    submitFilterProduct,
    submitEditProduct,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    submitDeleteProduct,
  };
};

export default useSetorkuProductViewModel;
