import React from 'react';
import ModalContainer from 'presentation/components/ModalContainer';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const RefundModal = ({ header, subHeader, open, onClose, applyButton, cancelButton, control }: any) => {
    return (
        <ModalContainer
            header={header}
            subHeader={subHeader}
            open={open}
            onClose={onClose}
            applyButton={applyButton}
            cancelButton={cancelButton}
        >
            <FormControl fullWidth>
                <Controller
                    name="refund.acquirementid"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            disabled
                            label="code"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: "1rem" }}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth>
                <Controller
                    name="reason"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Reason"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: "1rem" }}
                        >
                            <MenuItem value="12"> Invalid Transaction </MenuItem>
                            <MenuItem value="1"> Reason 1 </MenuItem>
                            <MenuItem value="2"> Reason 2 </MenuItem>
                        </Select>
                    )}
                />
            </FormControl>
        </ModalContainer>
    );
}

export default RefundModal;