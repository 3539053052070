export const exportCSV = (data: any, fileName: string) => {
  {
    const dataType = "application/csv";
    const linkElement = document.createElement("a");
    linkElement.setAttribute(
      "href",
      window.URL.createObjectURL(new Blob([data], { type: dataType }))
    );
    linkElement.setAttribute("download", `${fileName}.csv`);
    linkElement.click();
  }
};
