import React from "react";
import { Box, Grid, Typography, NativeSelect, MenuItem } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { getLng, setLng } from "utils/storage/LocalStorage";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function LangChangeNavbar() {
    const handleChange = (event: SelectChangeEvent) => {
        setLng(event.target.value);
    };

    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            <Box sx={{ alignItems: "center", display: "flex", pl: 1 }}>
                <LanguageIcon
                    sx={{ color: "#286353", marginRight: { sm: "0.5rem" } }}
                />
                <NativeSelect
                    defaultValue={getLng()}
                    onChange={(e): any => setLng(e.target.value)}
                    disableUnderline
                    sx={{
                        color: "#286353",
                        "& .MuiNativeSelect-icon": {
                            color: "#286353 !important",
                        },
                    }}
                >
                    <option value={"id"}>ID</option>
                    <option value={"en"}>EN</option>
                </NativeSelect>
            </Box>
        </Grid>
    );
}
