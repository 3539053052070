import React from "react";
import {
    Stack,
    Box,
    Card,
    CardContent,
    Container,
    Tooltip,
    Typography,
    Button,
} from "@mui/material";
import LayoutComponent from "presentation/layout/Layout";
import DataTable from "presentation/components/DataTable";
import currencyFormatter from "utils/helper/currencyFormatter";
import DisiMerchantDepositViewModel from "./MerchantDepositViewModel";
import FilterForm from "./components/FilterForm";
import { FormProvider } from "react-hook-form";
import AddForm from "./components/AddForm";

const DisiMerchantDepositComponent = () => {
    const {
        role,
        t,
        columns,
        merchantDepositSaldo,
        merchantDeposit,
        page,
        pageSize,
        setPage,
        setPageSize,
        filterForm,
        onFilterSubmit,
        resetFilterValue,
        onExportList,
        addForm,
        onAddSubmit,
        modal,
        resetAddFormValue,
        handleModal,
    } = DisiMerchantDepositViewModel();

    return (
        <LayoutComponent>
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
            >
                <Box
                    display="grid"
                    sx={{
                        py: "1rem",
                        gap: "1rem",
                        width: "95%",
                        height: "fit-content",
                        "& .MuiCard-root .MuiCardContent-root": {
                            p: "1rem",
                        },
                    }}
                >
                    <Card>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto",
                                    gridAutoFlow: "row",
                                    gap: ".5rem 0",
                                    p: {
                                        xs: 0,
                                        sm: "1rem",
                                    },
                                }}
                            >
                                <Typography
                                    className="headerName"
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        mb: "1.5rem",
                                        fontWeight: 600,
                                        gridColumn: {
                                            xs: "1 / span 2",
                                            sm: "1",
                                        },
                                    }}
                                >
                                    {t("disi.merchant_deposit.table_header")}
                                </Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        gap: ".5rem",
                                        "& .MuiButtonBase-root": {
                                            width: "100%",
                                        },
                                        gridColumn: {
                                            xs: "1 / span 2",
                                            sm: "span 1",
                                        },
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleModal("filter")}
                                    >
                                        Filter
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={onExportList}
                                    >
                                        Export
                                    </Button>
                                    {role.isAdd && (
                                        <Button
                                            variant="contained"
                                            onClick={() => handleModal("add")}
                                        >
                                            {t(
                                                "disi.merchant_deposit.form_add_header"
                                            )}
                                        </Button>
                                    )}
                                </Box>
                                <Typography
                                    fontWeight={800}
                                    sx={{
                                        color: "#ff9800",
                                        fontSize: "1.25rem",
                                    }}
                                >
                                    {`${t(
                                        "disi.merchant_deposit.total_balance"
                                    )}: ${currencyFormatter(
                                        merchantDepositSaldo.data
                                    )}`}
                                </Typography>
                                <Box
                                    sx={{
                                        gridColumn: "1 / span 2",
                                    }}
                                >
                                    <DataTable
                                        {...{
                                            columns,
                                            page,
                                            pageSize,
                                            setPage,
                                            setPageSize,
                                            data: merchantDeposit.data.data,
                                            totalRows:
                                                merchantDeposit.data.totalRows,
                                            loading: merchantDeposit.loading,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <FormProvider {...filterForm}>
                    <FilterForm
                        isOpen={modal.filter}
                        handleClose={() => handleModal("filter")}
                        handleReset={resetFilterValue}
                        onSubmit={onFilterSubmit}
                    />
                </FormProvider>
                <FormProvider {...addForm}>
                    <AddForm
                        handleClose={() => handleModal("add")}
                        isOpen={modal.add}
                        onSubmit={onAddSubmit}
                    />
                </FormProvider>
            </Container>
        </LayoutComponent>
    );
};

export default DisiMerchantDepositComponent;
