import React, { useState } from "react";
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    Divider,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "domain/hooks/redux-hook";

type RejectFormProps = {
    isOpen?: boolean;
    onSubmit?: (...args: any[]) => void;
    onClose?: () => void;
};

const RejectForm: React.FC<RejectFormProps> = ({
    isOpen = false,
    onSubmit = () => {},
    onClose = () => {},
}) => {
    const { t } = useTranslation();
    const { register, handleSubmit } = useFormContext();

    const { loading } = useAppSelector(
        (state) => state.main.disi.detailPettyCashAction
    );

    const { loading: loadingAccountability } = useAppSelector(
        (state) => state.main.disi.detailPettyCashAccountabilityActions
    );

    return (
        <Modal open={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 640,
                        width: "90%",
                        background: "white",
                        borderRadius: "20px",
                        height: "fit-content",
                        maxHeight: 640,
                    }}
                >
                    <CardContent sx={{ textAlign: "center" }}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                fontSize: "2.125rem",
                                fontWeight: 600,
                            }}
                        >
                            {t("disi.detail_petty_cash.reject_header")}
                        </Typography>
                        <Typography
                            id="modal-modal-description"
                            sx={{
                                lineHeight: "166%",
                                color: "#6E6E6E",
                                mb: "1rem",
                            }}
                        >
                            {t("disi.detail_petty_cash.reject_subheader")}
                        </Typography>
                        <Divider
                            variant="middle"
                            sx={{
                                mt: "0 !important",
                                mr: "0 !important",
                                ml: "0 !important",
                                mb: "1rem !important",
                            }}
                        />
                        <TextField
                            {...register("rejectNote")}
                            fullWidth
                            label={t("disi.detail_petty_cash.reject_note")}
                        />
                    </CardContent>
                    <CardActions disableSpacing={true} sx={{ px: "6rem" }}>
                        <Button
                            disabled={loading || loadingAccountability}
                            onClick={onClose}
                            variant="text"
                            sx={{
                                color: "rgba(40, 99, 83, 1)",
                                width: "317.5px",
                            }}
                        >
                            {t("disi.petty_cash.cancel")}
                        </Button>
                        <Button
                            disabled={loading || loadingAccountability}
                            type="submit"
                            variant="contained"
                            sx={{
                                width: "317.5px",
                            }}
                        >
                            {loading || loadingAccountability ? (
                                <CircularProgress size={24} />
                            ) : (
                                t("disi.petty_cash.send_request")
                            )}
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Modal>
    );
};

export default RejectForm;
