type DateType = "DD-MM-YYYY" | "MM-DD-YYYY" | "YYYY-MM-DD";

export const formatDate = (_date: Date, format?: DateType) => {
    const _day = _date.getDate();
    const _month = _date.getMonth() + 1;

    const day = _day < 10 ? `0${_day}` : _day;
    const month = _month < 10 ? `0${_month}` : _month;
    const year = _date.getFullYear();

    switch (format) {
        case "MM-DD-YYYY":
            return `${month}-${day}-${year}`;
        case "YYYY-MM-DD":
            return `${year}-${month}-${day}`;
        case "DD-MM-YYYY":
        default:
            return `${day}-${month}-${year}`;
    }
};