import { AccountabilityFileParams, CancelTokenBody, DetailPettyCashAccountabilityApproveBody, DetailPettyCashAccountabilityBody, DetailPettyCashAccountabilityParams, DetailPettyCashAccountabilityRejectBody, DetailPettyCashApproveBody, DetailPettyCashRejectBody } from "domains/entity/main/disi";
import DisiRepository from "domains/repository/main/disi/DisiRepository";


export default class DetailPettyCashUseCase {
    constructor(private repository: DisiRepository) { }

    getDetailPettyCashHeaderData(pettyCashId: string) {
        return this.repository.getDetailPettyCashHeader(pettyCashId)
    }

    getDetailPettyCashBodyData(pettyCashId: string) {
        return this.repository.getDetailPettyCashBody(pettyCashId)
    }

    getDetailPettyCashAccountabilityData(args: DetailPettyCashAccountabilityParams) {
        return this.repository.getDetailPettyCashAccountability(args)
    }

    postDetailPettyCashApprove(data: DetailPettyCashApproveBody) {
        return this.repository.postDetailPettyCashApprove(data)
    }

    postDetailPettyCashReject(data: DetailPettyCashRejectBody) {
        return this.repository.postDetailPettyCashReject(data)
    }

    postDetailpettyCashAccountability(body: DetailPettyCashAccountabilityBody) {
        const formData = new FormData()
        const { file, ...rest } = body
        if (file === null) throw new Error("must include accountability file")
        formData.append('file', file);
        formData.append('JSON', JSON.stringify(rest))

        return this.repository.postDetailPettyAccountability(formData)
    }

    postDetailPettyCashAccountabilityApprove(body: DetailPettyCashAccountabilityApproveBody) {
        return this.repository.postDetailPettyAccountabilityApprove(body)
    }

    postDetailPettyCashAccountabilityReject(body: DetailPettyCashAccountabilityRejectBody) {
        return this.repository.postDetailPettyAccountabilityReject(body)
    }

    getAccountabilityFile(args: AccountabilityFileParams) {
        return this.repository.getAccountabilityFile(args)
    }

    postDetailPettyCashCancelToken(body: CancelTokenBody) {
        return this.repository.postDetailPettyCashCancelToken(body)
    }
}