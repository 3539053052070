import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { PPOBAddCollectingAgentListParams, PPOBCollectingAgentEditParams, PPOBCollectingAgentListParams } from "domains/entity/main/ppob/collectingAgent";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

export const collectingAgentListAction = createAsyncThunk(
    "ppobCollectingAgent/list",
    async (params: PPOBCollectingAgentListParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.getCollectingAgentList(params);
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const collectingAgentAddAction = createAsyncThunk(
    "ppobCollectingAgent/add",
    async (params: PPOBAddCollectingAgentListParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.addCollectingAgent({
                partnerId: params.partnerId,
                partnerName: params.partnerName,
                secretKey: params.secretKey,
                status: params.status,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const collectingAgentUpdateAction = createAsyncThunk(
    "ppobCollectingAgent/update",
    async (params: PPOBCollectingAgentEditParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.editCollectingAgent({
                partnerId: params.partnerId,
                partnerName: params.partnerName,
                secretKey: params.secretKey,
                status: params.status,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);


const slice = createSlice({
    name: "ppobCollectingAgent",
    initialState: {
        data: {
            totalRows: 0,
            pageLimit: 0,
            data: [{
                partnerId: '',
                description: '',
                secretKey: '',
                isActive: '',
            }],
        },
        detail: {
            partnerId: '',
            description: '',
            secretKey: '',
            isActive: '',
        },
        loading: false,
        error: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(collectingAgentListAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(collectingAgentListAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.detail = action.payload.data[0];
        });
        builder.addCase(collectingAgentListAction.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default slice.reducer;