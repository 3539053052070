import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Container,
  Stack,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";
import useSetorkuDashboardViewModel from "./DashboardViewModel";
import ChartComponent from "./component/AreaChartComp";
import AreaChartComponent from "./component/AreaChartComp";
import PieChartComponent from "./component/PieChartComp";


const SetorkuDashboardComponent = () => {
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    modalEdit,
    setModalEdit,
    t,
    handleSubmit,
    control,
    submitEdit,
  } = useSetorkuDashboardViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
            gap: "1rem",
          }}
        >
          <Box
            display="flex"
            sx={{
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              gap: "1rem",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.group.header")}
                </Typography>
              </Stack>
              <AreaChartComponent />
            </Grid>
          </Box>
          <div style={{
            width: "50%",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          >
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                padding: "1rem",
                gap: "1rem",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgba(34, 136, 102, 0.12)",
                },
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" component="h1">
                  Total Penjualan
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="month"
                  defaultValue="januari"
                  sx={{
                    width: "40%",
                    height: "100%",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <MenuItem value="januari">Januari</MenuItem>
                  <MenuItem value="februari">Februari</MenuItem>
                </Select>
              </Stack>
              <PieChartComponent />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                padding: "1rem",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgba(34, 136, 102, 0.12)",
                },
              }}
            >
              <Typography variant="h6" component="h1" sx={{ mb: "1.5rem" }}>
                Penjualan
              </Typography>
              <Typography variant="h6" component="h1" sx={{ mb: "1.5rem" }}>
                1.000.000
              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                gap: "1rem",
                padding: "1rem",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgba(34, 136, 102, 0.12)",
                },
              }}
            >
              <Typography variant="h6" component="h1" sx={{ mb: "1.5rem" }}>
                Transaksi
              </Typography>
              <Typography variant="h6" component="h1" sx={{ mb: "1.5rem" }}>
                1.000
              </Typography>
            </Box>
          </div>
        </div>
      </Container>
    </LayoutComponent>
  );
};

export default SetorkuDashboardComponent;
