import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import { refundTransactionHistoryAction, transactionHistoryListAction } from "utils/store/main/dana/transactionHistory";
import { Button, Stack, Typography } from "@mui/material";
import { showFilterModal, showRefundModal } from "utils/store/global/modal";
import { useForm } from "react-hook-form";

const useDanaHistoryTransactionViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.dana);
  const modal = useAppSelector((state) => state.global.modal);
  const { handleSubmit, control, setValue } = useForm();

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "acquirementid",
      headerName: t("dana.transaction_history.acquirement_id"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "customerid",
      headerName: t("dana.transaction_history.customer_id"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "productdesc",
      headerName: t("dana.transaction_history.product"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "tracking_ref",
      headerName: t("dana.transaction_history.no_ref"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "amount",
      headerName: t("dana.transaction_history.total"),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(params.value)}
          </>
        );
      }
    },
    {
      field: "status",
      headerName: t("dana.transaction_history.status"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Stack direction="row" spacing={1}>
            <Button onClick={() => { modalHandler('refund', row.row) }} variant="contained" color="primary" size="small">
              Refund
            </Button>
          </Stack>
        );
      },
    },
  ];

  const modalHandler = (type: string, data?: any) => {
    switch (type) {
      case "filter":
        dispatch(showFilterModal(!modal.filter))
        break;
      case "refund":
        console.log(data)
        dispatch(showRefundModal(!modal.refund))
        setValue('refund', data)
        setValue('reason', '12')
        break;
      default:
        break;
    }
  };

  const fetchTransactionHistory = async () => {
    await dispatch(
      transactionHistoryListAction({
        fromDate: dayjs(startDate).format("DD-MM-YYYY"),
        toDate: dayjs(endDate).format("DD-MM-YYYY"),
        acquirementId: "",
        customerid: "",
        productdesc: "",
        tracking_ref: "",
        amount: "",
        status: "ALL",
        pageSize: pageSize,
        pageNumber: page,
      })
    );
  };

  const submitRefund = async (data: any) => {
    const selectReason = [
      {
        id: '12',
        name: 'Invalid Transaction',
      },
      {
        id: '1',
        name: 'Reason 1',
      },
      {
        id: '2',
        name: 'Reason 2',
      }
    ]
    // console.log({
    //   acquirementid: data.refund.acquirementid,
    //   rc: data.reason,
    //   rcdesc: selectReason.find((item) => item.id === data.reason)?.name,
    //   status: data.refund.status,
    //   tracking_ref: data.refund.tracking_ref,
    // });
    await dispatch(refundTransactionHistoryAction({
      acquirementid: data.refund.acquirementid,
      rc: data.reason,
      rcdesc: selectReason.find((item) => item.id === data.reason)?.name || '',
      status: data.refund.status,
      tracking_ref: data.refund.tracking_ref,
    }));
    // if (selector.transactionHistory.refund.error === null) {
    //   await dispatch(showRefundModal(!modal.refund));
    // } else {
    //   console.log(selector.transactionHistory.refund.error);
    // }
  };

  React.useEffect(() => {
    fetchTransactionHistory();
  }, [page, pageSize, startDate, endDate]);

  return {
    data: selector,
    modal,
    modalHandler,
    submitRefund,
    handleSubmit,
    control,
    page,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    columns,
    t,
  };
};

export default useDanaHistoryTransactionViewModel;
