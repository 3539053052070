import { createSlice } from "@reduxjs/toolkit";

interface NavbarState {
    isNavbarOpen: boolean;
    openedMenu: string[];
}

const initialState: NavbarState = {
    isNavbarOpen: true,
    openedMenu: [],
};

const slice = createSlice({
    name: "navbar",
    initialState,
    reducers: {
        toggleNavbar: (state) => {
            state.isNavbarOpen = !state.isNavbarOpen;
        },
        toggleMenu: (state, action) => {
            const menu = action.payload;
            if (state.openedMenu.includes(menu)) {
                state.openedMenu = state.openedMenu.filter((m) => m !== menu);
            } else {
                state.openedMenu.push(menu);
                if (state.openedMenu.length > 1) {
                    state.openedMenu.shift();
                } else {
                    state.openedMenu = [menu];
                }
            }
            console.log(state.openedMenu.length);
        },
    },
});

export const { toggleNavbar, toggleMenu } = slice.actions;
export default slice.reducer;