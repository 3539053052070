import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SetorkuApi from "datas/setorku/SetorkuApi";
import { SetorkuReconParams, SetorkuReconResult } from "domains/entity/main/setorku/recon";
import { SetorkuInteractor } from "domains/interactor/main/setorku/SetorkuInteractor";

interface iState {
  data: SetorkuReconResult;
  loading: boolean;
  error: boolean;
}

export const reconciliationsListAction = createAsyncThunk(
  "setorku/reconciliations",
  async (data: SetorkuReconParams) => {
    const {
      fromDate,
      toDate,
      caId,
      pageSize,
      pageNumber,
      kodePembayaran,
      billerId,
    } = data;
    try {
      const interactor = new SetorkuInteractor(new SetorkuApi());
      const data = await interactor.getRecon({
        fromDate,
        toDate,
        caId,
        pageSize,
        pageNumber,
        kodePembayaran,
        billerId,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 1,
    totalRows: 6,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "setorku-rekon-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reconciliationsListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        reconciliationsListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(reconciliationsListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

const setorkuRekonListReducer = slice.reducer;
export default setorkuRekonListReducer;
