import React from "react";
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
} from "@mui/material";
import { useAppSelector } from "domain/hooks/redux-hook";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

type ApproveFormProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    handleSubmit?: () => void;
};

const ApproveForm: React.FC<ApproveFormProps> = ({
    isOpen = false,
    handleClose = () => {},
    handleSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const { loading } = useAppSelector(
        (state) => state.main.disi.detailPettyCashAction
    );

    const { loading: loadingAccountability } = useAppSelector(
        (state) => state.main.disi.detailPettyCashAccountabilityActions
    );

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Card
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 640,
                    width: "90%",
                    background: "white",
                    borderRadius: "20px",
                    height: "fit-content",
                    maxHeight: 640,
                }}
            >
                <CardContent
                    sx={{
                        textAlign: "center",
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontSize: "2.125rem",
                            fontWeight: 600,
                            mb: "1rem",
                        }}
                    >
                        {t("disi.detail_petty_cash.approve_header")}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{
                            lineHeight: "166%",
                            color: "#6E6E6E",
                            mb: "1rem",
                        }}
                    >
                        {t("disi.detail_petty_cash.approve_subheader")}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing={true} sx={{ px: "6rem" }}>
                    <Button
                        onClick={handleClose}
                        disabled={loading || loadingAccountability}
                        variant="text"
                        sx={{
                            color: "rgba(40, 99, 83, 1)",
                            width: "317.5px",
                        }}
                    >
                        {t("disi.detail_petty_cash.no")}
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={loading || loadingAccountability}
                        variant="contained"
                        sx={{
                            width: "317.5px",
                        }}
                    >
                        {loading || loadingAccountability ? (
                            <CircularProgress size={24} />
                        ) : (
                            t("disi.detail_petty_cash.yes")
                        )}
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
};

export default ApproveForm
