import { ExportPartnerdepositListParams } from "domains/entity/main/disi";
import DisiRepository from "domains/repository/main/disi/DisiRepository";

export default class PartnerDepositUseCase {
    constructor(private repository: DisiRepository) { }

    getPartnerDepositRealWallet() {
        return this.repository.getPartnerDepositRealWallet()
    }

    getPartnerDepositReadyWallet() {
        return this.repository.getPartnerDepositReadyWallet()
    }

    getPartnerDepositDebtWallet() {
        return this.repository.getPartnerDepositDebtWallet()
    }

    getPartnerDepositMerchantWallet() {
        return this.repository.getPartnerDepositMerchantWallet()
    }

    getPartnerDepositList(
        partnerId: string,
        pageSize: number,
        pageNumber: number,
        fromDate: string,
        toDate: string,
        trxId: string,
        tipeTrx: string,
        statusTrx: string,
    ) {
        return this.repository.getPartnerDepositList(
            partnerId,
            pageSize,
            pageNumber,
            fromDate,
            toDate,
            trxId,
            tipeTrx,
            statusTrx
        )
    }

    getPartnerStatus() {
        return this.repository.getPartnerDepositStatus()
    }

    getPartnerType() {
        return this.repository.getPartnerDepositType()
    }

    getDataWithdrawPartner() {
        return this.repository.getPartnerDepositDataWithdrawPartner()
    }

    postPartnerDepositCreateVa(bankVa: string) {
        return this.repository.postPartnerDepositCreateVa(bankVa)
    }

    postPartnerDepositRequestOTP(tipe = "1") {
        return this.repository.postPartnerDepositRequestOTP(tipe)
    }

    postPartnerDepositWithdraw(codeOTP: string, amount: string) {
        return this.repository.postPartnerDepositWithdraw(codeOTP, amount)
    }

    exportPartnerDepositList(params: ExportPartnerdepositListParams) {
        return this.repository.exportPartnerDepositList(params)
    }

}