import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { GridColDef } from "@mui/x-data-grid";
import { showAddModal, showDeleteModal, showEditModal, showFilterModal } from "utils/store/global/modal";
import { Button, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { transactionSuspectListAction } from "utils/store/main/dana/transactionSuspect";
import dayjs from "dayjs";

//modal-filter

const useDanaTransactionSuspectViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.dana);
  const modal = useAppSelector((state) => state.global.modal);

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());

  const columns: GridColDef[] = [
    // { field: "id", headerName: "No", flex: 1, minWidth: 50 },
    {
      field: "idAcquisition",
      headerName: t('dana.transaction_suspect.id_acquisition'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "idCustomer",
      headerName: t('dana.transaction_suspect.id_customer'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "product",
      headerName: t('dana.transaction_suspect.product'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "finishedDate",
      headerName: t('dana.transaction_suspect.finished_date'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "noRef",
      headerName: t('dana.transaction_suspect.no_ref'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "total",
      headerName: t('dana.transaction_suspect.total'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "rc",
      headerName: t('dana.transaction_suspect.rc_desc'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: t('dana.transaction_suspect.status'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 100,
      renderCell: () => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => modalHandler('edit')}>
              <CheckCircleIcon color="success" />
            </IconButton>
            <IconButton onClick={() => modalHandler('delete')}>
              <CancelIcon color="error" />
            </IconButton>
          </Stack>
        )
      }
    },
  ];

  const modalHandler = (type: string) => {
    switch (type) {
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete));
        break;
      default:
        break;
    }
  };

  const submitFilter = () => {
    fetchDanaTransactionSuspect(startDate, endDate);
    modalHandler('filter');
  };

  const fetchDanaTransactionSuspect = async (
    startDate: Date,
    endDate: Date,
  ) => {
    await dispatch(transactionSuspectListAction({
      acquirementid: "",
      customerid: "",
      amount: "",
      finishTime: "",
      fromDate: dayjs(startDate).format("DD-MM-YYYY"),
      toDate: dayjs(endDate).format("DD-MM-YYYY"),
      pageNumber: page,
      pageSize: pageSize,
      productdesc: "",
      tracking_ref: "",
    }));
  };

  React.useEffect(() => {
    fetchDanaTransactionSuspect(
      startDate,
      endDate,
    );
  }, [dispatch]);

  return {
    data: selector,
    modal,
    modalHandler,
    submitFilter,
    page,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    columns,
    t,
  };
};

export default useDanaTransactionSuspectViewModel;
