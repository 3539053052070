import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateRangeComponent from 'presentation/components/DateRangeComponent';
import ModalContainer from 'presentation/components/ModalContainer';
import React from 'react';

interface Props {
    header?: string;
    subHeader?: string;
    open: boolean;
    onClose: () => void;
    applyButton: () => void;
    cancelButton: () => void;
    startDate?: Date;
    endDate?: Date;
    setStartDate: React.Dispatch<React.SetStateAction<Date>>
    setEndDate: React.Dispatch<React.SetStateAction<Date>>
} 

const FilterModal = (props: Props) => {
    return (
        <ModalContainer
            header={props.header ?? "Header"}
            subHeader={props.subHeader ?? "SubHeader"}
            open={props.open}
            onClose={props.onClose}
            applyButton={props.applyButton}
            cancelButton={props.cancelButton}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                spacing={3}
                direction="row"
                className="DateModal"
                sx={{
                  alignItems: "center",
                  mb: "1rem",
                }}
              >
                <DateRangeComponent
                  startDate={props.startDate ?? new Date() }
                  endDate={props.endDate ?? new Date() }
                  setStartDate={props.setStartDate}
                  setEndDate={props.setEndDate}
                  sx={{ my: "1rem" }}
                />
              </Stack>
            </LocalizationProvider>
        </ModalContainer>
    );
}

export default FilterModal;