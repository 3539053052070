import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";
import PartnerDepositCreateVAResult from "domains/entity/main/disi/PartnerDepositCreateVAResult";

type iState = {
    result: PartnerDepositCreateVAResult
    loading: boolean;
    error: any;
}

const initialState: iState = {
    result: {
        vaexpired: "-",
        vanumber: "-"
    },
    loading: false,
    error: ""
}

export const partnerDepositCreateVaAction = createAsyncThunk('partner-deposit-create-va', async (bankVa: string, { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())

        const result = await useCase.postPartnerDepositCreateVa(bankVa)

        return result
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositVASlice = createSlice({
    name: "partner-deposit-va-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(partnerDepositCreateVaAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(partnerDepositCreateVaAction.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
            state.error = ""
        })
        builder.addCase(partnerDepositCreateVaAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const partnerDepositVaReducer = partnerDepositVASlice.reducer

export default partnerDepositVaReducer