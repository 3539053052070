import { createSlice } from "@reduxjs/toolkit";
import SuccessModal from "presentation/components/SuccessModal";

interface AlertState {
    type: "success" | "error";
    open: boolean;
}

const initialState: AlertState = {
    type: "success",
    open: false,
};

const slice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setAlert: (state, action) => {
            const { type, open } = action.payload;
            state.type = type;
            state.open = open;
        }
    },
});

export const { setAlert } = slice.actions;
export default slice.reducer;