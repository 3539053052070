import { SetorkuDetailPaymentCodeParams, SetorkuDetailPaymentCodeResult, SetorkuExportPaymentCodeParams, SetorkuListPaymentCodeParams, SetorkuListPaymentCodeResult } from "domains/entity/main/setorku/listPaymentCode";
import { SetorkuAddPartnerParams, SetorkuDeletePartnerParams, SetorkuEditPartnerParams, SetorkuPartnerParams, SetorkuPartnerResult } from "domains/entity/main/setorku/partner";
import { SetorkuAddProductParams, SetorkuDeleteProductParams, SetorkuEditProductParams, SetorkuProductParams, SetorkuProductResult } from "domains/entity/main/setorku/product";
import { SetorkuExportReconParams, SetorkuReconParams, SetorkuReconResult } from "domains/entity/main/setorku/recon";
import {
  SetorkuExportTransactionParams,
  SetorkuTransactionParams,
  SetorkuTransactionResult,
} from "domains/entity/main/setorku/transaction";
import { SetorkuRepository } from "domains/repository/main/setorku/SetorkuRepository";

export class SetorkuInteractor implements SetorkuRepository {
  constructor(private repository: SetorkuRepository) {}

  public async getTransaction(
    params: SetorkuTransactionParams
  ): Promise<SetorkuTransactionResult> {
    return await this.repository.getTransaction(params);
  }

  public async exportTransaction(params: SetorkuExportTransactionParams): Promise<any> {
    return await this.repository.exportTransaction(params);
  }

  public async getPartner(params: SetorkuPartnerParams): Promise<SetorkuPartnerResult> {
    return await this.repository.getPartner(params);
  }

  public async addPartner(params: SetorkuAddPartnerParams): Promise<string> {
    return await this.repository.addPartner(params);
  }

  public async editPartner(params: SetorkuEditPartnerParams): Promise<string> {
    return await this.repository.editPartner(params);
  }

  public async deletePartner(params: SetorkuDeletePartnerParams): Promise<string> {
    return await this.repository.deletePartner(params);
  }

  public async getProduct(params: SetorkuProductParams): Promise<SetorkuProductResult> {
    return await this.repository.getProduct(params);
  }

  public async addProduct(params: SetorkuAddProductParams): Promise<string> {
    return await this.repository.addProduct(params);
  }

  public async editProduct(params: SetorkuEditProductParams): Promise<string> {
    return await this.repository.editProduct(params);
  }

  public async deleteProduct(params: SetorkuDeleteProductParams): Promise<string> {
    return await this.repository.deleteProduct(params);
  }

  public async getRecon(params: SetorkuReconParams): Promise<SetorkuReconResult> {
    return await this.repository.getRecon(params);
  }

  public async exportRecon(params: SetorkuExportReconParams): Promise<any> {
    return await this.repository.exportRecon(params);
  }

  public async getListPaymentCode(params: SetorkuListPaymentCodeParams): Promise<SetorkuListPaymentCodeResult> {
    return await this.repository.getListPaymentCode(params);
  }

  public async getDetailPaymentCode(params: SetorkuDetailPaymentCodeParams): Promise<SetorkuDetailPaymentCodeResult> {
    return await this.repository.getDetailPaymentCode(params);
  }

  public async exportPaymentCode(params: SetorkuExportPaymentCodeParams): Promise<any> {
    return await this.repository.exportPaymentCode(params);
  }
}
