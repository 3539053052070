import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SetorkuProductUseCase from "domain/interactor/main/setorku/ProductUseCase";
import SetorkuApi from "datas/setorku/SetorkuApi";
import { SetorkuAddProductParams, SetorkuDeleteProductParams, SetorkuEditProductParams, SetorkuProductParams, SetorkuProductResult } from "domains/entity/main/setorku/product";
import { SetorkuInteractor } from "domains/interactor/main/setorku/SetorkuInteractor";

interface iState {
  data: SetorkuProductResult;
  loading: boolean;
  error: boolean;
  detail: {
    productId: string;
    companyId: string;
    companyDesc: string;
    productName: string;
    prefix: string;
    id: number;
  }
}

export const productListAction = createAsyncThunk(
  "setorku/product-list",
  async (data: SetorkuProductParams) => {
    const { fromDate, toDate } = data;
    try {
      const interactor = new SetorkuInteractor(new SetorkuApi());
      const data = await interactor.getProduct({ fromDate, toDate });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const addProductAction = createAsyncThunk(
  "setorku/add-product",
  async (data: SetorkuAddProductParams) => {
    const {
      productId,
      perusahaanId,
      kodePerusahaan,
      namaPerusahaan,
      prefix,
      secretKey,
    } = data;
    try {
      const interactor = new SetorkuInteractor(new SetorkuApi());
      const data = await interactor.addProduct({
        productId,
        perusahaanId,
        kodePerusahaan,
        namaPerusahaan,
        prefix,
        secretKey
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateProductAction = createAsyncThunk(
  "setorku/update-product",
  async (data: SetorkuEditProductParams) => {
    const { productId, perusahaanId, kodePerusahaan, namaPerusahaan, prefix } =
      data;
    try {
      const interactor = new SetorkuInteractor(new SetorkuApi());
      const response = await interactor.editProduct({
        productId,
        perusahaanId,
        kodePerusahaan,
        namaPerusahaan,
        prefix
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteProductAction = createAsyncThunk(
  "setorku/delete-product",
  async (data: SetorkuDeleteProductParams) => {
    const { productId, companyId } = data;
    try {
      const interactor = new SetorkuInteractor(new SetorkuApi());
      const response = await interactor.deleteProduct({ productId, companyId });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 1,
    totalRows: 6,
  },
  loading: false,
  error: false,
  detail: {
    productId: "",
    companyId: "",
    companyDesc: "",
    productName: "",
    prefix: "",
    id: 0
  },
};

const slice = createSlice({
  name: "setorku-produk",
  initialState,
  reducers: {
    detailProduct: (state, action: PayloadAction<any>) => {
      console.log("detail", action);
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        productListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(productListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

export const { detailProduct } = slice.actions;
export default slice.reducer;
