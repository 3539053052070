import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import useMerchantTransaksiViewModel from "./MerchantTransaksiViewModel";
import DataTable from "presentation/components/DataTable";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import TimeRangePickerComponent from "presentation/components/TimeRangePickerComponent";
import RefreshIcon from "@mui/icons-material/Refresh";
import LayoutComponent from "presentation/layout/Layout";
import ModalContainer from "presentation/components/ModalContainer";

export const MerchantTransaksiComponent = () => {
  const {
    merchant,
    // fetchData,
    // partner,
    modal, modalHandler,
    fetchPartner,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    handleClose,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    clearFilter,
    partnerId,
    setPartnerId,
    handleChangePartner,
    onSubmit,
    columns,
    onExpor,
    t,
    openReason,
    handleOpenReason,
    reason,
    setReason,
    submitRefund,
    handleChangeReason,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    handleRefreshButtonClick,
    isExport,
  } = useMerchantTransaksiViewModel();

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "white",
    borderRadius: "20px",
    p: 4,
  };

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          backgroundColor: { xs: "#fff", md: "#f4f4f4" },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("merchantOnBoarding.transaksi.header_trans")}
                </Typography>

                <Stack
                  spacing={2}
                  direction="row"
                  className="headerButton"
                  alignItems="center"
                >
                  {merchant.loading ? (
                    <CircularProgress color="success" />
                  ) : (
                    <IconButton
                      sx={{ fontSize: "2rem" }}
                      onClick={handleRefreshButtonClick}
                    >
                      <RefreshIcon fontSize="inherit" />
                    </IconButton>
                  )}

                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                      }}
                      onClick={() => modalHandler("filter")}
                    >
                      {t("setorku.transaction.filter_button")}
                    </Button>
                  </div>
                  {Boolean(isExport) && (
                    <Button
                      onClick={onExpor}
                      variant="contained"
                      sx={{
                        height: "42px",
                        borderColor: "rgba(34, 136, 102, 0.5)",
                        color: "#FFFFFF",
                        backgroundColor: "rgba(40, 99, 83, 1) !important",
                        shadows: "rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      Export
                    </Button>
                  )}
                </Stack>
              </Stack>
              <DataTable
                {...{
                  data: merchant.data.data,
                  totalRows: merchant.data.totalRows,
                  columns,
                  setPage,
                  setPageSize,
                  page,
                  pageSize,
                }}
              />

              <ModalContainer open={modal.filter} onClose={() => modalHandler('filter')} applyButton={onSubmit} cancelButton={clearFilter}>
              <DateRangeComponent
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartdate}
                    setEndDate={setEndate}
                    sx={{ my: "1rem" }}
                  />

                  <TimeRangePickerComponent
                    timeStart={startTime}
                    setTimeStart={setStartTime}
                    timeEnd={endTime}
                    setTimeEnd={setEndTime}
                    sx={{ my: "1rem" }}
                  />
              </ModalContainer>

              <ModalContainer open={modal.refund} onClose={()=>modalHandler('refund')} applyButton={submitRefund}>
                <Box sx={style} className="ModelFilter">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {t("merchantOnBoarding.transaksi.reason_label")}
                  </Typography>
                  <TextField
                    label={t("merchantOnBoarding.transaksi.reason_label")}
                    id="Reason"
                    value={reason}
                    onChange={handleChangeReason}
                    multiline
                    rows={4}
                    size="small"
                    sx={{ width: "100%", mt: 2 }}
                  />
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        height: "42px",
                        color: "#ffff",
                        background: "rgba(34, 136, 102, 0.5) !important",
                        mt: "1rem",
                      }}
                      onClick={() => submitRefund()}
                    >
                      Refund
                    </Button>
                  </div> */}
                </Box>
              </ModalContainer>
            </Grid>
          </Box>
        </div>
      </Container>
    </LayoutComponent>
  );
};
export default MerchantTransaksiComponent;
