import React from "react";
import { TextField, MenuItem, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import FileUploadComponent from "presentation/components/FileUploadComponent";
import { useAppSelector } from "domain/hooks/redux-hook";
import FormModalContainer from "presentation/components/FormModalContainer";

type RequestFormProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    onSubmit?: (...input: any[]) => void;
};

const RequestForm: React.FC<RequestFormProps> = ({
    isOpen = false,
    handleClose = () => {},
    onSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const {
        control,
        register,
        setValue,
        formState: { errors },
        watch,
        clearErrors,
        handleSubmit,
    } = useFormContext();

    const { pettyCashType, pettyCashChannel, pettyCashCreate } = useAppSelector(
        (state) => state.main.disi
    );

    const pettyType = watch("typePettyCash");

    React.useEffect(() => {
        if (pettyType && pettyType !== "REIMBURSE") {
            setValue("file", null);
        }
    }, [pettyType]);

    return (
        <FormModalContainer
            open={isOpen}
            onClose={handleClose}
            handleSubmit={handleSubmit}
            applyButton={onSubmit}
            applyText={t("disi.petty_cash.send_request") as string}
            cancelButton={handleClose}
            cancelText={t("disi.petty_cash.cancel") as string}
            header={t("disi.petty_cash.send_request") as string}
            subHeader={t("disi.petty_cash.send_request_desc") as string}
            loading={pettyCashCreate.loading}
        >
            <Controller
                name="trxDate"
                control={control}
                render={({ field: { value, name } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={t(
                                "disi.detail_petty_cash.form_accountability_date_time"
                            )}
                            inputFormat="DD-MM-YYYY"
                            value={value === "" ? null : value}
                            onChange={(val) => {
                                const date = dayjs(val)
                                    .toDate()
                                    .toLocaleDateString("default");
                                setValue(name, date);
                                clearErrors(name);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    onChange={(e) => e.target.blur()}
                                    onKeyDown={(e) => e.preventDefault()}
                                    error={Boolean(errors.trxDate)}
                                    helperText={
                                        errors.trxDate &&
                                        errors.trxDate.message &&
                                        typeof errors.trxDate.message ===
                                            "string" &&
                                        `${t(
                                            "disi.detail_petty_cash.form_accountability_date_time"
                                        )} ${t(errors.trxDate.message)}`
                                    }
                                />
                            )}
                        />
                    </LocalizationProvider>
                )}
            />
            <Controller
                control={control}
                name="typePettyCash"
                render={({ field: { onChange, value } }) => (
                    <TextField
                        select
                        value={value}
                        onChange={onChange}
                        label={t("disi.petty_cash.table_petty_type")}
                        error={Boolean(errors.typePettyCash)}
                        helperText={
                            errors.typePettyCash &&
                            errors.typePettyCash.message &&
                            typeof errors.typePettyCash.message === "string" &&
                            `${t("disi.petty_cash.table_petty_type")} ${t(
                                errors.typePettyCash.message
                            )}`
                        }
                    >
                        {pettyCashType.data.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="channelPettyCash"
                render={({ field: { onChange, value } }) => (
                    <TextField
                        select
                        value={value}
                        onChange={onChange}
                        label="Jenis Penarikan"
                        error={Boolean(errors.channelPettyCash)}
                        helperText={
                            errors.channelPettyCash &&
                            errors.channelPettyCash.message &&
                            typeof errors.channelPettyCash.message ===
                                "string" &&
                            `${t("disi.petty_cash.table_channel_type")} ${t(
                                errors.channelPettyCash.message
                            )}`
                        }
                    >
                        {pettyCashChannel.data.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="amount"
                render={({ field: { onChange } }) => (
                    <NumericFormat
                        onChange={(e) => {
                            e.target.value = e.target.value.replace(/[.]/g, "");
                            onChange(e);
                        }}
                        error={Boolean(errors.amount)}
                        helperText={
                            errors.amount &&
                            errors.amount.message &&
                            typeof errors.amount.message === "string" &&
                            `${t("disi.petty_cash.table_amount")} ${t(
                                errors.amount.message
                            )}`
                        }
                        customInput={TextField}
                        fullWidth
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        InputProps={{
                            startAdornment: (
                                <span
                                    style={{
                                        paddingRight: ".3rem",
                                    }}
                                >
                                    Rp.
                                </span>
                            ),
                        }}
                        label={t("disi.petty_cash.table_channel_type")}
                        autoComplete="off"
                    />
                )}
            />
            <TextField
                {...register("description")}
                error={Boolean(errors.description)}
                helperText={errors.description?.message as string}
                fullWidth
                multiline
                rows={2}
                label="Catatan Transaksi"
            />
            {pettyType === "REIMBURSE" && (
                <FileUploadComponent
                    onChange={(file) => {
                        setValue("file", file);
                        clearErrors("file");
                    }}
                    sx={{
                        borderColor: errors.file ? "#f00" : "#cbd5e1",
                        color: errors.file ? "#f00" : "#000",
                    }}
                    error={
                        errors.file &&
                        errors.file.message &&
                        typeof errors.file.message === "string"
                            ? `File ${t(errors.file.message)}`
                            : ""
                    }
                />
            )}
        </FormModalContainer>
    );
};

export default RequestForm;
