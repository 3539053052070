import { createSlice, createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import AddressApi from "datas/address";
import { AddressData, VillageData } from "domains/entity/main/AddressResult";
import AddressUseCase from "domains/interactor/main/address";

type iState = {
    provinces: Array<AddressData>
    districts: Array<AddressData>
    subDistricts: Array<AddressData>
    villages: Array<VillageData>
}

const initialState: iState = {
    provinces: [],
    districts: [],
    subDistricts: [],
    villages: []
}

export const provinceListAction = createAsyncThunk('province-address', async () => {
    try {
        const useCase = new AddressUseCase(new AddressApi())

        return await useCase.getProvinces()
    } catch (error) {
        throw error
    }
})

export const districtListAction = createAsyncThunk('districts-address', async (id: string) => {
    try {
        const useCase = new AddressUseCase(new AddressApi())

        return await useCase.getDistricts(id)
    } catch (error) {
        throw error
    }
})

export const subDistrictListAction = createAsyncThunk('subdistricts-address', async (id: string) => {
    try {
        const useCase = new AddressUseCase(new AddressApi())

        return await useCase.getSubDistricts(id)
    } catch (error) {
        throw error
    }
})

export const villageListAction = createAsyncThunk('village-address', async (id: string) => {
    try {
        const useCase = new AddressUseCase(new AddressApi())

        return await useCase.getVillages(id)
    } catch (error) {
        throw error
    }
})

const addressSlice = createSlice({
    name: "address-state",
    initialState,
    reducers: {
        clearProvinceChildren(state) {
            state.districts = []
            state.subDistricts = []
            state.villages = []
        }
    },
    extraReducers: builder => {
        builder.addCase(provinceListAction.fulfilled, (state, action) => {
            state.provinces = action.payload
            state.districts = []
            state.subDistricts = []
            state.villages = []
        })

        builder.addCase(districtListAction.fulfilled, (state, action) => {
            state.districts = action.payload
            state.subDistricts = []
            state.villages = []
        })

        builder.addCase(subDistrictListAction.fulfilled, (state, action) => {
            state.subDistricts = action.payload
            state.villages = []
        })

        builder.addCase(villageListAction.fulfilled, (state, action) => {
            state.villages = action.payload
        })
    }
})

export const { clearProvinceChildren } = addressSlice.actions

const addressReducer = addressSlice.reducer

export default addressReducer;