import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { DetailMerchantDepositResult } from "domains/entity/main/disi";
import DisiApi from "datas/disi/DisiApi";
import DetailMerchantDepositUseCase from "domains/interactor/main/disi/DetailMerchantDeposit";

type iState = {
    data: DetailMerchantDepositResult;
    loading: boolean;
    error: any;
}

const initialData = {
    data: [],
    totalRows: 0,
    pageLimit: 0
}

const initialState: iState = {
    data: initialData,
    loading: false,
    error: ""
}

type DetailMerchantDepositBodyArgs = {
    pageSize: number;
    pageNumber: number;
    fromDate: string;
    toDate: string;
    merchantNm: string;
    partnerNm: string;
}

export const detailMerchantDepositBodyListAction = createAsyncThunk('disi-detail-merchant-list', async (args: DetailMerchantDepositBodyArgs, { rejectWithValue }) => {
    try {
        const { pageNumber, pageSize, fromDate, merchantNm, partnerNm, toDate } = args

        const useCase = new DetailMerchantDepositUseCase(new DisiApi())
        const result = await useCase.getDetailMerchantDepositBodyList(pageSize, pageNumber, fromDate, toDate, merchantNm, partnerNm)

        return result
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const detailMerchantDepositBodySlice = createSlice({
    name: 'detail-merchant-deposit-body-slice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(detailMerchantDepositBodyListAction.pending, state => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(detailMerchantDepositBodyListAction.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = ""
        })
        builder.addCase(detailMerchantDepositBodyListAction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
            state.data = initialData
        })
    }
})

const detailMerchantDepositBodyReducer = detailMerchantDepositBodySlice.reducer

export default detailMerchantDepositBodyReducer