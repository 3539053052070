import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { PPOBProductEditParams, PPOBProductListParams, PPOBProductListResult } from "domains/entity/main/ppob/product";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

interface iState {
  data: PPOBProductListResult;
  loading: boolean;
  error: boolean;
}

export const productListAction = createAsyncThunk(
  "ppob/partner",
  async (data: PPOBProductListParams) => {
    const { pageSize, pageNumber, filterText } = data;
    try {
      const interactor = new PPOBInteractor(new PPOBApi);
      const response = await interactor.getProductList({
        pageSize,
        pageNumber,
        filterText
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const editProductAction = createAsyncThunk(
  "ppob/partner/edit",
  async (data: PPOBProductEditParams) => {
    const { ppobId, productId, isActive } = data;
    try {
      const interactor = new PPOBInteractor(new PPOBApi);
      const response = await interactor.editProductList({
        ppobId,
        productId,
        isActive
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    list: [],
    total: 0,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "ppob-product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        productListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(productListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

const ppobProductReducer = slice.reducer;
export default ppobProductReducer;
