import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import ConstantsResult from "domains/entity/main/ConstantResult";
import PettyCashUseCase from "domains/interactor/main/disi/PettyCash";

type iState = {
    data: ConstantsResult;
    loading: boolean;
    error: any;
}

const initialState: iState = {
    data: [],
    loading: false,
    error: ""
}

export const pettyCashChannelAction = createAsyncThunk('petty-cash-channel-action', async (_, { rejectWithValue }) => {
    try {

        const useCase = new PettyCashUseCase(new DisiApi())

        return await useCase.getPettyCashChannel()
    } catch (error) {
        const { response } = error;
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const pettyCashChannelSlice = createSlice({
    name: "petty-cash-channel-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(pettyCashChannelAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(pettyCashChannelAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(pettyCashChannelAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})


const pettyCashChannelReducer = pettyCashChannelSlice.reducer

export default pettyCashChannelReducer