import httpCommon from "datas/httpCommon";
import SetorkuDaftarKodeResult from "domain/entity/main/structure/SetorkuDaftarKodeResult";
import SetorkuPartnerResult from "domain/entity/main/structure/SetorkuPartnerResult";
import SetorkuProductResult from "domain/entity/main/structure/SetorkuProductResult";
import SetorkuRekonResult from "domain/entity/main/structure/SetorkuRekonResult";
import SetorkuTransaksiResult from "domain/entity/main/structure/SetorkuTransaksiResult";
import { SetorkuRepository } from "domain/repository/main/SetorkuRepository";

export default class SetorkuApi implements SetorkuRepository {
  async getSetorkuRekonList(
    fromDate: string,
    toDate: string,
    caId: string,
    pageSize: number,
    pageNumber: number,
    kodePembayaran: string,
    billerId: string
  ): Promise<SetorkuRekonResult> {
    const params = new URLSearchParams({
      toDate,
      fromDate,
      caId,
      pageSize: String(pageSize),
      pageNumber: String(pageNumber),
      kodePembayaran,
      billerId,
    });
    return await httpCommon
      .get<SetorkuRekonResult>(`/setorku/recon?${params}`)
      .then((res) => res.data);
  }

  async getSetorkuTransaksiList(
    fromDate: string,
    toDate: string,
    status: string,
    partnerId: string,
    pageSize: number,
    pageNumber: number
  ): Promise<SetorkuTransaksiResult> {
    const params = new URLSearchParams({
      toDate,
      fromDate,
      status,
      partnerId,
      pageSize: String(pageSize),
      pageNumber: String(pageNumber),
    });
    return await httpCommon
      .get<SetorkuTransaksiResult>(`/setorku/transaction?${params}`)
      .then((res) => res.data);
  }

  async getSetorkuDaftarKodeList(
    fromDate: string,
    toDate: string,
    partnerId: string,
    pageSize: number,
    pageNumber: number
  ): Promise<SetorkuDaftarKodeResult> {
    const params = new URLSearchParams({
      toDate,
      fromDate,
      partnerId,
      pageSize: String(pageSize),
      pageNumber: String(pageNumber),
    });
    return await httpCommon
      .get<SetorkuDaftarKodeResult>(`/setor/list?${params}`)
      .then((res) => res.data);
  }

  async getSetorkuPartnerList(
    fromDate: string,
    toDate: string
  ): Promise<SetorkuPartnerResult> {
    const params = new URLSearchParams({
      fromDate,
      toDate,
    });
    return await httpCommon
      .get<SetorkuPartnerResult>(`/setorku/partner?${params}`)
      .then((res) => res.data);
  }

  async addSetorkuPartner(
    partnerId: string,
    partnerName: string,
    isActive: string
  ): Promise<void> {
    const body = {
      partnerId: partnerId,
      partnerName: partnerName,
      isActive: isActive,
    };
    return await httpCommon
      .post(`/setorku/partner`, body)
      .then((res) => res.data);
  }

  async updateSetorkuPartner(
    partnerId: string,
    partnerName: string,
    isActive: string
  ): Promise<void> {
    const body = {
      partnerId: partnerId,
      partnerName: partnerName,
      isActive: isActive,
    };
    return await httpCommon
      .put(`/setorku/partner`, body)
      .then((res) => res.data);
  }

  async deleteSetorkuPartner(partnerId: string): Promise<void> {
    const params = new URLSearchParams({
      partnerId: partnerId,
    });
    return await httpCommon
      .delete(`/setorku/partner?${params}`)
      .then((res) => res.data);
  }

  async getSetorkuProductList(
    fromDate: string,
    toDate: string
  ): Promise<SetorkuProductResult> {
    const params = new URLSearchParams({
      fromDate,
      toDate,
    });
    return await httpCommon
      .get<SetorkuProductResult>(`/setorku/product?${params}`)
      .then((res) => res.data);
  }

  async addSetorkuProduct(
    productId: string,
    perusahaanId: string,
    kodePerusahaan: string,
    namaPerusahaan: string,
    prefix: string,
    secretKey: string
  ): Promise<void> {
    const body = {
      productId: productId,
      perusahaanId: perusahaanId,
      kodePerusahaan: kodePerusahaan,
      namaPerusahaan: namaPerusahaan,
      prefix: prefix,
      secretKey: secretKey,
    };
    return await httpCommon
      .post(`/setorku/product`, body)
      .then((res) => res.data);
  }

  async updateSetorkuProduct(productId: string, perusahaanId: string, kodePerusahaan: string, namaPerusahaan: string, prefix: string): Promise<void> {
    const body = {
      productId: productId,
      perusahaanId: perusahaanId,
      kodePerusahaan: kodePerusahaan,
      namaPerusahaan: namaPerusahaan,
      prefix: prefix,
    };
    return await httpCommon
      .put(`/setorku/product`, body)
      .then((res) => res.data);
  }

  async deleteSetorkuProduct(productId:string, companyId:string): Promise<void> {
    const body = {
      productId: productId,
      companyId: companyId,
    };
    return await httpCommon
      .delete(`/setorku/product`, {
        data: body,
      })
      .then((res) => res.data);
  }

  async exportSetorkuRekonList(
    fromDate: string,
    toDate: string,
    caId: string,
    kodePembayaran: string,
    billerId: string
  ): Promise<void> {
    const a = document.createElement("a");
    const params = new URLSearchParams({
      toDate,
      fromDate,
      caId,
      kodePembayaran,
      billerId,
    });
    a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/setorku/recon/export?${params}`;
    a.click();
  }

  async exportSetorkuTransaksiList(
    fromDate: string,
    toDate: string,
    status: string,
    partnerId: string
  ): Promise<void> {
    const a = document.createElement("a");
    const params = new URLSearchParams({
      toDate,
      fromDate,
      status,
      partnerId,
    });
    a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/setorku/transaction/export?${params}`;
    a.click();
  }

  async exportSetorkuDaftarKodeList(
    fromDate: string,
    toDate: string,
    partnerId: string
  ): Promise<void> {
    const a = document.createElement("a");
    const params = new URLSearchParams({
      toDate,
      fromDate,
      partnerId,
    });
    a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/setor/export?${params}`;
    a.click();
  }
}
