import React from "react";
import {
    Box,
    IconButton,
    TextField,
    MenuItem,
    Card,
    CardContent,
    CardActions,
    Typography,
    Modal,
    Button,
    Divider,
    Stack,
    Checkbox,
    FormControlLabel,
    FormGroup,
    CircularProgress,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import currencyFormatter from "utils/helper/currencyFormatter";
import OTPComponent from "presentation/components/OTPComponent";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { partnerDepositWithdrawAction } from "utils/store/main/disi/PartnerDepositWithdraw";
import {
    partnerDepositRequestOTPAction,
    resetPartnerDepositRequestOTP,
} from "utils/store/main/disi/PartnerDepositRequestOTP";
import { openSnackBar } from "utils/store/snackbar";

type WithdrawComponentProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    onSubmit?: (...input: any[]) => any;
};

const format = (num: string | number) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

type ConfirmationDialogueProps = {
    header?: string;
    subHeader?: string;
    negativeText?: string;
    positiveText?: string;
    loading?: boolean;
    confirmHandler?: (...args: any[]) => void;
    cancelHandler?: () => void;
};

const ConfirmationDialogue: React.FC<ConfirmationDialogueProps> = ({
    header = "",
    subHeader = "",
    negativeText = "",
    positiveText = "",
    cancelHandler = () => {},
    confirmHandler = () => {},
    loading = false,
}) => {
    return (
        <Card
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "white",
                width: "90%",
                maxWidth: 640,
                maxHeight: 640,
            }}
        >
            <CardContent sx={{ textAlign: "center" }}>
                <Typography sx={{ fontWeight: 800, fontSize: "1.25rem" }}>
                    {header}
                </Typography>
                <Typography sx={{ color: "rgba(0, 0, 0, 0.38)" }}>
                    {subHeader}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing={true}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                }}
            >
                <Button
                    onClick={cancelHandler}
                    variant="outlined"
                    disabled={loading}
                >
                    {negativeText}
                </Button>
                <Button
                    onClick={confirmHandler}
                    variant="contained"
                    disabled={loading}
                >
                    {loading && (
                        <CircularProgress size={16} sx={{ mx: ".3rem" }} />
                    )}
                    {positiveText}
                </Button>
            </CardActions>
        </Card>
    );
};

const ConfirmDialogue = React.forwardRef<
    HTMLDivElement,
    ConfirmationDialogueProps
>((props, ref) => {
    return <ConfirmationDialogue {...props} />;
});

type OTPDialogue = {
    header?: string;
    subHeader?: string;
    btnText?: string;
    onSubmit?: (...args: any[]) => void;
    onClose?: () => void;
    loading?: boolean;
};

const OtpDialogue: React.FC<OTPDialogue> = ({
    header = "",
    subHeader = "",
    btnText = "",
    onSubmit = () => {},
    onClose = () => {},
    loading = false,
}) => {
    const [otpData, setOTPData] = React.useState("");

    const resetOTP = () => {
        setOTPData("");
    };

    return (
        <Card
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "white",
                width: "90%",
                maxWidth: 640,
                maxHeight: 640,
                p: {
                    xs: "2rem",
                    sm: "4rem",
                },
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(otpData, resetOTP);
                }}
            >
                <CardContent
                    sx={{
                        display: "grid",
                        textAlign: "center",
                        gap: "1.5rem",
                    }}
                >
                    <IconButton
                        sx={{ position: "absolute", top: 8, right: 8 }}
                        onClick={onClose}
                    >
                        <CloseRoundedIcon />
                    </IconButton>

                    <Typography
                        fontWeight={800}
                        sx={{
                            fontSize: {
                                xs: "1.5rem",
                                sm: "2rem",
                            },
                        }}
                    >
                        {header}
                    </Typography>
                    <Typography
                        sx={{
                            color: "rgba(0, 0,0, 0.38)",
                            fontSize: {
                                xs: ".75rem",
                                sm: "1rem",
                            },
                        }}
                    >
                        {subHeader}
                    </Typography>
                    <OTPComponent
                        length={6}
                        otpValue={otpData}
                        onChange={(result) => setOTPData(result)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mx: { xs: "auto", sm: "4rem" } }}
                        disabled={otpData.length !== 6 || loading}
                    >
                        {loading ? <CircularProgress size={24} /> : btnText}
                    </Button>
                </CardContent>
            </form>
        </Card>
    );
};

const OtpDialogueComponent = React.forwardRef<HTMLDivElement, OTPDialogue>(
    (props, _) => <OtpDialogue {...props} />
);

const removeDot = (value: string) => {
    return value.replaceAll(".", "");
};

const WithdrawComponent: React.FC<WithdrawComponentProps> = ({
    isOpen = false,
    handleClose = () => {},
}) => {
    const { totalReadyWallet } = useAppSelector(
        (state) => state.main.disi.partnerDepositWallet
    );

    const formattedLimitWithdraw = React.useMemo(() => {
        return format(totalReadyWallet);
    }, [totalReadyWallet]);

    const { t } = useTranslation();

    const {
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useFormContext();

    const dispatch = useAppDispatch();

    const {
        result: requestOTPResult,
        loading: loadingOTP,
        error: errorOTP,
    } = useAppSelector((state) => state.main.disi.partnerDepositRequestOTP);

    const {
        result: withdrawResult,
        loading: withdrawLoading,
        error: withdrawError,
    } = useAppSelector((state) => state.main.disi.partnerDepositWithdraw);

    const { data: listBank } = useAppSelector(
        (state) => state.main.disi.partnerDepositPartner
    );

    const [selectedBankId, setSelectedBankId] = React.useState("");

    const [checkBox, setCheckBox] = React.useState(false);

    const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (checked) {
            setValue("amount", formattedLimitWithdraw);
        } else {
            setValue("amount", "");
        }
        setCheckBox(checked);
    };

    const [actionState, setActionState] = React.useState<
        "" | "cancel" | "submit" | "OTP"
    >("");

    const onClose = (_: any, reason: string) => {
        if (reason && reason === "backdropClick") return;
        setSelectedBankId("");
        setCheckBox(false);
        setActionState("");
        handleClose();
    };

    const handleConfirmWithdraw = async (_: any) => {
        try {
            await dispatch(partnerDepositRequestOTPAction("2"));

            setActionState("OTP");
        } catch (e) {
            console.log(e);
        }
    };

    const handleConfirm = (e: any) => {
        return actionState === "cancel"
            ? onClose(null, "")
            : handleConfirmWithdraw(e);
    };

    const otpSubHeader = t("disi.partner_deposit.otp_subheader");

    const otpType =
        requestOTPResult.tipe === "WA"
            ? t("disi.partner_deposit.otp_wa")
            : t("disi.partner_deposit.otp_email");

    const otpSendedTo =
        requestOTPResult.tipe === "WA"
            ? requestOTPResult.data[0][4]
            : requestOTPResult.data[0][2];

    const handleSubmitOtp = async (codeOTP: string, clearOTP: any) => {
        await dispatch(
            partnerDepositWithdrawAction({
                amount: removeDot(getValues("amount")),
                codeOTP,
            })
        );

        clearOTP();
    };

    React.useEffect(() => {
        if (!loadingOTP && errorOTP) {
            dispatch(
                openSnackBar({
                    message: errorOTP,
                    severity: "error",
                })
            );

            setTimeout(() => {
                dispatch(resetPartnerDepositRequestOTP());
            }, 3000);
        }
    }, [loadingOTP, errorOTP]);

    React.useEffect(() => {
        if (!withdrawLoading && withdrawResult) {
            onClose(null, "");
        }
    }, [withdrawResult, withdrawLoading]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            {actionState === "" ? (
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        borderRadius: "20px",
                        width: "90%",
                        maxWidth: 640,
                        maxHeight: 640,
                    }}
                >
                    <CardContent
                        sx={{
                            "@media screen and (max-height: 40rem)": {
                                maxHeight: 440,
                            },
                            maxHeight: 600,
                            overflow: "auto",
                            p: 4,
                            "&::-webkit-scrollbar": {
                                width: 0,
                                background: "transparent",
                            },
                            "&::-moz-scrollbar": {
                                width: 0,
                                background: "transparent",
                            },
                            "&::-ms-scrollbar": {
                                width: 0,
                                background: "transparent",
                            },
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                {t("disi.partner_deposit.withdraw_header")}
                            </Typography>
                            <IconButton
                                onClick={() =>
                                    onClose(null, "totallynotbackdropclick")
                                }
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </Stack>
                        <Divider
                            variant="middle"
                            sx={{
                                mt: "0 !important",
                                mr: "0 !important",
                                ml: "0 !important",
                                mb: "1rem !important",
                            }}
                        />
                        <Box
                            display="grid"
                            sx={{
                                gridTemplateColumns: ".7fr 1fr",
                                gap: ".5rem",
                            }}
                        >
                            <Typography sx={{ p: "1rem" }}>
                                {t("disi.partner_deposit.withdraw_amount")}
                            </Typography>
                            <Controller
                                control={control}
                                name="amount"
                                render={({ field: { value } }) => (
                                    <NumericFormat
                                        onChange={(e) => {
                                            const { value } = e.target;

                                            setValue("amount", value);

                                            if (
                                                Number(removeDot(value)) >
                                                totalReadyWallet
                                            ) {
                                                setError("amount", {});
                                            } else {
                                                clearErrors("amount");
                                            }
                                        }}
                                        value={value}
                                        customInput={TextField}
                                        fullWidth
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        onKeyDown={(e) => {
                                            const { code } = e;

                                            if (
                                                code === "Comma" ||
                                                code === "Period"
                                            ) {
                                                e.preventDefault();
                                                return;
                                            }

                                            if (
                                                code === "Digit0" &&
                                                value === ""
                                            ) {
                                                e.preventDefault();
                                                return;
                                            }

                                            return e;
                                        }}
                                        allowLeadingZeros={false}
                                        InputProps={{
                                            startAdornment: (
                                                <span
                                                    style={{
                                                        paddingRight: ".3rem",
                                                    }}
                                                >
                                                    Rp.
                                                </span>
                                            ),
                                        }}
                                        error={Boolean(errors.amount)}
                                        helperText={`${t(
                                            "disi.partner_deposit.withdraw_limit"
                                        )}${currencyFormatter(
                                            totalReadyWallet
                                        )}`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                            <FormGroup
                                sx={{
                                    gridColumnEnd: -1,
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={checkBox}
                                            onChange={handleCheckBox}
                                        />
                                    }
                                    label={
                                        <Typography sx={{ fontSize: ".75rem" }}>
                                            {`${t(
                                                "disi.partner_deposit.withdraw_current_balance"
                                            )}${currencyFormatter(
                                                totalReadyWallet
                                            )}`}
                                        </Typography>
                                    }
                                />
                            </FormGroup>
                            <Typography sx={{ p: "1rem" }}>
                                {t("disi.partner_deposit.recharge_bank")}
                            </Typography>
                            <TextField
                                fullWidth
                                select
                                value={selectedBankId}
                                onChange={(e) =>
                                    setSelectedBankId(e.target.value)
                                }
                            >
                                {listBank.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </CardContent>
                    <CardActions
                        disableSpacing={true}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        <Button
                            onClick={() => setActionState("cancel")}
                            variant="text"
                        >
                            {t("disi.partner_deposit.withdraw_cancel")}
                        </Button>
                        <Button
                            disabled={
                                Boolean(errors.amount) ||
                                getValues("amount") === "" ||
                                selectedBankId === ""
                            }
                            onClick={() => setActionState("submit")}
                            variant="contained"
                        >
                            {t("disi.partner_deposit.withdraw_submit")}
                        </Button>
                    </CardActions>
                </Card>
            ) : actionState === "OTP" ? (
                <OtpDialogueComponent
                    header={t("disi.partner_deposit.otp_header") ?? ""}
                    subHeader={`${otpSubHeader} ${otpType} ${t(
                        "disi.partner_deposit.otp_to"
                    )} ${otpSendedTo}`}
                    btnText={t("disi.partner_deposit.otp_button") ?? ""}
                    onClose={() => onClose(null, "")}
                    onSubmit={handleSubmitOtp}
                    loading={withdrawLoading}
                />
            ) : (
                <ConfirmDialogue
                    header={
                        t(
                            `disi.partner_deposit.dialogue_${actionState}_header`
                        ) ?? ""
                    }
                    subHeader={
                        t(
                            `disi.partner_deposit.dialogue_${actionState}_subheader`
                        ) ?? ""
                    }
                    negativeText={
                        t(
                            `disi.partner_deposit.dialogue_${actionState}_negative`
                        ) ?? ""
                    }
                    positiveText={
                        t(
                            `disi.partner_deposit.dialogue_${actionState}_positive`
                        ) ?? ""
                    }
                    cancelHandler={() => setActionState("")}
                    confirmHandler={handleConfirm}
                    loading={loadingOTP}
                />
            )}
        </Modal>
    );
};

export default WithdrawComponent;
