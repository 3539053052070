import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import useDanaTransactionSuspectViewModel from "./DanaTransactionSuspectViewModel";
import FilterModal from "./components/FilterModal";
import RejectReasonModal from "./components/RejectReasonModal";
import ResiModal from "./components/ResiModal";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

const DanaTransactionSuspectComponent = () => {
  const {
    data,
    modal,
    modalHandler,
    submitFilter,
    page,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    columns,
    t,
  } = useDanaTransactionSuspectViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("dana.transaction_suspect.title")}
                </Typography>

                <Stack spacing={2} direction="row" className="headerButton">
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                      }}
                      onClick={() => modalHandler('filter')}
                    >
                      {t("dana.transaction_suspect.filter_button")}
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("dana.transaction_suspect.export_button")}
                  </Button>
                </Stack>
              </Stack>

              <DataTable
                {...{
                  data: [
                    {
                      idAcquisition: 1,
                      idCustomer: 101,
                      product: "Dummy A",
                      finishedDate: "2022-12-31",
                      noRef: "REF001",
                      total: 1000000,
                      rc: "RC001",
                      status: "Completed"
                    },
                    {
                      idAcquisition: 2,
                      idCustomer: 102,
                      product: "Dummy B",
                      finishedDate: "2022-12-31",
                      noRef: "REF002",
                      total: 500000,
                      rc: "RC002",
                      status: "Completed"
                    },
                  ],
                  totalRows: 1,
                  columns,
                  setPage,
                  setPageSize,
                  page,
                  pageSize,
                }}
              />
            </Grid>
          </Box>
        </div>

        <FilterModal open={modal.filter} onClose={() => modalHandler('filter')} applyButton={submitFilter} cancelButton={() => modalHandler('filter')} startDate={startDate} endDate={endDate} setStartDate={setStartdate} setEndDate={setEndate} />
        <RejectReasonModal open={modal.delete} onClose={() => modalHandler('delete')} applyButton={() => modalHandler('delete')} cancelButton={() => modalHandler('delete')} startDate={startDate} endDate={endDate} setStartDate={setStartdate} setEndDate={setEndate} header={""} subHeader={""} />
        <ResiModal open={modal.edit} onClose={() => modalHandler('edit')} applyButton={() => modalHandler('edit')} cancelButton={() => modalHandler('edit')} startDate={startDate} endDate={endDate} setStartDate={setStartdate} setEndDate={setEndate} header={""} subHeader={""} />
      </Container>
    </LayoutComponent>
  );
};

export default DanaTransactionSuspectComponent;
