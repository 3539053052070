import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ModalContainer from 'presentation/components/ModalContainer';
import React from 'react';

interface Props {
  header: string;
  subHeader: string;
  open: boolean;
  onClose: () => void;
  applyButton: () => void;
  cancelButton: () => void;
  startDate: Date;
  endDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
}

const RejectReasonModal = ({ header, subHeader, open, onClose, applyButton, cancelButton, startDate, endDate, setStartDate, setEndDate }: Props) => {
  return (
    <ModalContainer
      header={header}
      subHeader={subHeader}
      open={open}
      onClose={onClose}
      applyButton={applyButton}
      cancelButton={cancelButton}
    >
      <FormControl fullWidth sx={{ mb: "1rem" }}>
        <InputLabel id="demo-simple-select-label">Resi</InputLabel>
        <TextField
          id="outlined-basic"
          label="Resi"
          variant="outlined"
          sx={{ mb: "1rem" }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: "1rem" }}>
        <InputLabel id="demo-simple-select-label">Resi</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
        </Select>
      </FormControl>
    </ModalContainer>
  );
}

export default RejectReasonModal;