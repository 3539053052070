import { Button, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/formatDate";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { partnerListAction } from "utils/store/main/ppob/partner";
import useRole from "domain/hooks/useRole";
import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import { MerchantOnBoardingInteractor } from "domains/interactor/main/merchantOnBoarding/merchantOnBoardingInteractor";
import { MerchantOnBoardingAPI } from "datas/merchantOnBoarding/merchantOnBoardingAPI";
import { exportCSV } from "domain/hooks/exportCSV";
import {
    getTransactionListAction,
    postTransactionRefundAction,
} from "utils/store/main/merchantOnBoarding/transaction";
import { showFilterModal, showRefundModal } from "utils/store/global/modal";
import currencyFormatter from "utils/helper/currencyFormatter";

const useMerchantTransaksiViewModel = () => {
    const { t }: any = useTranslation();
    const dispatch = useAppDispatch();
    const merchant = useAppSelector((state) => state.main.merchant);
    const partners = useAppSelector((state) => state.main.ppob);
    const modal = useAppSelector((state) => state.global.modal);
    const { isExport, isEdit } = useRole("transaksi-merchantonboarding");
    // const dateNowFormated = dayjs(new Date()).format("DD-MM-YYYY");
    // const transaksiUseCase = new TransaksiUseCase()

    const [reason, setReason] = React.useState<string>("");
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(6);
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartdate] = React.useState<Date>(new Date());
    const [endDate, setEndate] = React.useState<Date>(new Date());
    const [startTime, setStartTime] = React.useState<string>("");
    const [endTime, setEndTime] = React.useState<string>("");
    const [partnerId, setPartnerId] = React.useState("ALL");
    const [refund, setRefund] = React.useState({ RRN: "", refundAmount: "" });
    const [openReason, setOpenReason] = React.useState(false);

    const columns: GridColDef[] = [
        // {
        //   field: "id",
        //   headerName: t("merchantOnBoarding.transaksi.table_no"),
        //   flex: 1,
        //   minWidth: 100,
        // },
        {
            field: "paymentTime",
            headerName: t("merchantOnBoarding.transaksi.table_date_time"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "mpan",
            headerName: t("merchantOnBoarding.transaksi.table_pan_merchant"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "acquirerName",
            headerName: t(
                "merchantOnBoarding.transaksi.table_recipient_institution"
            ),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "issuerName",
            headerName: t(
                "merchantOnBoarding.transaksi.table_issueing_institution"
            ),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "customerName",
            headerName: t("merchantOnBoarding.transaksi.table_issuer_cust"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "customerNumber",
            headerName: t("merchantOnBoarding.transaksi.table_pan_cust"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "bankReferenceNo",
            headerName: t("merchantOnBoarding.transaksi.table_tracking_ref"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "amountValue",
            headerName: t("merchantOnBoarding.transaksi.table_payment_amount"),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography variant="body1">
                    {currencyFormatter(params.row.amountValue)}
                </Typography>
            ),
        },
        {
            field: "feeAmountValue",
            headerName: t("merchantOnBoarding.transaksi.table_service_fee"),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography variant="body1">
                    {currencyFormatter(params.row.feeAmountValue)}
                </Typography>
            ),
        },
        {
            field: "mdrAmount",
            headerName: t("merchantOnBoarding.transaksi.table_mdr"),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography variant="body1">
                    {currencyFormatter(params.row.mdrAmount)}
                </Typography>
            ),
        },
        {
            field: "totalAmount",
            headerName: t("merchantOnBoarding.transaksi.table_total_amount"),
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <Typography variant="body1">
                    {currencyFormatter(params.row.totalAmount)}
                </Typography>
            ),
        },
        {
            field: "transactionType",
            headerName: t("merchantOnBoarding.transaksi.table_trans_type"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "transactionStatusDesc",
            headerName: t("merchantOnBoarding.transaksi.table_status"),
            flex: 1,
            minWidth: 200,
        },
        {
            field: "Aksi",
            headerName: t("merchantOnBoarding.transaksi.table_action"),
            flex: 1,
            minWidth: 200,
            renderCell: (row: any) => {
                if (row.row.transactionStatusDesc !== "Success") {
                    return (
                        <Typography>
                            {t("merchantOnBoarding.transaksi.table_finish")}
                        </Typography>
                    );
                }

                if (!isEdit) {
                    return (
                        <Typography
                            sx={{ fontSize: ".75rem", fontWeight: 400 }}
                        >
                            {t("not_having_role")}
                        </Typography>
                    );
                }

                return (
                    <Button
                        onClick={() =>
                            refundTransaksi(
                                row.row.originalReferenceNo,
                                row.row.amountValue
                            )
                        }
                        sx={{
                            borderColor: "#2E7D32",
                            color: "#2E7D32",
                        }}
                        variant="outlined"
                    >
                        {t("merchantOnBoarding.transaksi.table_refund_button")}
                    </Button>
                );
            },
        },
    ];

    const modalHandler = (type: string) => {
        switch (type) {
            case "filter":
                dispatch(showFilterModal(!modal.filter));
                break;
            case "refund":
                dispatch(showRefundModal(!modal.refund));
                break;
            default:
                break;
        }
    };

    const fetchDataTransaksi = async (
        fromDate: string,
        toDate: string,
        pageNumber: number,
        pageSize: number
    ) => {
        await dispatch(
            getTransactionListAction({ fromDate, toDate, pageNumber, pageSize })
        );
    };

    const fetchPartner = async () => {
        await dispatch(
            partnerListAction({
                pageNumber: 0,
                pageSize: 100,
                filterText: "",
            })
        );
    };

    const handleChangeReason = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setReason(event.target.value);
    };

    const handleRefreshButtonClick = () => {
        fetchDataTransaksi(startDateTime, endDateTime, 0, 6);
    };

    const handleClose = () => {
        setOpen(!open);
        setStartTime("");
        setEndTime("");
    };

    const startDateTime = `${formatDate(startDate)} ${
        startTime === "" ? "00:00" : startTime
    }`;
    const endDateTime = `${formatDate(endDate)} ${
        endTime === "" ? "23:59" : endTime
    }`;

    const handleChangePartner = (event: SelectChangeEvent) => {
        setPartnerId(event.target.value as string);
    };

    const handleOpenReason = () => setOpenReason(!openReason);

    const refundTransaksi = (RRN: string, refundAmount: string) => {
        modalHandler("refund");
        setRefund({ RRN, refundAmount });
    };

    const submitRefund = async () => {
        await dispatch(
            postTransactionRefundAction({
                amount: refund.refundAmount,
                originalReferenceNo: refund.RRN,
                reason,
            })
        );
        modalHandler("refund");
        setReason("");
    };

    const clearFilter = () => {
        setStartdate(new Date());
        setEndate(new Date());

        const start = dayjs(new Date()).format("DD-MM-YYYY 00:00");
        const end = dayjs(new Date()).format("DD-MM-YYYY 23:59");
        fetchDataTransaksi(start, end, page, pageSize);
        setOpen(false);
    };

    const onSubmit = (): void => {
        fetchDataTransaksi(startDateTime, endDateTime, page, pageSize);
        // handleClose();
        modalHandler("filter");
    };

    React.useEffect(() => {
        fetchDataTransaksi(startDateTime, endDateTime, page, pageSize);
    }, [pageSize, page]);

    React.useEffect(() => {
        fetchPartner();
    }, []);

    const exportMerchantTransaksi = async () => {
        const interactor = new MerchantOnBoardingInteractor(
            new MerchantOnBoardingAPI()
        );
        const response =
            await interactor.exportMerchantOnBoardingTransactionList({
                fromDate: dayjs(startDate).format("DD-MM-YYYY 00:00"),
                toDate: dayjs(endDate).format("DD-MM-YYYY 23:59"),
            });
        exportCSV(response, "merchant-transaksi");
    };

    const onExpor = async () => {
        const startDay =
            startDate.getDate() < 10
                ? `0${startDate.getDate()}`
                : startDate.getDate();
        const startMonth =
            startDate.getMonth() + 1 < 10
                ? `0${startDate.getMonth() + 1}`
                : startDate.getMonth() + 1;
        const year = startDate.getFullYear();

        const endDay =
            endDate.getDate() < 10
                ? `0${endDate.getDate()}`
                : endDate.getDate();
        const endMonth =
            endDate.getMonth() + 1 < 10
                ? `0${endDate.getMonth() + 1}`
                : endDate.getMonth() + 1;
        const endYear = endDate.getFullYear();

        const interactor = new MerchantOnBoardingInteractor(
            new MerchantOnBoardingAPI()
        );
        const response =
            await interactor.exportMerchantOnBoardingTransactionList({
                fromDate: dayjs(startDate).format("DD-MM-YYYY"),
                toDate: dayjs(endDate).format("DD-MM-YYYY"),
            });
    };

    // React.useEffect(() => {
    //   let timer: NodeJS.Timer;

    //   if (loading) {
    //     timer = setTimeout(() => {
    //       setIsLoading(false);
    //       // loading = false
    //     }, 2000);

    //     const start = formatDate(new Date());
    //     const end = formatDate(new Date());
    //     // fetchDataTransaksi(pageSize, page, start, end);
    //     fetchDataTransaksi(start, end, page, pageSize);
    //   }

    //   return () => {
    //     if (timer) {
    //       clearTimeout(timer);
    //     }
    //   };
    // }, [loading]);

    return {
        fetchDataTransaksi,
        merchant: merchant.transaction,
        partners,
        fetchPartner,
        modalHandler,
        modal,
        page,
        setPage,
        pageSize,
        setPageSize,
        open,
        setOpen,
        handleClose,
        startDate,
        endDate,
        setStartdate,
        setEndate,
        clearFilter,
        partnerId,
        setPartnerId,
        handleChangePartner,
        onSubmit,
        columns,
        onExpor,
        t,
        openReason,
        handleOpenReason,
        reason,
        setReason,
        submitRefund,
        handleChangeReason,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        handleRefreshButtonClick,
        isExport,
    };
};

export default useMerchantTransaksiViewModel;
