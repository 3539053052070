import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SetorkuApi from "data/main/setorkuApi";
import SetorkuPartnerResult from "domain/entity/main/structure/SetorkuPartnerResult";
import SetorkuPartnerUseCase from "domain/interactor/main/setorku/PartnerUseCase";

interface iData {
  fromDate: string;
  toDate: string;
}

interface iState {
  data: SetorkuPartnerResult;
  loading: boolean;
  error: boolean;
  detail: {
    partnerId: string;
    partnerName: string;
    isActive: string;
  };
}

export const setorkuPartnerAction = createAsyncThunk(
  "setorku/partner-list",
  async (data: iData) => {
    const { fromDate, toDate } = data;
    try {
      const interactor = new SetorkuPartnerUseCase(new SetorkuApi());
      const response = await interactor.getPartner(fromDate, toDate);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addSetorkuPartnerAction = createAsyncThunk(
  "setorku/add-partner",
  async (data: {
    partnerId: string;
    partnerName: string;
    isActive: string;
  }) => {
    try {
      const interactor = new SetorkuPartnerUseCase(new SetorkuApi());
      const response = await interactor.addPartner(data.partnerId, data.partnerName, data.isActive);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSetorkuPartnerAction = createAsyncThunk(
  "setorku/updateSetorkuPartner",
  async (data: {
    partnerId: string;
    partnerName: string;
    isActive: string;
  }) => {
    try {
      const interactor = new SetorkuPartnerUseCase(new SetorkuApi());
      const response = await interactor.editPartner(data.partnerId, data.partnerName, data.isActive);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSetorkuPartnerAction = createAsyncThunk(
  "setorku/deleteSetorkuPartner",
  async (partnerId: string) => {
    try {
      const interactor = new SetorkuPartnerUseCase(new SetorkuApi());
      const response = await interactor.deletePartner(partnerId);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 1,
    totalRows: 6,
  },
  detail: {
    partnerId: "",
    partnerName: "",
    isActive: "",
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "setorku-partner",
  initialState,
  reducers: {
    detailSetorkuPartner: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setorkuPartnerAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        setorkuPartnerAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(setorkuPartnerAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

export const { detailSetorkuPartner } = slice.actions;
export default slice.reducer;
