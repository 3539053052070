import React from "react";
import { TextField, MenuItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import {
    districtListAction,
    subDistrictListAction,
    villageListAction,
} from "utils/store/global/address";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormModalContainer from "presentation/components/FormModalContainer";

type AddFormProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    onSubmit?: (...input: any[]) => void;
};

const AddForm: React.FC<AddFormProps> = ({
    handleClose = () => {},
    isOpen = false,
    onSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { districts, provinces, subDistricts, villages } = useAppSelector(
        (state) => state.global.address
    );

    const {
        merchantDepositBank,
        merchantDepositBankVa,
        merchantDepositActions,
    } = useAppSelector((state) => state.main.disi);

    const { control, handleSubmit, setValue, register, watch } =
        useFormContext();

    const _province = watch("province");

    const _district = watch("districts");

    const _subDistrict = watch("city");

    const _urbanVillage = watch("urbanVillage");

    const _bankCode = watch("bankCode");

    React.useEffect(() => {
        if (_province) {
            setValue("districts", "");
            setValue("city", "");
            setValue("urbanVillage", "");
            dispatch(districtListAction(_province));
        }
    }, [_province, dispatch]);

    React.useEffect(() => {
        if (_district) {
            setValue("city", "");
            setValue("urbanVillage", "");
            dispatch(subDistrictListAction(_district));
        }
    }, [_district, dispatch]);

    React.useEffect(() => {
        if (_subDistrict) {
            setValue("urbanVillage", "");
            dispatch(villageListAction(_subDistrict));
        }
    }, [_subDistrict, dispatch]);

    React.useEffect(() => {
        if (_urbanVillage) {
            const choosenVillage = villages.find(
                (item) => item.id === _urbanVillage
            );
            setValue("postalCode", choosenVillage?.postalCode || "");
        }
    }, [_urbanVillage, dispatch, setValue]);

    React.useEffect(() => {
        if (_bankCode) {
            const choosenBank = merchantDepositBank.data.find(
                (item) => item.id === _bankCode
            );

            setValue("bankNm", choosenBank?.name || "");
        }
    }, [_bankCode, setValue]);

    return (
        <FormModalContainer
            open={isOpen}
            onClose={handleClose}
            applyButton={onSubmit}
            applyText={t("disi.merchant_deposit.form_add_submit") as string}
            cancelButton={handleClose}
            cancelText={t("disi.merchant_deposit.form_add_cancel") as string}
            handleSubmit={handleSubmit}
            header={t("disi.merchant_deposit.form_add_header") as string}
            subHeader={t("disi.merchant_deposit.form_add_subheader") as string}
            loading={merchantDepositActions.loading}
        >
            <TextField
                {...register("partnerNm")}
                fullWidth
                label={t("disi.merchant_deposit.form_add_partner_name")}
                disabled
            />
            <TextField
                {...register("merchantId")}
                fullWidth
                label={t("disi.merchant_deposit.form_add_merchant_id")}
            />
            <TextField
                {...register("merchantNm")}
                fullWidth
                label={t("disi.merchant_deposit.form_add_merchant_name")}
            />
            <TextField
                {...register("email")}
                fullWidth
                label={t("disi.merchant_deposit.form_add_email")}
            />
            <TextField
                {...register("phone")}
                fullWidth
                label={t("disi.merchant_deposit.form_add_phone")}
            />
            <TextField
                {...register("address")}
                fullWidth
                label={t("disi.merchant_deposit.form_add_address")}
            />
            <Controller
                control={control}
                name="province"
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        fullWidth
                        label={t("disi.merchant_deposit.form_add_province")}
                    >
                        {provinces.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="districts"
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        disabled={_province === ""}
                        fullWidth
                        label={t("disi.merchant_deposit.form_add_district")}
                    >
                        {districts.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="city"
                render={({ field }) => (
                    <TextField
                        {...field}
                        disabled={_district === ""}
                        select
                        fullWidth
                        label={t("disi.merchant_deposit.form_add_subdistrict")}
                    >
                        {subDistricts.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="urbanVillage"
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        disabled={_subDistrict === ""}
                        fullWidth
                        label={t("disi.merchant_deposit.form_add_village")}
                    >
                        {villages.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <TextField
                {...register("postalCode")}
                label={t("disi.merchant_deposit.form_add_postal")}
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
            />
            <Controller
                control={control}
                name="bankVa"
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        fullWidth
                        label={t("disi.merchant_deposit.form_add_bank_va")}
                    >
                        {merchantDepositBankVa.data.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <Controller
                control={control}
                name="bankCode"
                render={({ field }) => (
                    <TextField
                        {...field}
                        select
                        fullWidth
                        label={t("disi.merchant_deposit.form_add_bank_name")}
                    >
                        {merchantDepositBank.data.map((item) => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            />
            <TextField
                {...register("bankAccount")}
                label={t("disi.merchant_deposit.form_add_account_number")}
                fullWidth
            />
        </FormModalContainer>
    );
};

export default AddForm;
