import React from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import FileUploadComponent from "presentation/components/FileUploadComponent";
import { useAppSelector } from "domain/hooks/redux-hook";
import { useTranslation } from "react-i18next";
import FormModalContainer from "presentation/components/FormModalContainer";

type AccountabilityFormProps = {
    isOpen?: boolean;
    onSubmit?: (...args: any[]) => void;
    onClose?: () => void;
};

const AccountabilityForm: React.FC<AccountabilityFormProps> = ({
    isOpen = false,
    onSubmit = () => {},
    onClose = () => {},
}) => {
    const { t } = useTranslation();

    const {
        setValue,
        control,
        register,
        handleSubmit,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const { loading } = useAppSelector(
        (state) => state.main.disi.detailPettyCashAccountabilityActions
    );

    return (
        <FormModalContainer
            open={isOpen}
            onClose={onClose}
            header={
                t("disi.detail_petty_cash.form_accountability_header") as string
            }
            subHeader={
                t(
                    "disi.detail_petty_cash.form_accountability_subheader"
                ) as string
            }
            applyText={
                t("disi.detail_petty_cash.form_accountability_header") as string
            }
            applyButton={onSubmit}
            cancelText={t("disi.detail_petty_cash.cancel") as string}
            cancelButton={onClose}
            handleSubmit={handleSubmit}
            loading={loading}
        >
            <TextField
                inputProps={{
                    readOnly: true,
                }}
                label={t("disi.detail_petty_cash.form_accountability_petty_id")}
            />
            <TextField
                {...register("channelPettyCash")}
                inputProps={{
                    readOnly: true,
                }}
                label="Channel Petty Cash"
            />
            <Controller
                name="trxDate"
                control={control}
                render={({ field: { value, name } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={t(
                                "disi.detail_petty_cash.form_accountability_date_time"
                            )}
                            inputFormat="DD-MM-YYYY"
                            value={value === "" ? null : value}
                            onChange={(val) => {
                                const date = dayjs(val)
                                    .toDate()
                                    .toLocaleDateString("default");
                                setValue(name, date);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    error={Boolean(errors.trxDate)}
                                    helperText={
                                        errors.trxDate &&
                                        errors.trxDate.message &&
                                        typeof errors.trxDate.message ===
                                            "string" &&
                                        `${t(
                                            "disi.detail_petty_cash.form_accountability_date_time"
                                        )} ${t(errors.trxDate.message)}`
                                    }
                                    onChange={(e) => e.target.blur()}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                            )}
                        />
                    </LocalizationProvider>
                )}
            />
            <Controller
                control={control}
                name="amount"
                render={({ field: { onChange } }) => (
                    <NumericFormat
                        onChange={(e) => {
                            e.target.value = e.target.value.replace(/[.]/g, "");
                            onChange(e);
                        }}
                        error={Boolean(errors.amount)}
                        helperText={
                            errors.amount &&
                            errors.amount.message &&
                            typeof errors.amount.message === "string" &&
                            `${t(
                                "disi.detail_petty_cash.form_accountability_amount"
                            )} ${t(errors.amount.message)}`
                        }
                        customInput={TextField}
                        fullWidth
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        InputProps={{
                            startAdornment: (
                                <span
                                    style={{
                                        paddingRight: ".3rem",
                                    }}
                                >
                                    Rp.
                                </span>
                            ),
                        }}
                        label={t(
                            "disi.detail_petty_cash.form_accountability_amount"
                        )}
                        autoComplete="off"
                    />
                )}
            />
            <FileUploadComponent
                onChange={(file) => {
                    setValue("file", file);
                    clearErrors("file");
                }}
                sx={{
                    borderColor: errors.file ? "#f00" : "#cbd5e1",
                    color: errors.file ? "#f00" : "#000",
                }}
                error={
                    errors.file &&
                    errors.file.message &&
                    typeof errors.file.message === "string"
                        ? `File ${t(errors.file.message)}`
                        : ""
                }
            />
        </FormModalContainer>
    );
};

export default AccountabilityForm;
