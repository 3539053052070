import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MerchantOnBoardingAPI } from "datas/merchantOnBoarding/merchantOnBoardingAPI";
import {
  TransactionListParams,
  TransactionListResult,
  TransactionRefundParams,
} from "domains/entity/main/merchantOnBoarding/transaction";
import { MerchantOnBoardingInteractor } from "domains/interactor/main/merchantOnBoarding/merchantOnBoardingInteractor";

export const getTransactionListAction = createAsyncThunk(
  "merchant-transaksi/getTransactionListAction",
  async (params: TransactionListParams) => {
    try {
      const interactor = new MerchantOnBoardingInteractor(
        new MerchantOnBoardingAPI()
      );
      const response = await interactor.getMerchantOnBoardingTransactionList(
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const postTransactionRefundAction = createAsyncThunk(
    "merchant-transaksi/getTransactionRefundAction",
    async (params: TransactionRefundParams) => {
        try {
            const interactor = new MerchantOnBoardingInteractor(
                new MerchantOnBoardingAPI()
            );
            const response = await interactor.merchantOnBoardingTransactionRefund(
                params
            );
            return response;
        } catch (error) {
            throw error;
        }
    }
);

interface iState {
  data: TransactionListResult;
  loading: boolean;
  error: boolean;
}

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 0,
    totalRows: 0,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "merchant-transaksi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionListAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransactionListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTransactionListAction.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  }
});

export default slice.reducer;
