import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import { DetailPettyCashAccountabilityApproveBody, DetailPettyCashAccountabilityBody, DetailPettyCashAccountabilityRejectBody } from "domains/entity/main/disi";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";

type iState = {
    result: string;
    loading: boolean;
    error: any
}

const initialState: iState = {
    result: "",
    loading: false,
    error: ""
}

export const createDetailPettyCashAccountabilityAction =
    createAsyncThunk('detail-petty-cash-accountability-create-actions',
        async (body: DetailPettyCashAccountabilityBody, { rejectWithValue }) => {
            try {
                const useCase = new DetailPettyCashUseCase(new DisiApi())
                return await useCase.postDetailpettyCashAccountability(body)
            } catch (error) {
                const { response } = error;
                if (!response) {
                    throw error
                }
                return rejectWithValue(response.data)
            }
        })

export const approveDetailPettyCashAccountabilityAction =
    createAsyncThunk('detail-petty-cash-accountability-approve-action', async (body: DetailPettyCashAccountabilityApproveBody, { rejectWithValue }) => {
        try {
            const useCase = new DetailPettyCashUseCase(new DisiApi())
            return await useCase.postDetailPettyCashAccountabilityApprove(body)
        } catch (error) {
            const { response } = error;
            if (!response) {
                throw error
            }
            return rejectWithValue(response.data)
        }
    })

export const rejectDetailPettyCashAccountabilityAction =
    createAsyncThunk('detail-petty-cash-accountability-reject-action', async (body: DetailPettyCashAccountabilityRejectBody, { rejectWithValue }) => {
        try {
            const useCase = new DetailPettyCashUseCase(new DisiApi())
            return await useCase.postDetailPettyCashAccountabilityReject(body)
        } catch (error) {
            const { response } = error;
            if (!response) {
                throw error
            }
            return rejectWithValue(response.data)
        }
    })

const detailPettyCashAccountabilityActionsSlice = createSlice({
    name: "detail-petty-cash-accountability-actions-slice",
    initialState,
    reducers: {
        resetDetailPettyCashAccountabilityActions: () => {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(createDetailPettyCashAccountabilityAction.pending, state => {
            state.loading = true;
            state.result = "";
            state.error = "";
        })
        builder.addCase(createDetailPettyCashAccountabilityAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload as { responsedesc: string }
            state.loading = false;
            state.result = responsedesc;
            state.error = "";
        })
        builder.addCase(createDetailPettyCashAccountabilityAction.rejected, (state, action) => {
            state.loading = false;
            state.result = "";
            state.error = action.payload
        })

        builder.addCase(approveDetailPettyCashAccountabilityAction.pending, state => {
            state.loading = true;
            state.result = "";
            state.error = "";
        })
        builder.addCase(approveDetailPettyCashAccountabilityAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload as { responsedesc: string }
            state.loading = false;
            state.result = responsedesc;
            state.error = "";
        })
        builder.addCase(approveDetailPettyCashAccountabilityAction.rejected, (state, action) => {
            state.loading = false;
            state.result = "";
            state.error = action.payload
        })

        builder.addCase(rejectDetailPettyCashAccountabilityAction.pending, state => {
            state.loading = true;
            state.result = "";
            state.error = "";
        })
        builder.addCase(rejectDetailPettyCashAccountabilityAction.fulfilled, (state, action) => {
            const { responsedesc = "" } = action.payload as { responsedesc: string }
            state.loading = false;
            state.result = responsedesc;
            state.error = "";
        })
        builder.addCase(rejectDetailPettyCashAccountabilityAction.rejected, (state, action) => {
            state.loading = false;
            state.result = "";
            state.error = action.payload
        })
    }
})

const detailPettyCashAccountabilityActionsReducer = detailPettyCashAccountabilityActionsSlice.reducer

export const { resetDetailPettyCashAccountabilityActions } = detailPettyCashAccountabilityActionsSlice.actions

export default detailPettyCashAccountabilityActionsReducer