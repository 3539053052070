import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import mainReducer from "./main";
import globalReducer from "./global";
import snackBarReducer from "./snackbar";

const store = configureStore(
    {
        reducer: {
            main: mainReducer,
            auth: authReducer,
            global: globalReducer,
            snackbar: snackBarReducer,
        }
    }
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export default store