import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";
import ConstantsResult from "domains/entity/main/ConstantResult";

type iState = {
    data: ConstantsResult;
    loading: boolean;
    error: any
}

const initialState: iState = {
    data: [],
    loading: false,
    error: ""
}

export const partnerDepositGetPartnerAction = createAsyncThunk('partner-deposit-get-partner-action', async (_, { rejectWithValue }) => {
    try {
        const useCase = new PartnerDepositUseCase(new DisiApi())
        return await useCase.getDataWithdrawPartner()
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const partnerDepositPartnerSlice = createSlice({
    name: "partner-deposit-status-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(partnerDepositGetPartnerAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(partnerDepositGetPartnerAction.fulfilled, (state, action) => {
            const { bankCode, bankNm } = action.payload

            const bankList: ConstantsResult = [{
                id: bankCode,
                name: bankNm
            }]

            state.loading = false;
            state.data = bankList
            state.error = ""
        })
        builder.addCase(partnerDepositGetPartnerAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const partnerDepositPartnerReducer = partnerDepositPartnerSlice.reducer

export default partnerDepositPartnerReducer 