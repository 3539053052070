import { AuthLoginRequest, AuthLoginResult } from "domains/entity/auth/login";
import { AuthLoginRepository } from "domains/repository/auth/LoginRepository";

export class LoginInteractor implements AuthLoginRepository {
    private authLoginRepository: AuthLoginRepository;

    public constructor(authLoginRepository: AuthLoginRepository) {
        this.authLoginRepository = authLoginRepository;
    }

    public async login(params: AuthLoginRequest): Promise<AuthLoginResult> {
        const result = await this.authLoginRepository.login(params);
        return result;
    }
}