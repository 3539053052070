import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import ConstantsResult from "domains/entity/main/ConstantResult";
import DisiApi from "datas/disi/DisiApi";
import MerchantDepositUseCase from "domains/interactor/main/disi/MerchantDeposit";

type iState = {
    data: ConstantsResult;
    loading: boolean;
    error: any;
}

const initialState: iState = {
    data: [],
    error: "",
    loading: false
}

export const merchantDepositPartnerAction = createAsyncThunk('merchant-deposit-partner', async (_, { rejectWithValue }) => {
    try {
        const useCase = new MerchantDepositUseCase(new DisiApi())

        return await useCase.getMerchantDepositPartner()
    } catch (error) {
        const { response = null } = error

        if (!response) {
            throw error
        }

        return rejectWithValue(response.data)
    }
})

const merchantDepositPartnerSlice = createSlice({
    name: "merchant-deposit-partner",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(merchantDepositPartnerAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(merchantDepositPartnerAction.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false;
            state.error = ""
        })
        builder.addCase(merchantDepositPartnerAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const merchantDepositPartnerReducer = merchantDepositPartnerSlice.reducer

export default merchantDepositPartnerReducer