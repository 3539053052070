import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import DisiApi from "datas/disi/DisiApi";
import DetailMerchantDepositUseCase from "domains/interactor/main/disi/DetailMerchantDeposit";

type iState = {
    data: number;
    loading: boolean;
    error: any
}

export const detailMerchantDepositSaldoAction = createAsyncThunk('disi-detail-merchant-balance', async (_, { rejectWithValue }) => {
    try {
        const useCase = new DetailMerchantDepositUseCase(new DisiApi())
        const result = await useCase.getDetailMerchantDepositBalance()

        return Number(result)
    } catch (error) {
        const { response = null } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const initialState: iState = {
    data: 0,
    loading: false,
    error: ""
}


const detailMerchantDepositSaldoSlice = createSlice({
    name: 'detail-merchant-deposit-saldo-slice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(detailMerchantDepositSaldoAction.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(detailMerchantDepositSaldoAction.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = false
            state.error = ""
        })
        builder.addCase(detailMerchantDepositSaldoAction.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
            state.data = 0
        })
    }
})

const detailMerchantDepositSaldoReducer = detailMerchantDepositSaldoSlice.reducer

export default detailMerchantDepositSaldoReducer