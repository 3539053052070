import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { setorkuDaftarKodeAction } from "utils/store/main/setorku/setorkuDaftarKode";
import SetorkuApi from "data/main/setorkuApi";
import dayjs from "dayjs";
import { setorkuPartnerAction } from "utils/store/main/setorku/setorkuPartner";
import { GridColDef } from "@mui/x-data-grid";
import { partnerListAction, addPartnerAction, editPartnerAction } from "utils/store/main/ppob/partner";
import { useForm } from "react-hook-form";
import { addPaymentMethodAction, comboboxPartnerAction, comboboxPaymentMethodAction, paymentMethodListAction } from "utils/store/main/ppob/paymentMethod";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { Stack } from "@mui/system";
import { collectingAgentListAction, collectingAgentUpdateAction } from "utils/store/main/ppob/collectingAgent";
import { useParams } from "react-router-dom";
import detailCollectingAgent, { detailCollectingAgentAddAction, detailCollectingAgentDeleteAction, detailCollectingAgentListAction, detailCollectingAgentUpdateAction } from "utils/store/main/ppob/detailCollectingAgent";
import { showAddModal, showDeleteModal, showEditModal } from "utils/store/global/modal";

//modal-filter

const usePPOBDetailCollectingAgentViewModel = () => {
  const { t }: any = useTranslation();
  const {id} = useParams()
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      add: {
        partnerId: id,
        ppobId: 4,
        inqType: 2,
        payType: 2,
        advType: 1,
        status: '1',
      },
      edit: {
        partnerId: '',
        ppobId: 4,
        inqType: 2,
        payType: 2,
        advType: 1,
        status: '1',
      },
      delete: {
        partnerId: '',
        ppobId: 4,
      },
      agentDetail: {
        partnerId: "",
        description: "",
        secretKey: "",
        isActive: "",
      }
    },
  });

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [disabledEdit, setDisabledEdit] = React.useState<boolean>(true);
  const [modalAdd, setModalAdd] = React.useState(false);
  const [modalFilter, setModalFilter] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      flex: 1,
      minWidth: 50,
    },
    // {
    //   field: "ppobId",
    //   headerName: t("ppob.collecting_agent.id_ppob"),
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "ppobName",
      headerName: t("ppob.collecting_agent.ppob_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "inqType",
      headerName: t("ppob.collecting_agent.inquiry"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "payType",
      headerName: t("ppob.collecting_agent.pay"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "advType",
      headerName: t("ppob.collecting_agent.advice"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "isActive",
      headerName: t("ppob.collecting_agent.status"),
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Typography variant="body2">
            {row.row.isActive === "1" ? "Active" : "Inactive"}
          </Typography>
        );
      },
    },
    {
      field: "",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Button onClick={()=>modalHandler('edit', row.row)} variant="text" size="small">
                <EditIcon />
              </Button>
              <Button onClick={()=>modalHandler('delete', row.row)} variant="text" size="small">
                <DeleteIcon />
              </Button>
            </Stack>
          )
      },
    },
  ];

  const modalHandler = (type: string, data?:any) => {
    switch (type) {
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit))
        setValue("edit", data);
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete))
        setValue("delete", data);
        break;
      default:
        break;
    }
  };

  const submitAdd = async (data: any) => {
    console.log(data.add);
    await dispatch(
      detailCollectingAgentAddAction(data.add)
    );
    reset();
    fetchCollectingAgentList(id as string);
  };

  const submitEdit = async (data: any) => {
    console.log(data.edit);
    modalHandler('edit')
    await dispatch(
      detailCollectingAgentUpdateAction({
        partnerId: data.edit.partnerId,
        ppobId: data.edit.ppobId,
        inqType: data.edit.inqType,
        payType: data.edit.payType,
        advType: data.edit.advType,
        status: data.edit.status,
      })
    );
    fetchCollectingAgentList(id as string);
  };

  const submitDelete = async (data: any) => {
    console.log(data.delete);
    modalHandler('delete')
    await dispatch(
      detailCollectingAgentDeleteAction({
        ppobId: data.delete.ppobId,
        partnerId: data.delete.partnerId,
      })
    );
    fetchCollectingAgentList(id as string);
  }

  const editAgent = async (data: any) => {
    console.log(data.agentDetail)
    setDisabledEdit(true);
    await dispatch(
      collectingAgentUpdateAction({
        partnerId: data.agentDetail.partnerId,
        partnerName: data.agentDetail.description,
        secretKey: data.agentDetail.secretKey,
        status: data.agentDetail.isActive,
      })
    );
    // reset();
    // fetchCollectingAgentList(id as string);
  };

  const fetchCollectingAgentList = async (partnerId:string) => {
    await dispatch(
      collectingAgentListAction({
        pageNumber: page,
        pageSize: pageSize,
        partnerId: partnerId,
        filterText: "",
      })
    );
    await dispatch(
      detailCollectingAgentListAction(id as string)
    );
  };

  React.useEffect(() => {
    fetchCollectingAgentList(id as string);
  }, [page, pageSize, dispatch]);

  React.useEffect(() => {
    setValue("agentDetail.partnerId", selector.collectingAgent.detail.partnerId);
    setValue("agentDetail.description", selector.collectingAgent.detail.description);
    setValue("agentDetail.secretKey", selector.collectingAgent.detail.secretKey);
    setValue("agentDetail.isActive", selector.collectingAgent.detail.isActive);
  }, [selector.collectingAgent.detail.partnerId]);
  
  return {
    data: selector,
    modal,
    modalHandler,
    disabledEdit,
    setDisabledEdit,
    editAgent,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitAdd,
    submitEdit,
    submitDelete
  };
};

export default usePPOBDetailCollectingAgentViewModel;
