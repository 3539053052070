import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DanaApi from "datas/dana/DanaApi";
import { HistoryTransactionParams, HistoryTransactionResult, RefundHistoryTransactionParams, ExportHistoryTransactionParams } from "domains/entity/main/dana/historyTransaction";
import { DanaInteractor } from "domains/interactor/main/dana/DanaInteractor";

export const transactionHistoryListAction = createAsyncThunk(
    'dana/transactionHistory',
    async (data: HistoryTransactionParams) => {
        try {
            const interactor = new DanaInteractor(new DanaApi());
            const response = await interactor.getHistoryTransaction(data);
            return response;
        } catch (error) {
            throw error;
        }
    }
);


export const refundTransactionHistoryAction = createAsyncThunk(
    'dana/refundTransactionHistory',
    async (data: RefundHistoryTransactionParams) => {
        try {
            const interactor = new DanaInteractor(new DanaApi());
            const response = await interactor.refundHistoryTransaction(data);
            return response;
        } catch (error) {
            throw error;
        }
    }
);

interface iState {
    data: HistoryTransactionResult;
    loading: boolean;
    error: string | null;
    refund: {
        loading: boolean;
        error: string | null;
    }
}

const initialState: iState = {
    data: {
        totalRows: 0,
        pageLimit: 0,
        data: [],
    },
    loading: false,
    error: null,
    refund: {
        loading: false,
        error: null,
    }
};

const slice = createSlice({
    name: 'danaTransactionHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(transactionHistoryListAction.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(transactionHistoryListAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(transactionHistoryListAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || null;
        });
        builder.addCase(refundTransactionHistoryAction.pending, (state) => {
            state.refund.loading = true;
            state.refund.error = null;
        });
        builder.addCase(refundTransactionHistoryAction.fulfilled, (state) => {
            state.refund.loading = false;
        });
        builder.addCase(refundTransactionHistoryAction.rejected, (state, action) => {
            state.refund.loading = false;
            state.refund.error = action.error.message || null;
        });
    }
});

export default slice.reducer;