import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { GridColDef } from "@mui/x-data-grid";
import { partnerListAction, addPartnerAction, editPartnerAction } from "utils/store/main/ppob/partner";
import { useForm } from "react-hook-form";
import { showAddModal, showDeleteModal, showEditModal, showFilterModal } from "utils/store/global/modal";

//modal-filter

const usePPOBPartnerViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      add: {
        partnerId: "",
        partnerName: "",
        response: "1",
        access: 0,
      },
      edit: {
        partnerId: "",
        partnerName: "",
        response: "",
        access: "",
      },
    },
  });

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);

  const columns: GridColDef[] = [
    // {
    //   field: "partnerId",
    //   headerName: t("ppob.partner.id_partner"),
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "partnerName",
      headerName: t("ppob.partner.partner_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "respType",
      headerName: t("ppob.partner.response_type"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "isAccess",
      headerName: t("ppob.partner.access"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <div>
            <Button
              variant="text"
              onClick={() => showModalEdit(row.row)}
            >
              <EditIcon />
            </Button>
          </div>
        );
      },
    },
  ];

  const closeModal = (type: string) => {
    switch (type) {
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete));
        break;
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      default:
        break;
    }
  };

  const showModalEdit = (data: any) => {
    closeModal("edit");
    setValue("edit.partnerId", data.partnerId);
    setValue("edit.partnerName", data.partnerName);
    setValue("edit.response", data.respType);
    setValue("edit.access", data.isAccess);
  };

  const submitFilter = (data: any) => {
    console.log(data.filterText);
    dispatch(
      partnerListAction({
        pageNumber: page,
        pageSize: pageSize,
        filterText: data.filterText,
      })
    );
    closeModal("filter");
  };

  const submitAdd = async (data: any) => {
    closeModal("add");
    await dispatch(
      addPartnerAction({
        partnerId: data.add.partnerId,
        partnerName: data.add.partnerName,
        respType: data.add.response,
        access: data.add.access,
      })
    );
    reset();
    fetchPartner();
  };

  const submitEdit = async (data: any) => {
    closeModal("edit");
    await dispatch(
      editPartnerAction({
        partnerId: data.edit.partnerId,
        partnerName: data.edit.partnerName,
        respType: data.edit.response,
        access: data.edit.access,
      })
    );
    reset();
    fetchPartner();
  };

  const fetchPartner = async () => {
    await dispatch(
      partnerListAction({
        pageNumber: page,
        pageSize: pageSize,
        filterText: "",
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      partnerListAction({
        pageNumber: page,
        pageSize: pageSize,
        filterText: "",
      })
    );
  }, [page, pageSize]);

  return {
    data: selector,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitFilter,
    submitAdd,
    submitEdit,
  };
};

export default usePPOBPartnerViewModel;
