import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import shadows from "@mui/material/styles/shadows";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DaftarKodeUseCase from "domain/interactor/main/setorku/DaftarKodeUseCase";
import PartnerUseCase from "domain/interactor/main/setorku/PartnerUseCase";
import { width } from "@mui/system";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import useProdukViewModel from "./PPOBDetailCollectingAgentViewModel";
import useHistoryTransaksiViewModel from "./PPOBDetailCollectingAgentViewModel";
import useDanaHistoryTransactionViewModel from "./PPOBDetailCollectingAgentViewModel";
import usePPOBPartnerViewModel from "./PPOBDetailCollectingAgentViewModel";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";
import usePPOBPaymentMethodViewModel from "./PPOBDetailCollectingAgentViewModel";
import usePPOBCollectingAgentViewModel from "./PPOBDetailCollectingAgentViewModel";
import usePPOBDetailCollectingAgentViewModel from "./PPOBDetailCollectingAgentViewModel";
import ConfirmationModalComponent from "presentation/components/ConfirmationModalComponent";


const PPOBDetailCollectingAgentComponent = () => {
  const {
    data,
    modal,
    modalHandler,
    disabledEdit,
    setDisabledEdit,
    editAgent,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitAdd,
    submitEdit,
    submitDelete,
  } = usePPOBDetailCollectingAgentViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.collecting_agent.detail_header")}
                </Typography>

                <Stack spacing={2} direction="row" className="headerButton" sx={{
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                }}>
                  <Button
                    onClick={() => modalHandler("add")}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("ppob.collecting_agent.add_button")}
                  </Button>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                className="headerSetorku"
                spacing={2}
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <Stack
                  spacing={2}
                  direction="column"
                  className="headerSetorku"
                  sx={{
                    "@media (max-width: 600px)": {
                      mb: "1rem",
                    },
                  }}
                >
                  <FormControl fullWidth sx={{ mb: "1rem" }}>
                    <Controller
                      name="agentDetail.partnerId"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={disabledEdit}
                          label={t("ppob.collecting_agent.id_partner")}
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: "1rem" }}>
                    <Controller
                      name="agentDetail.description"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={disabledEdit}
                          label={t("ppob.collecting_agent.partner_name")}
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: "1rem" }}>
                    <Controller
                      name="agentDetail.secretKey"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={disabledEdit}
                          label={t("ppob.collecting_agent.secret_key")}
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: "1rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      {t("ppob.collecting_agent.status")}
                    </InputLabel>
                    <Controller
                      name="agentDetail.isActive"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          disabled={disabledEdit}
                          labelId="demo-simple-select-label"
                          label={t("ppob.collecting_agent.status")}
                          variant="outlined"
                        >
                          <MenuItem value="0">Active</MenuItem>
                          <MenuItem value="1">Inactive</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={disabledEdit ? () => setDisabledEdit(false) : handleSubmit(editAgent)}
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                    }}
                  >
                    {disabledEdit ? t("ppob.collecting_agent.change_button") : t("ppob.collecting_agent.save_button")}
                  </Button>
                  <Button
                    onClick={() => modalHandler("add")}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                      "@media (min-width: 600px)": {
                        display: "none",
                        width: "100%",
                      },
                    }}
                  >
                    {t("ppob.collecting_agent.add_button")}
                  </Button>
                </Stack>
                {data.paymentMethod.loading ? (
                  <h1>Loading...</h1>
                ) : (
                  <DataTable
                    {...{
                      data: data.detailCollectingAgent.data.list,
                      totalRows: data.detailCollectingAgent.data.total,
                      columns,
                      setPage,
                      setPageSize,
                      page,
                      pageSize,
                    }}
                  />
                )}
              </Stack>

            </Grid>
          </Box>
        </div>

        <ConfirmationModalComponent action={handleSubmit(submitDelete)} type='delete' open={modal.delete} onClose={() => modalHandler('delete')} />
        <ModalContainer
          open={modal.edit}
          onClose={() => modalHandler("edit")}
          applyButton={handleSubmit(submitEdit)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.partnerId"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled
                  variant="outlined"
                  label={t("ppob.collecting_agent.id_partner")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.id_ppob")}
            </InputLabel>
            <Controller
              name="edit.ppobId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.id_ppob")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.inquiry")}
            </InputLabel>
            <Controller
              name="edit.inqType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.inquiry")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.pay")}
            </InputLabel>
            <Controller
              name="edit.payType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.pay")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.advice")}
            </InputLabel>
            <Controller
              name="edit.advType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.advice")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.status")}
            </InputLabel>
            <Controller
              name="edit.status"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.status")}
                  variant="outlined"
                >
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">Inactive</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </ModalContainer>

        <ModalContainer
          open={modal.add}
          onClose={() => modalHandler("add")}
          applyButton={handleSubmit(submitAdd)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.partnerId"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled
                  variant="outlined"
                  label={t("ppob.collecting_agent.id_partner")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.id_ppob")}
            </InputLabel>
            <Controller
              name="add.ppobId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.id_ppob")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.inquiry")}
            </InputLabel>
            <Controller
              name="add.inqType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.inquiry")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.pay")}
            </InputLabel>
            <Controller
              name="add.payType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.pay")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.advice")}
            </InputLabel>
            <Controller
              name="add.advType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.advice")}
                  variant="outlined"
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.collecting_agent.status")}
            </InputLabel>
            <Controller
              name="add.status"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  label={t("ppob.collecting_agent.status")}
                  variant="outlined"
                >
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">Inactive</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </ModalContainer>
      </Container>
    </LayoutComponent>
  );
};

export default PPOBDetailCollectingAgentComponent;
