import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import shadows from "@mui/material/styles/shadows";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DaftarKodeUseCase from "domain/interactor/main/setorku/DaftarKodeUseCase";
import PartnerUseCase from "domain/interactor/main/setorku/PartnerUseCase";
import { width } from "@mui/system";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import useProdukViewModel from "./PPOBSellingPriceViewModel";
import useHistoryTransaksiViewModel from "./PPOBSellingPriceViewModel";
import useDanaHistoryTransactionViewModel from "./PPOBSellingPriceViewModel";
import usePPOBPartnerViewModel from "./PPOBSellingPriceViewModel";
import ModalContainer from "presentation/components/ModalContainer";
import { Controller } from "react-hook-form";
import usePPOBPaymentMethodViewModel from "./PPOBSellingPriceViewModel";
import usePPOBSellingPriceViewModel from "./PPOBSellingPriceViewModel";
import UploadFile from "presentation/components/UploadFile";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

const PPOBSellingPriceComponent = () => {
  const {
    data,
    page,
    modal,
    modalHandler,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    columns,
    t,
    handleSubmit,
    control,
    submitFilter,
    submitAdd,
    submitEdit,
  } = usePPOBSellingPriceViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                direction="column"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("ppob.selling_price.header")}
                </Typography>

                <Stack direction="row" className="headerButton" sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  pb: "1rem",
                  '@media (max-width: 600px)': {
                    flexDirection: "column",
                  },
                }}>
                  <Stack direction="row" sx={{
                    '@media (max-width: 600px)': {
                      justifyContent: "space-between",
                      mb: "1rem",
                    },
                  }}>
                  <Button
                    variant="outlined"
                    sx={{
                      height: "42px",
                      color: "rgba(34, 136, 102, 0.5)",
                      borderColor: "rgba(34, 136, 102, 0.5) !important",
                      mr: "0.5rem",
                      '@media (max-width: 600px)': {
                        width: "100%",
                      },
                    }}
                    onClick={() => modalHandler('add')}
                  >
                    {t("ppob.selling_price.add_price_button")}
                  </Button>
                  <Button
                    onClick={() => modalHandler('upload')}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                      ml: "0.5rem",
                      '@media (max-width: 600px)': {
                        width: "100%",
                      },
                    }}
                  >
                    {t("ppob.selling_price.upload_file_button")}
                  </Button>
                    </Stack>
                    <Stack direction="row" sx={{
                    '@media (max-width: 600px)': {
                      justifyContent: "space-between",
                    },
                    }}>
                  <Button
                    variant="outlined"
                    sx={{
                      height: "42px",
                      color: "rgba(34, 136, 102, 0.5)",
                      borderColor: "rgba(34, 136, 102, 0.5) !important",
                      mr: "0.5rem",
                      '@media (max-width: 600px)': {
                        width: "100%",
                      },
                    }}
                    onClick={() => modalHandler('filter')}
                  >
                    {t("ppob.selling_price.filter_button")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                      ml: "0.5rem",
                      '@media (max-width: 600px)': {
                        width: "100%",
                      },
                    }}
                  >
                    {t("ppob.selling_price.export_button")}
                  </Button>
                    </Stack>
                </Stack>
              </Stack>

              {data.paymentMethod.loading ? (
                <h1>Loading...</h1>
              ) : (
                <DataTable
                  {...{
                    data: data.paymentMethod.data.data,
                    totalRows: data.paymentMethod.data.total,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>

        <ModalContainer
          open={modal.upload}
          onClose={() => modalHandler('upload')}
          applyButton={handleSubmit(submitAdd)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="upload.partner"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("ppob.selling_price.filter_text")}
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="all">
                    {t("ppob.selling_price.all_partner")}
                  </MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <UploadFile />
        </ModalContainer>

        <ModalContainer
          open={modal.filter}
          onClose={() => modalHandler('filter')}
          applyButton={handleSubmit(submitFilter)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="filter.partner"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("ppob.selling_price.filter_text")}
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  {data.paymentMethod.partnerList.map((item: any, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="filter.paymentMethod"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("ppob.selling_price.filter_text")}
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  {data.paymentMethod.paymentMethodList.map(
                    (item: any, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </FormControl>
        </ModalContainer>

        <ModalContainer
          open={modal.edit}
          onClose={() => modalHandler('edit')}
          applyButton={handleSubmit(submitEdit)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.partner_name")}
            </InputLabel>
            <Controller
              name="edit.partnerName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.partner_name")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.category")}
            </InputLabel>
            <Controller
              name="edit.category"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.category")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.product_name")}
            </InputLabel>
            <Controller
              name="edit.productName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.product_name")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.description")}
            </InputLabel>
            <Controller
              name="edit.description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.description")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.sellingPrice"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.selling_price.nominal")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.publicPrice"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField

                  variant="outlined"
                  label={t("ppob.selling_price.public_price")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="edit.status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select 
                  variant="outlined"
                  label={t("ppob.selling_price.status")}
                  {...field}
                >
                  <MenuItem value="0">{t('ppob.selling_price.inactive')}</MenuItem>
                  <MenuItem value="1">{t('ppob.selling_price.active')}</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
        </ModalContainer>

        <ModalContainer
          open={modal.add}
          onClose={() => modalHandler('add')}
          applyButton={handleSubmit(submitAdd)}
        >
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.partner_name")}
            </InputLabel>
            <Controller
              name="add.partnerName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.partner_name")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.category")}
            </InputLabel>
            <Controller
              name="add.category"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.category")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.product_name")}
            </InputLabel>
            <Controller
              name="add.productName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.product_name")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <InputLabel id="demo-simple-select-label">
              {t("ppob.selling_price.description")}
            </InputLabel>
            <Controller
              name="add.description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label={t("ppob.selling_price.description")}
                  {...field}
                >
                  <MenuItem value="0">1</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.sellingPrice"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label={t("ppob.selling_price.nominal")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.publicPrice"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField

                  variant="outlined"
                  label={t("ppob.selling_price.public_price")}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: "1rem" }}>
            <Controller
              name="add.status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select 
                  variant="outlined"
                  label={t("ppob.selling_price.status")}
                  {...field}
                >
                  <MenuItem value="0">{t('ppob.selling_price.inactive')}</MenuItem>
                  <MenuItem value="1">{t('ppob.selling_price.active')}</MenuItem>
                  </Select>
              )}
            />
          </FormControl>
        </ModalContainer>
      </Container>
    </LayoutComponent>
  );
};

export default PPOBSellingPriceComponent;
