import httpCommon from "datas/httpCommon";
import MerchantRefundResult from "domain/entity/main/structure/MerchantRefundResult";
import MerchantTransaksiResult from "domain/entity/main/structure/MerchantTransaksiResult";
import MerchantOnBoardingRepository from "domain/repository/main/MerchantOnBoardingRepositoy";

export default class MerchantOnBoardingAPI implements MerchantOnBoardingRepository {
    async getMerchantTransaksi(
        fromDate: string,
        toDate: string,
        pageSize: number,
        pageNumber: number
    ): Promise<MerchantTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<MerchantTransaksiResult>(
            `/merchant/transaksi?${params}`
        ).then((res) => res.data);
    }
    
    async exportMerchatOnboardingTransaksi(
        fromDate: string,
        toDate: string
    ): Promise<any> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
        });
        return await httpCommon.get<any>(`/merchant/export?${params}`).then(
            (res) => res.data
        );
    }
    async getMerchantTransaksiRefund(
        originalReferenceNo: string,
        amount: string,
        reason: string
    ): Promise<string> {
        console.log("dari api", [originalReferenceNo, amount, reason]);
        return await httpCommon.post<string>(`/merchant/refund`, {
            originalReferenceNo: originalReferenceNo,
            amount: amount,
            reason: reason,
        }).then((res) => res.data);
    }
    async getMerchantRefund(
        fromDate?: string,
        toDate?: string,
        pageSize?: number,
        pageNumber?: number
    ): Promise<any> {
        const params = new URLSearchParams();
        if (fromDate && toDate) {
            params.append("fromDate", fromDate);
            params.append("toDate", toDate);
        }
        if (pageSize !== undefined) {
            params.append("pageSize", pageSize.toString());
        }
        if (pageNumber !== undefined) {
            params.append("pageNumber", (pageNumber + 1).toString());
        }

        return await httpCommon.get<MerchantRefundResult>(
            `/merchant/refund?${params}`,
            // {
            //     Headers,
            // }
        ).then((res) => res.data);
    }
    async exportMerchantOnboardingRefund(fromDate: string, toDate: string): Promise<any> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
        });
        return await httpCommon.get<any>(`/merchant/refund/export?${params}`).then(
            (res) => res.data
        );
    }
}