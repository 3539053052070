import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PPOBApi } from "datas/ppob/PPOBApi";
import { PPOBAddCollectingAgentListParams, PPOBCollectingAgentDetailAddParams, PPOBCollectingAgentDetailDeleteParams, PPOBCollectingAgentDetailEditParams, PPOBCollectingAgentDetailListResult, PPOBCollectingAgentListParams } from "domains/entity/main/ppob/collectingAgent";
import { PPOBInteractor } from "domains/interactor/main/ppob/PPOBInteractor";

export const detailCollectingAgentListAction = createAsyncThunk(
    "ppobDetailCollectingAgent/list",
    async (id: string) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.getCollectingAgentDetailList({
                partnerId: id,
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const detailCollectingAgentAddAction = createAsyncThunk(
    "ppobDetailCollectingAgent/add",
    async (params: PPOBCollectingAgentDetailAddParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.addPPOBCollectingAgentDetail(params);
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const detailCollectingAgentUpdateAction = createAsyncThunk(
    "ppobDetailCollectingAgent/update",
    async (params: PPOBCollectingAgentDetailEditParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.editPPOBCollectingAgentDetailEdit(params);
            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const detailCollectingAgentDeleteAction = createAsyncThunk(
    "ppobDetailCollectingAgent/delete",
    async (params: PPOBCollectingAgentDetailDeleteParams) => {
        try {
            const interactor = new PPOBInteractor(new PPOBApi);
            const response = await interactor.deletePPOBCollectingAgentDetail(params);
            return response;
        } catch (error) {
            throw error;
        }
    }
);


const slice = createSlice({
    name: "ppobDetailCollectingAgent",
    initialState: {
        data: {
            total: 0,
            list: [{
                partnerId: '',
                ppobId: 0,
                ppobName: '',
                inqType: 0,
                payType: 0,
                advType: 0,
                isActive: '',
            }],
        },
        detail: {
            id: 0,
            partnerId: '',
            ppobId: 0,
            ppobName: '',
            inqType: 0,
            payType: 0,
            advType: 0,
            isActive: '',
        },
        loading: false,
        error: false,
    },
    reducers: {
        detailCollectingAgent: (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(detailCollectingAgentListAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(detailCollectingAgentListAction.fulfilled, (state, action) => {
            console.log(action.payload);
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(detailCollectingAgentListAction.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(detailCollectingAgentAddAction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(detailCollectingAgentAddAction.fulfilled, (state, action) => {
            console.log(action.payload);
            state.loading = false;
            // state.data = action.payload;
        });
        builder.addCase(detailCollectingAgentAddAction.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export const { detailCollectingAgent } = slice.actions;
export default slice.reducer;