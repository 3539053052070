import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import { DetailPettyCashHeaderResult } from "domains/entity/main/disi/DetailPettyCashHeaderResult";
import DetailPettyCashUseCase from "domains/interactor/main/disi/DetailPettyCash";

type iState = {
    loading: boolean;
    error: any;
    data: DetailPettyCashHeaderResult
}

const initialData = {
    accountabilityStatus: "",
    amount: "",
    amountResponsibility: "",
    amountRest: "",
    description: "",
    dtTrx: "",
    merchantId: "",
    merchantNm: "",
    noRow: "",
    partnerId: "",
    pettyCashId: "",
    status: "",
    trackingReff: "",
    typeChannel: "",
    typePettyCash: "",
    token: "",
    tokenStatus: ""
}

const initialState: iState = {
    data: initialData,
    error: "",
    loading: false
}

export const detailPettyCashHeaderDataAction = createAsyncThunk("detail-petty-cash-header-action", async (partnerId: string, { rejectWithValue }) => {
    try {

        const useCase = new DetailPettyCashUseCase(new DisiApi())

        return await useCase.getDetailPettyCashHeaderData(partnerId)
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const detailPettyCashHeaderSlice = createSlice({
    name: "detail-petty-cash-header-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(detailPettyCashHeaderDataAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(detailPettyCashHeaderDataAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(detailPettyCashHeaderDataAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
            state.data = initialData
        })
    }
})

const detailPettyCashHeaderReducer = detailPettyCashHeaderSlice.reducer

export default detailPettyCashHeaderReducer