import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import shadows from "@mui/material/styles/shadows";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useTransaksiViewModel from "./SetorkuTransaksiViewModel";
import { useTranslation } from "react-i18next";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import ConfirmationModalComponent from "presentation/components/ConfirmationModalComponent";
import ModalComponent from "presentation/components/ModalComponent";

//data-grid

//modal-filter
const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

export const SetorkuTransaksiComponent = () => {
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    handleModal,
    onSubmit,
    columns,
    onExpor,
    t,
    showConfirmation,
    setShowConfirmation,
    formFilterData,
    clearFilter
  } = useTransaksiViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          {/* <DataTable/> */}
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("setorku.transaction.transaction_list_header")}
                </Typography>

                <Stack spacing={2} direction="row" className="headerButton">
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                      }}
                      onClick={handleModal}
                    >
                      {t("setorku.transaction.filter_button")}
                    </Button>
                    <ModalComponent
                      modal={open}
                      closeModal={handleModal}
                      header={t("setorku.transaction.filter_header")}
                      subHeader={t("setorku.transaction.filter_subheader")}
                      cancelButton={clearFilter}
                      applyButton={onSubmit}
                      formData={formFilterData}
                    />
                  </div>
                  <Button
                    onClick={onExpor}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("setorku.transaction.export_button")}
                  </Button>
                </Stack>
              </Stack>
              {data.transaksi.loading ? (
                <h1>Loading ...</h1>
              ) : (
                <DataTable
                  {...{
                    ...data.transaksi.data,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
              )}
            </Grid>
          </Box>
        </div>
        <ConfirmationModalComponent type="update" contentText="Data akan terupdate. Apakah Anda yakin?" open={showConfirmation} onClose={() => setShowConfirmation(false)} />
      </Container>
    </LayoutComponent>
  );
};

export default SetorkuTransaksiComponent;
