import React from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    Modal,
    Stack,
    CircularProgress,
    Typography,
} from "@mui/material";
import useMerchantRefundViewModel from "./MerchantRefundViewModel";
import DataTable from "presentation/components/DataTable";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import TimeRangePickerComponent from "presentation/components/TimeRangePickerComponent";
import RefreshIcon from "@mui/icons-material/Refresh";
import LayoutComponent from "presentation/layout/Layout";

const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "white",
    borderRadius: "20px",
    p: 4,
};

const MerchantRefundComponent = () => {
    const viewModel = useMerchantRefundViewModel();
    const { data, ...rest } = viewModel;

    return (
        <LayoutComponent>
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        paddingTop: "2rem",
                    }}
                >
                    <Box
                        sx={{
                            width: "95%",
                            backgroundColor: "white",
                            boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            borderRadius: "8px",
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "rgba(34, 136, 102, 0.12)",
                            },
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                boxShadow: { sm: "0 0 5px #ddd" },
                                padding: "1rem",
                            }}
                        >
                            <Stack
                                spacing={2}
                                direction="row"
                                className="headerSetorku"
                                sx={{
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    className="headerName"
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        mb: "1.5rem",
                                        fontWeight: 600,
                                    }}
                                >
                                    {viewModel.t(
                                        "merchantOnBoarding.refund.header_trans"
                                    )}
                                </Typography>

                                <Stack
                                    spacing={2}
                                    direction="row"
                                    className="headerButton"
                                    alignItems="center"
                                >
                                    {viewModel.loading ? (
                                        <CircularProgress color="success" />
                                    ) : (
                                        <IconButton
                                            sx={{ fontSize: "2rem" }}
                                            onClick={
                                                viewModel.handleRefreshButtonClick
                                            }
                                        >
                                            <RefreshIcon fontSize="inherit" />
                                        </IconButton>
                                    )}

                                    <div>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                height: "42px",
                                                color: "rgba(34, 136, 102, 0.5)",
                                                borderColor:
                                                    "rgba(34, 136, 102, 0.5) !important",
                                            }}
                                            onClick={viewModel.handleOpen}
                                        >
                                            {viewModel.t(
                                                "merchantOnBoarding.refund.filter_button"
                                            )}
                                        </Button>
                                        <Modal
                                            open={viewModel.open}
                                            onClose={viewModel.handleClose}
                                        >
                                            <Box
                                                sx={style}
                                                className="ModelFilter"
                                            >
                                                <Typography
                                                    id="modal-modal-title"
                                                    variant="h6"
                                                    component="h2"
                                                    sx={{
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {viewModel.t(
                                                        "merchantOnBoarding.refund.filter_button"
                                                    )}
                                                </Typography>
                                                <Typography
                                                    id="modal-modal-description"
                                                    sx={{
                                                        fontSize: "0.5rem",
                                                        lineHeight: "166%",
                                                        color: "#6E6E6E",
                                                        mb: "1rem",
                                                    }}
                                                >
                                                    {viewModel.t(
                                                        "merchantOnBoarding.refund.filter_desc"
                                                    )}
                                                </Typography>
                                                <Divider
                                                    variant="middle"
                                                    sx={{
                                                        mt: "0 !important",
                                                        mr: "0 !important",
                                                        ml: "0 !important",
                                                        mb: "1rem !important",
                                                    }}
                                                />

                                                <DateRangeComponent
                                                    startDate={
                                                        viewModel.startDate
                                                    }
                                                    endDate={viewModel.endDate}
                                                    setStartDate={
                                                        viewModel.setStartdate
                                                    }
                                                    setEndDate={
                                                        viewModel.setEndDate
                                                    }
                                                    sx={{ my: "1rem" }}
                                                />

                                                <TimeRangePickerComponent
                                                    timeStart={
                                                        viewModel.startTime
                                                    }
                                                    setTimeStart={
                                                        viewModel.setStartTime
                                                    }
                                                    timeEnd={viewModel.endTime}
                                                    setTimeEnd={
                                                        viewModel.setEndTime
                                                    }
                                                    sx={{ my: "1rem" }}
                                                />

                                                <Stack
                                                    spacing={2}
                                                    direction="row"
                                                >
                                                    <Button
                                                        onClick={
                                                            viewModel.clearFilter
                                                        }
                                                        variant="text"
                                                        sx={{
                                                            color: "rgba(40, 99, 83, 1)",
                                                            width: "317.5px",
                                                        }}
                                                    >
                                                        {viewModel.t(
                                                            "merchantOnBoarding.refund.clear_filter_button"
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={
                                                            viewModel.onFilterSubmit
                                                        }
                                                        variant="contained"
                                                        sx={{
                                                            color: "white",
                                                            background:
                                                                "rgba(40, 99, 83, 1) !important",
                                                            width: "317.5px",
                                                        }}
                                                    >
                                                        {viewModel.t(
                                                            "merchantOnBoarding.refund.apply_filter_button"
                                                        )}
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Modal>
                                    </div>
                                    <Button
                                        onClick={viewModel.onExport}
                                        variant="contained"
                                        sx={{
                                            height: "42px",
                                            borderColor:
                                                "rgba(34, 136, 102, 0.5)",
                                            color: "#FFFFFF",
                                            backgroundColor:
                                                "rgba(40, 99, 83, 1) !important",
                                            shadows: "rgba(0, 0, 0, 0.12)",
                                        }}
                                    >
                                        Export
                                    </Button>
                                </Stack>
                            </Stack>
                            <DataTable
                                {...{
                                    ...data,
                                    ...rest,
                                }}
                            />
                        </Grid>
                    </Box>
                </div>
            </Container>
        </LayoutComponent>
    );
};

export default MerchantRefundComponent;
