import React from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { exportCSV } from "domain/hooks/exportCSV";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import DetailMerchantDepositUseCase from "domains/interactor/main/disi/DetailMerchantDeposit";
import DisiApi from "datas/disi/DisiApi";
import { detailMerchantDepositHeaderAction } from "utils/store/main/disi/DetailMerchantDepositHeader";
import { detailMerchantDepositBodyListAction } from "utils/store/main/disi/DetailMerchantDepositBody";
import { detailMerchantDepositSaldoAction } from "utils/store/main/disi/DetailMerchantDepositTotalSaldo";
import { showFilterModal } from "utils/store/global/modal";
import { formatDate } from "utils/formatDate";
import useRouterDomState from "utils/helper/useRouterDomState";
import currencyFormatter from "utils/helper/currencyFormatter";
import {
    MerchantDepositState,
    setUserDepositSavedState,
} from "utils/store/main/disi/MerchantDeposit";

const date = new Date();

const initialFilterState = {
    fromDate: date,
    toDate: date,
};

const DetailMerchantDepositViewModel = () => {
    const { t } = useTranslation();

    const location = useLocation();

    const { routerState } = useRouterDomState<{
        merchantNm: string;
        partnerNm: string;
        savedState: MerchantDepositState;
    }>(location, "/Dashboard/disi/merchant-deposit");

    const dispatch = useAppDispatch();

    const detailMerchantDepositHeader = useAppSelector(
        (state) => state.main.disi.detailMerchantDepositHeader
    );

    const detailMerchantDepositBody = useAppSelector(
        (state) => state.main.disi.detailMerchantDepositBody
    );

    const detailMerchantDepositTotalSaldo = useAppSelector(
        (state) => state.main.disi.detailMerchantDepositSaldo
    );

    const { filter: filterModal } = useAppSelector(
        (state) => state.global.modal
    );

    const handleFilterModal = () => {
        dispatch(showFilterModal(!filterModal));
    };

    const [page, setPage] = React.useState(0);

    const [pageSize, setPageSize] = React.useState(6);

    const filterForm = useForm({
        defaultValues: initialFilterState,
    });

    const handleResetForm = () => {
        filterForm.reset(initialFilterState);
    };

    const { getValues } = filterForm;

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "No",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "tanggalTrx",
            headerName:
                t("disi.detail_merchant_deposit.table_date") || "Tanggal",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "description",
            headerName:
                t("disi.detail_merchant_deposit.table_description") ||
                "Deskripsi",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "reff",
            headerName: t("disi.detail_merchant_deposit.table_ref") || "Ref",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "jumlah",
            headerName:
                t("disi.detail_merchant_deposit.table_amount") || "Jumlah",
            flex: 1,
            minWidth: 100,
            renderCell: (row) => currencyFormatter(row.row.jumlah),
        },
        {
            field: "debit",
            headerName:
                t("disi.detail_merchant_deposit.table_debit") || "Debit",
            flex: 1,
            minWidth: 100,
            renderCell: (row) => currencyFormatter(row.row.debit),
        },
        {
            field: "kredit",
            headerName:
                t("disi.detail_merchant_deposit.table_kredit") || "Kredit",
            flex: 1,
            minWidth: 100,
            renderCell: (row) => currencyFormatter(row.row.kredit),
        },
    ];

    const fetchList = () => {
        const fromDate = getValues("fromDate");
        const toDate = getValues("toDate");

        dispatch(
            detailMerchantDepositBodyListAction({
                pageNumber: page + 1,
                pageSize,
                fromDate: formatDate(fromDate),
                toDate: formatDate(toDate),
                merchantNm: routerState.merchantNm,
                partnerNm: routerState.partnerNm,
            })
        );
    };

    const onSubmitFilter = () => {
        fetchList();
        handleFilterModal();
    };

    const fetchHeader = () => {
        dispatch(
            detailMerchantDepositHeaderAction({
                merchantNm: routerState.merchantNm,
                partnerNm: routerState.partnerNm,
            })
        );
    };

    const fetchBalance = () => {
        dispatch(detailMerchantDepositSaldoAction());
    };

    const onExportClick = () => {
        if (routerState.merchantNm) {
            const useCase = new DetailMerchantDepositUseCase(new DisiApi());

            const params = {
                merchantNm: routerState.merchantNm,
                fromDate: formatDate(getValues("fromDate")),
                toDate: formatDate(getValues("toDate")),
            };

            useCase.exportMerchantDepositDetailList(params).then((csv) => {
                exportCSV(csv, "detail-merchant-deposit-list");
            });
        }
    };

    React.useEffect(() => {
        if (routerState.merchantNm && routerState.partnerNm) {
            fetchList();
        }
    }, [page, pageSize, routerState]);

    React.useEffect(() => {
        if (
            routerState.merchantNm &&
            routerState.partnerNm &&
            routerState.savedState.filterForm
        ) {
            fetchHeader();
            fetchBalance();
        }

        if (routerState.savedState) {
            dispatch(setUserDepositSavedState(routerState.savedState));
        }
    }, [routerState]);

    return {
        t,
        detailMerchantDepositHeader,
        detailMerchantDepositBody,
        detailMerchantDepositTotalSaldo,
        page,
        pageSize,
        setPage,
        setPageSize,
        columns,
        filterForm,
        filterModal,
        handleFilterModal,
        onSubmitFilter,
        handleResetForm,
        onExportClick,
    };
};

export default DetailMerchantDepositViewModel;
