import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button, Divider, Stack, FormControl, InputLabel, Select, MenuItem, Modal, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import DateRangeComponent from './DateRangeComponent'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useForm, Controller } from 'react-hook-form'

interface iFilterModal {
    modal: boolean
    closeModal: () => void
    applyButton: any,
    cancelButton: any,
    header: string,
    subHeader: string,
    formData: any
}

const ModalComponent: React.FC<iFilterModal> = ({modal, closeModal, formData, cancelButton, applyButton, header, subHeader}) => {
  const { register,handleSubmit, control } = useForm()
    const { t }:any = useTranslation()
    const style = {
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        bgcolor: "white",
        borderRadius: "20px",
        p: 4,
      };

  return (
    <>
    <Modal open={modal} onClose={closeModal}>
          <Box sx={style} className="ModelFilter">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 600,
              }}
            >
              {header}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                fontSize: "0.5rem",
                lineHeight: "166%",
                color: "#6E6E6E",
                mb: "1rem",
              }}
            >
              {subHeader}
            </Typography>
            <Divider
              variant="middle"
              sx={{
                mt: "0 !important",
                mr: "0 !important",
                ml: "0 !important",
                mb: "1rem !important",
              }}
            />
            {/* {children} */}
            <Stack spacing={2} direction="column">
              {Array.isArray(formData) ? formData.map((data, index) => {
                return (
                  <Box key={index}>
                    <Typography
                      id="modal-modal-description"
                      sx={{
                        fontSize: "0.5rem",
                        lineHeight: "166%",
                        color: "#6E6E6E",
                        mb: "0.5rem",
                      }}
                    >
                      {data.label}
                    </Typography>
                    {data.type === "date" ? (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangeComponent
                        startDate={data.value.startDate}
                        setStartDate={data.value.setStartDate}
                        endDate={data.value.endDate}
                        setEndDate={data.value.setEndDate}
                        />
                      </LocalizationProvider>
                    ) : data.type === "select" ? (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {data.label}
                        </InputLabel>
                        <Controller
                          name={data.dataId}
                          control={control}
                          defaultValue={data.value[0]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={data.label}
                              defaultValue={data.value[0]}
                            >
                              {Array.isArray(data.value) ? data.value.map((data:any, index:any) => {
                                return (
                                  <MenuItem key={index} value={data}>
                                    {data}
                                  </MenuItem>
                                )
                              }): []}
                            </Select>
                          )}
                        />
                      </FormControl>
                    ) : data.type === 'text' ? (
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">
                          {data.label}
                        </InputLabel> */}
                        <TextField
                          id="outlined-basic"
                          label={data.label}
                          variant="outlined"
                          disabled={data.disabled}
                          defaultValue={data.value}
                          {...register(data.dataId, data.required ? { required: true } : { required: false })}
                            />
                      </FormControl>
                    ): ''}
                  </Box>
                )
              }): []}
            </Stack>
            <Stack spacing={2} direction="row">
              <Button
                onClick={cancelButton}
                variant="text"
                sx={{
                  color: "rgba(40, 99, 83, 1)",
                  width: "317.5px",
                }}
              >
                {t("setorku.product.remove_filter_button")}
              </Button>
              <Button
                onClick={handleSubmit(applyButton)}
                type="submit"
                variant="contained"
                sx={{
                  color: "white",
                  background: "rgba(40, 99, 83, 1) !important",
                  width: "317.5px",
                }}
              >
                {t("setorku.product.apply_button")}
              </Button>
            </Stack>
          </Box>
        </Modal>
    </>
  )
}

export default ModalComponent