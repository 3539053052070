import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import {
  collectingAgentAddAction,
  collectingAgentListAction,
} from "utils/store/main/ppob/collectingAgent";
import { useNavigate } from "react-router-dom";
import {
  showAddModal,
  showDeleteModal,
  showEditModal,
  showFilterModal,
} from "utils/store/global/modal";

//modal-filter

const usePPOBCollectingAgentViewModel = () => {
  const navigate = useNavigate();
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      add: {
        partnerId: "",
        description: "",
        secretKey: "",
        status: 1,
      },
    },
  });

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(5);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "No",
      flex: 1,
      minWidth: 50,
    },
    // {
    //   field: "partnerId",
    //   headerName: t("ppob.collecting_agent.id_partner"),
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "description",
      headerName: t("ppob.collecting_agent.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "secretKey",
      headerName: t("ppob.collecting_agent.secret_key"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "isActive",
      headerName: t("ppob.collecting_agent.status"),
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        if (row.row.isActive === "1") {
          return (
            <Typography variant="body2" color="success">
              {t("ppob.collecting_agent.active")}
            </Typography>
          );
        } else {
          return (
            <Typography variant="body2" color="error">
              {t("ppob.collecting_agent.inactive")}
            </Typography>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (row) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => navigateDetail(row.row)}
            >
              {t("ppob.collecting_agent.detail")}
            </Button>
          </Stack>
        );
      },
    },
  ];

  const closeModal = (type: string) => {
    switch (type) {
      case "add":
        dispatch(showAddModal(!modal.add));
        break;
      case "filter":
        dispatch(showFilterModal(!modal.filter));
        break;
      case "edit":
        dispatch(showEditModal(!modal.edit));
        break;
      case "delete":
        dispatch(showDeleteModal(!modal.delete));
        break;
      default:
        break;
    }
  };

  const navigateDetail = async (data: any) => {
    navigate(`${data.partnerId}`);
  };

  const submitAdd = async (data: any) => {
    closeModal("add");
    await dispatch(
      collectingAgentAddAction({
        partnerId: data.add.partnerId,
        partnerName: data.add.partnerName,
        secretKey: data.add.secretKey,
        status: data.add.status,
      })
    );
    reset();
    fetchCollectingAgentList();
  };

  const fetchCollectingAgentList = async () => {
    await dispatch(
      collectingAgentListAction({
        filterText: "",
        pageNumber: page,
        pageSize: pageSize,
        partnerId: "",
      })
    );
  };

  // const fetchCombobox = async () => {
  //   await dispatch(comboboxPartnerAction())
  //   await dispatch(comboboxPaymentMethodAction())
  // }

  React.useEffect(() => {
    fetchCollectingAgentList();
    // fetchCombobox()
  }, [page, pageSize]);

  return {
    data: selector,
    modal,
    closeModal,
    page,
    setPage,
    pageSize,
    setPageSize,
    columns,
    t,
    handleSubmit,
    control,
    submitAdd,
  };
};

export default usePPOBCollectingAgentViewModel;
