import httpCommon from "datas/httpCommon";
import { ExportTransactionListParams, TransactionListParams, TransactionListResult, TransactionRefundParams, TransactionRefundResult } from "domains/entity/main/merchantOnBoarding/transaction";
import { ExportTransactionRefundParams, TransactionRefundListParams, TransactionRefundListResult } from "domains/entity/main/merchantOnBoarding/transactionRefund";
import { MerchantOnBoardingRepository } from "domains/repository/main/merchantOnBoarding/MerchantOnBoardingRepository";

export class MerchantOnBoardingAPI implements MerchantOnBoardingRepository {
    async getMerchantOnBoardingTransactionList(params: TransactionListParams): Promise<TransactionListResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            pageSize: params.pageSize.toString(),
            pageNumber: params.pageNumber.toString()
        });
        return await httpCommon.get(`/merchant/transaksi?${data}`).then((res) => res.data);
    }

    async merchantOnBoardingTransactionRefund(params: TransactionRefundParams): Promise<TransactionRefundResult> {
        return await httpCommon.post(`/merchant/refund`, params).then((res) => res.data);
    }

    async exportMerchantOnBoardingTransactionList(params: ExportTransactionListParams): Promise<any> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate
        });
        return await httpCommon.get(`/merchant/export?${data}`).then((res) => res.data);
    }

    async getMerchantOnBoardingTransactionRefundList(params: TransactionRefundListParams): Promise<TransactionRefundListResult> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate,
            pageSize: params.pageSize.toString(),
            pageNumber: params.pageNumber.toString()
        });
        return await httpCommon.get(`/merchant/refund?${data}`).then((res) => res.data);
    }

    async exportMerchantOnBoardingTransactionRefundList(params: ExportTransactionRefundParams): Promise<any> {
        const data = new URLSearchParams({
            fromDate: params.fromDate,
            toDate: params.toDate
        });
        return await httpCommon.get(`/merchant/refund/export?${data}`).then((res) => res.data);
    }
}