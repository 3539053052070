import React from "react";
import {
    TextField,
    MenuItem,
    Card,
    CardContent,
    CardActions,
    Typography,
    Modal,
    Button,
    Divider,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import { formatDate } from "utils/formatDate";

type RequestFormProps = {
    isOpen?: boolean;
    handleClose?: () => void;
    handleReset?: () => void;
    onSubmit?: (...input: any[]) => void;
};

const FilterForm: React.FC<RequestFormProps> = ({
    isOpen = false,
    handleClose = () => {},
    handleReset = () => {},
    onSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, setValue } = useFormContext();

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        borderRadius: "20px",
                        width: "90%",
                        maxWidth: 640,
                        maxHeight: 640,
                    }}
                >
                    <CardContent
                        sx={{
                            "@media screen and (max-height: 40rem)": {
                                maxHeight: 440,
                            },
                            maxHeight: 600,
                            overflow: "auto",
                            p: 4,
                        }}
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                fontWeight: 600,
                            }}
                        >
                            Filter
                        </Typography>
                        <Typography
                            id="modal-modal-description"
                            sx={{
                                fontSize: "0.5rem",
                                lineHeight: "166%",
                                color: "#6E6E6E",
                                mb: "1rem",
                            }}
                        >
                            User Deposit
                        </Typography>
                        <Divider
                            variant="middle"
                            sx={{
                                mt: "0 !important",
                                mr: "0 !important",
                                ml: "0 !important",
                                mb: "1rem !important",
                            }}
                        />
                        <Stack>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Controller
                                        name="fromDate"
                                        control={control}
                                        render={({
                                            field: { value, name },
                                        }) => (
                                            <DesktopDatePicker
                                                label={t(
                                                    "disi.partner_deposit.filter_start_date"
                                                )}
                                                inputFormat="DD-MM-YYYY"
                                                disableFuture
                                                value={
                                                    value === "" ? null : value
                                                }
                                                onChange={(val) => {
                                                    const date =
                                                        dayjs(val).toDate();
                                                    setValue(name, date);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        onChange={(e) =>
                                                            e.target.blur()
                                                        }
                                                        onKeyDown={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <MinimizeOutlinedIcon
                                        sx={{
                                            color: "rgba(0, 0, 0, 0.54)",
                                            m: "1rem",
                                        }}
                                    />
                                    <Controller
                                        name="toDate"
                                        control={control}
                                        render={({
                                            field: { value, name },
                                        }) => (
                                            <DesktopDatePicker
                                                label={t(
                                                    "disi.partner_deposit.filter_end_date"
                                                )}
                                                mask=""
                                                inputFormat="DD-MM-YYYY"
                                                disableFuture
                                                value={
                                                    value === "" ? null : value
                                                }
                                                onChange={(val) => {
                                                    const date =
                                                        dayjs(val).toDate();
                                                    setValue(name, date);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        onChange={(e) =>
                                                            e.target.blur()
                                                        }
                                                        onKeyDown={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Stack>
                    </CardContent>
                    <CardActions
                        disableSpacing={true}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        <Button onClick={handleReset} variant="text">
                            {t("disi.partner_deposit.filter_cancel")}
                        </Button>
                        <Button type="submit" variant="contained">
                            {t("disi.partner_deposit.filter_submit")}
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </Modal>
    );
};

export default FilterForm;
