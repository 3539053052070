import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateRangeComponent from 'presentation/components/DateRangeComponent';
import ModalContainer from 'presentation/components/ModalContainer';
import React from 'react';

interface Props {
    header: string;
    subHeader: string;
    open: boolean;
    onClose: () => void;
    applyButton: () => void;
    cancelButton: () => void;
    startDate: Date;
    endDate: Date;
    setStartDate: any
    setEndDate: any
} 

const FilterModal = ({ header, subHeader, open, onClose, applyButton, cancelButton, startDate, endDate, setStartDate, setEndDate }: Props) => {
    return (
        <ModalContainer
            header={header}
            subHeader={subHeader}
            open={open}
            onClose={onClose}
            applyButton={applyButton}
            cancelButton={cancelButton}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                spacing={3}
                direction="row"
                className="DateModal"
                sx={{
                  alignItems: "center",
                  mb: "1rem",
                }}
              >
                <DateRangeComponent
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  sx={{ my: "1rem" }}
                />
              </Stack>
            </LocalizationProvider>
        </ModalContainer>
    );
}

export default FilterModal;