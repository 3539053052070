/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, SxProps, TextField } from "@mui/material";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";

type TimeRangePickerComponentProps = {
    timeStart?: string;
    timeEnd?: string;
    setTimeStart?: React.Dispatch<React.SetStateAction<string>>;
    setTimeEnd?: React.Dispatch<React.SetStateAction<string>>;
    sx?: SxProps;
};

const TimeRangePickerComponent: React.FC<TimeRangePickerComponentProps> = (
    props
) => {
    const { t } = useTranslation();

    const {
        sx,
        timeStart = "00:00",
        timeEnd = "00:00",
        setTimeStart = () => {},
        setTimeEnd = () => {},
    } = props;

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...sx,
            }}
        >
            <TextField
                type="time"
                fullWidth
                label={t("time.start")}
                value={timeStart}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setTimeStart(e.target.value)}
                onKeyDown={(e) => e.preventDefault()}
                helperText="Optional"
            />

            <MinimizeOutlinedIcon
                sx={{
                    color: "rgba(0, 0, 0, 0.54)",
                    m: "1rem",
                }}
            />

            <TextField
                type="time"
                fullWidth
                label={t("time.end")}
                value={timeEnd}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setTimeEnd(e.target.value)}
                onKeyDown={(e) => e.preventDefault()}
            />
        </Box>
    );
};

export default TimeRangePickerComponent;
