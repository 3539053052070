import {
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    Container,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Modal,
    Divider,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
interface DataTableProps<T> {
    data: T[];
    totalRows: number;
    columns: GridColDef[];
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    pageSize: number;
    loading?: boolean;
}
export default function DataTable<T>({
    data,
    totalRows,
    columns,
    setPage,
    setPageSize,
    page,
    pageSize,
    loading = false,
}: DataTableProps<T>) {
    const { t } = useTranslation();
    const style = {
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        bgcolor: "white",
        borderRadius: "20px",
        p: 4,
    };

    return (
        <Box
            sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgba(34, 136, 102, 0.12)",
                    fontWeight: 500
                },
            }}
        >
            <DataGrid
                className="scrollTable"
                rows={data.map((item, index) => ({ ...item, id: index + 1 }))}
                columns={columns}
                paginationMode="server"
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                    console.log("newPageSize", newPageSize);
                }}
                rowsPerPageOptions={[6, 10, 20]}
                onPageChange={(pages) => {
                    console.log("ini page", pages);
                    setPage(pages);
                }}
                loading={loading}
                page={page}
                rowCount={totalRows}
                sx={{
                    border: "none",
                    height: 432,
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        fontSize: "12px",
                        fontWeight: 400,
                    },
                    "& .MuiDataGrid-columnHeadersInner": {
                        fontSize: "14px",
                        fontWeight: 500,
                    },
                    "& ::-webkit-scrollbar": {
                        width: "2px",
                        height: "8px",
                    },
                    "& ::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                        borderRadius: "50px",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "50px",
                    },
                    "& ::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
            />
        </Box>
    );
}
