import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Modal,
  Divider,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import shadows from "@mui/material/styles/shadows";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DaftarKodeUseCase from "domain/interactor/main/setorku/DaftarKodeUseCase";
import PartnerUseCase from "domain/interactor/main/setorku/PartnerUseCase";
import { width } from "@mui/system";
// import "../../setorku/_setorku.scss";
import DataTable from "presentation/components/DataTable";
import LayoutComponent from "presentation/layout/Layout";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DateRangeComponent from "presentation/components/DateRangeComponent";
import useProdukViewModel from "./MultiFinanceUploadViewModel";
import useMultifinanceUploadViewModel from "./MultiFinanceUploadViewModel";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "white",
  borderRadius: "20px",
  p: 4,
};

export const MultifinanceUploadComponent = () => {
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    partnerId,
    columns,
    modalAddProduct,
    setModalAddProduct,
    modalFilter,
    setModalFilter,
    modalEditProduct,
    setModalEditProduct,
    modalDeleteProduct,
    setModalDeleteProduct,
    t,
  } = useMultifinanceUploadViewModel();

  return (
    <LayoutComponent>
      <Container
        maxWidth={false}
        sx={{ height: "100vh", backgroundColor: { xs: "#fff", md: "#f4f4f4" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "2rem",
          }}
        >
          <Box
            sx={{
              width: "95%",
              backgroundColor: "white",
              boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(34, 136, 102, 0.12)",
              },
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: { sm: "0 0 5px #ddd" },
                padding: "1rem",
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                className="headerSetorku"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className="headerName"
                  variant="h6"
                  component="h1"
                  sx={{
                    mb: "1.5rem",
                    fontWeight: 600,
                  }}
                >
                  {t("setorku.product.payment_code_list_header")}
                </Typography>

                <Stack spacing={2} direction="row" className="headerButton">
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "42px",
                        color: "rgba(34, 136, 102, 0.5)",
                        borderColor: "rgba(34, 136, 102, 0.5) !important",
                      }}
                      onClick={() => setModalFilter(true)}
                    >
                      {t("setorku.product.filter_button")}
                    </Button>
                  </div>
                  <Button
                    // onClick={onExpor}
                    variant="contained"
                    sx={{
                      height: "42px",
                      borderColor: "rgba(34, 136, 102, 0.5)",
                      color: "#FFFFFF",
                      backgroundColor: "rgba(40, 99, 83, 1) !important",
                      shadows: "rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {t("setorku.product.add_button")}
                  </Button>
                </Stack>
              </Stack>

                <DataTable
                  {...{
                    data: [] ,
                    totalRows: 1,
                    columns,
                    setPage,
                    setPageSize,
                    page,
                    pageSize,
                  }}
                />
            </Grid>
          </Box>
        </div>

        <Modal open={modalFilter} onClose={() => setModalFilter(false)}>
          <Box sx={style} className="ModelFilter">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 600,
              }}
            >
              {t("setorku.product.filter_header")}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                fontSize: "0.5rem",
                lineHeight: "166%",
                color: "#6E6E6E",
                mb: "1rem",
              }}
            >
              {t("setorku.product.filter_subheader")}
            </Typography>
            <Divider
              variant="middle"
              sx={{
                mt: "0 !important",
                mr: "0 !important",
                ml: "0 !important",
                mb: "1rem !important",
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                spacing={3}
                direction="row"
                className="DateModal"
                sx={{
                  alignItems: "center",
                  mb: "1rem",
                }}
              >
                <DateRangeComponent
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartdate}
                  setEndDate={setEndate}
                  sx={{ my: "1rem" }}
                />
              </Stack>
            </LocalizationProvider>
            <FormControl fullWidth sx={{ mb: "1rem" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ background: "white" }}
              >
                {t("setorku.product.id_product")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={partnerId}
                label={t("setorku.product.id_product")}
                // onChange={handleChangePartner}
              >
                <MenuItem value="ALL">ALL</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: "1rem" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ background: "white" }}
              >
                {t("setorku.product.id_company")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={partnerId}
                label={t("setorku.product.id_company")}
                // onChange={handleChangePartner}
              >
                <MenuItem value="ALL">ALL</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: "1rem" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ background: "white" }}
              >
                {t("setorku.product.company_code")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={partnerId}
                label={t("setorku.product.company_code")}
                // onChange={handleChangePartner}
              >
                <MenuItem value="ALL">ALL</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: "1rem" }}>
              <TextField variant="outlined" label={t("setorku.product.company_name")}/>
            </FormControl>
            <FormControl fullWidth sx={{ mb: "1rem" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ background: "white" }}
              >
                {t("setorku.product.prefix")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={partnerId}
                label={t("setorku.product.prefix")}
                // onChange={handleChangePartner}
              >
                <MenuItem value="ALL">ALL</MenuItem>
              </Select>
            </FormControl>
            <Stack spacing={2} direction="row">
              <Button
                // onClick={clearFilter}
                variant="text"
                sx={{
                  color: "rgba(40, 99, 83, 1)",
                  width: "317.5px",
                }}
              >
                {t("setorku.product.remove_filter_button")}
              </Button>
              <Button
                // onClick={onSubmit}
                variant="contained"
                sx={{
                  color: "white",
                  background: "rgba(40, 99, 83, 1) !important",
                  width: "317.5px",
                }}
              >
                {t("setorku.product.apply_button")}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </LayoutComponent>
  );
};

export default MultifinanceUploadComponent;
