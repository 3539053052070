import { useEffect, useState } from 'react';
import { getUser } from 'utils/storage/LocalStorage';

interface Role {
    isExport: boolean;
    isAdd: boolean;
    isEdit: boolean;
    isDelete: boolean;
    isFilter: boolean;
}

const useRole = (routeName: string): Role => {
    const [role, setRole] = useState<Role>({
        isExport: false,
        isAdd: false,
        isEdit: false,
        isDelete: false,
        isFilter: false,
    });

    useEffect(() => {
        const roleData = JSON.parse(getUser() || '{}');
        if (roleData) {
            roleData?.parentMenuList.forEach((parent:any) => {
                parent.menuList.forEach((menu:any) => {
                    if (menu.routeName === routeName) {
                        setRole({
                            isExport: menu.isExport === '1',
                            isAdd: menu.isAdd === '1',
                            isEdit: menu.isEdit === '1',
                            isDelete: menu.isDelete === '1',
                            isFilter: menu.isFilter === '1',
                        });
                    }
                });
            });
        }
    }, [routeName]);

    return role;
};

export default useRole;
