import React from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { partnerDepositAction } from "utils/store/main/disi/PartnerDeposit";
import { partnerDepositGetPartnerAction } from "utils/store/main/disi/PartnerDepositPartner";
import { partnerDepositGetStatusAction } from "utils/store/main/disi/PartnerDepositStatus";
import { partnerDepositGetTypeAction } from "utils/store/main/disi/PartnerDepositType";
import { partnerDepositWalletAction } from "utils/store/main/disi/PartnerDepositWallet";
import { resetPartnerDepositWithdraw } from "utils/store/main/disi/PartnerDepositWithdraw";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { getUser } from "utils/storage/LocalStorage";
import { formatDate } from "utils/formatDate";
import currencyFormatter from "utils/helper/currencyFormatter";
import PartnerDepositUseCase from "domains/interactor/main/disi/PartnerDeposit";
import DisiApi from "datas/disi/DisiApi";
import { ExportPartnerdepositListParams } from "domains/entity/main/disi";
import { exportCSV } from "domain/hooks/exportCSV";
import useSnackbar from "domain/hooks/useSnackbar";

const date = new Date();

const initialFormState = {
    toDate: date,
    fromDate: date,
    trxId: "",
    tipeTrx: "",
    statusTrx: "",
};

const initialWithdrawFormState = {
    codeOTP: "",
    amount: "",
};

const PartnerDepositViewModel = () => {
    const username = React.useMemo(() => {
        const user = JSON.parse(getUser() || "{}");
        const _username = user.username ? user.username : "";
        return _username;
    }, []);

    const location = useLocation();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { partnerDeposit, partnerDepositWallet, partnerDepositWithdraw } =
        useAppSelector((state) => state.main.disi);

    const [page, setPage] = React.useState(0);

    const [pageSize, setPageSize] = React.useState(6);

    const [filterModal, setFilterModal] = React.useState(false);

    const [rechargeModal, setRechargeModal] = React.useState(false);

    const [withdrawModal, setWithdrawModal] = React.useState(false);

    const handleWithdrawModalOpen = () => setWithdrawModal(true);

    const handleWithdrawModalClose = () => setWithdrawModal(false);

    const handleRechargeModalOpen = () => setRechargeModal(true);

    const handleRechargeModalclose = () => setRechargeModal(false);

    const handleFilterModalOpen = () => setFilterModal(true);

    const handleFilterModalClose = () => setFilterModal(false);

    const handleFilterReset = () => {
        filterForm.reset(initialFormState);
    };

    const filterForm = useForm({
        defaultValues: initialFormState,
    });

    const withdrawForm = useForm({
        defaultValues: initialWithdrawFormState,
    });

    const { getValues } = filterForm;

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "no",
            flex: 1,
            maxWidth: 100,
        },
        {
            field: "dtTrx",
            headerName:
                t("disi.partner_deposit.table_date_time") ?? "Tanggal & Waktu",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "trxId",
            headerName:
                t("disi.partner_deposit.table_transaction_id") ??
                "ID Transaksi",
            flex: 1,
            minWidth: 200,
        },
        // {
        //     field: "merchantId",
        //     headerName:
        //         t("disi.partner_deposit.table_merchant_id") ?? "ID Merchant",
        //     flex: 1,
        //     minWidth: 200,
        // },
        {
            field: "amount",
            headerName:
                t("disi.partner_deposit.table_transfer_amount") ??
                "Jumlah Transfer",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => currencyFormatter(row.row.amount),
        },
        {
            field: "admin",
            headerName:
                t("disi.partner_deposit.table_admin_fee") ?? "Biaya Admin",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => currencyFormatter(row.row.admin),
        },
        {
            field: "pajak",
            headerName: t("disi.partner_deposit.table_tax_fee") ?? "Pajak",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => currencyFormatter(row.row.pajak),
        },
        {
            field: "beforeAmount",
            headerName:
                t("disi.partner_deposit.table_before_balance") ??
                "Saldo Sebelum",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => currencyFormatter(row.row.beforeAmount),
        },
        {
            field: "afterAmount",
            headerName:
                t("disi.partner_deposit.table_after_balance") ??
                "Saldo Sesudah",
            flex: 1,
            minWidth: 200,
            renderCell: (row) => currencyFormatter(row.row.afterAmount),
        },
        {
            field: "description",
            headerName:
                t("disi.partner_deposit.table_description") ??
                "Catatan Transaksi",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "tipeTrx",
            headerName:
                t("disi.partner_deposit.table_transaction_type") ??
                "Tipe Transaksi",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "statusTrx",
            headerName: t("disi.partner_deposit.table_status") ?? "Status",
            flex: 1,
            minWidth: 200,
        },
    ];

    const fetchWallet = () => {
        dispatch(partnerDepositWalletAction());
    };

    const fetchConstants = () => {
        dispatch(partnerDepositGetStatusAction());
        dispatch(partnerDepositGetTypeAction());
        dispatch(partnerDepositGetPartnerAction());
    };

    const fetchList = () => {
        const fromDate = getValues("fromDate");
        const toDate = getValues("toDate");
        const statusTrx = getValues("statusTrx");
        const tipeTrx = getValues("tipeTrx");
        const trxId = getValues("trxId");

        dispatch(
            partnerDepositAction({
                fromDate: formatDate(fromDate),
                toDate: formatDate(toDate),
                pageNumber: page + 1,
                pageSize: pageSize,
                partnerId: username,
                statusTrx,
                tipeTrx,
                trxId,
            })
        );
    };

    const onFilterSubmit = () => {
        fetchList();
        setFilterModal(false);
    };

    const onExportClick = () => {
        const useCase = new PartnerDepositUseCase(new DisiApi());

        const fromDate = getValues("fromDate");
        const toDate = getValues("toDate");
        const statusTrx = getValues("statusTrx");
        const tipeTrx = getValues("tipeTrx");
        const trxId = getValues("trxId");

        const params: ExportPartnerdepositListParams = {
            fromDate: formatDate(fromDate),
            toDate: formatDate(toDate),
            statusTrx,
            tipeTrx,
            trxId,
        };

        useCase.exportPartnerDepositList(params).then((csv) => {
            exportCSV(csv, "partner-deposit-list");
        });
    };

    React.useEffect(() => {
        fetchWallet();
        fetchConstants();
    }, []);

    React.useEffect(() => {
        fetchList();
    }, [page, pageSize]);

    React.useEffect(() => {
        if (location.state) {
            handleRechargeModalOpen();
        }
    }, [location]);

    const {
        result: withdrawResult,
        loading: withdrawLoading,
        error: withdrawError,
    } = partnerDepositWithdraw;

    useSnackbar(
        {
            error: withdrawError,
            loading: withdrawLoading,
            result: withdrawResult,
        },
        resetPartnerDepositWithdraw
    );

    return {
        t,
        partnerDeposit,
        partnerDepositWallet,
        columns,
        page,
        setPage,
        pageSize,
        setPageSize,
        onFilterSubmit,
        filterForm,
        filterModal,
        handleFilterModalClose,
        handleFilterModalOpen,
        handleFilterReset,
        rechargeModal,
        handleRechargeModalOpen,
        handleRechargeModalclose,
        withdrawForm,
        withdrawModal,
        handleWithdrawModalClose,
        handleWithdrawModalOpen,
        onExportClick,
    };
};

export default PartnerDepositViewModel;
