import { SetorkuRepository } from "domain/repository/main/SetorkuRepository";

export default class SetorkuPartnerUseCase {
    constructor(private repository: SetorkuRepository) {}

    async getPartner(
        fromDate: string,
        toDate: string
    ) {
        return await this.repository.getSetorkuPartnerList(
            fromDate,
            toDate
        );
    }

    async addPartner(
        partnerId: string,
        partnerName: string,
        isActive: string
    ) {
        return await this.repository.addSetorkuPartner(
            partnerId,
            partnerName,
            isActive,
        );
    }

    async editPartner(
        partnerId: string,
        partnerName: string,
        isActive: string
    ) {
        return await this.repository.updateSetorkuPartner(
            partnerId,
            partnerName,
            isActive,
        );
    }

    async deletePartner(
        partnerId: string
    ) {
        return await this.repository.deleteSetorkuPartner(
            partnerId
        );
    }
}