import React from "react";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { closeSnackBar } from "utils/store/snackbar";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const SnackBarComponent = () => {
    const dispatch = useAppDispatch();

    const snackbar = useAppSelector((state) => state.snackbar);

    const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") return;
        dispatch(closeSnackBar());
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleAutoHide = () => {
        if (snackbar.severity === "success") return 1000;
        return 3000;
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackbar.open}
            autoHideDuration={handleAutoHide()}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={snackbar.severity}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackBarComponent;
