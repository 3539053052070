import React from "react";
import {
    Stack,
    Box,
    Card,
    CardContent,
    Container,
    Tooltip,
    Typography,
    Button,
    CircularProgress,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LayoutComponent from "presentation/layout/Layout";
import PettyCashViewModel from "./PettyCashViewModel";
import DataTable from "presentation/components/DataTable";
import { FormProvider } from "react-hook-form";
import RequestForm from "./components/RequestForm";
import FilterForm from "./components/FilterForm";
import currencyFormatter from "utils/helper/currencyFormatter";

const DisiPettyCashComponent = () => {
    const viewModel = PettyCashViewModel();

    const {
        t,
        columns,
        page,
        pageSize,
        setPage,
        setPageSize,
        requestForm,
        onRequestSubmit,
        filterForm,
        onFilterSubmit,
        role,
        pettyCashHeader,
        pettyCashBody,
        modal,
        handleModal,
        resetFilterForm,
        onExportClick,
    } = viewModel;

    return (
        <LayoutComponent>
            <Container
                maxWidth={false}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "100vh",
                    backgroundColor: { xs: "#fff", md: "#f4f4f4" },
                }}
            >
                <Box
                    display="grid"
                    sx={{
                        pt: "2rem",
                        width: "95%",
                        gap: {
                            lg: "3rem 2rem",
                            xs: "1rem",
                        },
                        gridTemplateColumns: {
                            lg: "1fr 1fr",
                            xs: "1fr",
                        },
                        gridTemplateRows: {
                            lg: "10rem min-content",
                            xs: "10rem 10rem min-content",
                        },
                        gridTemplateAreas: {
                            lg: `
                                "Saldo AccountabilitySaldo"
                                "DataTable DataTable";
                            `,
                            xs: `
                                "Saldo"
                                "AccountabilitySaldo"
                                "DataTable"
                            `,
                        },
                    }}
                >
                    <Card
                        sx={{
                            backgroundColor: "white",
                            boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            borderRadius: "8px",
                            display: "grid",
                            gridArea: "Saldo",
                        }}
                    >
                        <CardContent sx={{ pb: 0 }}>
                            <Stack
                                sx={{
                                    p: "1rem",
                                    minHeight: "100%",
                                    justifyContent: "space-around",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: ".2rem",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "1.25rem",
                                            fontWeight: 500,
                                        }}
                                    >
                                        {t("disi.petty_cash.saldo")}
                                    </Typography>
                                    <Tooltip title={t("disi.petty_cash.saldo")}>
                                        <InfoRoundedIcon />
                                    </Tooltip>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "2.125rem",
                                    }}
                                >
                                    {pettyCashHeader.loading ? (
                                        <CircularProgress />
                                    ) : (
                                        currencyFormatter(
                                            pettyCashHeader.data.saldo
                                        )
                                    )}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            backgroundColor: "white",
                            boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                            borderRadius: "8px",
                            display: "grid",
                            gridArea: "AccountabilitySaldo",
                        }}
                    >
                        <CardContent sx={{ pb: 0 }}>
                            <Stack
                                sx={{
                                    p: "1rem",
                                    minHeight: "100%",
                                    justifyContent: "space-around",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        aLignItems: "center",
                                        gap: ".2rem",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "1.25rem",
                                            fontWeight: 500,
                                        }}
                                    >
                                        {t(
                                            "disi.petty_cash.saldo_accountability"
                                        )}
                                    </Typography>
                                    <Tooltip
                                        title={t(
                                            "dis.petty_cash.saldo_accountability"
                                        )}
                                    >
                                        <InfoRoundedIcon />
                                    </Tooltip>
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "2.125rem",
                                    }}
                                >
                                    {pettyCashHeader.loading ? (
                                        <CircularProgress />
                                    ) : (
                                        currencyFormatter(
                                            pettyCashHeader.data
                                                .saldoPertanggungJawab
                                        )
                                    )}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Box sx={{ gridArea: "DataTable", background: "white" }}>
                        <Box sx={{ p: 4 }}>
                            <Stack
                                spacing={2}
                                direction="row"
                                sx={{
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    className="headerName"
                                    variant="h6"
                                    component="h1"
                                    sx={{
                                        mb: "1.5rem",
                                        fontWeight: 600,
                                    }}
                                >
                                    {t("disi.petty_cash.header_trans")}
                                </Typography>
                                <Box sx={{ display: "flex", gap: ".5rem" }}>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: "42px",
                                            color: "rgba(34, 136, 102, 0.5)",
                                            borderColor:
                                                "rgba(34, 136, 102, 0.5) !important",
                                        }}
                                        onClick={() => handleModal("filter")}
                                    >
                                        Filter
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: "42px",
                                            borderColor:
                                                "rgba(34, 136, 102, 0.5)",
                                            color: "#FFFFFF",
                                            backgroundColor:
                                                "rgba(40, 99, 83, 1) !important",
                                            shadows: "rgba(0, 0, 0, 0.12)",
                                        }}
                                        onClick={onExportClick}
                                    >
                                        Export
                                    </Button>
                                    {Boolean(role.isAdd) && (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                height: "42px",
                                                borderColor:
                                                    "rgba(34, 136, 102, 0.5)",
                                                color: "#FFFFFF",
                                                backgroundColor:
                                                    "rgba(40, 99, 83, 1) !important",
                                                shadows: "rgba(0, 0, 0, 0.12)",
                                            }}
                                            onClick={() => handleModal("add")}
                                        >
                                            Request
                                        </Button>
                                    )}
                                </Box>
                            </Stack>
                            <DataTable
                                {...{
                                    data: pettyCashBody.data.data,
                                    totalRows: pettyCashBody.data.totalRows,
                                    columns,
                                    setPage,
                                    setPageSize,
                                    page,
                                    pageSize,
                                    loading: pettyCashBody.loading,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <FormProvider {...requestForm}>
                    <RequestForm
                        isOpen={modal.add}
                        handleClose={() => handleModal("add")}
                        onSubmit={onRequestSubmit}
                    />
                </FormProvider>
                <FormProvider {...filterForm}>
                    <FilterForm
                        isOpen={modal.filter}
                        handleClose={() => handleModal("filter")}
                        handleReset={resetFilterForm}
                        onSubmit={onFilterSubmit}
                    />
                </FormProvider>
            </Container>
        </LayoutComponent>
    );
};

export default DisiPettyCashComponent;
