import { AlertColor } from "@mui/lab";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type IState = {
    open: boolean;
    message: string;
    severity: AlertColor
}

type ReducerAction = Omit<IState, 'open'>

const initialState: IState = {
    open: false,
    message: "",
    severity: "info"
}

const slice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        openSnackBar: (state, action: PayloadAction<ReducerAction>) => {
            const { payload } = action
            return { ...state, open: true, ...payload }
        },
        closeSnackBar: (state) => {
            return { ...state, open: false, message: "" }
        }
    }
})

const snackBarReducer = slice.reducer

export const { openSnackBar, closeSnackBar } = slice.actions

export default snackBarReducer