import httpCommon from "datas/httpCommon";
import MainRepository from "../../domain/repository/main/MainRepository";
import SetorkuRekonResult from "../../domain/entity/main/structure/SetorkuRekonResult";
import SetorkuTransaksiResult from "../../domain/entity/main/structure/SetorkuTransaksiResult";
import SetorkuPartnerResult from "../../domain/entity/main/structure/SetorkuPartnerResult";
import SetorkuProductResult from "../../domain/entity/main/structure/SetorkuProductResult";
import SetorkuDaftarKodeResult from "../../domain/entity/main/structure/SetorkuDaftarKodeResult";
import PPOBTransaksiResult from "../../domain/entity/main/structure/PPOBTransaksiResult";
import PPOBPartnerResult from "../../domain/entity/main/structure/PPOBPartnerResult";
import OtotransTransaksiResult from "../../domain/entity/main/structure/OtotransTransaksiResult";
import OtotransPartnerResult from "../../domain/entity/main/structure/OtotransPartnerResult";
import SolusiPayTransaksiResult from "../../domain/entity/main/structure/SolusiPayTransaksiResult";
import SolusiPayPartnerResult from "../../domain/entity/main/structure/SolusiPayPartnerResult";
import UserAksesResult from "../../domain/entity/main/structure/UserAksesResult";
import UserAksesDetailResult from "../../domain/entity/main/structure/UserAksesDetailResult";
import UserAksesProdukListResult from "../../domain/entity/main/structure/UserAksesProdukListResult";
import ManajemenPenggunaResult from "../../domain/entity/main/structure/ManajemenPenggunaResult";
import MerchantTransaksiResult from "../../domain/entity/main/structure/MerchantTransaksiResult";

import { AccountActivationStepOneForm } from "../../domain/entity/main/structure/AccountActivationStepOneResult";
import { AccountActivationStepTwoForm } from "../../domain/entity/main/structure/AccountActivatoinStepTwoResult";
import { AccountActivationStepThreeForm } from "../../domain/entity/main/structure/AccountActivationStepThreeResult";
import { AccountActivationStepFourForm } from "../../domain/entity/main/structure/AccountActivationStepFourResult";
import { AccountActivationStepFiveForm } from "../../domain/entity/main/structure/AccountActivationStepFiveResult";
import AddressResult from "../../domain/entity/main/structure/AddressResult";
import LookupResult from "../../domain/entity/main/structure/LookupResult";
import MerchantRefundResult from "../../domain/entity/main/structure/MerchantRefundResult";

import { getToken, getUser } from "utils/storage/LocalStorage"

let user = getUser()

if (user !== null) {
    user = JSON.parse(user)
}

const headers = {
    Authorization: `Bearer ${getToken()}`,
    username: user !== null ? (user as any).username : null
}

export default class MainApi implements MainRepository {
    async getSetorkuRekonList(
        fromDate: string,
        toDate: string,
        caId: string,
        pageSize: number,
        pageNumber: number,
        kodePembayaran: string,
        billerId: string
    ): Promise<SetorkuRekonResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            caId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
            kodePembayaran,
            billerId,
        });
        return await httpCommon.get<SetorkuRekonResult>(
            `/setorku/recon?${params}`
        ).then((res) => res.data);
    }

    async getSetorkuTransaksiList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number
    ): Promise<SetorkuTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<SetorkuTransaksiResult>(
            `/setorku/transaction?${params}`
        ).then((res) => res.data);
    }

    async getSetorkuDaftarKodeList(
        fromDate: string,
        toDate: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number
    ): Promise<SetorkuDaftarKodeResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<SetorkuDaftarKodeResult>(
            `/setor/list?${params}`
        ).then((res) => res.data);
    }

    async getSetorkuPartnerList(): Promise<SetorkuPartnerResult[]> {
        return await httpCommon.get<SetorkuPartnerResult[]>(
            `/global/partner/setorku?`
        ).then((res) => res.data);
    }

    async getSetorkuProductList(): Promise<SetorkuProductResult> {
        return await httpCommon.get<SetorkuProductResult>(
            `/setorku/product?`
        ).then((res) => res.data);
    }

    async exportSetorkuRekonList(
        fromDate: string,
        toDate: string,
        caId: string,
        kodePembayaran: string,
        billerId: string
    ): Promise<void> {
        const a = document.createElement("a");
        const params = new URLSearchParams({
            toDate,
            fromDate,
            caId,
            kodePembayaran,
            billerId,
        });
        a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/setorku/recon/export?${params}`;
        a.click();
    }

    async exportSetorkuTransaksiList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerId: string
    ): Promise<void> {
        const a = document.createElement("a");
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerId,
        });
        a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/setorku/transaction/export?${params}`;
        a.click();
    }

    async exportSetorkuDaftarKodeList(
        fromDate: string,
        toDate: string,
        partnerId: string
    ): Promise<void> {
        const a = document.createElement("a");
        const params = new URLSearchParams({
            toDate,
            fromDate,
            partnerId,
        });
        a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/setor/export?${params}`;
        a.click();
    }

    async getPPOBTransaksiList(
        fromDate: string,
        toDate: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number
    ): Promise<PPOBTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<PPOBTransaksiResult>(
            `/ppob/transaction?${params}`
        ).then((res) => res.data);
    }
    async getPPOBPartnerList(): Promise<PPOBPartnerResult[]> {
        return await httpCommon.get<PPOBPartnerResult[]>(
            `/global/partner/ppob?`
        ).then((res) => res.data);
    }

    async exportPPOBTransaksiList(
        fromDate: string,
        toDate: string,
        partnerId: string
    ): Promise<void> {
        const a = document.createElement("a");
        const params = new URLSearchParams({
            toDate,
            fromDate,
            partnerId,
        });
        a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/ppob/transaction/export?${params}`;
        a.click();
    }

    async getOtotransTransaksiList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number,
        sortBy: string,
        dateType: string
    ): Promise<OtotransTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
            sortBy,
            dateType,
        });
        return await httpCommon.get<OtotransTransaksiResult>(
            `/ototrans/transaction?${params}`
        ).then((res) => res.data);
    }
    async getOtotransPartnerList(): Promise<OtotransPartnerResult[]> {
        return await httpCommon.get<OtotransPartnerResult[]>(
            `/global/partner/ototrans?`
        ).then((res) => res.data);
    }

    async exportOtotransTransaksiList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerid: string,
        sortBy: string,
        dateType: string
    ): Promise<void> {
        const a = document.createElement("a");
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerid,
            sortBy,
            dateType,
        });
        a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/ototrans/transaction/export?${params}`;
        a.click();
    }

    async getSolusiPayTransaksiList(
        fromDate: string,
        toDate: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number
    ): Promise<SolusiPayTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<SolusiPayTransaksiResult>(
            `/solusipay/transaction?${params}`
        ).then((res) => res.data);
    }

    async getSolusiPayPartnerList(): Promise<SolusiPayPartnerResult[]> {
        return await httpCommon.get<SolusiPayPartnerResult[]>(
            `/global/partner/solusipay?`
        ).then((res) => res.data);
    }
    async exportSolusiPayTransaksiList(
        fromDate: string,
        toDate: string,
        partnerid: string
    ): Promise<void> {
        const a = document.createElement("a");
        const params = new URLSearchParams({
            toDate,
            fromDate,
            partnerid,
        });
        a.href = `http://117.54.12.140:8080/dashboard-ids/webresources/solusipay/transaction/export?${params}`;
        a.click();
    }

    async getUserAksesList(
        pageSize: number,
        pageNumber: number
    ): Promise<UserAksesResult> {
        const params = new URLSearchParams({
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<UserAksesResult>(
            `/users/role?${params}`
        ).then((res) => res.data);
    }
    async getUserAksesDetail(role_id: string): Promise<UserAksesDetailResult> {
        return await httpCommon.get<UserAksesDetailResult>(
            `/users/${role_id}`
        ).then((res) => res.data);
    }
    async getUserAksesProductList(
        roleId: string,
        pageSize: number,
        pageNumber: number
    ): Promise<UserAksesProdukListResult> {
        const params = new URLSearchParams({
            roleId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<UserAksesProdukListResult>(
            `/users/menu?${params}`
        ).then((res) => res.data);
    }

    async getAccountActivationStepOne(): Promise<AccountActivationStepOneForm> {
        const data: AccountActivationStepOneForm = {
            idsServices: ["1"],
            serviceType: "1",
        };

        return await new Promise<AccountActivationStepOneForm>((res) =>
            setTimeout(() => {
                res(data);
            }, 500)
        );
    }

    async getAccountActivationStepTwo(
        type: "corporate" | "personal"
    ): Promise<AccountActivationStepTwoForm> {
        return await httpCommon.get<AccountActivationStepTwoForm>(
            `/activation/${type === "corporate" ? "corporate" : "personal"}`,
            {
                headers,
            }
        ).then((res) => res.data);
    }

    async getAccountActivationStepThree(): Promise<
        AccountActivationStepThreeForm[]
    > {
        return await httpCommon.get<AccountActivationStepThreeForm[]>(
            `/activation/shareholder`,
            { headers }
        ).then((res) => res.data);
    }

    async getAccountActivationStepFour(): Promise<
        AccountActivationStepFourForm[]
    > {
        return await httpCommon.get<AccountActivationStepFourForm[]>(
            `/activation/pic`,
            { headers }
        ).then((res) => res.data);
    }

    async getAccountActivationStepFive(): Promise<
        AccountActivationStepFiveForm
    > {
        return await httpCommon.get<AccountActivationStepFiveForm>(
            `/activation/bank`,
            { headers }
        ).then((res) => {
            const checkId = typeof res.data.id;
            if (
                (checkId === "number" || checkId === "string") &&
                res.data.id !== 0
            ) {
                return { ...res.data };
            }
            return {
                ...res.data,
                id: "",
                identityType: "",
            };
        });
    }

    async getAccountActivationImage(path: string): Promise<any> {
        const param = new URLSearchParams({
            path,
        });
        return await httpCommon.get<any>(`/activation/download?${param}`, {
            headers: {
                ...headers,
                "Content-Type": "image/jpeg",
            },
            responseType: "arraybuffer",
        }).then((res) => {
            const data = `data:${
                res.headers["Content-Type"]
            };base64,${Buffer.from(res.data, "binary").toString("base64")}`;
            return data;
        });
    }

    async postAccountActivationStepOne(
        body: AccountActivationStepOneForm
    ): Promise<any> {
        return await new Promise<any>((res) =>
            setTimeout(() => res({ object: "berhasil", status: 200 }), 300)
        );
        // return await httpCommon.post<AccountActivationResult>(`/gedhang`, body).then(res => res.data)
    }

    async postAccountActivationStepTwo(
        type: "personal" | "corporate",
        body: AccountActivationStepTwoForm
    ): Promise<any> {
        const {
            account,
            businessAddress,
            corporate,
            personal,
            personalAddress,
        } = body;

        const bodyCorporate = { account, businessAddress, corporate };
        const bodyPersonal = {
            account,
            businessAddress,
            personal,
            personalAddress,
        };

        return await httpCommon.post<any>(
            `/activation/${type === "personal" ? "personal" : "corporate"}`,
            type === "personal" ? bodyPersonal : bodyCorporate,
            {
                headers,
            }
        ).then((res) => res.data);
    }

    async postAccountActivationStepThree(
        body: AccountActivationStepThreeForm[]
    ): Promise<any> {
        return await httpCommon.post<any>(`/activation/shareholder`, body, {
            headers,
        }).then((res) => res.data);
    }

    async postAccountActivationStepFour(
        body: AccountActivationStepFourForm[]
    ): Promise<any> {
        return await httpCommon.post<any>(`/activation/pic`, body, {
            headers,
        }).then((res) => res.data);
    }

    async postAccountActivationStepFive(
        body: AccountActivationStepFiveForm
    ): Promise<any> {
        return await httpCommon.post<any>(`/activation/bank`, body, {
            headers,
        }).then((res) => res.data);
    }

    async postAccountActivationStepTwoImage(
        fileImage: File,
        fileImageSelfie: File
    ): Promise<any> {
        const formData = new FormData();
        formData.append("fileIdentity", fileImage);
        formData.append("fileSelfie", fileImageSelfie);
        return await httpCommon.post<any>("/activation/upload", formData, {
            headers: {
                ...headers,
                "Content-type": "multipart/form-data",
            },
        }).then((res) => res.data);
    }

    async postAccountActivatoinStepSixFile(
        file: File,
        documentType: string
    ): Promise<any> {
        const formData = new FormData();
        formData.append("file", file);
        return await httpCommon.post<any>(
            `/activation/document?type=${documentType}`,
            formData,
            {
                headers: {
                    ...headers,
                    "Content-type": "multipart/form-data",
                },
            }
        );
    }

    async deleteShareholder(
        body: AccountActivationStepThreeForm
    ): Promise<any> {
        return await httpCommon.delete<any>(`/activation/shareholder`, {
            headers,
            data: body,
        });
    }

    async getProvinces(): Promise<Array<AddressResult>> {
        const { Authorization } = { ...headers };

        return await httpCommon.get<Array<AddressResult>>(
            `/activation/postal/province`,
            {
                headers: {
                    Authorization,
                },
            }
        ).then((res) => res.data);
    }

    async getCities(
        districtId: string | number
    ): Promise<Array<AddressResult>> {
        const { Authorization } = { ...headers };

        return await httpCommon.get<Array<AddressResult>>(
            `/activation/postal/city?id=${districtId}`,
            {
                headers: {
                    Authorization,
                },
            }
        ).then((res) => res.data);
    }

    async getDistricts(cityId: string | number): Promise<Array<AddressResult>> {
        const { Authorization } = { ...headers };

        return await httpCommon.get<Array<AddressResult>>(
            `/activation/postal/subdistrict?id=${cityId}`,
            {
                headers: {
                    Authorization,
                },
            }
        ).then((res) => res.data);
    }

    async getVillages(
        districtId: string | number
    ): Promise<Array<AddressResult>> {
        const { Authorization } = { ...headers };

        return await httpCommon.get<Array<AddressResult>>(
            `/activation/postal/village?id=${districtId}`,
            {
                headers: {
                    Authorization,
                },
            }
        ).then((res) => res.data);
    }
    async putEditMenuAkses(body: any): Promise<any> {
        return await httpCommon.put<any>(`/users/access`, body).then(
            (res) => res.data
        );
    }
    async getManajemenPengguna(
        roleId: string,
        pageSize: number,
        pageNumber: number
    ): Promise<ManajemenPenggunaResult> {
        const params = new URLSearchParams({
            roleId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<ManajemenPenggunaResult>(
            `/users/menu?${params}`
        ).then((res) => res.data);
    }

    async getMerchantTransaksi(
        fromDate: string,
        toDate: string,
        pageSize: number,
        pageNumber: number
    ): Promise<MerchantTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
        });
        return await httpCommon.get<MerchantTransaksiResult>(
            `/merchant/transaksi?${params}`
        ).then((res) => res.data);
    }

    async getLookup(type: string, lang: string): Promise<Array<LookupResult>> {
        const params = new URLSearchParams({
            type,
            lang,
        });

        const { Authorization } = { ...headers };

        return await httpCommon.get<Array<LookupResult>>(
            `/activation/lookup/list?${params}`,
            {
                headers: {
                    Authorization,
                },
            }
        ).then((res) => res.data);
    }

    async getOtotransBulkList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number,
        sortBy: string,
        dateType: string
    ): Promise<OtotransTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
            sortBy,
            dateType,
        });
        return await httpCommon.get<OtotransTransaksiResult>(
            `/ototrans/transaction?${params}`
        ).then((res) => res.data);
    }

    async exportMerchatOnboardingTransaksi(
        fromDate: string,
        toDate: string
    ): Promise<any> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
        });
        return await httpCommon.get<any>(`/merchant/export?${params}`).then(
            (res) => res.data
        );
    }
    async MerchantTransaksiRefund(
        originalReferenceNo: string,
        amount: string,
        reason: string
    ): Promise<string> {
        console.log("dari api", [originalReferenceNo, amount, reason]);
        return await httpCommon.post<string>(`/merchant/refund`, {
            originalReferenceNo: originalReferenceNo,
            amount: amount,
            reason: reason,
        }).then((res) => res.data);
    }
    async getMerchantRefund(
        fromDate?: string,
        toDate?: string,
        pageSize?: number,
        pageNumber?: number
    ): Promise<MerchantRefundResult> {
        const params = new URLSearchParams();
        if (fromDate && toDate) {
            params.append("fromDate", fromDate);
            params.append("toDate", toDate);
        }
        if (pageSize !== undefined) {
            params.append("pageSize", pageSize.toString());
        }
        if (pageNumber !== undefined) {
            params.append("pageNumber", (pageNumber + 1).toString());
        }

        return await httpCommon.get<MerchantRefundResult>(
            `/merchant/refund?${params}`,
            {
                headers,
            }
        ).then((res) => res.data);
    }
    async exportMerchantOnboardingRefund(fromDate: string, toDate: string): Promise<any> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
        });
        return await httpCommon.get<any>(`/merchant/refund/export?${params}`).then(
            (res) => res.data
        );
    }
}
