import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SetorkuApi from "data/main/setorkuApi";
import SetorkuDaftarKodeResult from "domain/entity/main/structure/SetorkuDaftarKodeResult";

interface iData {
  fromDate: string;
  toDate: string;
  partnerId: string;
  pageSize: number;
  pageNumber: number;
}

interface iState {
  data: SetorkuDaftarKodeResult;
  loading: boolean;
  error: boolean;
}

export const setorkuDaftarKodeAction = createAsyncThunk(
  "setorku/getSetorkuDaftarKode",
  async (data: iData) => {
    const {
      fromDate,
      toDate,
      partnerId,
      pageSize,
      pageNumber,
    } = data;
    try {
      const api = new SetorkuApi();
      const response = await api.getSetorkuDaftarKodeList(
        fromDate,
        toDate,
        partnerId,
        pageSize,
        pageNumber,
        );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 1,
    totalRows: 6,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "setorku-daftar-kode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setorkuDaftarKodeAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        setorkuDaftarKodeAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(setorkuDaftarKodeAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

const setorkuDaftarKodeReducer = slice.reducer;
export default setorkuDaftarKodeReducer;
