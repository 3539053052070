import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from 'formik';
import DateRangeComponent from 'presentation/components/DateRangeComponent';
import ModalContainer from 'presentation/components/ModalContainer';
import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { start } from 'repl';

interface Props {
    header?: string;
    subHeader?: string;
    open: boolean;
    onClose: () => void;
    applyButton: (data: any) => void;
    dateData: {
        startDate: Date;
        endDate: Date;
        setStartDate: Dispatch<SetStateAction<Date>>;
        setEndDate: Dispatch<SetStateAction<Date>>;
    }
} 

const FilterModal: React.FC<Props> = (props: Props) => {
    const {t} = useTranslation();
    const {handleSubmit, control} = useForm()

    return (
        <ModalContainer
            header={props.header ?? "Header"}
            subHeader={props.subHeader ?? "SubHeader"}
            open={props.open}
            onClose={props.onClose}
            applyButton={handleSubmit(props.applyButton)}
            cancelButton={props.onClose}
        >
            <DateRangeComponent
                startDate={props.dateData.startDate}
                endDate={props.dateData.endDate}
                setStartDate={props.dateData.setStartDate}
                setEndDate={props.dateData.setEndDate}
            />
            <FormControl>
              <Controller
                name="filterText"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("setorku.product.secret_key")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                )}
              />
            </FormControl>
        </ModalContainer>
    );
}

export default FilterModal;