import httpCommon from "datas/httpCommon";
import OtotransPartnerResult from "domain/entity/main/structure/OtotransPartnerResult";
import OtotransTransaksiResult from "domain/entity/main/structure/OtotransTransaksiResult";
import ototransRepository from "domain/repository/main/ototransRepository";

export class OtotransApi implements ototransRepository {
    async getOtotransTransaksiList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerId: string,
        pageSize: number,
        pageNumber: number,
        sortBy: string,
        dateType: string
    ): Promise<OtotransTransaksiResult> {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerId,
            pageSize: String(pageSize),
            pageNumber: String(pageNumber),
            sortBy,
            dateType,
        });
        return await httpCommon.get<OtotransTransaksiResult>(
            `/ototrans/transaction?${params}`
        ).then((res) => res.data);
    }
    async getOtotransPartnerList(): Promise<OtotransPartnerResult[]> {
        return await httpCommon.get<OtotransPartnerResult[]>(
            `/global/partner/ototrans?`
        ).then((res) => res.data);
    }

    async exportOtotransTransaksiList(
        fromDate: string,
        toDate: string,
        status: string,
        partnerid: string,
        sortBy: string,
        dateType: string
    ) {
        const params = new URLSearchParams({
            toDate,
            fromDate,
            status,
            partnerid,
            sortBy,
            dateType,
        });
        return httpCommon.get(`/ototrans/transaction/export?${params}`).then(res => res.data)
    }

}