import React from "react";
import { Routes, Route, useLocation, useRoutes } from "react-router-dom";
import LoginComponent from "presentation/containers/auth/login/Login";
import MerchantTransaksiComponent from "presentation/containers/main/merchatOnboarding/transaksi/MerchantTransaksiComponent";
import MerchantRefundComponent from "presentation/containers/main/merchatOnboarding/refund/MerchantRefundComponent";
import HomeComponent from "presentation/containers/main/home/HomeComponent";
import OtotransBulkComponent from "presentation/containers/main/ototrans/bulk-disbursement/OtotransBulkComponent";
import OtotransTransaksiComponent from "presentation/containers/main/ototrans/transaksi/OtotransTransaksi";
import SetorkuDaftarKodeComponent from "presentation/containers/main/setorku/daftar-kode/SetorkuDaftarKodeComponent";
import SetorkuRekonComponent from "presentation/containers/main/setorku/rekonsiliasi/SetorkuRekonComponent";
import SetorkuTransaksiComponent from "presentation/containers/main/setorku/transaksi/SetorkuTransaksiComponent";
import { SetorkuPartnerComponent } from "presentation/containers/main/setorku/partner/SetorkuPartnerComponent";
import { SetorkuProductComponent } from "presentation/containers/main/setorku/produk/SetorkuProductComponent";
import DownloadFTPComponent from "presentation/containers/main/multifinance/download-ftp/DownloadFTPComponent";
import MultifinanceUploadComponent from "presentation/containers/main/multifinance/upload/MultifinanceUploadComponent";
import MultifinanceRekonTransaksiComponent from "presentation/containers/main/multifinance/rekon-transaksi/RekonTransaksiComponent";
import MultifinanceHistoryTransaksiComponent from "presentation/containers/main/multifinance/history-transaksi/historyTransaksiComponent";
import DanaHistoryTransactionComponent from "presentation/containers/main/dana/history-transaction/DanaTransactionHistoryComponent";
import DanaTransactionSuspectComponent from "presentation/containers/main/dana/transaction-suspect/DanaTransactionSuspectComponent";
import { navMain } from "./ProductRouters";
import { getUser } from "utils/storage/LocalStorage";
import PageNotFoundComponent from "presentation/containers/main/pageNotFound/PageNotFound";
import PPOBTransactionComponent from "presentation/containers/main/ppob/transaction/TransactionComponent";
import PPOBPartnerComponent from "presentation/containers/main/ppob/partner/PPOBPartnerComponent";
import PPOBProductComponent from "presentation/containers/main/ppob/product/ProductComponent";
import SetorkuBulkUploadComponent from "presentation/containers/main/setorku/bulk-upload/BulkUploadComponent";
import PPOBGroupComponent from "presentation/containers/main/ppob/group/groupComponent";
import OtotransPartnerComponent from "presentation/containers/main/ototrans/partner/PartnerComponent";
import DisiPettyCashComponent from "presentation/containers/main/disi/PettyCash/PettyCashComponent";
import DisiDetailPettyCashComponent from "presentation/containers/main/disi/DetailPettyCash/DetailPettyCashComponent";
import DisiPartnerDepositComponent from 'presentation/containers/main/disi/PartnerDeposit/ParterDepositComponent'
import DisiMerchantDepositComponent from "presentation/containers/main/disi/MerchantDeposit/MerchantDepositComponent";
import DisiDetailMerchantDepositComponent from 'presentation/containers/main/disi/DetailMerchantDeposit/DetailMerchantDepositComponent'

import PPOBPaymentMethodComponent from "presentation/containers/main/ppob/paymentMethod/PPOBPaymentMethodComponent";
import PPOBCollectingAgentComponent from "presentation/containers/main/ppob/collectingAgent/PPOBCollectingAgentComponent";
import PPOBDetailCollectingAgentComponent from "presentation/containers/main/ppob/detailCollectingAgent/PPOBDetailCollectingAgentComponent";
import PPOBSellingPriceComponent from "presentation/containers/main/ppob/sellingPrice/PPOBSellingPriceComponent";
import SetorkuDashboardComponent from "presentation/containers/main/setorku/dashboard/DashboardComponent";
import OtotransBankAccountComponent from "presentation/containers/main/ototrans/bank-account/BankAccountComponent";

const Router = () => {
  const routes = [
    {
      path: "dashboard/MerchantOnboarding/transaksi-merchantonboarding",
      element: <MerchantTransaksiComponent />,
    },
    {
      path: "dashboard/MerchantOnboarding/refund-merchantonboarding",
      element: <MerchantRefundComponent />,
    },
    { path: "dashboard/ototrans/disbursment-bulk", element: <OtotransBulkComponent /> },
    { path: "dashboard/ototrans/bankacc", element: <OtotransBankAccountComponent /> },
    { path: "dashboard/ototrans/disbursment-trxall", element: <OtotransTransaksiComponent /> },
    { path: "dashboard/ototrans/ototrans-partner", element: <OtotransPartnerComponent />},
    { path: "dashboard/Setorku/dashboard", element: <SetorkuDashboardComponent />},
    { path: "dashboard/Setorku/listpaymentcode", element: <SetorkuDaftarKodeComponent/>},
    { path: "dashboard/Setorku/rekonsiliasi", element: <SetorkuRekonComponent/>},
    { path: "dashboard/Setorku/transaksi", element: <SetorkuTransaksiComponent/>},
    { path: "dashboard/Setorku/partner", element: <SetorkuPartnerComponent/>},
    { path: "dashboard/Setorku/product", element: <SetorkuProductComponent/>},
    { path: "dashboard/Setorku/bulkupload", element: <SetorkuBulkUploadComponent/>},
    { path: "dashboard/Multifinance/rekon-transaksi", element: <MultifinanceRekonTransaksiComponent/>},
    { path: "dashboard/Multifinance/download-ftp", element: <DownloadFTPComponent/>},
    { path: "dashboard/Multifinance/upload", element: <MultifinanceUploadComponent/>},
    { path: "dashboard/Multifinance/history-transaksi", element: <MultifinanceHistoryTransaksiComponent/>},
    { path: "dashboard/DANA/history-transaction", element: <DanaHistoryTransactionComponent/>},
    { path: "dashboard/DANA/transaction-suspect", element: <DanaTransactionSuspectComponent/>},
    { path: "dashboard/PPOB/transaksi", element: <PPOBTransactionComponent />},
    { path: "dashboard/PPOB/partner-ppob", element: <PPOBPartnerComponent />},
    { path: "dashboard/PPOB/payment-method", element: <PPOBPaymentMethodComponent />},
    { path: "dashboard/PPOB/Agent", element: <PPOBCollectingAgentComponent />},
    { path: "dashboard/PPOB/Agent/:id", element: <PPOBDetailCollectingAgentComponent />},
    { path: "dashboard/PPOB/Biller", element: <PPOBProductComponent />},
    { path: "dashboard/PPOB/Group", element: <PPOBGroupComponent />},
    { path: "dashboard/PPOB/selling-price", element: <PPOBSellingPriceComponent />},
    { path: "dashboard/disi/petty-cash", element: <DisiPettyCashComponent/>},
    { path: "dashboard/disi/detail-petty-cash", element: <DisiDetailPettyCashComponent/>},
    { path: "dashboard/disi/partner-deposit", element: <DisiPartnerDepositComponent/>},
    { path: "dashboard/disi/merchant-deposit", element: <DisiMerchantDepositComponent/>},
    { path: "dashboard/disi/detail-merchant-deposit", element: <DisiDetailMerchantDepositComponent/>},
    { path: "*", element: <PageNotFoundComponent />}
  ];

  return (
    <>
      <Routes>
        <Route index element={<LoginComponent />} />
        <Route path="dashboard" element={<HomeComponent />} />
        {routes.map((route, i) => (
          <Route key={i} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
};

export default Router;