import { SelectChangeEvent } from "@mui/material/Select";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { Stack, Button } from "@mui/material";
import dayjs from "dayjs";
import { setorkuAction } from "utils/store/main/setorku/transaksi";
import { setorkuPartnerAction } from "utils/store/main/setorku/setorkuPartner";
import SetorkuApi from "datas/setorku/SetorkuApi";
import { SetorkuInteractor } from "domains/interactor/main/setorku/SetorkuInteractor";
import { exportCSV } from "domain/hooks/exportCSV";
import { useForm } from "react-hook-form";
//modal-filter

const useTransaksiViewModel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.setorku);
  const {handleSubmit,register, setValue} = useForm()


  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(6);
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());
  const [partnerId, setPartnerId] = React.useState("ALL");
  const [status, setStatus] = React.useState("ALL");
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleModal = () => setOpen(!open);

  const fetchData = async (
    pageSize: number,
    page: number,
    status = "ALL",
    partnerId = "ALL",
    startDate = dayjs(new Date()).format("DD-MM-YYYY"),
    endDate = dayjs(new Date()).format("DD-MM-YYYY")
  ) => {
    dispatch(
      setorkuAction.transaksiListAction({
        fromDate: startDate,
        toDate: endDate,
        status,
        partnerId,
        pageSize,
        pageNumber: page,
      })
    );
  };

  const fetchPartner = async () => {
    dispatch(
      setorkuPartnerAction({
        fromDate: dayjs(new Date()).format("DD-MM-YYYY"),
        toDate: dayjs(new Date()).format("DD-MM-YYYY"),
      })
    );
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "No", flex: 1, minWidth: 100 },
    {
      field: "paymendCode",
      headerName: t("setorku.transaction.payment_code") || "Payment Code",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "paymentDate",
      headerName: t("setorku.transaction.payment_date") || "Payment Date",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "amount",
      headerName: t("setorku.transaction.amount") || "Amount",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "reciptNo",
      headerName: t("setorku.transaction.receipt_number") || "Receipt Number",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "partnerId",
      headerName: t("setorku.transaction.partner_id") || "Partner ID",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "trackingReff",
      headerName: t("setorku.transaction.tracking_ref") || "Tracking Ref",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: t("setorku.transaction.status") || "Status",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "",
      headerName: "",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => setShowConfirmation(true)}
          >
            {t("setorku.transaction.update") || "Update"}
          </Button>
        </Stack>
      ),
    },
  ];

  const formFilterData = [
    {
      label: t("setorku.product.id_product"),
      value: {
        startDate: startDate,
        setStartDate: setStartdate,
        endDate: endDate,
        setEndDate: setEndate,
      },
      type: "date",
    },
    {
      label: t("setorku.product.status"),
      type: "select",
      value: ["ALL", ...selector.partner.data.data.map((item) => item.partnerId)],
      dataId: "partner",
    },
    {
      label: t("setorku.product.status"),
      type: "select",
      value: ["ALL","SUCCESS", "FAIL", "PENDING"],
      dataId: "status",
    },
  ]

  const clearFilter = () => {
    setPartnerId("ALL");
    setStatus("ALL");
    fetchData(
      pageSize,
      page,
      status,
      partnerId,
      dayjs(new Date()).format("DD-MM-YYYY"),
      dayjs(new Date()).format("DD-MM-YYYY")
    );
    setOpen(false);
  };

  const onSubmit = (data:any): void => {
    console.log(data)
    fetchData(
      pageSize,
      page,
      data.status,
      data.partner,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY")
    );
    setOpen(false);
  };
  React.useEffect(() => {
    fetchPartner();
  }, []);

  React.useEffect(() => {
    fetchData(
      pageSize,
      page,
      status,
      partnerId,
      dayjs(startDate).format("DD-MM-YYYY"),
      dayjs(endDate).format("DD-MM-YYYY")
    );
  }, [pageSize, page]);

  const onExpor = async () => {
    const interactor = new SetorkuInteractor(new SetorkuApi());
    const result = await interactor.exportTransaction({
      fromDate: dayjs(startDate).format("DD-MM-YYYY"),
      toDate: dayjs(endDate).format("DD-MM-YYYY"),
      status,
      partnerId,
    });
    exportCSV(result, "setorku-transaction");
  };

  return {
    data: selector,
    page,
    setPage,
    pageSize,
    setPageSize,
    open,
    handleModal,
    onSubmit,
    columns,
    onExpor,
    t,
    showConfirmation,
    setShowConfirmation,
    formFilterData,
    clearFilter,
  };
};

export default useTransaksiViewModel;
