import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import ConstantsResult from "domains/entity/main/ConstantResult";
import PettyCashUseCase from "domains/interactor/main/disi/PettyCash";

type iState = {
    data: ConstantsResult;
    loading: boolean;
    error: any;
}

const initialState: iState = {
    data: [],
    loading: false,
    error: ""
}

export const pettyCashAccountabilityStatusAction = createAsyncThunk('petty-cash-accountability-status-action', async (_, { rejectWithValue }) => {
    try {

        const useCase = new PettyCashUseCase(new DisiApi())

        return await useCase.getPettyCashAccountabilityStatus()
    } catch (error) {
        const { response } = error;
        if (!response) {
            throw error
        }
        return rejectWithValue(response.data)
    }
})

const pettyCashAccountabilityStatusSlice = createSlice({
    name: "petty-cash-accountability-status-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(pettyCashAccountabilityStatusAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(pettyCashAccountabilityStatusAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(pettyCashAccountabilityStatusAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const pettyCashAccountabilityStatusReducer = pettyCashAccountabilityStatusSlice.reducer

export default pettyCashAccountabilityStatusReducer