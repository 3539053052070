import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisiApi from "datas/disi/DisiApi";
import PettyCashHeaderResult from "domains/entity/main/disi/PettyCashHeaderResult";
import PettyCashUseCase from "domains/interactor/main/disi/PettyCash";

type iState = {
    data: PettyCashHeaderResult,
    loading: boolean;
    error: any
}

const initialState: iState = {
    data: {
        partnerId: '',
        saldo: "",
        saldoPertanggungJawab: ""
    },
    loading: false,
    error: ""
}

export const pettyCashHeaderAction = createAsyncThunk('petty-cash-header-action', async (_, { rejectWithValue }) => {
    try {
        const useCase = new PettyCashUseCase(new DisiApi())

        return await useCase.getPettyCashHeaderData()
    } catch (error) {
        const { response } = error
        if (!response) {
            throw error;
        }

        return rejectWithValue(response.data)
    }
})

const pettyCashHeaderSlice = createSlice({
    name: "petty-cash-header-slice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(pettyCashHeaderAction.pending, state => {
            state.loading = true;
            state.error = ""
        })
        builder.addCase(pettyCashHeaderAction.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = ""
        })
        builder.addCase(pettyCashHeaderAction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

const pettyCashHeaderReducer = pettyCashHeaderSlice.reducer

export default pettyCashHeaderReducer