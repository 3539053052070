import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SetorkuTransactionApi from "datas/setorku/SetorkuApi";
import { SetorkuExportTransactionParams, SetorkuTransactionParams, SetorkuTransactionResult } from "domains/entity/main/setorku/transaction";
import { SetorkuInteractor } from "domains/interactor/main/setorku/SetorkuInteractor";

interface iState {
  data: SetorkuTransactionResult;
  loading: boolean;
  error: boolean;
}

const transaksiListAction = createAsyncThunk(
  "setorku/transaksi",
  async (data: SetorkuTransactionParams) => {
    const {
      fromDate,
      toDate,
      status,
      partnerId,
      pageNumber,
      pageSize,
    } = data;
    try {
        const interactor = new SetorkuInteractor(new SetorkuTransactionApi());
        const data = await interactor.getTransaction({
            fromDate,
            toDate,
            status,
            partnerId,
            pageSize,
            pageNumber,
        })
        console.log('data',data)
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: iState = {
  data: {
    data: [],
    pageLimit: 1,
    totalRows: 6,
  },
  loading: false,
  error: false,
};

const slice = createSlice({
  name: "setorku-transaksi-list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(transaksiListAction.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = false;
      })
      .addCase(
        transaksiListAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log("fulfilled", action);
          state.data = action.payload;
          state.error = false;
          state.loading = false;
        }
      )
      .addCase(transaksiListAction.rejected, (state, action) => {
        console.log("rejected", action);
        state.loading = false;
        state.error = true;
      });
  },
});

export const setorkuAction = {
  transaksiListAction,
}

const setorkuTransaksiListReducer = slice.reducer;
export default setorkuTransaksiListReducer;
