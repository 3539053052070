import { PettyCashBodyParams, PettyCashCreateBody } from "domains/entity/main/disi";
import DisiRepository from "domains/repository/main/disi/DisiRepository";


export default class PettyCashUseCase {
    constructor(private repository: DisiRepository) { }

    async getPettyCashHeaderData() {
        return this.repository.getPettyCashHeaderData()
    }

    async getPettyCashBodyData(args: PettyCashBodyParams) {
        return this.repository.getPettyCashBodyData(args)
    }

    async getPettyCashType() {
        return this.repository.getPettyCashType()
    }

    async getPettyCashStatus() {
        return this.repository.getPettyCashStatus()
    }

    async getPettyCashAccountabilityStatus() {
        return this.repository.getPettyCashAccountabilityStatus()
    }

    async getPettyCashChannel() {
        return this.repository.getPettyCashChannel()
    }

    async postPettyCashCreateData(body: PettyCashCreateBody) {
        const formData = new FormData()
        const { file, ...rest } = body

        if (file) {
            formData.append("file", file);
        }

        formData.append("JSON", JSON.stringify(rest))

        return this.repository.postPettyCash(formData)
    }

    async exportPettyCash(args: Omit<PettyCashBodyParams, "pageNumber" | "pageSize">) {
        return this.repository.exportPettyCash(args)
    }
}