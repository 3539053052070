/* eslint-disable react/react-in-jsx-scope */
import { useAppSelector } from "domain/hooks/redux-hook";
import MerchantRefundComponent from "presentation/containers/main/merchatOnboarding/refund/MerchantRefundComponent";
import MerchantTransaksiComponent from "presentation/containers/main/merchatOnboarding/transaksi/MerchantTransaksiComponent";
import { useSelector } from "react-redux";
import { getUser } from "utils/storage/LocalStorage";
import { Routers, Routes } from "./RouterInterface";
import HomeComponent from "presentation/containers/main/home/HomeComponent";

const user = JSON.parse(getUser()|| '{}')
const menuFromStorage = user?.parentMenuList

export const navMain = [
    {
        key: "home",
        name: "Home",
        path: "/dashboard",
        // component: () => <HomeComponent />,
    }
]

const navProduct = [
    {
        key: "merchantonboarding",
        name: "Merchant On Boarding",
        path: "/merchant",
        exclude: true,
        childs: [
            {
                key: "MerchantOnBoarding.transaksi",
                name: "Transaksi",
                routeName: "merchant_refund",
                path: "/transaksi",
            },
            {
                key: "MerchantOnBoarding.refund",
                name: "Transaksi Refund",
                routeName: "merchant_refund",
                path: "/refund",
            },
        ],
    },
    {
        key: "ototrans",
        name: "Ototrans",
        path: "/ototrans",
        exclude: true,
        childs: [
            {
                key: "Ototrans.transaksi",
                name: "Transaksi",
                routeName: "transaksi_setorku",
                path: "/transaksi",
            },
            {
                key: "Ototrans.bulk",
                name: "Bulk",
                routeName: "rekon_setorku",
                path: "/bulk",
            },
        ],
    },
    {
        key: "setorku",
        name: "Setorku",
        path: "/setorku",
        exclude: true,
        childs: [
            {
                key: "SetorKu.daftarKode",
                name: "Daftar Kode",
                routeName: "daftarkode_setorku",
                path: "/daftar-kode",
            },
            {
                key: "SetorKu.rekonsiliasi",
                name: "Rekonsiliasi",
                routeName: "rekon_setorku",
                path: "/rekonsiliasi",
            },
            {
                key: "SetorKu.transaksi",
                name: "Transaksi",
                routeName: "transaksi_setorku",
                path: "/transaksi",
            },
            {
                key: "SetorKu.partner",
                name: "Partner",
                routeName: "partner_setorku",
                path: "/partner",
            },
            {
                key: "SetorKu.produk",
                name: "Produk",
                routeName: "produk_setorku",
                path: "/produk",
            },
        ],
    },
    {
        key: "multifinance",
        name: "Multifinance",
        path: "/multifinance",
        exclude: true,
        childs: [
            {
                key: "Multifinance.downloadFTP",
                name: "Download FTP",
                routeName: "download_multifinance",
                path: "/download-ftp",
            },
            {
                key: "Multifinance.historyTransaksi",
                name: "History Transaksi",
                routeName: "history_multifinance",
                path: "/history-transaksi",
            },
            {
                key: "Multifinance.rekonTransaksi",
                name: "Rekon Transaksi",
                routeName: "rekonTransaksi_multifinance",
                path: "/rekon-transaksi",
            },
            {
                key: "Multifinance.upload",
                name: "Upload",
                routeName: "upload_multifinance",
                path: "/upload",
            },
        ]
    },
    {
        key: "dana",
        name: "Dana",
        path: "/dana",
        exclude: true,
        childs: [
            {
                key: "Dana.historyTransaction",
                name: "History Transaction",
                routeName: "histroyTransaction_dana",
                path: "/history-transaction",
            },
            {
                key: "Dana.transactionSuspect",
                name: "Transaction Suspect",
                routeName: "transactionSuspect_dana",
                path: "/transaction-suspect",
            },
        ]
    },
]

const data = menuFromStorage?.map((item:any) => item.parentPrefix.toLowerCase()) || []
export const productRouters = navProduct.filter((item:any) => data.includes(item.key))|| []