import httpCommon from "datas/httpCommon";
import { AuthLoginRequest, AuthLoginResult } from "domains/entity/auth/login";
import { AuthLoginRepository } from "domains/repository/auth/LoginRepository";

export default class LoginApi implements AuthLoginRepository {
    async login(data: AuthLoginRequest): Promise<AuthLoginResult> {
        return await httpCommon
            .post<AuthLoginResult>(`/login`,data)
            .then((res) => res.data);
    }
}