import { ExportMerchantDepositList } from "domains/entity/main/disi";
import DisiRepository from "domains/repository/main/disi/DisiRepository";


export default class MerchantDepositUseCase {
    constructor(private repository: DisiRepository) { }

    getMerchantDepositList(pageSize: number, pageNumber: number, merchantNm: string, partnerNm: string, status: string) {
        return this.repository.getMerchantDepositList(pageSize, pageNumber, merchantNm, partnerNm, status)
    }

    getTotalSaldo() {
        return this.repository.getMerchantDepositSaldo()
    }

    exportMerchantDepositList(params: ExportMerchantDepositList) {
        return this.repository.exportmerchantDepositList(params)
    }

    getMerchantDepositBankList(params: "bank" | "bankVa") {
        return this.repository.getMerchantDepositBankList(params)
    }

    getMerchantDepositPartner() {
        return this.repository.getMerchantDepositPartner()
    }

    postMerchantDepositAdd(body: any) {
        return this.repository.postMerchantDepositAdd(body)
    }
}