import React from "react";
import { Modal, Button, Box, Typography, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

interface iConfirmationModal {
  open: boolean;
  onClose: () => void;
  type?: string | "update" | "delete" | "refund";
  contentText?: string;
  action?: any;
}

const ConfirmationModalComponent: React.FC<iConfirmationModal> = ({
  open,
  onClose,
  type,
  contentText,
  action,
}) => {
  const { t }: any = useTranslation();
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    bgcolor: "white",
    borderRadius: "20px",
    p: 4,
  };

  const successModal = () => {
    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={style} className="ModelFilter">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Success
          </Typography>
          </Box>
      </Modal>
    );
  };
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style} className="ModelFilter">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {type === "update"
            ? "Update"
            : type === "delete"
            ? "Delete"
            : "Refund"}
        </Typography>
        <Divider
          variant="middle"
          sx={{
            mt: "0 !important",
            mr: "0 !important",
            ml: "0 !important",
            mb: "1rem !important",
          }}
        />
        {/* {children} */}
        <Stack spacing={2} direction="column">
          <Typography
            id="modal-modal-description"
            sx={{
              fontWeight: 400,
              textAlign: "center",
              fontSize: "0.875rem",
              color: "rgba(0, 0, 0, 0.6)",
              mb: "0.5rem",
            }}
          >
            {type === "update"
              ? "Are you sure you want to update this transaction?"
              : type === "delete"
              ? "Are you sure you want to delete this transaction?"
              : "Are you sure you want to refund this transaction?"}
          </Typography>
        </Stack>
        <Stack spacing={2} direction="row">
          <Button
            onClick={onClose}
            variant="text"
            sx={{
              color: "rgba(40, 99, 83, 1)",
              width: "317.5px",
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={action}
            type="submit"
            variant="contained"
            sx={{
              color: "white",
              background: "rgba(40, 99, 83, 1) !important",
              width: "317.5px",
            }}
          >
            {type === "update"
              ? "UPDATE"
              : type === "delete"
              ? "DELETE"
              : "REFUND"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmationModalComponent;
