import AddressRepository from "domains/repository/main/address";


export default class AddressUseCase {
    constructor(private repository: AddressRepository) { }

    getProvinces() {
        return this.repository.getProvinces()
    }

    getDistricts(id: string) {
        return this.repository.getDistricts(id)
    }

    getSubDistricts(id: string) {
        return this.repository.getSubDistricts(id)
    }

    getVillages(id: string) {
        return this.repository.getVillages(id)
    }
}