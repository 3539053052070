import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "domain/hooks/redux-hook";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridColDef } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { addPaymentMethodAction, comboboxPartnerAction, comboboxPaymentMethodAction, editPaymentMethodAction, paymentMethodListAction } from "utils/store/main/ppob/paymentMethod";
import { Stack } from "@mui/system";
import { sellingCategoryListAction, sellingPriceListAction } from "utils/store/main/ppob/sellingPrice";
import { showAddModal, showDeleteModal, showEditModal, showFilterModal, showRefundModal, showUploadModal } from "utils/store/global/modal";

//modal-filter

const usePPOBSellingPriceViewModel = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.main.ppob);
  const modal = useAppSelector((state) => state.global.modal)
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      add: {
        partnerName: "",
        category: "",
        productName: "",
        description: "",
        sellingPrice: "",
        publicPrice: "",
        status: "1",
      },
      edit: {
        partnerName: "",
        category: "",
        productName: "",
        description: "",
        sellingPrice: "",
        publicPrice: "",
        status: "1",
      },
      filter: {
        partner: "",
        paymentMethod: "",
      },
      upload: {
        partner: "",
      }
    },
  });

  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [modalAdd, setModalAdd] = React.useState(false);
  const [modalFilter, setModalFilter] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [startDate, setStartdate] = React.useState<Date>(new Date());
  const [endDate, setEndate] = React.useState<Date>(new Date());

  const columns: GridColDef[] = [
    // {
    //   field: "partnerId",
    //   headerName: t("ppob.selling_price.id_partner"),
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "partnerName",
      headerName: t("ppob.selling_price.partner_name"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "category",
      headerName: t("ppob.selling_price.category"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "productId",
      headerName: t("ppob.selling_price.product_id"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "description",
      headerName: t("ppob.selling_price.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sellingPrice",
      headerName: t("ppob.selling_price.selling_price"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "publicPrice",
      headerName: t("ppob.selling_price.public_price"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "margin",
      headerName: t("ppob.selling_price.margin"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: t("ppob.selling_price.status"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minWidth: 100,
      renderCell: (row) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Button
              variant="text"
              onClick={() => showModalEdit(row.row)}
            >
              <EditIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const modalHandler = (type:string) => {
    switch (type) {
        case "add":
            dispatch(showAddModal(!modal.add));
            break;
        case "edit":
            dispatch(showEditModal(!modal.edit));
            break;
        case "filter":
            dispatch(showFilterModal(!modal.filter));
            break;
        case "delete":
            dispatch(showDeleteModal(!modal.delete));
            break;
        case "refund":
            dispatch(showRefundModal(!modal.refund));
            break;
            case "upload":
            dispatch(showUploadModal(!modal.upload));
            break;
        default:
            break;
    }
}

  const showModalDelete = (data: any) => {
    setModalDelete(true);
  };

  const showModalEdit = (data: any) => {
    console.log(data)
    setModalEdit(true);
    setValue("edit", data.row);
  };

  const submitFilter = (data: any) => {
    console.log(data);
    dispatch(
      paymentMethodListAction({
        pageNumber: page,
        pageSize: pageSize,
        partnerId: data.filter.partner,
        paymentMethod: data.filter.paymentMethod,
      })
    );
    setModalFilter(false);
  };

  const submitAdd = async (data: any) => {
    await dispatch(
      addPaymentMethodAction({
        partnerId: data.add.partnerId,
        paymentMethod: data.add.paymentMethod,
        nominalFee: data.add.nominalFee,
        access: data.add.access,
      })
    );
    reset();
    setModalAdd(false);
    fetchPaymentMethod();
  };

  const submitEdit = async (data: any) => {
    console.log(data)
    await dispatch(
      editPaymentMethodAction({
        partnerId: data.edit.partnerId,
        paymentMethod: data.edit.paymentMethod,
        nominalFee: data.edit.nominal,
        access: data.edit.access,
      })
    );
    reset();
    setModalEdit(false);
    fetchPaymentMethod();
  };

  const fetchPaymentMethod = async () => {
    await dispatch(sellingPriceListAction({
      pageNumber: page,
      pageSize: pageSize,
      partnerId: "",
      categoryId: "",
      productSKUCode: "",
    }))
  };

  const fetchCombobox = async () => {
    await dispatch(sellingCategoryListAction())
  }

  React.useEffect(() => {
    fetchPaymentMethod();
    fetchCombobox()
  }, [page, pageSize]);

  return {
    data: selector,
    page,
    modal,
    modalHandler,
    setPage,
    pageSize,
    setPageSize,
    open,
    startDate,
    endDate,
    setStartdate,
    setEndate,
    columns,
    t,
    modalAdd,
    setModalAdd,
    modalEdit,
    setModalEdit,
    modalFilter,
    setModalFilter,
    modalDelete,
    setModalDelete,
    handleSubmit,
    control,
    submitFilter,
    submitAdd,
    submitEdit
  };
};

export default usePPOBSellingPriceViewModel;
